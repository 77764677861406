import React, { useEffect, useState } from 'react';
import { convertUnderscoreToTitleCase, rSelectMapFun, retreive_rSelectVariables } from '../../../utils/functionalUtils';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createStorageInventory, retrieve_specific_warehouse_storage_types_and_capacities_via_delivery_id } from '../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import * as app_consts from '../../../utils/constants';

const StorageStep = ({ nextStep, setPrevData, prevData }) => {

    const [previewData, setPreviewData] = useState(prevData);
    const [gross_weight, setGrossWeight] = useState("");
    const [delivery_id, setDeliveryId] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState("");
    const dispatch = useDispatch();
    const [warehouse_storage_data, setWarehouseStorageData] = useState([]);


    useEffect(() => {
        setPreviewData(prevData);
        if (prevData)
        {
            // Retrieve Warehouse Storage Type from Delivery ID
            dispatch(retrieve_specific_warehouse_storage_types_and_capacities_via_delivery_id(prevData?.delivery_id));
            setGrossWeight(parseFloat(prevData.gross_weight).toFixed(2));
            setDeliveryId(prevData.delivery_id);
        }
    }, [prevData]);

    // Listen to Changes on Warehouse Storages List
    const storageListListener = useSelector((state) => state.retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryId);
    // 
    useEffect(() => {
        const fetchData = async () => {
            if (storageListListener.data)
            {
                let mappedData = [];

                await Promise.all(
                    storageListListener?.data?.map(async (data_to_map) => {
                        const { label, value } = retreive_rSelectVariables(app_consts.AVAILABLE_STORAGE_LIST_MAP, data_to_map);
                        const result = await rSelectMapFun(label, value);
                        mappedData.push(result);
                    })
                );

                setWarehouseStorageData(mappedData);
            }
        };

        fetchData();
    }, [storageListListener]);

    // Yup Validation
    const storageSchema = yup.object().shape({
        tare_weight: yup.string().required('Tare Weight is Required'),
        deposit_date: yup.string().required('Deposit Date is required'),
        description: yup.string().required('Description is required'),
        storage_type: yup.string().required('Storage Type is Required'),
        offloading_time: yup.string().required('Offlaoding Time is required'),
        detailed_storage_description: yup.string().required('Detailed Storage Description is required'),
    });

    // Formik Validation
    const formik = useFormik({
        initialValues: {
            tare_weight: "",
            deposit_date: '',
            description: '',
            quantity: '',
            storage_type: '',
            offloading_time: '',
            net_weight: '',
            detailed_storage_description: '',
            delivery_id: delivery_id,
        },
        validationSchema: storageSchema,
        onSubmit: async (values) => {
            setError("");
            setLoading(true);
            try
            {
                const storage_params = {
                    tare_weight: values.tare_weight,
                    deposit_date: values.deposit_date,
                    description: values.description,
                    quantity: parseFloat(gross_weight - values.tare_weight).toFixed(2),
                    storage_type: values.storage_type,
                    offloading_time: values.offloading_time,
                    net_weight: parseFloat(gross_weight - values.tare_weight).toFixed(2),
                    detailed_storage_description: values.detailed_storage_description,
                    delivery_id: delivery_id,
                };

                await dispatch(createStorageInventory(storage_params));

                nextStep();

            } catch (error)
            {
                if (error.response)
                {
                    if (error.response.status === 500)
                    {
                        setError("Failed to Store Commodity in Inventory.");
                    }
                    else
                    {
                        setError(error.response.data.message);
                    }
                }
                else
                {
                    setError("Failed to Store Commodity in Inventory.");
                }
            } finally
            {
                setLoading(false);
            }
        },
    });

    // Handle Changes in React Select Field
    const handlRSelectChanges = (option, name) => {
        if (option)
        {
            formik.setFieldValue(name, option.value);
        }
        else
        {
            formik.setFieldValue(name, "");
        }
    };

    // Listen to Changes in Reducer
    const warehouse_receipt_data = useSelector((state) => state.createStorageInventory);
    // 
    useEffect(() => {
        if (warehouse_receipt_data.data)
        {
            setPrevData({ ...prevData, ["warehouse_receipt_data"]: warehouse_receipt_data.data });
        }
    }, [warehouse_receipt_data]);


    const handleOnblur = (name, value) => {
        if (name === "tare_weight")
        {
            if (value > gross_weight)
            {
                formik.setFieldValue(name, "");
            }
            else
            {
                formik.setFieldValue(name, parseFloat(value).toFixed(2));
            }
        }
        else
        {
            if (value)
            {
                formik.setFieldValue(name, parseFloat(value).toFixed(2));
            }
        }
    };

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className="px-4 flex flex-col items-stretch justify-start border-gray-100">
                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                        Inventory Storage {previewData.commodity ? <span className='font-medium text-xl'> - {convertUnderscoreToTitleCase(previewData.commodity)}</span> : ""}
                    </h3>
                </div>
                <div className="grid gap-6 mb-8 lg:grid-cols-3 p-6 sm:p-16 lg:p-8 lg:w-12/12 m-auto">
                    <div>
                        <label htmlFor="storage_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Storage Type
                        </label>
                        <select
                            name="storage_type"
                            value={formik.values.storage_type}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose Storage Type</option>
                            {
                                warehouse_storage_data?.map((storage) => {
                                    return (
                                        <>
                                            <option value={storage.value}>{storage.label}</option>
                                        </>
                                    );
                                })
                            }
                        </select>
                        {formik.touched.storage_type && formik.errors.storage_type && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.storage_type}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="gross_weight" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Gross Weight  <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="gross_weight"
                            disabled
                            value={parseFloat(gross_weight).toFixed(2)}
                            className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="tare_weight" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Tare Weight <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            max={parseFloat(gross_weight).toFixed(2)}
                            default="0.00"
                            name="tare_weight"
                            value={formik.values.tare_weight}
                            onBlur={() => handleOnblur("tare_weight", formik.values.tare_weight)}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.tare_weight && formik.errors.tare_weight && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.tare_weight}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="net_weight" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Net Weight <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="net_weight"
                            disabled
                            value={parseFloat(gross_weight - formik.values.tare_weight).toFixed(2)}
                            className="bg-gray-100 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="quantity" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Quantity <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span>
                        </label>
                        <input
                            step="0.001"
                            type="number"
                            min="0.000"
                            default="0.000"
                            name="quantity"
                            disabled
                            value={parseFloat(gross_weight - formik.values.tare_weight).toFixed(2)}
                            className="bg-gray-100 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.quantity && formik.errors.quantity && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.quantity}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="deposit_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Deposit Date
                        </label>
                        <input
                            type="datetime-local"
                            name="deposit_date"
                            value={formik.values.deposit_date}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.deposit_date && formik.errors.deposit_date && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.deposit_date}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="offloading_time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Off loading Time
                        </label>
                        <input
                            type="datetime-local"
                            name="offloading_time"
                            value={formik.values.offloading_time}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.offloading_time && formik.errors.offloading_time && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.offloading_time}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Commodity Description
                        </label>
                        <input
                            type="text"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.description && formik.errors.description && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.description}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Detailed Commodity Storage Description
                        </label>
                        <input
                            type="text"
                            name="detailed_storage_description"
                            value={formik.values.detailed_storage_description}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.detailed_storage_description && formik.errors.detailed_storage_description && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.detailed_storage_description}</p>
                        )}
                    </div>
                </div>
                {error && error.length > 0 && (
                    <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
                        <span className="font-medium">Error: </span>
                        {error}
                    </div>
                )}
                <div className='mb-4 px-6 flex flex-row justify-end items-center'>
                    {/* <button
                        onClick={prevStep}
                        type="submit"
                        className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                    >
                        Previous Step: Commodity Grading Information
                    </button> */}
                    <button
                        // onClick={nextStep}
                        type="submit"
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                    >
                        Next Step: Inventory Information
                    </button>
                </div>
            </form>
        </>
    );
};

export default StorageStep;