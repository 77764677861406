import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.2mm"  // 210mm * 0.12 = 25.2mm
      height="35.64mm"  // 297mm * 0.12 = 35.64mm
      version="1.1"
      viewBox="0 0 210 297"
    >
      <g>
        <g
          fill="#009e49"
          fillOpacity="1"
          transform="translate(-343.623 20.87) scale(1.06585)"
        >
          <path
            d="M445.95 35.93c9.98-12.69 21.83-23.68 33.62-34.63-.55 12.49-.83 25.07.62 37.52 2.48 23 9.88 45.05 17.31 66.84 6.62 20.01 12.86 41.16 9.53 62.44-3.71 26.79-19.61 49.78-36.53 70.09 11.19-18.12 21.7-37.15 26.42-58.09 2.34-9.83 2.28-20.04 1.62-30.06-2.01-18.27-9.95-35.07-16.18-52.16-5.08-13.68-9.04-27.89-10.51-42.44-.57-8.23-1.65-16.52-.53-24.75-18.61 15-34.37 33.96-43.72 56.1-6.86 15.94-11.63 32.69-15.88 49.48-1.65-11.45-.61-23.13 1.55-34.44 4.73-24.43 17.11-46.74 32.68-65.9z"
            opacity="1"
          ></path>
          <path
            d="M368.82 137.03c-.56-13.44-.62-27.04 2.36-40.24 5.49 10.36 12.6 19.83 21.2 27.81 15.7 15.51 35.11 26.7 50.63 42.41 16.56 15.38 26.34 37.88 25.28 60.57-3.16-10.47-7.81-20.6-14.83-29.06-15.74-19.62-38.9-30.94-57.07-47.92-6.87-6.1-12.58-13.32-17.84-20.82-.68 14.53-1.42 29.06-2.4 43.57-4.42-11.6-6.07-24.03-7.33-36.32z"
            opacity="1"
          ></path>
          <path
            d="M331.65 185.22c32.53 13.1 68.87 9.95 101.73 21.82 12.22 4.19 23.67 12.08 29.95 23.6 1.05 1.96.42 4.28.52 6.4-8.1-11.36-19.51-20.67-32.98-24.72-17.46-5.34-35.95-3.94-53.92-3.25-4.51-.22-9.02-.66-13.53-.82 5.6 8.14 13.75 14 21.92 19.33 6.11 3.81 12.32 7.59 19.07 10.18-14.87 1.05-29.65-4.69-41.07-14.03-13.1-10.53-22.01-25.02-31.69-38.51z"
            opacity="1"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Icon;