import React, { useEffect, useState } from 'react';

const ImageDocUploadModal = ({ isOpen, docName, uploadDocumentFunction, closeUploadModal, modalLoading, modalError, uploadLabal }) => {

    const [isModalUploadOpen, setIsUploadModalOpen] = useState(false);
    const [documentName, setDocumentName] = useState(false);
    const [loading, setIsloading] = useState(false);
    const [error, setError] = useState("");
    const [filePreview, setFilePreview] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadButtonLabel, setuploadButtonLabel] = useState("");

    useEffect(() => {
        setDocumentName(docName);
        setIsUploadModalOpen(isOpen);
        setSelectedFile(null);
        setFilePreview(null);
    }, [isOpen, docName]);

    useEffect(() => {
        setIsloading(modalLoading);
    }, [modalLoading]);

    useEffect(() => {
        setError(modalError);
    }, [modalError]);

    useEffect(() => {
        setuploadButtonLabel(uploadLabal);
    }, [uploadLabal]);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && (selectedFile.type === 'application/pdf'))
        {
            setError("");
            setSelectedFile(selectedFile);

            const reader = new FileReader();
            reader.onload = (event) => {
                setFilePreview(event.target.result);
            };
            reader.readAsDataURL(selectedFile);
        } else
        {
            setSelectedFile(null);
            setFilePreview(null);
            setError("Please select a valid PDF file (PDF).");
        }
    };



    return (
        <>
            {
                isModalUploadOpen && (
                    <div className="fixed inset-4 flex items-center justify-center z-50 overflow-y-auto">
                        <div className="fixed inset-0 bg-black opacity-50"></div>
                        {/* Modal content goes here */}
                        <div className="relative p-8 bg-white rounded-lg shadow dark:bg-gray-800 max-h-screen overflow-y-auto w-full max-w-screen-lg">
                            {/* Add your modal content here */}
                            <div class="flex justify-between w-full px-6 items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                    Upload {documentName}
                                </h3>
                                {
                                    loading ?
                                        ""
                                        :
                                        <>
                                            <button type="button" onClick={closeUploadModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                                                <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                <span class="sr-only">Close</span>
                                            </button>
                                        </>
                                }
                            </div>
                            {error && error.length > 0 && (
                                <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                    <span className="font-medium">Error: </span>
                                    {error}
                                </div>
                            )}
                            <div class="flex items-center justify-center w-full">
                                <label for="dropzone-file" class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                    {filePreview ? (
                                        selectedFile.type.startsWith('image/') ? (
                                            <img src={filePreview} alt="File Preview" className="max-h-full max-w-full" />
                                        ) : (
                                            <object
                                                data={filePreview}
                                                type="application/pdf"
                                                width="100%"
                                                height="100%"
                                                aria-label="File Preview"
                                            />
                                        )
                                    ) : (

                                        <div>
                                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                                                <svg class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                </svg>
                                                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                                <p class="text-xs text-gray-500 dark:text-gray-400">PDF</p>
                                            </div>
                                            <input
                                                id="dropzone-file"
                                                type="file"
                                                accept="pdf"
                                                className="hidden"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    )}

                                </label>
                            </div>
                            <br />
                            <div className="flex flex-row justify-between items-center space-x-4">
                                {/* Close button */}
                                <button
                                    type="button"
                                    onClick={() => { if (!loading) closeUploadModal(); }}
                                    className={`${loading ? "cursor-not-allowed" : "cursor-pointer"} text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2`}
                                >
                                    Close
                                </button>

                                <button
                                    type="submit"
                                    onClick={() => { if (!loading) uploadDocumentFunction(selectedFile); }}
                                    className={`${loading ? "cursor-not-allowed bg-gray-600 hover:bg-gray-700" : "cursor-pointer bg-primary-600 hover:bg-primary-700"} text-white  focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2"`}
                                >
                                    {uploadButtonLabel}
                                </button>


                            </div>
                        </div>
                    </div>
                )}
        </>
    );
};

export default ImageDocUploadModal;