import React, { useEffect, useState } from 'react';
import { convertUnderscoreToTitleCase } from '../../../utils/functionalUtils';
import * as app_consts from '../../../utils/constants';
import SoyaBeanGradingStep from './grading_steps/SoyaBeanGradingStep';
import WheatGradingStep from './grading_steps/WheatGradingStep';
import SunflowerGradingStep from './grading_steps/SunflowerGradingStep';
import MaizeGradingStep from './grading_steps/MaizeGradingStep';

const CommodityGradingStep = ({ nextStep, prevStep, prevData, setPrevData }) => {

    const [previewData, setPreviewData] = useState(prevData);

    useEffect(() => {
        setPreviewData(prevData);
    }, [prevData]);

    // Formik Data and Validation


    return (
        <>
            <div className="px-4 flex flex-col items-stretch justify-start border-gray-100">
                <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                    Commodity Grading Information {previewData.commodity ? <span className='font-medium text-xl'> - {convertUnderscoreToTitleCase(previewData.commodity)}</span> : ""}
                </h3>
            </div>
            {/* Determine Which Grading Should Show */}
            {
                previewData.commodity.replace(/ /g, '_').toLowerCase() === app_consts.SOYA_BEANS ? <SoyaBeanGradingStep setPrevData={setPrevData} prevData={prevData} nextStep={nextStep} /> : previewData.commodity.replace(/ /g, '_').toLowerCase() === app_consts.WHEAT ? <WheatGradingStep setPrevData={setPrevData} prevData={prevData} nextStep={nextStep} /> : previewData.commodity.replace(/ /g, '_').toLowerCase() === app_consts.SUNFLOWER_SEED ? <SunflowerGradingStep setPrevData={setPrevData} prevData={prevData} nextStep={nextStep} /> : previewData.commodity.replace(/ /g, '_').toLowerCase() === app_consts.MAIZE ? <MaizeGradingStep setPrevData={setPrevData} prevData={prevData} nextStep={nextStep} /> : ""
            }
        </>
    );
};

export default CommodityGradingStep;