import React, { useEffect, useState } from 'react';
import * as app_consts from '../../utils/constants';
import TableRowsData from './TableRowsData';
import { format, parseISO } from 'date-fns';
import { addThreeDots, capitalizeSentences } from '../../utils/functionalUtils';
import { useParams } from 'react-router-dom';

export const WarehouseReceiptHistoryTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={index + 1 + (page_size * (pageSelected - 1))} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.message || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.timestamp ? format(parseISO(item.timestamp), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const OfferHistoryTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={index + 1 + (page_size * (pageSelected - 1))} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item || "---"} type={app_consts.TEXT_ROW} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const BrokerOfferTradeTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, broker_offer_id } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={index + 1 + (page_size * (pageSelected - 1))} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.amount ? `${parseFloat(item?.amount).toFixed(2)} ZMW` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.quantity ? `${parseFloat(item?.quantity).toFixed(2)} MT` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData data={item.trade_date ? format(parseISO(item.trade_date), "yyyy-MM-dd HH:mm:ss") : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/broker/${entity_id}/${entity_mid}/offer_management/${broker_offer_id}/trade/${item?.id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const BidManagemenetTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.broker_bid_id || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.broker_bid_reference_no || "---"} type={app_consts.TEXT_LINK} nav_link={`/broker/${entity_id}/${entity_mid}/bid_management/${item?.broker_bid_id}`} />
                                <TableRowsData data={item?.commodity_order_reference_no ? item?.commodity_order_reference_no : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.bid_volume ? `${parseFloat(item?.bid_volume).toFixed(2)} MT` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item.broker_bid_date ? format(parseISO(item.broker_bid_date), "yyyy-MM-dd") : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.bid_status ? capitalizeSentences(item?.bid_status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/broker/${entity_id}/${entity_mid}/bid_management/${item?.broker_bid_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const WarehouseReceiptListTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.receipt_number ? addThreeDots(item?.receipt_number, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipts/${item?.receipt_number}`} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.owner_name ? addThreeDots(item?.owner_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.quantity ? `${parseFloat(item?.quantity).toFixed(2)}` : "0.00"} type={app_consts.TEXT_ROW} textClassname="flex flex-row items-center justify-center" />
                                <TableRowsData data={item?.warehouse_receipt_origin ? item?.warehouse_receipt_origin.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_STATUS} textClassname="bg-green-100 text-green-800" textDarkClassName="dark:bg-green-900" />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipts/${item?.receipt_number}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const TradeFulfillmentListTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.fulfillment?.id || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.contract?.contract_reference_no ? addThreeDots(item?.contract?.contract_reference_no) : '-'} type={app_consts.TEXT_LINK} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/trade_fulfillments/${item?.contract?.contract_reference_no}`} />
                                <TableRowsData data={item?.fulfillment?.commodity ? item?.fulfillment?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.contract?.quantity ? `${parseFloat(item?.contract?.quantity).toFixed(2)}MT` : "0.00MT"} type={app_consts.TEXT_ROW} textClassname="flex flex-row items-center justify-center" />
                                <TableRowsData data={item?.trade_confirmation?.trade_date ? format(parseISO(item?.trade_confirmation?.trade_date), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.contract?.status ? item?.contract?.status.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_STATUS} textClassname="bg-green-100 text-green-800" textDarkClassName="dark:bg-green-900" />
                                <TableRowsData data={item?.fulfillment?.status ? item?.fulfillment?.status.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_STATUS} textClassname="bg-green-100 text-green-800" textDarkClassName="dark:bg-green-900" />
                                <TableRowsData data={item?.trade_confirmation?.status ? capitalizeSentences(item?.trade_confirmation?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/trade_fulfillments/${item?.contract?.contract_reference_no}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const SuperBidManagemenetTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.broker_bid_id || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.date_to_be_placed_on_platform || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.volume ? `${parseFloat(item?.volume).toFixed(2)} MT` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.requested_unit_price ? `${parseFloat(item?.requested_unit_price)}` : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.quality ? item?.quality.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.order_type ? item?.order_type.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.commodity_special_terms_and_conditions ? addThreeDots(item?.commodity_special_terms_and_conditions, 20) : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.bid_status ? capitalizeSentences(item?.bid_status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/broker/${entity_id}/${entity_mid}/s_bid_management/${item?.broker_bid_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const EntityProfileUserProfilesTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.profile_id || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.user_first_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.user_last_name || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_mobile || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.account_email || "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.profile_created_on ? format(parseISO(item?.profile_created_on), "yyyy-MM-dd HH:mm:ss") : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.profile_status ? capitalizeSentences(item?.profile_status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/profile/${item?.profile_id}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};

export const VirtualReceiptListTable = ({ data, currentItems, page_size, currentPage }) => {

    const [pageSelected, setPageSelected] = useState(currentPage);
    const { entity_id, entity_mid, view } = useParams();

    useEffect(() => {
        setPageSelected(currentPage);
    }, [currentPage]);


    return (
        <>
            {
                data?.length > 0 &&
                currentItems?.map((item, index) => {
                    return (
                        <>
                            <tr key={item?.receipt_number} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                {/* Other Rows */}
                                <TableRowsData data={item?.receipt_number || "--"} type={app_consts.TEXT_CHECKBOX} />
                                <TableRowsData data={item?.receipt_number ? addThreeDots(item?.receipt_number, 20) : '-'} type={app_consts.TEXT_LINK} nav_link={`/broker/${entity_id}/${entity_mid}/virtual_receipts/${item?.receipt_number}`} />
                                <TableRowsData data={item?.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.producer?.contact_name ? addThreeDots(item?.producer?.contact_name, 20) : '-'} type={app_consts.TEXT_ROW} />
                                <TableRowsData data={item?.quantity ? `${parseFloat(item?.quantity).toFixed(2)}` : "0.00"} type={app_consts.TEXT_ROW} textClassname="flex flex-row items-center justify-center" />
                                <TableRowsData data={item?.virtual_receipt_origin ? item?.virtual_receipt_origin.replace(/_/g, ' ').toUpperCase() : "---"} type={app_consts.TEXT_STATUS} textClassname="bg-green-100 text-green-800" textDarkClassName="dark:bg-green-900" />
                                <TableRowsData data={item?.status ? capitalizeSentences(item?.status) : "---"} type={app_consts.TEXT_STATUS} />
                                <TableRowsData type={app_consts.TEXT_VIEW_DETAILS} nav_link={`/broker/${entity_id}/${entity_mid}/virtual_receipts/${item?.receipt_number}`} />
                            </tr>
                        </>
                    );
                }
                )
            }
        </>
    );
};