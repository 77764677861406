import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ProtectedRoute = ({ element: Component, ...rest }) => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    const token = localStorage.getItem('authToken');
    const account = localStorage.getItem('account');
    const tokenCreatedAt = localStorage.getItem('tokenCreatedAt');

    if (token && account && tokenCreatedAt)
    {
      const currentTime = new Date().getTime();
      const tokenCreatedTime = new Date(tokenCreatedAt).getTime();
      const timeDifference = currentTime - tokenCreatedTime;
      const oneHourInMilliseconds = 60 * 60 * 1000;

      if (timeDifference > oneHourInMilliseconds)
      {
        localStorage.clear();
        return false;
      }

      return true;
    }

    return false;
  };

  useEffect(() => {
    if (!isAuthenticated())
    {
      navigate("/");
    } else
    {
      const account = localStorage.getItem('account') ? JSON.parse(localStorage.getItem('account')) : {};
      const hasProfile = account.profiles && account.profiles.length > 0;
      const pendingProfile = hasProfile ? account.profiles.find((profile) => profile.status === 'pending' && profile.account_id === account.id) : undefined;
      const contactInfo = account.profiles.find((profile) => profile.entity_kyc_set === 1 && profile.account_id === account.id);
      const registrationCertificateProfile = account.profiles.find((profile) => profile.entity_reg_uploaded === 1 && profile.account_id === account.id);
      const registrationTaxCertificateProfile = account.profiles.find((profile) => profile.entity_tax_uploaded === 1 && profile.account_id === account.id);
      const pacraPrintOutProfile = account.profiles.find((profile) => profile.entity_pacra_printout_uploaded === 1 && profile.account_id === account.id);
      const registrationCompanyProfile = account.profiles.find((profile) => profile.entity_profile_uploaded === 1 && profile.account_id === account.id);
      const registrationBoardResolution = account.profiles.find((profile) => profile.entity_resolution_uploaded === 1 && profile.account_id === account.id);
      const registrationDirectorDetails = account.profiles.find((profile) => profile.entity_owners_uploaded === 1 && profile.account_id === account.id);
      const registrationBankDetails = account.profiles.find((profile) => profile.bank_account_set === 1 && profile.account_id === account.id);
      const registrationDeclaration = account.profiles.find((profile) => profile.bank_account_set === 1 && profile.entity_set !== 1 && profile.account_id === account.id);
      const registrationVerification = account.profiles.find((profile) => profile.entity_set === 0 && profile.account_id === account.id);
      const zamaceProfile = account.profiles.find(profile => profile.type === "exchange");
      const warehouseOperatorProfile = account.profiles.find(profile => profile.type === "warehouse_operator");
      const brokerProfile = account.profiles.find(profile => profile.type === "broker");
      // Warehouse 
      const isWarehouseOperatorRoute = window.location.pathname.startsWith("/warehouse");
      const isBrokerRoute = window.location.pathname.startsWith('/broker');

      const isExchangeRoute = window.location.pathname.startsWith('/exchange');

      if (isExchangeRoute && (!zamaceProfile || account.active !== 1) || isWarehouseOperatorRoute && (!warehouseOperatorProfile || account.active !== 1))
      {
        navigate("/"); // Redirect unauthorized users
        return;
      }

      let nextPage = null;
      // if (zamaceProfile && account.active === 1)
      // {
      //   nextPage = '/exchange';
      // } else 
      if (hasProfile && account.active === 1 && account.profiles[0].entity_set === 1)
      {
        nextPage = '/home';
      } else if (account.verified === 0)
      {
        nextPage = '/verify_account';
      } else if (!hasProfile)
      {
        nextPage = '/membership_type';
      } else if (hasProfile && pendingProfile)
      {
        if (hasProfile && !contactInfo)
        {
          nextPage = '/complete_entity_kyc';
        } else if (hasProfile && !registrationCertificateProfile)
        {
          nextPage = '/registration_certificate';
        } else if (hasProfile && contactInfo && registrationCertificateProfile && !registrationTaxCertificateProfile)
        {
          nextPage = '/registration_tax_certificate';
        }
        else if (hasProfile && contactInfo && registrationCertificateProfile && registrationTaxCertificateProfile && !pacraPrintOutProfile)
        {
          nextPage = '/registration_pacra_printout';
        } else if (
          hasProfile &&
          contactInfo &&
          registrationCertificateProfile &&
          registrationTaxCertificateProfile &&
          pacraPrintOutProfile &&
          !registrationCompanyProfile
        )
        {
          nextPage = '/organization_profile';
        } else if (
          hasProfile &&
          contactInfo &&
          registrationCertificateProfile &&
          registrationTaxCertificateProfile &&
          pacraPrintOutProfile &&
          registrationCompanyProfile &&
          !registrationBoardResolution
        )
        {
          nextPage = '/board_resolution_document';
        } else if (
          hasProfile &&
          contactInfo &&
          registrationCertificateProfile &&
          registrationTaxCertificateProfile &&
          pacraPrintOutProfile &&
          registrationCompanyProfile &&
          registrationBoardResolution &&
          !registrationDirectorDetails
        )
        {
          nextPage = '/director_details';
        } else if (
          hasProfile &&
          contactInfo &&
          registrationCertificateProfile &&
          registrationTaxCertificateProfile &&
          pacraPrintOutProfile &&
          registrationCompanyProfile &&
          registrationBoardResolution &&
          registrationDirectorDetails &&
          !registrationBankDetails
        )
        {
          nextPage = '/bank_details';
        } else if (
          hasProfile &&
          contactInfo &&
          registrationCertificateProfile &&
          registrationTaxCertificateProfile &&
          pacraPrintOutProfile &&
          registrationCompanyProfile &&
          registrationBoardResolution &&
          registrationDirectorDetails &&
          registrationBankDetails &&
          !registrationDeclaration
        )
        {
          nextPage = '/registration_declaration';
        } else if (
          hasProfile &&
          contactInfo &&
          registrationCertificateProfile &&
          registrationTaxCertificateProfile &&
          pacraPrintOutProfile &&
          registrationCompanyProfile &&
          registrationBoardResolution &&
          registrationDirectorDetails &&
          registrationBankDetails &&
          registrationDeclaration &&
          registrationVerification
        )
        {
          nextPage = '/registration_verification';
        }
      }

      if (nextPage && hasProfile && account.active === 1 && account.profiles[0].entity_set === 1)
      {
        if (nextPage === '/exchange')
          // {
          //   if (isExchangeRoute)
          //   {
          //     navigate(window.location.pathname);
          //   } else
          //   {
          //     navigate('/exchange');
          //   }
          // }
          // else 
          if (nextPage === '/home')
          {
            if ((warehouseOperatorProfile || isBrokerRoute || isExchangeRoute) /* && account.profiles[0].status !== "pending" */) //to be changes
            {
              navigate(window.location.pathname);
            } else
            {
              navigate('/home');
            }
          }
          else
          {
            navigate(nextPage);
          }
      }

    }
  }, [navigate]);

  if (!isAuthenticated())
  {
    return null;
  }

  return <Component {...rest} />;
};

export default ProtectedRoute;











