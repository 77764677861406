import React from 'react';
import * as app_consts from '../../utils/constants';
import { Link, useNavigate } from 'react-router-dom';
import { addThreeDots } from '../../utils/functionalUtils';

const TableRowsData = ({ data, type, nav_link = "#", textClassname, textDarkClassName }) => {

    const navigate = useNavigate();

    return (
        <>
            {
                type === app_consts.TEXT_ROW ?
                    <>
                        <td className={`px-6 py-4 ${textClassname}`}>
                            <span className="text-normal font-medium text-gray-600 whitespace-nowrap dark:text-white">{data}
                            </span>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_LINK ?
                    <>
                        <td scope="row" className="px-6 py-4 font-semibold text-green-900 whitespace-nowrap dark:text-white">
                            <Link to={nav_link}>
                                {data}
                            </Link>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_STATUS ?
                    <>
                        <td className="px-6 py-4 text-normalfont-medium">
                            <span class={`${textClassname || "bg-yellow-100 text-yellow-800"} text-xs uppercase font-medium px-2 py-0.5 rounded d${textDarkClassName || "dark:bg-yellow-900"} dark:text-yellow-300`}>{addThreeDots(data?.toUpperCase(), 20)}</span>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_VIEW_DETAILS ?
                    <>
                        <td className="px-6 py-4">
                            <button
                                onClick={() => navigate(nav_link)}
                                type="button"
                                class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 min-w-[110px]"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-2">
                                    <path d="M10 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5z" />
                                    <path fill-rule="evenodd" d="M.664 10.59a1.651 1.651 0 010-1.186A10.004 10.004 0 0110 3c4.257 0 7.893 2.66 9.336 6.41.147.381.146.804 0 1.186A10.004 10.004 0 0110 17c-4.257 0-7.893-2.66-9.336-6.41zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                                </svg>
                                View Details
                            </button>
                        </td>
                    </>
                    :
                    ""
            }
            {
                type === app_consts.TEXT_CHECKBOX ?
                    <>
                        {/* Pass the ID */}
                        <td className="w-4 p-4">
                            <div className="flex items-center">
                                <input id={`checkbox-table-search-${data}`} type="checkbox" className="w-4 h-4 text-green-600 text-lg bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                <label htmlFor={`checkbox-table-search-${data}`} className="sr-only">checkbox</label>
                            </div>
                        </td>
                    </>
                    :
                    ""
            }
        </>
    );
};

<th scope="col" className="p-4">
    <div className="flex items-center">
        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
        <label for="checkbox-all-search" className="sr-only">checkbox</label>
    </div>
</th>;
export default TableRowsData;