import React, { useEffect, useState } from 'react';
import WarehouseReceiptPDF from '../../../layouts/generated_PDFs/WarehouseReceiptPDF';
import { useDispatch, useSelector } from 'react-redux';
import { generateWarehouseReceipt, generateWarehouseReceiptReset } from '../../../utils/actions';
// import { PDFViewer } from '@react-pdf/renderer';

const GenerateWarehouseReceipt = ({ nextStep, prevStep, prevData }) => {

    const dispatch = useDispatch();

    const [pdf_data, setPDFData] = useState([]);

    const [previewData, setPreviewData] = useState(prevData);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [warehouse_receipt_id, setWarehouseReceiptId] = useState("");
    const [warehouseReceiptData, setWarehouseReceiptData] = useState([]);
    const [downloadWarehouseReceipt, setDownloadWarehouseReceipt] = useState(false);

    useEffect(() => {
        if (prevData)
        {
            setWarehouseReceiptId(prevData.warehouse_receipt_data.warehouse_receipt_id);
            setPreviewData(prevData);
        }
    }, [prevData]);

    // Request Receipt Generation on Page Load
    useEffect(() => {
        if (warehouse_receipt_id)
        {
            generateReceipt();
        }
    }, [warehouse_receipt_id]);

    const generateReceipt = async () => {
        setError("");
        setLoading(true);
        try
        {
            await dispatch(generateWarehouseReceipt({ warehouse_receipt_id: warehouse_receipt_id }));
        }
        catch (error)
        {
            console.log(error);
            if (error.response)
            {
                if (error.response.status === 500)
                {
                    setError("Failed to Generate Warehouse Receipt.");
                }
                else
                {
                    setError(error.response.data.message);
                }
            }
            else
            {
                setError("Failed to Generate Warehouse Receipt.");
            }
        } finally
        {
            setTimeout(function () {
                setLoading(false);
            }, 500);
        }
    };

    // Listen to Generate Warehouse Receipt State
    const generateReceiptListener = useSelector((state) => state.generateWarehouseReceipt);
    // 
    useEffect(() => {
        if (generateReceiptListener.data)
        {
            console.log(generateReceiptListener.data);
            setWarehouseReceiptData(generateReceiptListener.data);
            dispatch(generateWarehouseReceiptReset());
        }
    }, [generateReceiptListener]);

    return (
        <>
            {loading ? <>
                <div className="text-center mt-4 h-10">
                    <div role="status" className=' flex flex-col items-center justify-center'>
                        <svg
                            aria-hidden="true"
                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                        <span className="my-2 text-gray-800 font-meduim">Generating Warehouse Receipt...</span>
                    </div>
                </div>
            </>
                :
                <>
                    {
                        error && error.length > 0 ?
                            <>
                                <div className="text-center mt-8 h-10">
                                    <div role="status" className=' flex flex-col items-center justify-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" className='text-redd-700' viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                        </svg>
                                        <span className="my-2 text-red-600 font-meduim">Error Generating Warehouse Receipt.</span>
                                        <span onClick={generateReceipt} className="cursor-pointer my-2 text-blue-800 underline font-meduim">Retry</span>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div>
                                    <div className="p-4 sm:ml-10">
                                        <div className="p-4 rounded-lg dark:border-gray-700">
                                            <div className="relative">
                                                <div>
                                                    <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                                        <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                            <div>
                                                                <h5 class="mr-3 text-3xl text-gray-800 font-bold dark:text-white">Warehouse Receipt </h5>
                                                                <p class="text-gray-500 dark:text-gray-400 my-2">Preview and Print Warehouse Receipt</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    warehouseReceiptData && (
                                                        <>
                                                            <div className="grid grid-cols-1 md:grid-cols-1 mb-4 w-12/12 w-7/12 m-auto">
                                                                <div className="flex justify-between items-center p-4 bg-gray-100 border-b border-gray-300">
                                                                    <h2 className="text-xl font-bold dark:text-white">Warehouse Receipt</h2>

                                                                    <button
                                                                        onClick={() => setDownloadWarehouseReceipt(true)}
                                                                        type="button"
                                                                        className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                                                    >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                                        </svg>
                                                                        Download

                                                                    </button>
                                                                </div>
                                                                <div className="border border-gray-200 h-auto">
                                                                    {/* <PDFViewer height="100vh" width="100vh" showToolbar> */}
                                                                    {/* </PDFViewer> */}
                                                                    <div style={{ height: "90vh", width: "100%", overflow: "scroll" }} className='border-2 border-text-gray-400'>
                                                                        {/* <PDFViewer height="100%" width="100%" showToolbar>*/}
                                                                        {
                                                                            warehouseReceiptData && (
                                                                                <WarehouseReceiptPDF data={warehouseReceiptData} downloadable={true} downloadWarehouseReceipt={downloadWarehouseReceipt} setDownloadWarehouseReceipt={setDownloadWarehouseReceipt} />
                                                                            )
                                                                        }
                                                                        {/*  </PDFViewer> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="border border-gray-200 h-auto">
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </>
            }
        </>
    );
};

export default GenerateWarehouseReceipt;