export const maize_grading_scale = (type, measurement) => {
    // const final_reading
    switch (type)
    {
        case 'test_density':
            if (measurement >= 67.25) return 'A';
            else if (measurement >= 64.75) return 'B';
            else if (measurement >= 61.75) return 'C';
            else return 0;
        case 'moisture_content':
            if (measurement <= 12.5) return 'A';
            else if (measurement <= 12.5) return 'B';
            else if (measurement <= 12.5) return 'C';
            else return 0;
        case 'broken_grains':
            if (measurement <= 6.0) return 'A';
            else if (measurement <= 7.0) return 'B';
            else if (measurement <= 8.0) return 'C';
            else return 0;
        case 'extraneous_matter':
            if (measurement <= 1.0) return 'A';
            else if (measurement <= 1.5) return 'B';
            else if (measurement <= 2.0) return 'C';
            else return 0;
        case 'other_coloured_grains':
            if (measurement <= 3.0) return 'A';
            else if (measurement <= 4.0) return 'B';
            else if (measurement <= 5.0) return 'C';
            else return 0;
        case 'total_other_defective_grains':
            if (measurement <= 11.0) return 'A';
            else if (measurement <= 18.5) return 'B';
            else if (measurement <= 26.0) return 'C';
            else return 0;
        case 'discoloured_grains':
            if (measurement <= 3.0) return 'A';
            else if (measurement <= 6.0) return 'B';
            else if (measurement <= 9.0) return 'C';
            else return 0;
        case 'insect_pest_damaged_grains':
            if (measurement <= 3.0) return 'A';
            else if (measurement <= 6.0) return 'B';
            else if (measurement <= 9.0) return 'C';
            else return 0;
        case 'diseased_grain':
            if (measurement <= 2.0) return 'A';
            else if (measurement <= 2.0) return 'B';
            else if (measurement <= 2.0) return 'C';
            else return 0;
        case 'immature_shrivelled_grain':
            if (measurement <= 1.0) return 'A';
            else if (measurement <= 1.5) return 'B';
            else if (measurement <= 2.0) return 'C';
            else return 0;
        case 'fungal_damaged_grains':
            if (measurement <= 0.5) return 'A';
            else if (measurement <= 1.0) return 'B';
            else if (measurement <= 1.5) return 'C';
            else return 0;
        case 'germinated_grains':
            if (measurement <= 0) return 'A';
            else if (measurement <= 0) return 'B';
            else if (measurement <= 0) return 'C';
            else return 0;
        case 'pass_thru_6_35mm_sieve':
            if (measurement <= 1.5) return 'A';
            else if (measurement <= 2.0) return 'B';
            else if (measurement <= 2.5) return 'C';
            else return 0;
        case 'diplodia':
            if (measurement <= 0) return 'A';
            else if (measurement <= 0) return 'B';
            else if (measurement <= 0) return 'C';
            else return 0;
        case 'fusarium':
            if (measurement <= 0.5) return 'A';
            else if (measurement <= 0.5) return 'B';
            else if (measurement <= 0.5) return 'C';
            else return 0;
        default:
            return 0; // Return 0 for unknown fields
    }
};

export const soya_grading_scale = (type, measurement) => {
    switch (type)
    {
        case 'moisture_content':
            if (measurement >= 0.0 && measurement <= 12.0) return 'A';
            else if (measurement >= 0.0 && measurement <= 12.0) return 'B';
            else if (measurement >= 0.0 && measurement <= 12.0) return 'C';
            else return 0;
        case 'extraneous_matter':
            if (measurement >= 0.0 && measurement <= 1.0) return 'A';
            else if (measurement >= 0.0 && measurement <= 2.0) return 'B';
            else if (measurement >= 0.0 && measurement <= 3.0) return 'C';
            else return 0;
        case 'total_defective_grains':
            if (measurement >= 0.0 && measurement <= 9.0) return 'A';
            else if (measurement >= 0.0 && measurement <= 11.5) return 'B';
            else if (measurement >= 0.0 && measurement <= 14.0) return 'C';
            else return 0;
        case 'split_beans':
            if (measurement >= 0.0 && measurement <= 6.0) return 'A';
            else if (measurement >= 0.0 && measurement <= 8.0) return 'B';
            else if (measurement >= 0.0 && measurement <= 10.0) return 'C';
            else return 0;
        case 'green_beans':
            if (measurement >= 0.0 && measurement <= 2.0) return 'A';
            else if (measurement >= 0.0 && measurement <= 2.0) return 'B';
            else if (measurement >= 0.0 && measurement <= 2.0) return 'C';
            else return 0;
        case 'immature_shrivelled_beans':
            if (measurement >= 0.0 && measurement <= 1.0) return 'A';
            else if (measurement >= 0.0 && measurement <= 1.5) return 'B';
            else if (measurement >= 0.0 && measurement <= 2.0) return 'C';
            else return 0;
        case 'oil_content':
            if (measurement >= 18.0 && measurement <= 100.0) return 'A';
            else if (measurement >= 16.0 && measurement <= 100.0) return 'B';
            else if (measurement >= 14.0 && measurement <= 100.0) return 'C';
            else return 0;
        case 'protein_content':
            if (measurement >= 36.0 && measurement <= 100.0) return 'A';
            else if (measurement >= 33.0 && measurement <= 100.0) return 'B';
            else if (measurement >= 30.0 && measurement <= 100.0) return 'C';
            else return 0;
        default:
            return 'Invalid Type';
    }
};

export const wheat_grading_scale = (type, measurement) => {
    switch (type)
    {
        case 'specific_weight':
            if (measurement >= 77.0) return 'B1';
            else if (measurement >= 76.0) return 'B2';
            else if (measurement >= 74.0) return 'B3';
            else if (measurement >= 72.0) return 'B4';
            else return 0;
        case 'moisture_content':
            if (measurement >= 0 && measurement <= 13) return 'B1';
            else if (measurement >= 0 && measurement <= 13) return 'B2';
            else if (measurement >= 0 && measurement <= 13) return 'B3';
            else if (measurement >= 0 && measurement <= 13) return 'B4';
            else return 0;
        case 'protein':
            if (measurement >= 12.0) return 'B1';
            else if (measurement >= 11.0) return 'B2';
            else if (measurement >= 10.0) return 'B3';
            else if (measurement >= 9.0) return 'B4';
            else return 0;
        case 'hagberg_falling_number':
            if (measurement >= 250) return 'B1';
            else if (measurement >= 250) return 'B2';
            else if (measurement >= 250) return 'B3';
            else if (measurement >= 250) return 'B4';
            else return 0;
        case 'screenings':
            if (measurement >= 0 && measurement <= 3) return 'B1';
            else if (measurement >= 0 && measurement <= 3) return 'B2';
            else if (measurement >= 0 && measurement <= 3) return 'B3';
            else if (measurement >= 0 && measurement <= 3) return 'B4';
            else return 0;
        case 'other_grains':
            if (measurement >= 0 && measurement <= 1) return 'B1';
            else if (measurement >= 0 && measurement <= 1) return 'B2';
            else if (measurement >= 0 && measurement <= 1) return 'B3';
            else if (measurement >= 0 && measurement <= 1) return 'B4';
            else return 0;
        case 'foreign_matter':
            if (measurement >= 0 && measurement <= 1) return 'B1';
            else if (measurement >= 0 && measurement <= 1) return 'B2';
            else if (measurement >= 0 && measurement <= 1) return 'B3';
            else if (measurement >= 0 && measurement <= 1) return 'B4';
            else return 0;
        case 'damaged_kernels':
            if (measurement >= 0 && measurement <= 2) return 'B1';
            else if (measurement >= 0 && measurement <= 2) return 'B2';
            else if (measurement >= 0 && measurement <= 2) return 'B3';
            else if (measurement >= 0 && measurement <= 2) return 'B4';
            else return 0;
        case 'combined_deviations':
            if (measurement >= 0 && measurement <= 5) return 'B1';
            else if (measurement >= 0 && measurement <= 5) return 'B2';
            else if (measurement >= 0 && measurement <= 5) return 'B3';
            else if (measurement >= 0 && measurement <= 5) return 'B4';
            else return 0;
        case 'heavily_frost_damaged_kernels':
            if (measurement >= 0 && measurement <= 5) return 'B1';
            else if (measurement >= 0 && measurement <= 5) return 'B2';
            else if (measurement >= 0 && measurement <= 5) return 'B3';
            else if (measurement >= 0 && measurement <= 5) return 'B4';
            else return 0;
        case 'field_fungi_infected_kernels':
            if (measurement >= 0 && measurement <= 2) return 'B1';
            else if (measurement >= 0 && measurement <= 2) return 'B2';
            else if (measurement >= 0 && measurement <= 2) return 'B3';
            else if (measurement >= 0 && measurement <= 2) return 'B4';
            else return 0;
        case 'storage_fungi_infected_kernels':
            if (measurement >= 0 && measurement <= 0.5) return 'B1';
            else if (measurement >= 0 && measurement <= 0.5) return 'B2';
            else if (measurement >= 0 && measurement <= 0.5) return 'B3';
            else if (measurement >= 0 && measurement <= 0.5) return 'B4';
            else return 0;
        default:
            return 'Invalid Type';
    }
};

export const sunflower_grading_scale = (type, measurement) => {
    // const final_reading
    switch (type)
    {
        case 'moisture_content':
            if (measurement <= 12.00) return "A";
            else if (measurement <= 12.00) return "B";
            else if (measurement <= 12.00) return "C";
            else return 0;
        case 'extraneous_matter':
            if (measurement <= 2.0) return "A";
            else if (measurement <= 3.0) return "B";
            else if (measurement <= 4.0) return "C";
            else return 0;
        case 'total_defective_grains':
            if (measurement <= 6.0) return "A";
            else if (measurement <= 12.0) return "B";
            else if (measurement <= 15.0) return "C";
            else return 0;
        case 'heat_damaged':
            if (measurement <= 1.0) return "A";
            else if (measurement <= 2.0) return "B";
            else if (measurement <= 3.0) return "C";
            else return 0;
        case 'pest_damaged':
            if (measurement <= 1.0) return "A";
            else if (measurement <= 2.0) return "B";
            else if (measurement <= 3.0) return "C";
            else return 0;
        case 'head_rot_other_fungal_damaged':
            if (measurement <= 1.0) return "A";
            else if (measurement <= 2.0) return "B";
            else if (measurement <= 3.0) return "C";
            else return 0;
        case 'immature_shriveled_beans':
            if (measurement <= 1.0) return "A";
            else if (measurement <= 2.0) return "B";
            else if (measurement <= 4.0) return "C";
            else return 0;
        case 'germinated_seed':
            if (measurement <= 1.0) return "A";
            else if (measurement <= 2.0) return "B";
            else if (measurement <= 4.0) return "C";
            else return 0;
        case 'weather_damaged_seed':
            if (measurement <= 1.0) return "A";
            else if (measurement <= 2.0) return "B";
            else if (measurement <= 4.0) return "C";
            else return 0;
        case 'oil_content':
            if (measurement >= 38.0) return "A";
            else if (measurement >= 32.0) return "B";
            else if (measurement >= 26.0) return "C";
            else return 0;
        case 'protein_content':
            if (measurement >= 18.0) return "A";
            else if (measurement >= 15.0) return "B";
            else if (measurement >= 12.0) return "C";
            else return 0;
        default:
            return 0; // Return 0 for unknown fields
    }
};

// Acceptable Grades for Different Commodities
export const acceptable_grades = (type) => {
    switch (type)
    {
        case 'maize':
            return ["A", "B", "C"];
        case 'soya_beans':
            return ["A", "B", "C"];
        case 'wheat':
            return ["B1", "B2", "B3", "B4"];
        case 'sunflower_seed':
            return ["A", "B", "C"];
        default:
            return []; // Return [] for unknown fields
    }
};