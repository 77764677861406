// import { div, Font, div, p, div } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
// Import the font files
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import PDFSVGLogo from '../../components/PDFSVGLogo';
import { format } from 'date-fns';
import DynamicNavLogo from '../../components/DynamicNavLogo';
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';

const WarehouseReceiptTradingInvoicePDF = ({ data, downloadTradingInvoice, downloadable, setDownloadTradingInvoice }) => {

    const [invoice_data, setInvoiceData] = useState(data);

    const targetRef = useRef();

    //
    useEffect(() => {
        setInvoiceData(data);
    }, [data]);

    useEffect(() => {
        if (downloadable === true && downloadTradingInvoice === true)
        {
            generatePDF(targetRef, { filename: `${invoice_data?.broker_offer_data?.commodity_order_reference_no} Trading Invoice` || "Trading Invoice" });
            setDownloadTradingInvoice(false);
        }
    }, [downloadTradingInvoice]);


    // Register the Lato font
    // Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    return (
        <>
            {
                invoice_data && (
                    <>
                        <div title="Warehouse Receipt Trading Invoice" ref={targetRef}>
                            <div size="A4" style={{
                                flexDirection: "column",
                                backgroundColor: "rgba(0,0,0,0)",
                                height: "100%",
                                fontFamily: [LatoBold, LatoRegular]
                            }}>
                                <div fixed>
                                    <div style={{
                                        width: "100%",
                                        paddingRight: 10,
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "start",
                                        marginBottom: 5,
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            textTransform: "uppercase",
                                            width: "70%",
                                            paddingLeft: 30,
                                        }}>
                                            <div>
                                                {/* <PDFSVGLogo height='80px' width='150px' /> */}
                                                <DynamicNavLogo height='80px' width='150px' />
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: 5,
                                                paddingLeft: 5,
                                                fontWeight: 500,
                                                fontSize: 14,
                                                color: "#000"
                                            }}>
                                                <p >Plot Number. 26592</p>
                                                <p style={{ marginTop: 12 }}>Kafue Road, Makeni Area.</p>
                                                <p style={{ marginTop: 12 }}>Lusaka, Zambia</p>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "start",
                                            justifyContent: "start",
                                            textTransform: "uppercase",
                                            width: "30%"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                marginTop: 25
                                            }}>
                                                <p style={{ letterSpacing: 1, fontWeight: 400, fontSize: 24, }}>TRADE INVOICE</p>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 28,
                                                paddingLeft: 5
                                            }}>
                                                <p style={{ fontWeight: 500, fontSize: 14, color: "#000" }}>Date:</p>
                                                <p style={{ fontWeight: 600, fontSize: 14, color: "#000", marginLeft: 2 }}>{invoice_data?.trade_data?.trade_date ? format(new Date(invoice_data?.trade_data?.trade_date), 'yyyy-MM-dd') : "---"}</p>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: 12,
                                                paddingLeft: 5
                                            }}>
                                                <p style={{ fontWeight: 500, fontSize: 14, color: "#000" }}>Invoice #:</p>
                                                <p style={{ fontWeight: 600, fontSize: 14, color: "#000", marginLeft: 2 }}>{invoice_data?.trade_data?.trade_invoice_number ? invoice_data?.trade_data?.trade_invoice_number : "---"}</p>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                marginTop: 12,
                                                paddingLeft: 5
                                            }}>
                                                <p style={{ fontWeight: 500, fontSize: 14, color: "#000" }}>Offer Ref No.: </p>
                                                <p style={{ fontWeight: 600, fontSize: 14, color: "#000", marginTop: 2 }}>{invoice_data?.broker_offer_data?.commodity_order_reference_no ? invoice_data?.broker_offer_data?.commodity_order_reference_no : "---"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ paddingLeft: 30, marginTop: 20 }}>
                                    <div style={{ fontWeight: 700, fontSize: 18, color: "#000", textDecoration: "underline" }}>
                                        <p>Trade Buyer Details</p>
                                    </div>
                                    <div style={{ width: "100%", marginTop: 20, display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <p style={{ fontSize: 14, fontWeight: 500 }}>Bid Ref No.:</p>
                                            <p style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.broker_bid_data?.broker_bid_reference_no ? invoice_data?.broker_bid_data?.broker_bid_reference_no : "---"}</p>
                                        </div>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <p style={{ fontSize: 14, fontWeight: 600 }}>Zamace No.:</p>
                                            <p style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.buyer?.organization_id ? invoice_data?.buyer?.organization_id : "---"}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", marginTop: 20, display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <p style={{ fontSize: 14, fontWeight: 500 }}>Name:</p>
                                            <p style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.buyer?.entity_name ? invoice_data?.buyer?.entity_name : "---"}</p>
                                        </div>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <p style={{ fontSize: 14, fontWeight: 500 }}>Email Address:</p>
                                            <p style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.buyer?.entity_email ? invoice_data?.buyer?.entity_email : "---"}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: "100%", marginTop: 20, display: "flex", flexDirection: "row" }}>
                                        <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                                            <p style={{ fontSize: 14, fontWeight: 500 }}>Phone:</p>
                                            <p style={{ fontSize: 14, fontWeight: 600 }}>{invoice_data?.buyer?.entity_phone ? invoice_data?.buyer?.entity_phone : "---"}</p>
                                        </div>
                                    </div>
                                    <div style={{ paddingRight: "30px" }}>
                                        <div style={{
                                            color: "rgba(0, 0, 0, 0.87)",
                                            marginTop: 24,
                                            borderRadius: 4,
                                            borderStyle: "solid",
                                            borderColor: "#e5e9f2",
                                        }}>
                                            <div style={{
                                                width: "100%",
                                                display: "flex",
                                                flexDirection: "row",
                                                borderBottomWidth: 2,
                                                borderTopWidth: 2,
                                                borderStyle: "solid",
                                                borderColor: "#302f2f",
                                            }}>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        margin: "auto",
                                                        marginTop: 5,
                                                        color: "#302f2f",
                                                        fontSize: 12,
                                                        lineHeight: 1.6,
                                                        fontWeight: 700,
                                                        lineHeight: "normal",
                                                        padding: 3
                                                    }}>Description</p>
                                                </div>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        margin: "auto",
                                                        marginTop: 5,
                                                        color: "#302f2f",
                                                        fontSize: 12,
                                                        lineHeight: 1.6,
                                                        fontWeight: 700,
                                                        lineHeight: "normal",
                                                        padding: 3
                                                    }}>Unit price (ZMW)</p>
                                                </div>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        margin: "auto",
                                                        marginTop: 5,
                                                        color: "#302f2f",
                                                        fontSize: 12,
                                                        lineHeight: 1.6,
                                                        fontWeight: 700,
                                                        lineHeight: "normal",
                                                        padding: 3
                                                    }}>Quantity (MT)</p>
                                                </div>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        margin: "auto",
                                                        marginTop: 5,
                                                        color: "#302f2f",
                                                        fontSize: 12,
                                                        lineHeight: 1.6,
                                                        fontWeight: 700,
                                                        lineHeight: "normal",
                                                        padding: 3
                                                    }}>Amount (ZMW)</p>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                borderColor: "#e5e9f2",
                                                borderStyle: "solid",
                                                borderBottomWidth: 1,
                                            }}>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.commodity ? invoice_data?.broker_bid_data?.commodity.replace(/_/g, ' ').toUpperCase() : "---"}</p>
                                                </div>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.requested_unit_price ? invoice_data?.broker_bid_data?.requested_unit_price : "---"}</p>
                                                </div>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.bid_volume ? parseFloat(invoice_data?.broker_bid_data?.bid_volume).toFixed(2) : "---"}</p>
                                                </div>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.bid_total ? parseFloat(invoice_data?.broker_bid_data?.bid_total).toFixed(2) : "---"}</p>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                borderColor: "#e5e9f2",
                                                borderStyle: "solid",
                                                borderBottomWidth: 1,
                                                marginTop: 20
                                            }}>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}></p>
                                                </div>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 400,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}></p>
                                                </div>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 600,
                                                        textAlign: "center",
                                                        padding: 3
                                                    }}>Total</p>
                                                </div>
                                                <div style={{
                                                    width: "23%",
                                                    padding: 3,
                                                }}>
                                                    <p style={{
                                                        marginTop: 5,
                                                        color: "#252b32",
                                                        fontSize: 12,
                                                        fontWeight: 600,
                                                        textAlign: "center",
                                                        borderColor: "#e5e9f2",
                                                        borderStyle: "solid",
                                                        borderWidth: 2,
                                                        padding: 3
                                                    }}>{invoice_data?.broker_bid_data?.bid_total ? parseFloat(invoice_data?.broker_bid_data?.bid_total).toFixed(2) : "---"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div style={{
                                    bottom: 45,
                                    left: 0,
                                    right: 0,
                                    color: "#252b32",
                                    paddingLeft: 30,
                                    paddingRight: 20,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    marginTop: 20,
                                    marginTop: 35
                                }}>
                                    <div style={{ border: "1px solid #e5e9f2", padding: 5 }}>
                                        {/* <p style={{ fontWeight: 700, fontSize: 12, color: "#000", }}>All Payments to ZAMACE</p> */}

                                        <div style={{ marginTop: 5 }}>
                                            <div style={{ fontWeight: 700, fontSize: 18, color: "#000", textDecoration: "underline" }}>
                                                <p>Bank Account Details</p>
                                            </div>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                <div style={{ width: "50%", display: "flex", flexDirection: "column", fontSize: 11, padding: 10 }}>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <div style={{ width: "50%" }}>
                                                                <p style={{ fontWeight: 500 }}>Bank Name:</p>
                                                            </div>
                                                            <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <p style={{ fontWeight: 600 }}>Atlas Mara Bank Zambia</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <div style={{ width: "50%" }}>
                                                                <p style={{ fontWeight: 500 }}>Account Name:</p>
                                                            </div>
                                                            <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <p style={{ fontWeight: 600 }}>Zamace Limited</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <div style={{ width: "50%" }}>
                                                                <p style={{ fontWeight: 500 }}>Account Number:</p>
                                                            </div>
                                                            <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <p style={{ fontWeight: 600 }}>3256064357010 Settlement (ZMW)</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <div style={{ width: "50%" }}>
                                                                <p style={{ fontWeight: 500 }}>TPIN:</p>
                                                            </div>
                                                            <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <p style={{ fontWeight: 600 }}>1001859467</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ width: "50%", marginTop: 5, display: "flex", flexDirection: "column", fontSize: 11, padding: 10 }}>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", margniTop: 5 }}>
                                                            <div style={{ width: "50%" }}>
                                                                <p style={{ fontWeight: 500 }}>Branch Name:</p>
                                                            </div>
                                                            <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <p style={{ fontWeight: 600 }}>East Park Mall</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <div style={{ width: "50%" }}>
                                                                <p style={{ fontWeight: 500 }}>Branch Code: 325</p>
                                                            </div>
                                                            <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <p style={{ fontWeight: 600 }}>Zamace Limited</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <div style={{ width: "50%" }}>
                                                                <p style={{ fontWeight: 500 }}>Sort Code:</p>
                                                            </div>
                                                            <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <p style={{ fontWeight: 600 }}>200050</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center", margniTop: 5 }}>
                                                        <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                            <div style={{ width: "50%" }}>
                                                                <p style={{ fontWeight: 500 }}>Swift Code:</p>
                                                            </div>
                                                            <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", }}>
                                                                <p style={{ fontWeight: 600 }}>FMBZZMLX</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p
                                    style={{
                                        position: "absolute",
                                        bottom: 15,
                                        left: 0,
                                        right: 0,
                                        textAlign: "center",
                                        color: "#252b32",
                                    }}
                                    render={({ pageNumber, totalPages }) => `div ${pageNumber} of ${totalPages}`}
                                    fixed
                                />
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default WarehouseReceiptTradingInvoicePDF;