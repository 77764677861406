import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as app_consts from "../../../../utils/constants";
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { createCommodityGrading } from '../../../../utils/actions';
import { soya_grading_scale } from '../../../../utils/commodity_grading_scale';

const grading_criteria = { moisture_content: "", extraneous_matter: "", total_defective_grains: "", split_beans: "", green_beans: "", immature_shrivelled_beans: "", oil_content: "", protein_content: "", };

const SoyaBeanGradingStep = ({ nextStep, prevStep, prevData, setPrevData }) => {

  const [formData, setFormData] = useState(prevData);
  const [delivery_id, setDeliveryId] = useState("");
  const [commodity_grade, setCommodityGrade] = useState("--");
  const [commod_grad_criteria, setCommodGradeCriteria] = useState(grading_criteria);

  useEffect(() => {
    setFormData(prevData);
    setDeliveryId(prevData.delivery_id);
  }, [prevData]);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // Validate Form Params
  const commodityGradingSchema = yup.object().shape({
    moisture_content: yup.string().required('Moisture Content is required'),
    extraneous_matter: yup.string().required('Extraneous Matter is required'),
    total_defective_grains: yup.string().required('Total Defective Grains is required'),
    split_beans: yup.string().required('Split Benas is Required'),
    green_beans: yup.string().required('Green Beans is required'),
    immature_shrivelled_beans: yup.string().required('Immature Shrivelled Beans is required'),
    oil_content: yup.string().required('Oil Content is required'),
    protein_content: yup.string().required('Protein Content is required'),
  });

  // Formik Validation
  const formik = useFormik({
    initialValues: {
      moisture_content: '',
      extraneous_matter: '',
      total_defective_grains: "",
      split_beans: '',
      green_beans: '',
      immature_shrivelled_beans: '',
      oil_content: '',
      protein_content: '',
      commodity: app_consts.SOYA_BEANS,
      delivery_id: delivery_id,
      grade: commodity_grade
    },

    validationSchema: commodityGradingSchema,
    onSubmit: async (values) => {
      setError("");
      setLoading(true);
      try
      {
        const commodity_params = {
          moisture_content: parseFloat(values.moisture_content).toFixed(2),
          extraneous_matter: parseFloat(values.extraneous_matter).toFixed(2),
          total_defective_grains: parseFloat(values.total_defective_grains).toFixed(2),
          split_beans: parseFloat(values.split_beans).toFixed(2),
          green_beans: parseFloat(values.green_beans).toFixed(2),
          immature_shrivelled_beans: parseFloat(values.immature_shrivelled_beans).toFixed(2),
          oil_content: parseFloat(values.oil_content).toFixed(2),
          protein_content: parseFloat(values.protein_content).toFixed(2),
          commodity: app_consts.SOYA_BEANS,
          delivery_id: delivery_id,
          grade: commodity_grade,
        };

        await dispatch(createCommodityGrading(commodity_params));

        nextStep();

      } catch (error)
      {
        if (error.response)
        {
          setError(error.response.data.message);
        }
        else
        {
          setError("Failed to Grade Commodity");
        }
      } finally
      {
        setLoading(false);
      }
    },
  });

  const handleMeasurementChange = (e) => {
    const result = soya_grading_scale(e.target.name, e.target.value);
    if (result === "A")
    {
      setCommodGradeCriteria({ ...commod_grad_criteria, [e.target.name]: 1 });
    }
    else if (result === "B")
    {
      setCommodGradeCriteria({ ...commod_grad_criteria, [e.target.name]: 2 });
    }
    else if (result === "C")
    {
      setCommodGradeCriteria({ ...commod_grad_criteria, [e.target.name]: 3 });
    }
    else
    {
      setCommodGradeCriteria({ ...commod_grad_criteria, [e.target.name]: 0 });
    }
    formik.setFieldValue(e.target.name, e.target.value);
  };

  // Listen to Changes in Formik Values
  useEffect(() => {
    if (commod_grad_criteria === grading_criteria)
    {
      setCommodityGrade("---");
    }
    else
    {
      const sum = parseFloat(parseFloat(commod_grad_criteria.moisture_content) + parseFloat(commod_grad_criteria.extraneous_matter) + parseFloat(commod_grad_criteria.total_defective_grains) + parseFloat(commod_grad_criteria.split_beans) + parseFloat(commod_grad_criteria.green_beans) + parseFloat(commod_grad_criteria.immature_shrivelled_beans) + parseFloat(commod_grad_criteria.oil_content) + parseFloat(commod_grad_criteria.protein_content)).toFixed(2);

      const average = Math.round(sum / 8);


      if (average === 1)
      {
        setCommodityGrade("A");
      }
      else if (average === 2)
      {
        setCommodityGrade("B");
      }
      else if (average === 3)
      {
        setCommodityGrade("C");
      }
      else
      {
        setCommodityGrade("---");
      }
    }
  }, [commod_grad_criteria]);

  const handleOnblur = (name, value) => {
    if (value)
    {
      formik.setFieldValue(name, parseFloat(value).toFixed(2));
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-row w-full'>
          <div className="grid gap-6 mb-8 lg:grid-cols-3 p-6 sm:p-16 lg:p-8 lg:w-9/12 m-auto">
            <div>
              <label htmlFor="moisture_content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Mositure Content
              </label>
              <input
                step="0.01"
                type="number"
                min="0.00"
                default="0.00"
                name="moisture_content"
                value={formik.values.moisture_content}
                onChange={handleMeasurementChange}
                onBlur={() => handleOnblur("moisture_content", formik.values.moisture_content)}
                // required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              />
              {formik.touched.moisture_content && formik.errors.moisture_content && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.moisture_content}</p>
              )}
            </div>
            <div>
              <label htmlFor="extraneous_matter" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Extraneous Matter
              </label>
              <input
                step="0.01"
                type="number"
                min="0.00"
                default="0.00"
                name="extraneous_matter"
                value={formik.values.extraneous_matter}
                onChange={handleMeasurementChange}
                onBlur={() => handleOnblur("extraneous_matter", formik.values.extraneous_matter)}
                // required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              />
              {formik.touched.extraneous_matter && formik.errors.extraneous_matter && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.extraneous_matter}</p>
              )}
            </div>
            <div>
              <label htmlFor="total_defective_grains" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Total Defective Grain
              </label>
              <input
                step="0.01"
                type="number"
                min="0.00"
                default="0.00"
                name="total_defective_grains"
                value={formik.values.total_defective_grains}
                onChange={handleMeasurementChange}
                onBlur={() => handleOnblur("total_defective_grains", formik.values.total_defective_grains)}
                // required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              />
              {formik.touched.total_defective_grains && formik.errors.total_defective_grains && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.total_defective_grains}</p>
              )}
            </div>
            <div>
              <label htmlFor="split_beans" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Split Beans
              </label>
              <input
                step="0.01"
                type="number"
                min="0.00"
                default="0.00"
                name="split_beans"
                value={formik.values.split_beans}
                onChange={handleMeasurementChange}
                onBlur={() => handleOnblur("split_beans", formik.values.split_beans)}
                // required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              />
              {formik.touched.split_beans && formik.errors.split_beans && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.split_beans}</p>
              )}
            </div>
            <div>
              <label htmlFor="green_beans" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Green Beans
              </label>
              <input
                step="0.01"
                type="number"
                min="0.00"
                default="0.00"
                name="green_beans"
                value={formik.values.green_beans}
                onChange={handleMeasurementChange}
                onBlur={() => handleOnblur("green_beans", formik.values.green_beans)}
                // required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              />
              {formik.touched.green_beans && formik.errors.green_beans && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.green_beans}</p>
              )}
            </div>
            <div>
              <label htmlFor="immature_shrivelled_beans" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Immature Shrivelled Beans
              </label>
              <input
                step="0.01"
                type="number"
                min="0.00"
                default="0.00"
                name="immature_shrivelled_beans"
                value={formik.values.immature_shrivelled_beans}
                onChange={handleMeasurementChange}
                onBlur={() => handleOnblur("immature_shrivelled_beans", formik.values.immature_shrivelled_beans)}
                // required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              />
              {formik.touched.immature_shrivelled_beans && formik.errors.immature_shrivelled_beans && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.immature_shrivelled_beans}</p>
              )}
            </div>
            <div>
              <label htmlFor="oil_content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Oil Content
              </label>
              <input
                step="0.01"
                type="number"
                min="0.00"
                default="0.00"
                name="oil_content"
                value={formik.values.oil_content}
                onChange={handleMeasurementChange}
                onBlur={() => handleOnblur("oil_content", formik.values.oil_content)}
                // required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              />
              {formik.touched.oil_content && formik.errors.oil_content && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.oil_content}</p>
              )}
            </div>
            <div>
              <label htmlFor="protein_content" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Protein Content
              </label>
              <input
                step="0.01"
                type="number"
                min="0.00"
                default="0.00"
                name="protein_content"
                value={formik.values.protein_content}
                onChange={handleMeasurementChange}
                onBlur={() => handleOnblur("protein_content", formik.values.protein_content)}
                // required
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              />
              {formik.touched.protein_content && formik.errors.protein_content && (
                <p className="text-red-500 text-sm mt-1">{formik.errors.protein_content}</p>
              )}
            </div>
          </div>
          <div className='w-3/12 flex flex-column justify-center items-center h-auto bg-gray-50 p-2'>
            <div>
              <p className='text-center text-3xl mb-2 font-medium text-gray-700'>Commodity Grade</p>
              <p className='text-5xl text-center font-bold my-6'>{commodity_grade}</p>
            </div>
          </div>
        </div>
        {error && error.length > 0 && (
          <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
            <span className="font-medium">Error: </span>
            {error}
          </div>
        )}
        <div className='my-4 px-6 flex flex-row justify-end items-center'>
          {/* <button
    onClick={prevStep}
    type="submit"
    className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
>
    Previous Step: Truck Information
</button> */}
          <button
            // disabled={!(formData.values.commmodity_id)}
            // onClick={nextStep}
            type="submit"
            className={`text-white bg-green-600 cursor-pointer hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800`}
          >
            Next Step: Storage Information
          </button>
        </div>
      </form>
    </>
  );
};

export default SoyaBeanGradingStep;