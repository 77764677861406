import React from "react";

const DocumentViewer = ({ name, url, onEditClick }) => {
    return (
        <div className="border border-gray-200">
            <div className="flex justify-between items-center p-4 bg-gray-100 border-b border-gray-300">
                <h2 className="text-xl font-bold dark:text-white">{name}</h2>

                <button onClick={onEditClick} type="button" class="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-2">
                        <path d="M9.25 13.25a.75.75 0 001.5 0V4.636l2.955 3.129a.75.75 0 001.09-1.03l-4.25-4.5a.75.75 0 00-1.09 0l-4.25 4.5a.75.75 0 101.09 1.03L9.25 4.636v8.614z" />
                        <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
                    </svg>
                    Upload Document
                </button>
            </div>
            {url && (url.endsWith(".pdf") ? (
                <embed src={url} type="application/pdf" width="100%" height="500px" />
            ) : (
                <img src={url} alt={url} className="w-full" />
            ))}
        </div>
    );
};

export default DocumentViewer;

