import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import RSelect from 'react-select';
import { getFilteredEntityTypeActiveStatusVerified, getWarehouseListByEntityStatus, list_all_producers_with_entities, processBulkDeliveryReset, process_bulk_delivery, retreive_pending_entity_list_by_type, retrieveSpecificWarehouseStorageTypeReset, retrieve_specific_warehouse_storage_types } from '../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { convertToTitleCase, rSelectMapFun, retreive_rSelectVariables } from '../../../utils/functionalUtils';
import * as app_consts from '../../../utils/constants';
import { useParams } from 'react-router-dom';
import { acceptable_grades } from '../../../utils/commodity_grading_scale';
import countries from "../../../utils/countries.json";
import { FileInput } from 'flowbite-react';
import * as yup from 'yup';

const CommodityDeliveryInfoStep = ({ nextStep, setPrevData, prevData }) => {

    const dispatch = useDispatch();
    const { entity_id } = useParams();

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [warehouseList, setWarehouseList] = useState([]);
    const [depositor_type, setDepositorType] = useState("");
    const [selectedDepositorType, setSelectedDepositorType] = useState([]);
    const [producerList, setProducerList] = useState([]);
    const [depositor_own_label, setDepositorOwnlabel] = useState("");
    const [permitted_commodities, setPermittedCommodities] = useState([]);
    const [grades, setGrades] = useState();
    const [errors, setErrors] = useState({});
    const [storage_list_loading, setStorageListLoading] = useState(false);
    const [storage_list, setStorageList] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState({
        grade_cert_img: null,
    });

    // Retrieve Producers and Service Provider List On Page Load
    useEffect(() => {
        dispatch(list_all_producers_with_entities());

        // Fetch Warehouse List
        dispatch(getWarehouseListByEntityStatus({ entity_id: entity_id, status: app_consts.CERTIFIED }));
    }, []);

    // Yup Validation
    const deliveriesSchema = yup.object().shape({
        depositor_id: yup.string().required('Depositor is Required'),
        commodity: yup.string().required('Commodity is required'),
        quantity: yup.string().required('Quantity is required'),
        origin: yup.string().required('Origin is required'),
        grade: yup.string().required('Commodity Grade is required'),
        description: yup.string().required('Description is required'),
        warehouse_id: yup.string().required('Warehouse is required'),
        storage_type: yup.string().required('Storage Type is Required'),
        deposit_date: yup.string().required('First Deposit Date is required'),
        offloading_time: yup.string().required('Offlaoding Time is required'),
        detailed_storage_description: yup.string().required('Detailed Storage Description is required'),
        grade_cert_img: yup.string().required('Grading Certificate is required'),
    });

    const formik = useFormik({
        initialValues: {
            depositor_id: '',
            commodity: "",
            quantity: "",
            origin: "",
            grade: "",
            description: "",
            warehouse_id: "",
            storage_type: '',
            deposit_date: '',
            offloading_time: '',
            detailed_storage_description: '',
            grade_cert_img: ""
        },
        validationSchema: deliveriesSchema,
        onSubmit: async (values) => {
            setError("");
            setLoading(true);
            try
            {
                const bulkDeliveryParams = {
                    depositor_id: values.depositor_id,
                    commodity: values.commodity,
                    quantity: values.quantity,
                    origin: values.origin,
                    grade: values.grade,
                    description: values.description,
                    warehouse_id: values.warehouse_id,
                    storage_type: values.storage_type,
                    deposit_date: values.deposit_date,
                    offloading_time: values.offloading_time,
                    detailed_storage_description: values.detailed_storage_description,
                    grade_cert_img: values.grade_cert_img
                };

                await dispatch(process_bulk_delivery(bulkDeliveryParams));

            } catch (error)
            {
                if (error.response)
                {
                    if (error.response.status === 400)
                    {
                        setError(error.response.data.message);
                    }
                    else if (error.response.status === 500)
                    {
                        setError("Failed to Process Bulk Delivery.");
                    }
                }
                else
                {
                    setError("Failed to Process Bulk Delivery.");
                }
            } finally
            {
                setLoading(false);
            }
        },
    });

    // Listen to Create Bulk Delivery Reducer
    const warehouse_receipt_data = useSelector((state) => state.processBulkDelivery);
    // 
    useEffect(() => {
        if (warehouse_receipt_data.status !== app_consts.IDLE)
        {
            if (warehouse_receipt_data.isLoading)
            {
                setLoading(true);
            }
            else
            {
                setLoading(false);
                if (warehouse_receipt_data.error)
                {
                    setError(warehouse_receipt_data.message);
                }
                else if (warehouse_receipt_data.data)
                {
                    setPrevData({ ...prevData, ["warehouse_receipt_data"]: warehouse_receipt_data.data });
                    nextStep();
                }
                dispatch(processBulkDeliveryReset());
            }
        }
    }, [warehouse_receipt_data]);

    // Listen to Changes When Fetching Warehouse List
    const warehouseListData = useSelector((state) => state.warehouseList);

    useEffect(() => {
        const fetchData = async () => {
            if (warehouseListData.data)
            {
                let mappedData = [];

                await Promise.all(
                    warehouseListData?.data?.data?.map(async (data_to_map) => {
                        const { label, value } = retreive_rSelectVariables(app_consts.WAREHOUSES_MAP, data_to_map);
                        const result = await rSelectMapFun(label, value);
                        mappedData.push(result);
                    })
                );

                setWarehouseList(mappedData);
            }
        };

        fetchData();
    }, [warehouseListData]);

    // Listen to Producers State
    const producersState = useSelector((state) => state.listAllProducersWithEntities);
    // 
    useEffect(() => {
        if (producersState.data)
        {
            setProducerList(producersState?.data);
        }
    }, [producersState]);

    // Handle Changes in React Select Field
    const handlRSelectChanges = (option, name) => {
        if (option)
        {
            formik.setFieldValue(name, option.value);
        }
        else
        {
            formik.setFieldValue(name, "");
        }
    };

    // Handle Changes in Depositor Type Selection
    const handleDepositorTypeChange = async (depositorType) => {
        if (depositorType)
        {
            setDepositorType(depositorType);
            setSelectedDepositorType(await filterDepositors(depositorType));
        }
        else
        {
            setSelectedDepositorType([]);
        }
        if (depositorType !== app_consts.WAREHOUSE_OPERATOR.toLowerCase())
        {
            formik.setFieldValue('depositor_id', "");
        }
    };

    const filterDepositors = async (depositorType) => {
        const filteredList = producerList?.filter((producer) => depositorType === producer.type && producer.entity_id === entity_id);

        let mappedData = [];

        await Promise.all(
            filteredList?.map(async (data_to_map) => {
                const { label, value } = retreive_rSelectVariables(app_consts.PRODUCERS_LIST_MAP, data_to_map);
                const result = await rSelectMapFun(label, value);
                mappedData.push(result);
            })
        );
        if (depositorType && depositorType === app_consts.WAREHOUSE_OPERATOR.toLowerCase() && mappedData)
        {
            setDepositorOwnlabel(mappedData[0]?.label);
            formik.setFieldValue('depositor_id', mappedData[0]?.value);
            handleDepositorCommodities(mappedData[0]);
        }
        return mappedData;
    };

    // Handle Depositor Commodities
    const handleDepositorCommodities = (option) => {
        formik.setFieldValue("commodity", "");
        if (option)
        {
            // Fileter Out Producer Commodities
            const filteredList = producerList?.filter((producer) => option.value === producer.id);
            setPermittedCommodities(filteredList[0].commodities);
        }
        else
        {
            setPermittedCommodities([]);
        }
    };

    // Handle Acceptable Grades
    const handleAcceptableGrades = (commodity) => {
        formik.setFieldValue("grade", "");
        setGrades(acceptable_grades(commodity?.replace(/ /g, '_').toLowerCase()));
    };
    const MAX_FILE_SIZE_MB = 20;

    const handleFileChange = (e, fieldName) => {
        const selectedFile = e.target.files[0];

        if (selectedFile)
        {
            const fileSizeInMB = selectedFile.size / (1024 * 1024);
            if (fileSizeInMB > MAX_FILE_SIZE_MB)
            {
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: null }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: `File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`,
                }));
                return;
            }

            const allowedFileTypes = [
                'image/jpeg',
                'image/jpg',
                'image/png',
                'application/pdf',
            ];

            if (allowedFileTypes.includes(selectedFile.type))
            {
                formik.setFieldValue("grade_cert_img", selectedFile);
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: selectedFile }));
                setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null, file: null }));
            } else
            {
                formik.setFieldValue("grade_cert_img", "");
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: null }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: "Please select a valid image or PDF file (PNG, JPEG, or PDF).",
                }));
            }
        }
    };

    const handleOnblur = (name, value) => {
        if (value)
        {
            formik.setFieldValue(name, parseFloat(value).toFixed(2));
        }
    };

    // Listen to Changes on Warehouse Storages List
    const storageListListener = useSelector((state) => state.retrieveSpecificWarehouseStorageType);
    // 
    useEffect(() => {

        if (storageListListener.status !== app_consts.IDLE)
        {
            if (storageListListener.isLoading)
            {
                setStorageListLoading(true);
            }
            else
            {
                if (storageListListener.error)
                {
                    // setError(warehouseReceiptListListener.error);
                }
                else if (storageListListener.data)
                {
                    const fetchData = async () => {
                        if (storageListListener.data)
                        {
                            let mappedData = [];

                            await Promise.all(
                                storageListListener?.data?.map(async (data_to_map) => {
                                    const { label, value } = retreive_rSelectVariables(app_consts.AVAILABLE_STORAGE_LIST_MAP, data_to_map);
                                    const result = await rSelectMapFun(label, value);
                                    mappedData.push(result);
                                })
                            );

                            setStorageList(mappedData);
                        }
                    };

                    fetchData();
                }
                dispatch(retrieveSpecificWarehouseStorageTypeReset());
                setStorageListLoading(false);
            }
        }

    }, [storageListListener]);

    return (
        <>

            <form className='justify-center items-center' onSubmit={formik.handleSubmit}>
                <div className="px-4 flex flex-col items-stretch justify-start border-gray-100">
                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                        Depositor Information
                    </h3>
                </div>
                <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label htmlFor="depositor_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Depositor Type
                        </label>
                        <select
                            name="depositor_type"
                            onChange={(e) => handleDepositorTypeChange(e.target.value)}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value=''>Choose Depositor type</option>
                            <option value='individual'>Individual</option>
                            <option value='commercial'>Commercial</option>
                            <option value='public_entity'>Public Entity</option>
                            <option value='warehouse_operator'>Own</option>
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor='profileType'
                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                            Depositor
                        </label>
                        {
                            depositor_type === app_consts.WAREHOUSE_OPERATOR.toLowerCase() ?
                                <>
                                    <input
                                        type='text'
                                        name="depositor_id"
                                        disabled
                                        value={depositor_own_label || "Depositor Not Found"}
                                        className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                    />
                                </>
                                :
                                <>
                                    <RSelect
                                        name='depositor_id'
                                        // value={formik.values.depositor_id}
                                        onChange={(e) => { handlRSelectChanges(e, "depositor_id"); handleDepositorCommodities(e); }}
                                        options={selectedDepositorType}
                                        isClearable={true}
                                    />
                                </>
                        }
                        {formik.touched.depositor_id && formik.errors.depositor_id && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.depositor_id}</p>
                        )}
                    </div>
                </div>
                <div className="px-4 flex flex-row items-stretch justify-between border-gray-100">
                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                        Commodity Information
                    </h3>
                </div>
                <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label htmlFor="commodity" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Commodity
                        </label>
                        <select
                            name="commodity"
                            value={formik.values.commodity}
                            onChange={(e) => { formik.setFieldValue('commodity', e?.target?.value); handleAcceptableGrades(e?.target?.value); }}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose Commodity</option>
                            {
                                permitted_commodities?.map((per_commod) => {
                                    return (
                                        <>
                                            <option value={per_commod}>{convertToTitleCase(per_commod)}</option>
                                        </>
                                    );
                                })
                            }
                        </select>
                        {formik.touched.commodity && formik.errors.commodity && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.commodity}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="quantity" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Quantity <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="quantity"
                            onChange={formik.handleChange}
                            value={formik.values.quantity}
                            onBlur={() => handleOnblur("quantity", formik.values.quantity)}
                            className="bg-gray-100 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.quantity && formik.errors.quantity && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.quantity}</p>
                        )}
                    </div>
                    <div className="mb-6">
                        <label htmlFor="origin" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Origin Country
                        </label>
                        <select
                            name="origin"
                            value={formik.values.origin}
                            onChange={formik.handleChange}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            {countries.map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        {formik.touched.origin && formik.errors.origin && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.origin}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="grade" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Commodity Grading
                        </label>
                        <select
                            name="grade"
                            value={formik.values.grade}
                            onChange={formik.handleChange}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose Commodity Grade</option>
                            {
                                grades?.map((grade) => {
                                    return (
                                        <>
                                            <option value={grade}>{convertToTitleCase(grade)}</option>
                                        </>
                                    );
                                })
                            }
                        </select>
                        {formik.touched.grade && formik.errors.grade && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.grade}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="grade_cert_img" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Upload Grading Ceritficate
                        </label>
                        <FileInput
                            label="Upload Grading Ceritficate"
                            name="grade_cert_img"
                            onChange={(e) => handleFileChange(e, 'grade_cert_img')}
                        />
                        {errors.grade_cert_img && <p className="text-red-500 text-xs mt-1">{errors.grade_cert_img}</p>}
                    </div>
                    <div>
                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Commodity Description
                        </label>
                        <input
                            type="text"
                            name="description"
                            value={formik.values.description}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.description && formik.errors.description && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.description}</p>
                        )}
                    </div>
                </div>
                <div className="px-4 flex flex-row items-stretch justify-between border-gray-100">
                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                        Storage Information
                    </h3>
                </div>
                <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label
                            htmlFor='profileType'
                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                            Warehouse
                        </label>
                        <RSelect
                            name='warehouse_id'
                            // value={formik.values.warehouse_id}
                            onChange={(e) => { handlRSelectChanges(e, "warehouse_id"); if (e?.value) dispatch(retrieve_specific_warehouse_storage_types(e.value)); setStorageList([]); }}
                            options={warehouseList}
                            isClearable={true}
                        />
                        {formik.touched.warehouse_id && formik.errors.warehouse_id && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.warehouse_id}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="storage_type" className="flex flex-row justify-between block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            <span>Storage Type</span>
                            {
                                storage_list_loading && (
                                    <>
                                        <div className="text-center">
                                            <div role="status">
                                                <svg
                                                    aria-hidden="true"
                                                    className="inline w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                    viewBox="0 0 100 101"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                        fill="currentColor"
                                                    />
                                                    <path
                                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                        fill="currentFill"
                                                    />
                                                </svg>
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </label>
                        <select
                            name="storage_type"
                            value={formik.values.storage_type}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose Storage Type</option>
                            {
                                storage_list?.map((storage) => {
                                    return (
                                        <>
                                            <option value={storage.value}>{storage.label}</option>
                                        </>
                                    );
                                })
                            }
                        </select>
                        {formik.touched.storage_type && formik.errors.storage_type && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.storage_type}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="deposit_date" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            First Deposit Date
                        </label>
                        <input
                            type="datetime-local"
                            name="deposit_date"
                            value={formik.values.deposit_date}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.deposit_date && formik.errors.deposit_date && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.deposit_date}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="offloading_time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Last Deposit Date
                        </label>
                        <input
                            type="datetime-local"
                            name="offloading_time"
                            value={formik.values.offloading_time}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.offloading_time && formik.errors.offloading_time && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.offloading_time}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="description" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Detailed Commodity Storage Description
                        </label>
                        <input
                            type="text"
                            name="detailed_storage_description"
                            value={formik.values.detailed_storage_description}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.detailed_storage_description && formik.errors.detailed_storage_description && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.detailed_storage_description}</p>
                        )}
                    </div>
                </div>{error && error.length > 0 && (
                    <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
                        <span className="font-medium">Error: </span>
                        {error}
                    </div>
                )}
                <div className='mb-4 px-6 flex flex-row justify-end items-center'>
                    {/* <button
                        onClick={prevStep}
                        type="submit"
                        className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                    >
                        Previous Step: Commodity Grading Information
                    </button> */}
                    <button
                        // onClick={nextStep}
                        type="submit"
                        className={`${loading ? "bg-gray-400" : "bg-green-700 hover:bg-green-800 dark:bg-green-600 dark:hover:bg-green-700"} text-white  focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-green-800`}
                    >
                        {loading ? "Processing..." : "Next Step: Inventory Information"}
                    </button>
                </div>
            </form>
        </>
    );
};

export default CommodityDeliveryInfoStep;