import React, { useEffect, useState } from 'react';
import { convertToTitleCase, convertUnderscoreToTitleCase } from '../../../utils/functionalUtils';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { updateWarehouseReceiptSettlement } from '../../../utils/actions';
import { useDispatch } from 'react-redux';
import { INDIVIDUAL } from '../../../utils/constants';

const WarehouseReceiptStep = ({ nextStep, prevStep, prevData, }) => {

    const dispatch = useDispatch();

    const [previewData, setPreviewData] = useState(prevData);
    const [warehouse_receipt_data, setWarehouseReceiptData] = useState([]);
    const [warehouse_settlement_id, setWarehouseSettlement_id] = useState("");
    const [delivery_id, setDeliveryId] = useState("");
    const [total_amount, setTotalAmount] = useState("0.00");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState("");

    // Tax Rate
    const tax_rate = 0.16;

    useEffect(() => {
        setPreviewData(prevData);
        if (prevData)
        {
            setDeliveryId(prevData.delivery_id);
            setWarehouseSettlement_id(prevData.warehouse_receipt_data.warehouse_settlement_id);
        }
    }, [prevData]);

    useEffect(() => {
        if (prevData.warehouse_receipt_data)
        {
            setWarehouseReceiptData(prevData.warehouse_receipt_data);
        }
        setPreviewData(prevData);
    }, [prevData]);

    // Yup Validation
    const warehouseSettlementchema = yup.object().shape({
        payment_method: yup.string().required('Payment Method is Required'),
        payment_status: yup.string().required('Payment Status is required'),
    });

    // Formik Validation
    const formik = useFormik({
        initialValues: {
            payment_method: '',
            payment_status: '',
            delivery_id: delivery_id,
            warehouse_settlement_id: warehouse_settlement_id,
        },
        validationSchema: warehouseSettlementchema,
        onSubmit: async (values) => {
            setError("");
            setLoading(true);
            try
            {
                const settlementParams = {
                    payment_method: values.payment_method,
                    payment_status: values.payment_status,
                    warehouse_settlement_id: warehouse_settlement_id,
                    delivery_id: delivery_id,
                };


                await dispatch(updateWarehouseReceiptSettlement(settlementParams));

                nextStep();

            } catch (error)
            {
                if (error.response)
                {
                    if (error.response.status === 500)
                    {
                        setError("Failed to Add Warehouse Settlement Details.");
                    }
                    else
                    {
                        setError(error.response.data.message);
                    }
                }
                else
                {
                    setError("Failed to Add Warehouse Settlement Details.");
                }
            } finally
            {
                setLoading(false);
            }
        },
    });

    const handleOnblur = (name, value) => {
        if (value)
        {
            formik.setFieldValue(name, parseFloat(value).toFixed(2));
        }
    };

    // useEffect to Handle Formik Value Changes
    useEffect(() => {
        // Calculate Total Amount
        const totalAmount = ((parseFloat(formik.values.storage_fee) + parseFloat(formik.values.insurance_fee) + parseFloat(formik.values.receipt_issuance_fee) + parseFloat(formik.values.handling_fee)) + (parseFloat(formik.values.storage_fee) + parseFloat(formik.values.insurance_fee) + parseFloat(formik.values.receipt_issuance_fee) + parseFloat(formik.values.handling_fee)) * parseFloat(tax_rate)).toFixed(2);

        // Set Formik Total Amount
        if (!totalAmount)
        {
            formik.setFieldValue('total_amount', "0.00");
        } else
        {
            formik.setFieldValue('total_amount', totalAmount);
        }
    }, [formik.values]);

    return (
        <>
            <div className="px-6 flex flex-col items-stretch justify-start border-gray-100">
                <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                    Warehouse Receipt Information {previewData.commodity ? <span className='font-medium text-xl'> - {convertUnderscoreToTitleCase(previewData.commodity)}</span> : ""}
                </h3>
            </div>
            <div className="lg:p-6 md:p-4 p-2 flex lg:flex-row flex-col w-full">
                <div className='lg:w-2/4 w-full'>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300 '>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Warehouse Receipt
                        </span>
                        <span>
                            {warehouse_receipt_data.receipt_number ? warehouse_receipt_data.receipt_number : "---"}
                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Owner ID
                        </span>
                        <span>
                            {warehouse_receipt_data.depositor_id ? warehouse_receipt_data.depositor_id : "---"}
                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300 '>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Receipt Owner
                        </span>
                        <span>
                            {warehouse_receipt_data?.depositor_type && warehouse_receipt_data?.depositor_type !== INDIVIDUAL.toLowerCase() ? warehouse_receipt_data?.producer_entity_name : warehouse_receipt_data.depositor_name}
                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className=" block text-sm font-medium text-gray-600 dark:text-white">
                            Owner Address
                        </span>
                        <span className="text-end">
                            {warehouse_receipt_data?.depositor_type && warehouse_receipt_data?.depositor_type !== INDIVIDUAL.toLowerCase() ? `${warehouse_receipt_data?.producer_entity_physical_address}, ${warehouse_receipt_data?.producer_entity_province}, ${warehouse_receipt_data?.producer_entity_country}` : warehouse_receipt_data.depositor_address}

                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className="text-end block text-sm font-medium text-gray-600 dark:text-white">
                            Commodity
                        </span>
                        <span>
                            {warehouse_receipt_data.commodity_name ? convertToTitleCase(warehouse_receipt_data.commodity_name) : "---"}
                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Quantity
                        </span>
                        <span>
                            {warehouse_receipt_data.commodity_quantity ? (`${warehouse_receipt_data.commodity_quantity} Metric Tonnes`) : "---"}
                        </span>
                    </div>
                </div>
                <div className='lg:w-2/4 w-full'>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Date of Deposit
                        </span>
                        <span>
                            {warehouse_receipt_data.deposit_date ? format(new Date(warehouse_receipt_data.deposit_date), 'yyyy-MM-dd') : "---"}
                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Maturity Date
                        </span>
                        <span>
                            {warehouse_receipt_data.maturity_date ? format(new Date(warehouse_receipt_data.maturity_date), 'yyyy-MM-dd') : "---"}
                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Grade
                        </span>
                        <span>
                            {warehouse_receipt_data.grade ? warehouse_receipt_data.grade : "---"}
                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Warehouse Operator
                        </span>
                        <span>
                            {warehouse_receipt_data.warehouse_operator_name ? warehouse_receipt_data.warehouse_operator_name : "---"}
                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Warehouse Storage Site
                        </span>
                        <span>
                            {warehouse_receipt_data.storage_site ? warehouse_receipt_data.storage_site : "---"}
                        </span>
                    </div>
                    <div className='p-4 flex flex-row justify-between items-center m-auto w-11/12 border-t border-b border-gray-300'>
                        <span htmlFor="entity_email" className="block text-sm font-medium text-gray-600 dark:text-white">
                            Operator ID
                        </span>
                        <span>
                            {warehouse_receipt_data.operator_id ? warehouse_receipt_data.operator_id : "---"}
                        </span>
                    </div>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="px-4 flex flex-col items-stretch justify-start border-gray-100">
                    <h4 className="text-xl font-bold leading-7 text-green-800 sm:truncate sm:text-xl sm:tracking-tight">
                        Warehouse Settlement Information
                    </h4>
                </div>
                <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label htmlFor="payment_method" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Payment Method
                        </label>
                        <select
                            name="payment_method"
                            value={formik.values.payment_method}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose Payment Method</option>
                            <option value="cash">Cash</option>
                            <option value="bank_transfer">Bank Transfer</option>
                        </select>
                        {formik.touched.payment_method && formik.errors.payment_method && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.payment_method}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="storage_fee" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Storage Fee <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(ZMW)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="storage_fee"
                            disabled
                            value={parseFloat(warehouse_receipt_data?.storage_fee || 0).toFixed(2) || ""}
                            className=" cursor-not-allowed bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.storage_fee && formik.errors.storage_fee && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.storage_fee}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="receipt_issuance_fee" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Receipt Issuance Fee <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(ZMW)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="receipt_issuance_fee"
                            disabled
                            value={parseFloat(warehouse_receipt_data?.receipt_issuance_fee || 0).toFixed(2) || ""}
                            className=" cursor-not-allowed bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="handling_fee" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Handling Fee <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(ZMW)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="handling_fee"
                            disabled
                            value={parseFloat(warehouse_receipt_data?.handling_fee || 0).toFixed(2) || ""}
                            className=" cursor-not-allowed bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.handling_fee && formik.errors.handling_fee && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.handling_fee}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="tax_rate" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Tax Rate <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(16%)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="tax_rate"
                            disabled
                            value={parseFloat(warehouse_receipt_data?.tax_rate || 0).toFixed(2) || ""}
                            className=" cursor-not-allowed bg-gray-200 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.handling_fee && formik.errors.tax_rate && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.tax_rate}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="total_amount" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Total Amount <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(ZMW)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="total_amount"
                            disabled
                            value={parseFloat(warehouse_receipt_data?.total_amount || 0).toFixed(2) || ""}
                            className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="payment_status" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Payment Status
                        </label>
                        <select
                            name="payment_status"
                            value={formik.values.payment_status}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose Payment Status</option>
                            <option value="paid">Paid</option>
                            <option value="unpaid">Unpaid</option>
                        </select>
                        {formik.touched.payment_status && formik.errors.payment_status && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.payment_status}</p>
                        )}
                    </div>
                </div>
                {error && error.length > 0 && (
                    <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
                        <span className="font-medium">Error: </span>
                        {error}
                    </div>
                )}
                <div className='mb-4 px-6 flex flex-row justify-end items-center'>
                    {/* <button
                    onClick={prevStep}
                    type="submit"
                    className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-500 dark:hover:bg-gray-600 dark:focus:ring-gray-700"
                >
                    Previous Step: Storage Information
                </button> */}
                    <button
                        type="submit"
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                    >
                        Generate Warehouse Receipt
                    </button>
                </div>
            </form>
        </>
    );
};

export default WarehouseReceiptStep;