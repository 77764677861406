import React, { useEffect, useState } from 'react';
// import { div, Document, img, div, StyleSheet, div, Font } from "@react-pdf/renderer";
// Import the font files
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import { format } from 'date-fns';
import { convertUnderscoreToTitleCase } from '../../utils/functionalUtils';
// import QRCode from 'qrcode';
import PDFSVGLogo from "../../components/PDFSVGLogo";
import DynamicNavLogo from '../../components/DynamicNavLogo';
import QRCode from 'react-qr-code';
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';
import * as app_consts from '../../utils/constants';

const WarehouseReceiptPDF = ({ data, downloadWarehouseReceipt, downloadable, setDownloadWarehouseReceipt }) => {

    const targetRef = useRef();
    // Register the Lato font
    // Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    const [receiptData, setReceiptData] = useState(data);
    const [genQRCode, setGenQRCode] = useState("");

    useEffect(() => {
        if (data)
        {
            setReceiptData(data);
            // setGenQRCode(QRCode.toDataURL(`https://www.zamace.co.zm/${receiptData?.receipt_number}/${receiptData?.generation_id}`));
            // generateQRCodeimg(`https://www.zamace.co.zm/${receiptData?.receipt_number}/${receiptData?.generation_id}`);
        }
    }, [data]);

    useEffect(() => {
        if (downloadable === true && downloadWarehouseReceipt === true)
        {
            generatePDF(targetRef, { filename: `${receiptData?.receipt_number} Warehouse Receipt` || "Warehouse Receipt" });
            setDownloadWarehouseReceipt(false);
        }
    }, [downloadWarehouseReceipt]);

    // Generated QR Code
    // const generateQRCodeimg = async (data) => {
    //     try
    //     {
    //         const data_qr_code = QRCode.toCanvas(data);
    //         // Generate QR code as an img
    //         const qrDataURL = await QRCode.toDataURL(data);

    //         const blob = await qrDataURL.blob();
    //         return URL.createObjectURL(blob);
    //     } catch (error)
    //     {
    //         console.error('Error generating QR code:', error);
    //         return null;
    //     }
    // };

    return (
        <>
            {
                receiptData && (
                    <div title="Warehouse Receipt" ref={targetRef}>
                        <div size="A4" style={{
                            flexDirection: "column",
                            backgroundColor: "rgba(0,0,0,0)",
                            height: "100%",
                            fontFamily: [LatoBold, LatoRegular]
                        }}>
                            <div fixed>
                                <div style={{
                                    width: "100%",
                                    paddingRight: 10,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "start",
                                    marginBottom: 5,
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        divTransform: "uppercase",
                                        width: "50%",
                                        paddingLeft: 20,
                                    }}>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "end", justifyContent: "end", position: 'relative' }}>
                                            <DynamicNavLogo height='120px' width='500px' />
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "start", justifyContent: "start", position: 'relative' }}>
                                            <div style={{ letterSpacing: "-0.29px", fontWeight: 400, paddingLeft: 6, fontSize: 25, color: "#000", wordSpacing: "0.09px", lineHeight: 0.2, }}>WAREHOUSE RECEIPT</div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 25,
                                            paddingLeft: 5
                                        }}>
                                            <div style={{ fontWeight: 700, fontSize: 18, color: "#000" }}>CURRENT OWNER</div>
                                            <div style={{ fontWeight: 400, fontSize: 18 }}>{receiptData.depositor_type ? <>{receiptData.depositor_type !== app_consts.INDIVIDUAL.toLowerCase() ? receiptData?.depositor_entity_name.toUpperCase() : receiptData?.depositor_name.toUpperCase()}</> : ""}</div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 5,
                                            paddingLeft: 6
                                        }}>
                                            <div style={{ fontWeight: 700, fontSize: 18, color: "#000" }}>CURRENT OWNER IDENTIFICATION NO.</div>
                                            <div style={{ fontWeight: 400, fontSize: 18 }}>{receiptData.depositor_id && (receiptData.depositor_id.toUpperCase())}</div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginTop: 10,
                                            paddingLeft: 6
                                        }}>
                                            <div style={{ fontWeight: 700, fontSize: 18, color: "#000" }}>CURRENT ADDRESS</div>
                                            <div style={{ fontWeight: 400, fontSize: 18 }}>{receiptData.depositor_type ? <>{receiptData.depositor_type !== app_consts.INDIVIDUAL.toLowerCase() ? receiptData?.depositor_entity_address.toUpperCase() : receiptData?.depositor_address.toUpperCase()}</> : ""}</div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "end",
                                        justifyContent: "end",
                                        textTransform: "uppercase",
                                        width: "50%"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                        }}>
                                            <div style={{ letterSpacing: 3, fontWeight: 600, fontSize: 24 }}>RECEIPT SERIAL NO.</div>
                                            <div style={{ fontWeight: 400, fontSize: 13, color: "#252629", lineHeight: 1.5 }}>{receiptData.receipt_number && (receiptData.receipt_number)}</div>
                                        </div>
                                        <div style={{
                                            marginTop: 15, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                        }}>
                                            <div style={{
                                                marginTop: 2, display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-end",
                                            }}>
                                                <div style={{ fontWeight: 700, marginRight: 2, fontSize: 18, color: "#000" }}>DATE ISSUED:</div>
                                                <div style={{ fontWeight: 400, fontSize: 18 }}>{receiptData.deposit_date && (format(new Date(receiptData.deposit_date), 'yyyy-MM-dd'))}</div>
                                            </div>
                                            <div style={{
                                                marginTop: 2, display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-end",
                                            }}>
                                                <div style={{ fontWeight: 700, marginRight: 2, fontSize: 18, color: "#000" }}>MATURITY DATE:</div>
                                                <div style={{ fontWeight: 400, fontSize: 18 }}>{receiptData.maturity_date && (format(new Date(receiptData.maturity_date), 'yyyy-MM-dd'))}</div>
                                            </div>
                                        </div>
                                        <div style={{
                                            marginTop: 15, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end"
                                        }}>
                                            <div style={{
                                                marginTop: 3, display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-end",
                                                paddingLeft: 6
                                            }}>
                                                <div style={{ letterSpacing: 1, fontWeight: 700, fontSize: 18, color: "#000" }}>WAREHOUSE OPERATOR</div>
                                                <div style={{ letterSpacing: 1, fontWeight: 400, fontSize: 18 }}>{receiptData.warehouse_operator_name && (receiptData.warehouse_operator_name.toUpperCase())}</div>
                                            </div>
                                            <div style={{
                                                marginTop: 15,
                                                paddingLeft: 6,
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-end",
                                            }}>
                                                <div style={{
                                                    marginRight: 2, letterSpacing: 1, fontWeight: 700, fontSize: 18, color: "#000"
                                                }}>Storage Site:</div>
                                                <div style={{ letterSpacing: 1, fontWeight: 400, fontSize: 18 }}>{receiptData.storage_site && (receiptData.storage_site.toUpperCase())}</div>
                                            </div>
                                            <div style={{
                                                marginTop: 25,
                                                paddingLeft: 6,
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "flex-end",
                                            }}>
                                                <div style={{ letterSpacing: 1, fontWeight: 700, fontSize: 18, color: "#000" }}>District:</div>
                                                <div style={{ letterSpacing: 1, fontWeight: 400, fontSize: 18 }}>{receiptData.district
                                                    && (receiptData.district.toUpperCase())}</div>
                                            </div>
                                            <div style={{
                                                marginTop: 15, display: "flex",
                                                flexDirection: "column",
                                                alignItems: "flex-end"
                                            }}>
                                                <div style={{ letterSpacing: 1, fontWeight: 700, paddingLeft: 6, fontSize: 18, color: "#000" }}>OPERATOR ZAMACE ID</div>
                                                <div style={{ letterSpacing: 1, fontWeight: 400, paddingLeft: 6, fontSize: 18 }}>{receiptData.operator_id && (receiptData.operator_id)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width: "100%",
                                paddingLeft: 20,
                                paddingRight: 30,
                                paddingTop: 15,
                                paddingBottom: 15,
                                alignItems: "start",
                                marginBottom: 15,
                                position: "relative"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end",
                                }}>

                                    <div style={{
                                        letterSpacing: 1, marginRight: 2, fontWeight: 700, fontSize: 18, color: "#000",
                                        divTransform: "uppercase"
                                    }}>Product:</div>
                                    <div style={{ letterSpacing: 1, fontWeight: 400, fontSize: 18 }}>{receiptData.commodity_name && (convertUnderscoreToTitleCase(receiptData.commodity_name.toUpperCase()))}</div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end",
                                }}>
                                    <div style={{
                                        letterSpacing: 1, fontWeight: 700, marginRight: 2, fontSize: 18, color: "#000",
                                        divTransform: "uppercase"
                                    }}>Grade:</div>
                                    <div style={{ letterSpacing: 1, fontWeight: 400, fontSize: 18 }}>{receiptData.grade && (receiptData.grade.toUpperCase())}</div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end",
                                }}>
                                    <div style={{
                                        letterSpacing: 1, marginRight: 2, fontWeight: 700, fontSize: 18, color: "#000",

                                    }}>QUANTITY (in metric tonnes):</div>
                                    <div style={{ letterSpacing: 1, fontWeight: 400, fontSize: 18 }}>{receiptData.commodity_quantity && (receiptData.commodity_quantity)}</div>
                                </div>
                            </div>
                            <div style={{ marginTop: 5 }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center "
                                }}>
                                    <div style={{ letterSpacing: 1, fontWeight: 700, paddingLeft: 6, fontSize: 21, textAlign: "center" }}>STORAGE OPERATOR STORES</div>
                                    <div style={{ marginTop: 3, fontSize: "24px", textAlign: "center" }}>(metric tonnes in words)</div>
                                </div>
                                <div style={{
                                    marginTop: 15, display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center ",
                                    justifyContent: "space-between",
                                    width: "90%",
                                    alignItems: "start",
                                    marginBottom: 5,
                                    margin: "auto"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center"
                                    }}>
                                        <div style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>TEN THOUSANDS</div>
                                        <div style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <div style={{
                                                fontSize: 12,
                                                divTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[0])}</div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <div style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>THOUSANDS</div>
                                        <div style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <div style={{
                                                fontSize: 12,
                                                divTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[1])}</div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <div style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>HUNDREDS</div>
                                        <div style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <div style={{
                                                fontSize: 12,
                                                divTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[2])}</div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <div style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>TENS</div>
                                        <div style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <div style={{
                                                fontSize: 12,
                                                divTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[3])}</div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <div style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>ONES</div>
                                        <div style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <div style={{
                                                fontSize: 12,
                                                divTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[4])}</div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center ",
                                        justifyContent: "center",
                                    }}>
                                        <div style={{
                                            fontSize: 12,
                                            textAlign: "center"
                                        }}>DECIMALS</div>
                                        <div style={{
                                            border: '1px solid #E5E4E2', backgroundColor: "#E5E4E2", height: 20, width: 70, display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center ",
                                            justifyContent: "center",
                                            marginTop: 5
                                        }} >
                                            <div style={{
                                                fontSize: 12,
                                                divTransform: "uppercase", textAlign: "center"
                                            }}>{receiptData.quantity_words && (receiptData.quantity_words.split(" ")[6])}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                marginTop: 25, display: "flex", flexDirection: "row",
                                display: "flex",
                                width: "100%",
                                padding: 20,
                                alignItems: "start",
                                marginBottom: 5,
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center ", width: "60%",
                                    padding: 5
                                }}>
                                    <p style={{ fontSize: 18, }}>The following costs (VAT inclusive) are payable
                                        from the date of issue of this certificate:</p>
                                    <div style={{ width: "90%", marginTop: 6 }}>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", borderTop: "1px solid #E5E4E2", padding: "5px", borderBottom: "1px solid rgb(241 241 241)" }}>
                                            <p style={{ fontSize: 18, width: "50%", color: "rgb(109 107 103)" }}>Insurance Fee:</p>
                                            <p style={{ fontSize: 18, width: "50%", textAlign: "center" }}>Depositor</p>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", borderTop: "1px solid #E5E4E2", padding: "5px", borderBottom: "1px solid rgb(241 241 241)" }}>
                                            <p style={{ fontSize: 18, width: "50%", color: "rgb(109 107 103)" }}>Handling Fee:</p>
                                            <p style={{ fontSize: 18, width: "50%", textAlign: "center" }}>Depositor</p>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", borderTop: "1px solid #E5E4E2", padding: "5px", borderBottom: "1px solid rgb(241 241 241)" }}>
                                            <p style={{ fontSize: 18, width: "50%", color: "rgb(109 107 103)" }}>Storage Fee:</p>
                                            <p style={{ fontSize: 18, width: "50%", textAlign: "center" }}>Depositor</p>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "start", borderTop: "1px solid #E5E4E2", padding: "5px", borderBottom: "1px solid rgb(241 241 241)" }}>
                                            <p style={{ fontSize: 18, width: "50%", color: "rgb(109 107 103)" }}>Receipt Issuance Fee:</p>
                                            <p style={{ fontSize: 18, width: "50%", textAlign: "center" }}>Depositor</p>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center ",
                                    justifyContent: "space-between",
                                    width: "40%",
                                    padding: 5
                                }} >
                                    {
                                        receiptData.generation_id && (
                                            <>
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                                    {/* <img style={{ width: "250px", height: "250px" }} src={genQRCode} /> */}
                                                    <QRCode
                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                        value={`https://www.zamace.co.zm/${receiptData?.receipt_number}/${receiptData?.generation_id}`}
                                                        viewBox={`0 0 256 256`}
                                                    />
                                                </div>
                                            </>
                                        )
                                    }
                                    <div style={{
                                        color: "#8094ae",
                                        fontSize: 11,
                                        marginTop: 5,
                                        left: 0,
                                        right: 0,
                                        width: "100%"
                                    }}>
                                        {receiptData.receipt_number && (receiptData.receipt_number)}
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                position: "relative",
                                paddingLeft: 20,
                                paddingRight: 20,
                                paddingTop: 10,
                                paddingBottom: 10,
                                width: "100%",
                                bottom: 5,
                                display: "flex",
                                flexDirection: "row",
                            }}>
                                <p style={{
                                    color: "#8094ae",
                                    fontSize: 14,
                                    bottom: 10,
                                    left: 0,
                                    right: 0,
                                    width: "100%", display: "flex", flexDirection: "row",
                                }}>
                                    Generation ID: <p style={{ color: "#717274", fontWeight: 500 }} >{receiptData.generation_id && (receiptData.generation_id)}</p>
                                </p>
                            </div>
                        </div>
                    </div >
                )
            }
        </>
    );
};

export default WarehouseReceiptPDF;