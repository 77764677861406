import * as app_consts from "../../utils/constants";

export const table_columns = (type) => {
    let column = [];
    switch (type)
    {
        case app_consts.WAREHOUSE_RECEIPT_TABLE:
            column = [
                { key: 0, label: "#" },
                { key: 1, label: "Message" },
                { key: 2, label: "Timestamp" },
            ];
            break;
        case app_consts.OFFER_HISTORY_TABLE:
            column = [
                { key: 0, label: "#" },
                { key: 1, label: "Message" },
            ];
            break;
        case app_consts.BROKER_OFFER_TRADE_TABLE:
            column = [
                { key: 0, label: "#" },
                { key: 1, label: "Unit Price" },
                { key: 2, label: "Quantity" },
                { key: 3, label: "Status" },
                { key: 4, label: "Trade Date" },
                { key: 5, label: "Action" },
            ];
            break;
        case app_consts.BID_MANAGEMENT_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "Bid Reference No." },
                { key: 2, label: "Offer Reference No." },
                { key: 3, label: "Commodity" },
                { key: 4, label: "Bid Volume" },
                { key: 5, label: "Bid Date" },
                { key: 6, label: "Bid Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.WAREHOUSE_RECEIPT_LIST_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "RECEIPT SERIAL NO." },
                { key: 2, label: "COMMODITY" },
                { key: 3, label: "OWNER" },
                { key: 4, label: "QUANTITY (METRIC TONNES)" },
                { key: 5, label: "RECEIPT ORIGIN" },
                { key: 6, label: "STATUS" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.TRADE_FULFILLMENTS_LIST_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "CONTRACT REF NO." },
                { key: 2, label: "COMMODITY" },
                { key: 3, label: "QUANTITY" },
                { key: 4, label: "TRADE DATE" },
                { key: 5, label: "Contract Status" },
                { key: 6, label: "Fulfilment Status" },
                { key: 7, label: "Trade Status" },
                { key: 8, label: "Action" },
            ];
            break;
        case app_consts.SUPER_BID_MANAGEMENT_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "DATE TO BE PLACED ON PLATFORM." },
                { key: 2, label: "Commodity" },
                { key: 3, label: "Bid Volume" },
                { key: 4, label: "Requested Unit Price" },
                { key: 5, label: "Quality" },
                { key: 6, label: "Order Type" },
                { key: 7, label: "Special terms and Conditions" },
                { key: 8, label: "Status" },
                { key: 9, label: "Action" },
            ];
            break;
        case app_consts.ENTITY_USER_PROFILES_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "First Name" },
                { key: 2, label: "Last Name" },
                { key: 3, label: "Mobile" },
                { key: 4, label: "Email Address" },
                { key: 5, label: "Date Created" },
                { key: 6, label: "Status" },
                { key: 7, label: "Action" },
            ];
            break;
        case app_consts.VIRTUAL_RECEIPT_LIST_TABLE:
            column = [
                { key: 0, label: "" },
                { key: 1, label: "RECEIPT SERIAL NO." },
                { key: 2, label: "COMMODITY" },
                { key: 3, label: "OWNER" },
                { key: 4, label: "QUANTITY (METRIC TONNES)" },
                { key: 5, label: "RECEIPT ORIGIN" },
                { key: 6, label: "STATUS" },
                { key: 7, label: "Action" },
            ];
            break;
    }
    return column;
};