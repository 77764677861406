import { CheckCircleIcon } from '@heroicons/react/24/outline';
import React from 'react';

const AvatarText = ({ avatarText, avatarLabel, step }) => {
    return (
        <>
            <li className={`flex items-center text-${avatarText == step ? "green" : ""}-600 dark:text-${avatarText == step ? "green" : ""}-500`}>
                {
                    avatarText < step ?
                        <CheckCircleIcon className="h-5 w-5 text-green-500 mr-1" />
                        :
                        <>
                            <span className="flex items-center justify-center w-5 h-5 mr-2 text-xs border border-green-600 rounded-full shrink-0 dark:border-green-500">
                                {
                                    avatarText
                                }
                            </span>
                        </>
                }
                {
                    avatarLabel
                }
                <svg className="w-3 h-3 ml-2 sm:ml-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m7 9 4-4-4-4M1 9l4-4-4-4" />
                </svg>
            </li>
        </>
    );
};

export default AvatarText;