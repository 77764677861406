import React, { useEffect, useState } from 'react';
import {
  addNewBrokerOffer,
  createVirtualReceipt,
  getAllProducerList,
  getProducersAndWarehouseReceiptsForBroker,
  getProducersAndWarehouseReceiptsForBrokerReset,
  getWarehouseListByEntityStatus,
  retrieveBrokerProducersReset,
  retrieve_broker_producers,
} from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Select from 'react-select';
import provinces from '../../utils/provinces.json';
import districts from "../../utils/districts.json";

import { format } from 'date-fns';
import RSelect from 'react-select';
import locationOptions from '../../utils/districts.json';
import * as app_consts from '../../utils/constants';
import {
  rSelectMapFun,
  retreive_rSelectVariables,
} from '../../utils/functionalUtils';


const CreateVirtualReceiptModal = ({ showModal, closeModal, fetchBrokerOffers }) => {
  const dispatch = useDispatch();
  const { entity_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [currentReceipts, setCurrentReceipts] = useState([]);
  const [currentProducers, setCurrentProducers] = useState([]);
  const [producersData, setProducersData] = useState([]);
  const [warehouseReceiptsData, setWarehouseReceiptsData] = useState([]);
  const [warehousesData, setWarehousesData] = useState([]);
  const [broker_producers, setBrokerProducers] = useState([]);

  const [warehouseList, setWarehouseList] = useState([]);

  // Retrieve Producers and Service Provider List On Page Load
  useEffect(() => {
    dispatch(getAllProducerList());

    // Retrieve Pending Entity Lists By Service Provider
    // dispatch(retreive_pending_entity_list_by_type(app_consts.SERVICE_PROVIDER));

    // dispatch(getFilteredEntityTypeActiveStatusVerified(serviceProviderReqParams));
    // Fetch Warehouse List
    dispatch(getWarehouseListByEntityStatus({ entity_id: entity_id, status: app_consts.CERTIFIED }));
  }, []);

  // Listen to Changes When Fetching Warehouse List
  const warehouseListData = useSelector((state) => state.warehouseList);

  useEffect(() => {
    const fetchData = async () => {
      if (warehouseListData.data)
      {
        let mappedData = [];

        await Promise.all(
          warehouseListData?.data?.data?.map(async (data_to_map) => {
            const { label, value } = retreive_rSelectVariables(app_consts.WAREHOUSES_MAP_VR_LIST, data_to_map);
            const result = await rSelectMapFun(label, value);
            mappedData.push(result);
          })
        );

        setWarehouseList(mappedData);
      }
    };

    fetchData();
  }, [warehouseListData]);
  const producersAndReceipts = useSelector(
    (state) => state.getProducersAndWarehouseReceiptsList
  );

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem('account')) || {};
    const brokerProfile = account.profiles.find(
      (profile) => profile.type === 'broker'
    );
    const brokerIdEntity = brokerProfile.entity_id;

    dispatch(getProducersAndWarehouseReceiptsForBroker(brokerIdEntity));
  }, []);

  useEffect(() => {
    if (producersAndReceipts.status !== app_consts.IDLE)
    {
      if (producersAndReceipts.isLoading)
      {
        setLoading(true);
      } else
      {
        if (producersAndReceipts.error)
        {
          setError(producersAndReceipts.error);
        } else
        {
          setProducersData(producersAndReceipts?.data?.producers);
          setWarehouseReceiptsData(
            producersAndReceipts?.data?.issued_warehouse_receipts
          );
          setWarehousesData(producersAndReceipts?.data?.warehouses);
          dispatch(getProducersAndWarehouseReceiptsForBrokerReset());
          setLoading(false);
        }
      }
    }
  }, [producersAndReceipts]);

  // Retrieve Broker Producers
  useEffect(() => {
    const account = JSON.parse(localStorage.getItem('account')) || {};
    const brokerProfile = account.profiles.find(
      (profile) => profile.type === 'broker'
    );
    const brokerIdEntity = brokerProfile.entity_id;

    dispatch(retrieve_broker_producers(brokerIdEntity));
  }, []);

  // Handle Changes in React Select Field
  const handlRSelectChanges = (option, name) => {
    if (option)
    {
      formik.setFieldValue(name, option.value);
    } else
    {
      formik.setFieldValue(name, '');
    }
  };

  // Listen to Broker Producers State
  const retrieveBrokerProducer = useSelector(
    (state) => state.retrieveBrokerProducer
  );
  useEffect(() => {
    if (retrieveBrokerProducer.status !== app_consts.IDLE)
    {
      if (retrieveBrokerProducer.isLoading)
      {
      } else
      {
        if (retrieveBrokerProducer.error)
        {
          console.log(retrieveBrokerProducer.error);
        } else
        {
          filterDepositors(retrieveBrokerProducer.data);
        }
        dispatch(retrieveBrokerProducersReset());
      }
    }
  }, [retrieveBrokerProducer]);

  const filterDepositors = async (producerList) => {
    let mappedData = [];

    await Promise.all(
      producerList?.data?.map(async (data_to_map) => {
        const { label, value } = retreive_rSelectVariables(
          app_consts.PRODUCERS_LIST_MAP,
          data_to_map
        );
        const result = await rSelectMapFun(label, value);
        mappedData.push(result);
      })
    );

    setBrokerProducers(mappedData);
    return mappedData;
  };

  const producerOptions = currentProducers?.map((producer) => ({
    value: producer.producer_id,
    label: producer.producer_id + ' ' + '-' + ' ' + producer.contact_name,
  }));

  const receiptNumberOptions = currentReceipts?.map((warehouseReceipt) => ({
    value: warehouseReceipt.receipt_number,
    label: warehouseReceipt.receipt_number,
  }));

  const initialValues = {
    customer_name: '',
    producer_id: '',
    receipt_number: '',
    date_to_be_placed_on_platform: '',
    date_to_be_withdrawn_from_platform: '',
    commodity: '',
    unit_of_measure: 'Metric Tonnes',
    volume: '',
    volume_on_offer: '',
    requested_unit_price: '',
    quality: '',
    other_documentation_required: '',
    order_type: '',
    special_terms_and_conditions: '',
    location: '',
    delivery: '',
    quantity: '',
    province: '',
    district: '',
    physical_address: '',
    history: [],
  };

  const validationSchema = yup.object().shape({
    // producer_id: yup.string().required('Client ID is required'),
    commodity: yup.string().required('Commodity is required'),
    volume: yup.string().required('Volume is required'),
    quality: yup.string().required('Quality is required'),
    order_type: yup.string().required('Order Type is required'),
    // location: yup.string().required('Location is required'),
    warehouse_id: yup.string().required('Delivery is required'),
    province: yup.string().required('Province is required'),
    district: yup.string().required('District is required'),
    physical_address: yup.string().required('Origin Address is required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      setError('');
      try
      {
        const timestamp = new Date();
        const formattedTimestamp = format(timestamp, 'MMMM dd, yyyy hh:mm a');
        const historyStatement = `Created new virtual receipt on ${formattedTimestamp}`;

        const account = localStorage.getItem('account')
          ? JSON.parse(localStorage.getItem('account'))
          : {};
        const brokerProfile = account.profiles.find(
          (profile) => profile.type === 'broker'
        );
        const brokerId = brokerProfile.id;

        const virtual_receipt = {
          order_type: values.order_type,
          producer_id: values.producer_id,
          broker_id: brokerId,
          commodity: values.commodity.replace(/ /g, '_').toLowerCase(),
          quality: values.quality,
          unit_of_measure: "Metric Tonnes", // metric tonnes by default
          volume: values.volume,
          // history: [historyStatement],
          // quantity: values.quantity,
          physical_address: values.physical_address,
          province: values.province,
          district: values.district,
          location: values.district,
          delivery: values.delivery,
          warehouse_id: values.warehouse_id,
          status: "pending"
        };

        console.log("VR: ", virtual_receipt);

        await dispatch(createVirtualReceipt({ virtual_receipt }));

        // fetchBrokerOffers();
        formik.resetForm();
        closeModal();
      } catch (error)
{

        setError('Failed to create virtual receipt');
        setLoading(false);

      }
    },
  });

  const handleOnBlur = (name, value) => {
    formik.setFieldValue(name, parseFloat(value).toFixed(2));
  };

  // const locationOptionsArray = Object.entries(locationOptions).map(
  //   ([region, values]) => ({
  //     value: region,
  //     label: region,
  //     options: values.map((city) => ({ value: city, label: city })),
  //   })
  // );

  const handleChange = (fieldName, selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : '';
    formik.setFieldValue(fieldName, selectedValue);

    // setCurrentProducers([]);

    const account = JSON.parse(localStorage.getItem('account')) || {};
    const brokerProfile = account.profiles.find(
      (profile) => profile.type === 'broker'
    );
    const brokerAccountId = brokerProfile.account_id;

    if (fieldName === 'order_type')
    {
      if (selectedValue === 'client' || selectedValue === 'professional')
      {
        const filteredProducers = producersData?.filter(
          (producer) =>
            (selectedValue === 'client' &&
              producer.account_id !== brokerAccountId) ||
            (selectedValue === 'professional' &&
              producer.account_id === brokerAccountId)
        );

        if (filteredProducers.length === 0)
        {
          setError(
            `${selectedValue === 'client'
              ? 'No clients available'
              : 'You have not been authorized by zamace to place own virtual receipts'
            }.`
          );
        } else
        {
          setError('');
        }

        setCurrentProducers(filteredProducers);
      } else
      {
        setCurrentProducers([]);
        setError('');
      }
    }

    if (fieldName === 'producer_id')
    {
      setCurrentReceipts(
        warehouseReceiptsData?.filter(
          (warehouse_receipt) => warehouse_receipt.owner_id === selectedValue
        )
      );
    }

    if (fieldName === 'receipt_number')
    {
      const currentReceipt = warehouseReceiptsData.find(
        (warehouse_receipt) =>
          warehouse_receipt.receipt_number === selectedValue
      );

      const currentWarehouse = warehousesData.find(
        (warehouse) => warehouse.id === currentReceipt.warehouse_id
      );

      formik.setFieldValue('unit_of_measure', 'Metric Tonnes');

      formik.setFieldValue(
        'commodity',
        currentReceipt ? currentReceipt?.commodity.replace(/_/g, ' ').toUpperCase() : ''
      );
      formik.setFieldValue(
        'quality',
        currentWarehouse ? currentWarehouse.grade : ''
      );
      formik.setFieldValue(
        'volume',
        currentReceipt ? currentReceipt.quantity : ''
      );
      formik.setFieldValue(
        'location',
        currentWarehouse ? currentWarehouse.districts : ''
      );
      formik.setFieldValue(
        'warehouse_name',
        currentWarehouse ? currentWarehouse.name : ''
      );
    }
  };

  return (
    <>
      {showModal ? (
        <div className='fixed inset-0 flex items-center justify-center z-50 overflow-y-auto'>
          <div className='fixed inset-0 bg-black opacity-50'></div>
          <div className='relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto'>
            <div class='flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600'>
              <h3 class='text-lg font-semibold text-gray-900 dark:text-white'>
                Virtual Receipt Form
              </h3>
              <button
                type='button'
                onClick={() => closeModal()}
                class='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                data-modal-toggle='addBrokerOfferModal'
              >
                <svg
                  aria-hidden='true'
                  class='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fill-rule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clip-rule='evenodd'
                  ></path>
                </svg>
                <span class='sr-only'>Close</span>
              </button>
            </div>

            <form
              className='space-y-4 md:space-y-6'
              onSubmit={formik.handleSubmit}
            >
              <ol className='items-center w-full sm:w-auto space-y-4 sm:flex sm:space-x-8 sm:space-y-0'>
                <li className='flex items-center text-green-600 dark:text-green-500 space-x-2.5'>
                  <span className='flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500'>
                    1
                  </span>
                  <span>
                    <h4 className='font-medium leading-tight'>Create New Virtual Receipt</h4>
                    <p className='text-sm'>
                      Fill in the virtual receipt details where appropriate
                    </p>
                  </span>
                </li>
              </ol>
              <div className='container grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4'>
                <div>
                  <label
                    htmlFor='order_type'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Order Type
                  </label>
                  <select
                    name='order_type'
                    value={formik.values.order_type}
                    // onChange={(e) => handleChange('order_type', e.target)}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Select Order Type</option>
                    <option value='client'>Client Order</option>
                    <option value='professional'>
                      Professional(own) Order
                    </option>
                  </select>
                  {formik.touched.order_type && formik.errors.order_type && (
                    <p className='text-red-500 text-sm mt-1'>
                      {formik.errors.order_type}
                    </p>
                  )}
                </div>

                {formik.values.order_type === 'client' && (
                  <div>
                    <>
                      <div>
                        <label
                          htmlFor='producer_id'
                          className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                          Virtual Receipt owner?
                        </label>
                        <RSelect
                          name='producer_id'
                          // value={formik.values.depositor_id}
                          onChange={(e) =>
                            handlRSelectChanges(e, 'producer_id')
                          }
                          options={broker_producers}
                          required
                        />
                      </div>
                    </>
                  </div>
                )}

                <div>
                  <label
                    htmlFor='commodity'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Commodity
                  </label>
                  <select
                    name='commodity'
                    value={formik.values.commodity}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Select Commodity</option>
                    <option value='soya_beans'>Soya Beans</option>
                    <option value='wheat'>Wheat</option>
                    <option value='sunflower_seed'>Sunflower Seed</option>
                    <option value='maize'>Maize </option>
                  </select>
                  {formik.touched.commodity && formik.errors.commodity && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors.commodity}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='quality'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Quality
                  </label>
                  <select
                    name='quality'
                    value={formik.values.quality}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  >
                    <option value=''>Select Quality</option>
                    <option value='a'>A</option>
                    <option value='b'>B</option>
                    <option value='c'>C</option>
                  </select>
                  {formik.touched.quality && formik.errors.quality && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors.quality}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='volume'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Volume (MT)
                  </label>
                  <input
                    type='text'
                    name='volume'
                    value={formik.values.volume}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.volume && formik.errors.volume && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors.volume}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='profileType'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Delivery (Warehouse)
                  </label>
                  <RSelect
                    name='warehouse_id'
                    // value={formik.values.warehouse_id}
                    onChange={(e) => handlRSelectChanges(e, "warehouse_id")}
                    options={warehouseList}
                    isClearable={true}
                  />
                  {formik.touched.warehouse_id && formik.errors.warehouse_id && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors.warehouse_id}</p>
                  )}
                </div>

                {/* <div>
                  <label
                    htmlFor='unit_of_measure'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Unit Of Measure
                  </label>
                  <input
                    type='text'
                    name='unit_of_measure'
                    value={formik.values.unit_of_measure}
                    onChange={formik.handleChange}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                </div> */}

                {/* Province/State Input or Dropdown based on country */}
                <div className="mb-6">
                  <label htmlFor="province" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    Province / State
                  </label>
                  <select
                    name="province"
                    value={formik.values.province}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                  >
                    <option value="">Choose a province</option>
                    {provinces.Zambia.map((province, index) => (
                      <option key={index} value={province}>
                        {province}
                      </option>
                    ))}
                  </select>

                  {formik.touched.province && formik.errors.province && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors.province}</p>
                  )}
                </div>
                {/* District/County Input or Dropdown based on country */}
                <div className="mb-6">
                  <label htmlFor="district" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                    District / County
                  </label>
                  <select
                    name="district"
                    value={formik.values.district}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                  >
                    <option value="">Choose a District</option>
                    {districts?.[formik.values?.province]?.map((district, index) => (
                      <option key={index} value={district}>
                        {district}
                      </option>
                    ))}
                  </select>

                  {formik.touched.district && formik.errors.district && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors.district}</p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='location'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Location
                  </label>
                  <input
                    type='text'
                    name='location'
                    value={formik.values.district}
                    // onChange={formik.handleChange}
                    disabled
                    className='bg-gray-200 cursor-not-allowed border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                </div>

                <div className="mb-6">
                  <label htmlFor="physical_address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Product Origin Address</label>
                  <input
                    type="text"
                    name="physical_address"
                    value={formik.values.physical_address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.physical_address && formik.errors.physical_address && (
                    <p className="text-red-500 text-sm mt-1">{formik.errors.physical_address}</p>
                  )}
                </div>


                {/* <div>
                  <label
                    htmlFor='volume'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Total Volume of Warehouse Receipt
                  </label>
                  <input
                    type='text'
                    name='volume'
                    value={formik.values.volume}
                    onChange={formik.handleChange}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                </div> */}

                {/* <div>
                  <label
                    htmlFor='volume_on_offer'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Volume on offer
                  </label>
                  <input
                    step='0.01'
                    type='number'
                    min='0.00'
                    default='0.00'
                    name='volume_on_offer'
                    value={formik.values.volume_on_offer}
                    onChange={formik.handleChange}
                    onBlur={() =>
                      handleOnBlur(
                        'volume_on_offer',
                        formik.values.volume_on_offer
                      )
                    }
                    required
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.volume_on_offer &&
                    formik.errors.volume_on_offer && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.volume_on_offer}
                      </p>
                    )}
                </div> */}

                {/* <div>
                  <label
                    htmlFor='date_to_be_placed_on_platform'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Date to be placed on platform
                  </label>
                  <input
                    type='date'
                    name='date_to_be_placed_on_platform'
                    value={formik.values.date_to_be_placed_on_platform}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.date_to_be_placed_on_platform &&
                    formik.errors.date_to_be_placed_on_platform && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.date_to_be_placed_on_platform}
                      </p>
                    )}
                </div> */}

                {/* <div>
                  <label
                    htmlFor='date_to_be_withdrawn_from_platform'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Date to be withdrawn from platform
                  </label>
                  <input
                    type='date'
                    name='date_to_be_withdrawn_from_platform'
                    value={formik.values.date_to_be_withdrawn_from_platform}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.date_to_be_withdrawn_from_platform &&
                    formik.errors.date_to_be_withdrawn_from_platform && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.date_to_be_withdrawn_from_platform}
                      </p>
                    )}
                </div> */}

                {/* <div>
                  <label
                    htmlFor='requested_unit_price'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Requested Unit Price (optional)
                  </label>
                  <input
                    step='0.01'
                    type='number'
                    min='0.00'
                    default='0.00'
                    name='requested_unit_price'
                    value={formik.values.requested_unit_price}
                    onChange={formik.handleChange}
                    onBlur={() =>
                      handleOnBlur(
                        'requested_unit_price',
                        formik.values.requested_unit_price
                      )
                    }
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.requested_unit_price &&
                    formik.errors.requested_unit_price && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.requested_unit_price}
                      </p>
                    )}
                </div> */}

                {/* <div>
                  <label
                    htmlFor='other_documentation_required'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Other Documentation Required (optional)
                  </label>
                  <input
                    type='text'
                    name='other_documentation_required'
                    value={formik.values.other_documentation_required}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.other_documentation_required &&
                    formik.errors.other_documentation_required && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.other_documentation_required}
                      </p>
                    )}
                </div> */}

                {/* <div>
                  <label
                    htmlFor='special_terms_and_conditions'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Special Terms and Conditions (optional)
                  </label>
                  <input
                    type='text'
                    name='special_terms_and_conditions'
                    value={formik.values.special_terms_and_conditions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500'
                  />
                  {formik.touched.special_terms_and_conditions &&
                    formik.errors.special_terms_and_conditions && (
                      <p className='text-red-500 text-sm mt-1'>
                        {formik.errors.special_terms_and_conditions}
                      </p>
                    )}
                </div> */}
                {/* 
                <div>
                  <label
                    htmlFor='location'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Location
                  </label>

                  <Select
                    isClearable={true}
                    name='location'
                    value={locationOptionsArray.find(
                      (option) => option.value === formik.values.location
                    )}
                    onBlur={formik.handleBlur}
                    onChange={(selectedOption) =>
                      handleChange('location', selectedOption)
                    }
                    options={locationOptionsArray}
                  />

                  {formik.touched.location && formik.errors.location && (
                    <p className='text-red-500 text-sm mt-1'>
                      {' '}
                      {formik.errors.location}{' '}
                    </p>
                  )}
                </div>

                <div>
                  <label
                    htmlFor='delivery'
                    className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                  >
                    Delivery
                  </label>

                  <Select
                    isClearable={true}
                    name='delivery'
                    value={locationOptionsArray.find(
                      (option) => option.value === formik.values.delivery
                    )}
                    onBlur={formik.handleBlur}
                    onChange={(selectedOption) =>
                      handleChange('delivery', selectedOption)
                    }
                    options={locationOptionsArray}
                  />

                  {formik.touched.delivery && formik.errors.delivery && (
                    <p className='text-red-500 text-sm mt-1'>
                      {' '}
                      {formik.errors.delivery}{' '}
                    </p>
                  )}
                </div> */}
              </div>

              <div className='flex space-x-4'>
                <button
                  type='button'
                  onClick={closeModal}
                  className='text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2'
                >
                  Close
                </button>
                <button
                  type='submit'
                  // disabled={!formik.isValid}
                  className='text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2'
                >
                  {formik.isSubmitting ? 'Creating Virtual Receipt...' : 'Create Virtual Receipt'}
                </button>
              </div>
              {error && (
                <div
                  className='p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                  role='alert'
                >
                  <span className='font-medium'>Error: </span>
                  {error}
                </div>
              )}
              {loading && (
                <div className='text-center'>
                  <div role='status'>
                    <svg
                      aria-hidden='true'
                      className='inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600'
                      viewBox='0 0 100 101'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                        fill='currentColor'
                      />
                      <path
                        d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                        fill='currentFill'
                      />
                    </svg>
                    <span className='sr-only'>Please wait...</span>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default CreateVirtualReceiptModal;
