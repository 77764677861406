import React, { useState, createRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { verifyRegistration, resendOtp, getCurrentAccount } from '../../utils/actions';
import Footer from '../../layouts/Footer';
import NavHeader from '../../layouts/NavHeader';

const AccountVerification = ({ dispatch }) => {
    const [otp, setOtp] = useState(Array(6).fill(""));
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [resendLoading, setResendLoading] = useState(false);
    const [sendOtpMessage, setSendOtpMessage] = useState("Sending OTP");

    const storedData = localStorage.getItem('account');
    const parsedData = JSON.parse(storedData);

    const navigate = useNavigate();

    const inputsRef = otp.map(() => createRef());

    const handleChange = (element, index) => {
        if (isNaN(element.target.value))
        {
            return;
        } else if (element.target.value.length === 1)
        {
            setOtp([...otp.slice(0, index), element.target.value, ...otp.slice(index + 1)]);
            if (index < otp.length - 1)
            {
                inputsRef[index + 1].current.focus();
            }
        }
    };

    // UseEffect When the Page Loads
    // useEffect(() => {
    //     setMessage("");
    //     send_otp_action("Sending");
    //     setSendOtpMessage("Sending OTP");
    //     setMessage('OTP Sent successfully');
    // }, []);


    const handleKeyUp = (element, index) => {
        if (element.keyCode === 8 || element.keyCode === 46)
        {
            setOtp([...otp.slice(0, index), "", ...otp.slice(index + 1)]);
            if (index > 0)
            {
                inputsRef[index - 1].current.focus();
            }
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const otpString = otp.join('');
        const accountId = parsedData.id;

        if (!accountId)
        {
            setError('Account id is missing, please login again');
            setLoading(false);
            return;
        }

        setLoading(true);
        dispatch(verifyRegistration(accountId, otpString))
            .then((response) => {
                setError(null);
                setLoading(false);
                if (response.status === "success")
                {
                    return dispatch(getCurrentAccount());
                } else
                {
                    throw new Error(response.message);
                }
            })
            .then(() => {
                navigate('/membership_type');
            })
            .catch((error) => {
                setError(error.response ? error.response.data.message : error.message);
                setLoading(false);
            });
    };


    const handleResendOtp = async (e) => {
        e.preventDefault();
        setMessage("");
        setSendOtpMessage("Resending OTP...");
        send_otp_action("Resending");
    };

    const send_otp_action = (type) => {
        setResendLoading(true);
        const accountId = parsedData.id;
        dispatch(resendOtp(accountId))
            .then((response) => {
                setError(null);
                setResendLoading(false);
                if (type === "Sending")
                {
                    setMessage('OTP Sent successfully');
                }
                else if (type === "Resending")
                {
                    setMessage('OTP Resent successfully');
                }
            })
            .catch((error) => {
                setError(error.message);
                setResendLoading(false);
            });
    };

    return (

        <>
            <NavHeader />
            <div className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                    <h1 className="mt-10 text-center text-5xl font-bold tracking-tight text-green-900">
                        Step 2 - Verify your registration
                    </h1>
                    <h2 className="mt-6 text-center text-2xl font-thin leading-9 tracking-tight text-gray-800">
                        Verify email address or mobile number by entering the 6-digit OTP code received via email or SMS below.
                    </h2>
                </div>

                <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <div className="mt-2 flex justify-between mb-6">
                                {otp.map((value, index) => (
                                    <input
                                        key={index}
                                        ref={inputsRef[index]}
                                        id={`otp${index}`}
                                        name={`otp${index}`}
                                        type="text"
                                        maxLength="1"
                                        value={value}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyUp={(e) => handleKeyUp(e, index)}
                                        required
                                        className="block w-10 text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                    />
                                ))}
                            </div>
                            <button
                                type="submit"
                                className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-md font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                Verify
                            </button>
                        </div>
                        {error && <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                            <span className="font-medium">Error: </span>{error}</div>}
                        {message && <div className="p-4 mb-4 text-sm justify-center text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">{message}</div>}
                        {loading && <div className="text-center">
                            <div role="status">
                                <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span className="sr-only">Please wait...</span>
                            </div>
                        </div>}
                        {resendLoading && <div className="text-center">
                            <div role="status">
                                <svg aria-hidden="true" className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span className="sr-only">Please wait...</span>
                            </div>
                        </div>}
                    </form>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        OTP Expired?{' '}
                        <a className={`${resendLoading ? 'cursor-not-allowed' : 'cursor-pointer'} font-semibold leading-6 text-green-600 hover:text-green-500`} onClick={handleResendOtp}>
                            {resendLoading ? sendOtpMessage : 'Click here to resend OTP'}
                        </a>

                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default connect()(AccountVerification);




