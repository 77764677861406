import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { createDeliverynoteReset, createWarehouseDeliveryNote, getAllProducerList, getFilteredEntityTypeActiveStatusVerified, getWarehouseListByEntityStatus, list_all_producers_with_entities, retreive_pending_entity_list_by_type } from '../../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import countries from "../../../utils/countries.json";
import { useParams } from 'react-router-dom';
import * as app_consts from '../../../utils/constants';
import AddPendingEntityModal from '../../../components/modals/AddPendingEntityModal';
import RSelect from 'react-select';
import { convertToTitleCase, rSelectMapFun, retreive_rSelectVariables } from '../../../utils/functionalUtils';

const serviceProviderReqParams = { entity_category: "service_provider", verified: 1, active: 1, status: "active" };

const DeliveriesStep = ({ nextStep, prevFormData, setPrevFormData }) => {

    const dispatch = useDispatch();
    const { entity_id } = useParams();

    const [producerList, setProducerList] = useState([]);
    const [selectedDepositorType, setSelectedDepositorType] = useState([]);
    const [serviceProviderList, setServiceProviderList] = useState([]);
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [warehouseList, setWarehouseList] = useState([]);
    const [addEntityModal, setAddEntityModal] = useState(false);
    const [pending_entity_list, setPendingEntityList] = useState([]);
    const [permitted_commodities, setPermittedCommodities] = useState([]);
    const [depositor_type, setDepositorType] = useState("");
    const [depositor_own_label, setDepositorOwnlabel] = useState("");

    // Retrieve Producers and Service Provider List On Page Load
    useEffect(() => {
        dispatch(list_all_producers_with_entities());

        // Retrieve Pending Entity Lists By Service Provider
        dispatch(retreive_pending_entity_list_by_type(app_consts.SERVICE_PROVIDER));

        dispatch(getFilteredEntityTypeActiveStatusVerified(serviceProviderReqParams));
        // Fetch Warehouse List
        dispatch(getWarehouseListByEntityStatus({ entity_id: entity_id, status: app_consts.CERTIFIED }));
    }, []);

    // Listen to Producers State
    const producersState = useSelector((state) => state.listAllProducersWithEntities);
    // 
    useEffect(() => {
        if (producersState.data)
        {
            setProducerList(producersState?.data);
        }
    }, [producersState]);

    // Listen to Service Provider State
    const serviceProviderState = useSelector((state) => state.specificEntityProfileList);
    // 
    useEffect(() => {
        const fetchData = async () => {
            if (serviceProviderState.data)
            {
                let mappedData = [];

                await Promise.all(
                    serviceProviderState?.data?.data?.map(async (data_to_map) => {
                        const { label, value } = retreive_rSelectVariables(app_consts.SERVICE_PROVIDER_LIST_MAP, data_to_map);
                        const result = await rSelectMapFun(label, value);
                        mappedData.push(result);
                    })
                );
                setServiceProviderList(mappedData);
            }
        };

        fetchData();
    }, [serviceProviderState]);

    // Listen to Pending Entity State
    const pendingEntityState = useSelector((state) => state.retrievePendingEntityList);
    // 
    useEffect(() => {
        const fetchData = async () => {
            if (pendingEntityState.data)
            {
                let mappedData = [];

                await Promise.all(
                    pendingEntityState?.data?.data?.map(async (data_to_map) => {
                        const { label, value } = retreive_rSelectVariables(app_consts.PENDING_ENTITY_LIST_MAP, data_to_map);
                        const result = await rSelectMapFun(label, value);
                        mappedData.push(result);
                    })
                );

                setPendingEntityList(mappedData);
            }
        };

        fetchData();
    }, [pendingEntityState]);

    // Listen to Changes When Fetching Warehouse List
    const warehouseListData = useSelector((state) => state.warehouseList);

    useEffect(() => {
        const fetchData = async () => {
            if (warehouseListData.data)
            {
                let mappedData = [];

                await Promise.all(
                    warehouseListData?.data?.data?.map(async (data_to_map) => {
                        const { label, value } = retreive_rSelectVariables(app_consts.WAREHOUSES_MAP, data_to_map);
                        const result = await rSelectMapFun(label, value);
                        mappedData.push(result);
                    })
                );

                setWarehouseList(mappedData);
            }
        };

        fetchData();
    }, [warehouseListData]);


    // Validate Form Params
    const deliveriesSchema = yup.object().shape({
        driver_name: yup.string().required('Driver Name is Required'),
        driver_id: yup.string().required('Driver ID is required'),
        license_plate: yup.string().required('License Plate Number is required'),
        gross_weight: yup.string().required('Gross Weight is required'),
        transport_type: yup.string().required('Transposrt Type is required'),
        depositor_id: yup.string().required('Depositor is required'),
        service_provider_id: yup.string().when('service_provider_type', {
            is: 'registered',
            then: () => yup.string().required('Service Provider is required'),
            otherwise: () => yup.string().nullable(),
        }),
        commodity: yup.string().required('Commodity is required'),
        origin: yup.string().required('Commodity Origin is required'),
        warehouse_id: yup.string().required('Warehouse is required'),
        service_provider_type: yup.string().required('Service Provider Type is required'),
        pending_entity_id: yup.string().when('service_provider_type', {
            is: 'unregistered',
            then: () => yup.string().required('Service Provider is required'),
            otherwise: () => yup.string().nullable(),
        }),
    });

    const formik = useFormik({
        initialValues: {
            license_plate: "",
            driver_name: '',
            driver_id: '',
            gross_weight: '0.00',
            transport_type: '',
            status: '',
            latitude: '',
            longitude: '',
            depositor_id: '',
            warehouse_id: "",
            service_provider_id: '',
            commodity: "",
            origin: "",
            service_provider_type: "",
            trailer_number: "",
            pending_entity_id: "",
        },
        validationSchema: deliveriesSchema,
        onSubmit: async (values) => {
            setError("");
            setLoading(true);
            try
            {
                const deliveryNoteParams = {
                    license_plate: values.license_plate,
                    driver_name: values.driver_name,
                    driver_id: values.driver_id,
                    gross_weight: values.gross_weight,
                    transport_type: values.transport_type,
                    latitude: '',
                    longitude: '',
                    depositor_id: values.depositor_id,
                    warehouse_id: values.warehouse_id,
                    service_provider_id: values.service_provider_id,
                    commodity: values.commodity,
                    origin: values.origin,
                    service_provider_type: values.service_provider_type,
                    trailer_number: values.trailer_number,
                    pending_entity_id: values.pending_entity_id,
                };

                await dispatch(createWarehouseDeliveryNote(deliveryNoteParams));

            } catch (error)
            {
                if (error.response)
                {
                    if (error.response.status === 400)
                    {
                        setError(error.response.data.message);
                    }
                    else if (error.response.status === 500)
                    {
                        setError("Failed to Create Delivery Note.");
                    }
                }
                else
                {
                    setError("Failed to Create Delivery Note.");
                }
            } finally
            {
                setLoading(false);
            }
        },
    });

    // Disabled State
    const [disabled, setDisabled] = useState(false);

    // Handle Changes in Depositor Type Selection
    const handleDepositorTypeChange = async (depositorType) => {
        formik.setFieldValue("commodity", "");
        if (depositorType)
        {
            setDepositorType(depositorType);
            setSelectedDepositorType(await filterDepositors(depositorType));
        }
        else
        {
            setSelectedDepositorType([]);
        }
        if (depositorType !== app_consts.WAREHOUSE_OPERATOR.toLowerCase())
        {
            formik.setFieldValue('depositor_id', "");
        }
    };

    const filterDepositors = async (depositorType) => {
        const filteredList = producerList?.filter((producer) => depositorType === producer.type && producer.entity_id === entity_id);

        let mappedData = [];

        await Promise.all(
            filteredList?.map(async (data_to_map) => {
                const { label, value } = retreive_rSelectVariables(app_consts.PRODUCERS_LIST_MAP, data_to_map);
                const result = await rSelectMapFun(label, value);
                mappedData.push(result);
            })
        );
        if (depositorType && depositorType === app_consts.WAREHOUSE_OPERATOR.toLowerCase() && mappedData)
        {
            setDepositorOwnlabel(mappedData[0]?.label);
            formik.setFieldValue('depositor_id', mappedData[0]?.value);
            handleDepositorCommodities(mappedData[0]);
        }
        return mappedData;
    };

    // Listen to Create Delivery Note State
    const createDeliverNote = useSelector((state) => state.createDeliveryNote);
    // 
    useEffect(() => {
        if (createDeliverNote.data)
        {
            setPrevFormData({ ...prevFormData, ["delivery_id"]: createDeliverNote.data.id, ["commodity_id"]: createDeliverNote.data.commodity_id, ["commodity"]: createDeliverNote.data.commodity, ["gross_weight"]: createDeliverNote.data.gross_weight });
            dispatch(createDeliverynoteReset());
            nextStep();
        }
    }, [createDeliverNote]);

    const handleOnblur = (name, value) => {
        if (value)
        {
            formik.setFieldValue(name, parseFloat(value).toFixed(2));
        }
    };

    const openAddEntityModal = () => {
        setAddEntityModal(true);
    };

    // Handle Changes in React Select Field
    const handlRSelectChanges = (option, name) => {
        if (option)
        {
            formik.setFieldValue(name, option.value);
        }
        else
        {
            formik.setFieldValue(name, "");
        }
    };

    // Handle Depositor Commodities
    const handleDepositorCommodities = (option) => {
        formik.setFieldValue("commodity", "");
        if (option)
        {
            // Fileter Out Producer Commodities
            const filteredList = producerList?.filter((producer) => option.value === producer.id);
            setPermittedCommodities(filteredList[0].commodities);
        }
        else
        {
            setPermittedCommodities([]);
        }
    };

    // 
    const closePendingEntityModal = () => {
        setAddEntityModal(false);
        dispatch(retreive_pending_entity_list_by_type(app_consts.SERVICE_PROVIDER));
    };

    // Add Pending Entity Success Function
    const add_pending_entity_success_function = () => {
        dispatch(retreive_pending_entity_list_by_type(app_consts.SERVICE_PROVIDER));
    };

    return (
        <>
            <form className='justify-center items-center' onSubmit={formik.handleSubmit}>
                <div className="px-4 flex flex-col items-stretch justify-start border-gray-100">
                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                        Depositor's Information
                    </h3>
                </div>
                <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label
                            htmlFor='profileType'
                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                            Warehouse
                        </label>
                        <RSelect
                            name='warehouse_id'
                            // value={formik.values.warehouse_id}
                            onChange={(e) => handlRSelectChanges(e, "warehouse_id")}
                            options={warehouseList}
                            isClearable={true}
                        />
                        {formik.touched.warehouse_id && formik.errors.warehouse_id && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.warehouse_id}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="depositor_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Depositor Type
                        </label>
                        <select
                            name="depositor_type"
                            onChange={(e) => handleDepositorTypeChange(e.target.value)}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value=''>Choose Depositor type</option>
                            <option value='individual'>Individual</option>
                            <option value='commercial'>Commercial</option>
                            <option value='public_entity'>Public Entity</option>
                            <option value='warehouse_operator'>Own</option>
                        </select>
                    </div>
                    <div>
                        <label
                            htmlFor='profileType'
                            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
                        >
                            Depositor
                        </label>
                        {
                            depositor_type === app_consts.WAREHOUSE_OPERATOR.toLowerCase() ?
                                <>
                                    <input
                                        type='text'
                                        name="depositor_id"
                                        disabled
                                        value={depositor_own_label || "Depositor Not Found"}
                                        className="bg-gray-200 border cursor-not-allowed border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                    />
                                </>
                                :
                                <>
                                    <RSelect
                                        name='depositor_id'
                                        // value={formik.values.depositor_id}
                                        onChange={(e) => { handlRSelectChanges(e, "depositor_id"); handleDepositorCommodities(e); }}
                                        options={selectedDepositorType}
                                        isClearable={true}
                                    />
                                </>
                        }
                        {formik.touched.depositor_id && formik.errors.depositor_id && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.depositor_id}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="commodity" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Commodity
                        </label>
                        <select
                            name="commodity"
                            value={formik.values.commodity}
                            onChange={formik.handleChange}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Choose Commodity</option>
                            {
                                permitted_commodities?.map((per_commod) => {
                                    return (
                                        <>
                                            <option value={per_commod}>{convertToTitleCase(per_commod)}</option>
                                        </>
                                    );
                                })
                            }
                        </select>
                        {formik.touched.commodity && formik.errors.commodity && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.commodity}</p>
                        )}
                    </div>
                </div>
                <div className="px-4 flex flex-row items-stretch justify-between border-gray-100">
                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                        Transportation Information
                    </h3>
                    {
                        formik.values.service_provider_type === "unregistered" && (
                            <button
                                disabled={disabled}
                                type="button"
                                onClick={openAddEntityModal}
                                className={`text-white bg-gray-500 border border-gray-400 text-gray-700 cursor-pointer hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-hover:bg-bg-${disabled ? "gray-400" : "green-300"} font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800`}>
                                Add Unregistered Service Provider
                            </button>
                        )
                    }
                </div>
                <div className="grid gap-6 mb-2 lg:grid-cols-3 p-6 sm:p-16 lg:p-8">
                    <div>
                        <label htmlFor="service_provider_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Service Provider Type
                        </label>
                        <select
                            name="service_provider_type"
                            value={formik.values.service_provider_type}
                            onChange={(e) => {
                                formik.setFieldValue(e.target.name, e.target.value);
                                formik.handleChange(e.target.value);
                                formik.setFieldValue('service_provider_id', "");
                            }}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            <option value="">Select Service Provider Type</option>
                            <option value="unregistered">Unregistered</option>
                            <option value="registered">Registered</option>
                        </select>
                        {formik.touched.service_provider_type && formik.errors.service_provider_type && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.service_provider_type}</p>
                        )}
                    </div>
                    {
                        formik.values.service_provider_type && formik.values.service_provider_type.length > 0 && (
                            <>
                                <div>
                                    <label htmlFor="service_provider_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                        Service Provider
                                    </label>
                                    {
                                        formik.values.service_provider_type === "unregistered" ?
                                            <>
                                                <RSelect
                                                    name='pending_entity_id'
                                                    // value={formik.values.depositor_id}
                                                    onChange={(e) => handlRSelectChanges(e, "pending_entity_id")}
                                                    options={pending_entity_list}
                                                    isClearable={true}
                                                />
                                                {formik.touched.pending_entity_id && formik.errors.pending_entity_id && (
                                                    <p className="text-red-500 text-sm mt-1">{formik.errors.pending_entity_id}</p>
                                                )}
                                            </>
                                            :
                                            formik.values.service_provider_type === "registered" ?
                                                <>
                                                    <RSelect
                                                        name='service_provider_id'
                                                        // value={formik.values.depositor_id}
                                                        onChange={(e) => handlRSelectChanges(e, "service_provider_id")}
                                                        options={serviceProviderList}
                                                        isClearable={true}
                                                    />
                                                    {formik.touched.service_provider_id && formik.errors.service_provider_id && (
                                                        <p className="text-red-500 text-sm mt-1">{formik.errors.service_provider_id}</p>
                                                    )}
                                                </>
                                                :
                                                ""
                                    }
                                </div>
                            </>
                        )
                    }
                    <div>
                        <label htmlFor="driver_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Truck Driver
                        </label>
                        <input
                            type="text"
                            name="driver_name"
                            value={formik.values.driver_name}
                            onChange={formik.handleChange}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.driver_name && formik.errors.driver_name && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.driver_name}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="driver_id" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Truck Driver License Number
                        </label>
                        <input
                            type="text"
                            name="driver_id"
                            value={formik.values.driver_id}
                            onChange={formik.handleChange}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.driver_id && formik.errors.driver_id && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.driver_id}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="gross_weight" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Gross Weight <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span>
                        </label>
                        <input
                            step="0.01"
                            type="number"
                            min="0.00"
                            default="0.00"
                            name="gross_weight"
                            value={formik.values.gross_weight}
                            onChange={formik.handleChange}
                            onBlur={() => handleOnblur("gross_weight", formik.values.gross_weight)}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.gross_weight && formik.errors.gross_weight && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.gross_weight}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="license_plate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Truck License Plate
                        </label>
                        <input
                            type="text"
                            name="license_plate"
                            value={formik.values.license_plate}
                            onChange={formik.handleChange}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.license_plate && formik.errors.license_plate && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.license_plate}</p>
                        )}
                    </div>
                    <div>
                        <label htmlFor="trailer_number" className="flex flex-row items-center block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Trailer Plate Number  <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Optional)</span>
                        </label>
                        <input
                            type="text"
                            name="trailer_number"
                            value={formik.values.trailer_number}
                            onChange={formik.handleChange}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="transport_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Transport Type
                        </label>
                        <input
                            type="text"
                            name="transport_type"
                            value={formik.values.transport_type}
                            onChange={formik.handleChange}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        />
                        {formik.touched.transport_type && formik.errors.transport_type && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.transport_type}</p>
                        )}
                    </div>
                    <div className="mb-6">
                        <label htmlFor="origin" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                            Origin Country
                        </label>
                        <select
                            name="origin"
                            value={formik.values.origin}
                            onChange={formik.handleChange}
                            // required
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                        >
                            {countries.map((country, index) => (
                                <option key={index} value={country}>
                                    {country}
                                </option>
                            ))}
                        </select>
                        {formik.touched.origin && formik.errors.origin && (
                            <p className="text-red-500 text-sm mt-1">{formik.errors.origin}</p>
                        )}
                    </div>
                </div>
                {error && error.length > 0 && (
                    <div className="my-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 px-6 py-4 sm:px-16 lg:px-8" role="alert">
                        <span className="font-medium">Error: </span>
                        {error}
                    </div>
                )}
                <div className='mb-4 px-6 flex flex-row justify-end'>
                    <button
                        disabled={disabled}
                        type="submit"
                        className={`text-white bg-${disabled ? "gray-400" : "green-700"} ${disabled ? "cursor-not-allowed" : "cursor-pointer"} hover:bg-bg-${disabled ? "gray-400" : "green-800"} focus:ring-4 focus:outline-none focus:ring-hover:bg-bg-${disabled ? "gray-400" : "green-300"} font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-${disabled ? "gray-400" : "green-600"} dark:hover:bg-${disabled ? "gray-400" : "green-700"} dark:focus:ring-${disabled ? "gray-400" : "green-800"}`}
                    >
                        Next Step: Commodity Grading Information
                    </button>
                </div>
            </form>
            <AddPendingEntityModal isOpen={addEntityModal} setIsOpen={setAddEntityModal} type="service_provider" typeDisabled successFunc={closePendingEntityModal} successFunction={add_pending_entity_success_function} />
        </>
    );
};

export default DeliveriesStep;
