import apiService from './apiService';

export const ActionTypes = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_RESET: 'LOGIN_RESET',
  REGISTER_REQUEST: 'REGISTER_REQUEST',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  GET_CURRENT_ACCOUNT_REQUEST: 'GET_CURRENT_ACCOUNT_REQUEST',
  GET_CURRENT_ACCOUNT_SUCCESS: 'GET_CURRENT_ACCOUNT_SUCCESS',
  GET_CURRENT_ACCOUNT_FAILURE: 'GET_CURRENT_ACCOUNT_FAILURE',
  VERIFY_REGISTRATION_REQUEST: 'VERIFY_REGISTRATION_REQUEST',
  VERIFY_REGISTRATION_SUCCESS: 'VERIFY_REGISTRATION_SUCCESS',
  VERIFY_REGISTRATION_FAILURE: 'VERIFY_REGISTRATION_FAILURE',
  RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
  RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
  RESEND_OTP_FAILURE: 'RESEND_OTP_FAILURE',
  CREATE_PROFILE_REQUEST: 'CREATE_PROFILE_REQUEST',
  CREATE_PROFILE_SUCCESS: 'CREATE_PROFILE_SUCCESS',
  CREATE_PROFILE_FAILURE: 'CREATE_PROFILE_FAILURE',
  UPDATE_ENTITY_REQUEST: 'UPDATE_ENTITY_REQUEST',
  UPDATE_ENTITY_SUCCESS: 'UPDATE_ENTITY_SUCCESS',
  UPDATE_ENTITY_FAILURE: 'UPDATE_ENTITY_FAILURE',
  UPLOAD_REGISTRATION_DOCUMENT_REQUEST: 'UPLOAD_REGISTRATION_DOCUMENT_REQUEST',
  UPLOAD_REGISTRATION_DOCUMENT_SUCCESS: 'UPLOAD_REGISTRATION_DOCUMENT_SUCCESS',
  UPLOAD_REGISTRATION_DOCUMENT_FAILURE: 'UPLOAD_REGISTRATION_DOCUMENT_FAILURE',
  UPLOAD_DIRECTOR_IMAGES_SUCCESS: 'UPLOAD_DIRECTOR_IMAGES_SUCCESS',
  UPLOAD_DIRECTOR_IMAGES_FAILURE: 'UPLOAD_DIRECTOR_IMAGES_FAILURE',
  GET_DIRECTORS_REQUEST: 'GET_DIRECTORS_REQUEST',
  GET_DIRECTORS_SUCCESS: 'GET_DIRECTORS_SUCCESS',
  GET_DIRECTORS_FAILURE: 'GET_DIRECTORS_FAILURE',
  ADD_BANK_ACCOUNT_REQUEST: 'ADD_BANK_ACCOUNT_REQUEST',
  ADD_BANK_ACCOUNT_SUCCESS: 'ADD_BANK_ACCOUNT_SUCCESS',
  ADD_BANK_ACCOUNT_FAILURE: 'ADD_BANK_ACCOUNT_FAILURE',
  REGISTRATION_COMPLETE_REQUEST: 'REGISTRATION_COMPLETE_REQUEST',
  REGISTRATION_COMPLETE_SUCCESS: 'REGISTRATION_COMPLETE_SUCCESS',
  REGISTRATION_COMPLETE_FAILURE: 'REGISTRATION_COMPLETE_FAILURE',
  GET_ENTITIES_REQUEST: 'GET_ENTITIES_REQUEST',
  GET_ENTITIES_SUCCESS: 'GET_ENTITIES_SUCCESS',
  GET_ENTITIES_FAILURE: 'GET_ENTITIES_FAILURE',
  GET_ENTITY_REQUEST: 'GET_ENTITY_REQUEST',
  GET_ENTITY_SUCCESS: 'GET_ENTITY_SUCCESS',
  GET_ENTITY_FAILURE: 'GET_ENTITY_FAILURE',
  GET_ENTITIES_LIST_REQUEST: 'GET_ENTITIES_LIST_REQUEST',
  GET_ENTITIES_LIST_SUCCESS: 'GET_ENTITIES_LIST_SUCCESS',
  GET_ENTITIES_LIST_FAILURE: 'GET_ENTITIES_LIST_FAILURE',

  GET_ENTITY_PROFILE_REQUEST: 'GET_ENTITY_PROFILE_REQUEST',
  GET_ENTITY_PROFILE_SUCCESS: 'GET_ENTITY_PROFILE_SUCCESS',
  GET_ENTITY_PROFILE_FAILURE: 'GET_ENTITY_PROFILE_FAILURE',

  ADD_NEW_MEMBER_REQUEST: 'ADD_NEW_MEMBER_REQUEST',
  ADD_NEW_MEMBER_SUCCESS: 'ADD_NEW_MEMBER_SUCCESS',
  ADD_NEW_MEMBER_FAILURE: 'ADD_NEW_MEMBER_FAILURE',
  CHECK_REGISTRATION_REQUEST: 'CHECK_REGISTRATION_REQUEST',
  CHECK_REGISTRATION_SUCCESS: 'CHECK_REGISTRATION_SUCCESS',
  CHECK_REGISTRATION_FAILURE: 'CHECK_REGISTRATION_FAILURE',
  GET_WAREHOUSE_LIST_REQUEST: 'GET_WAREHOUSE_LIST_REQUEST',
  GET_WAREHOUSE_LIST_SUCCESS: 'GET_WAREHOUSE_LIST_SUCCESS',
  GET_WAREHOUSE_LIST_FAILURE: 'GET_WAREHOUSE_LIST_FAILURE',
  ADD_WAREHOUSE_REQUEST: 'ADD_WAREHOUSE_REQUEST',
  ADD_WAREHOUSE_SUCCESS: 'ADD_WAREHOUSE_SUCCESS',
  ADD_WAREHOUSE_FAILURE: 'ADD_WAREHOUSE_FAILURE',
  CREATE_ENTITY_PROFILE_REQUEST: 'CREATE_ENTITY_PROFILE_REQUEST',
  CREATE_ENTITY_PROFILE_SUCCESS: 'CREATE_ENTITY_PROFILE_SUCCESS',
  CREATE_ENTITY_PROFILE_FAILURE: 'CREATE_ENTITY_PROFILE_FAILURE',
  GET_SPECIFIC_WAREHOUSE_REQUEST: 'GET_SPECIFIC_WAREHOUSE_REQUEST',
  GET_SPECIFIC_WAREHOUSE_SUCCESS: 'GET_SPECIFIC_WAREHOUSE_SUCCESS',
  GET_SPECIFIC_WAREHOUSE_FAILURE: 'GET_SPECIFIC_WAREHOUSE_FAILURE',
  SEND_RESET_PASSWORD_OTP_REQUEST: 'SEND_RESET_PASSWORD_OTP_REQUEST',
  SEND_RESET_PASSWORD_OTP_SUCCESS: 'SEND_RESET_PASSWORD_OTP_SUCCESS',
  SEND_RESET_PASSWORD_OTP_FAILURE: 'SEND_RESET_PASSWORD_OTP_FAILURE',
  SEND_RESET_PASSWORD_OTP_RESET: 'SEND_RESET_PASSWORD_OTP_RESET',
  GET_ENTITY_LIST_REQUEST: 'GET_ENTITY_LIST_REQUEST',
  GET_ENTITY_LIST_SUCCESS: 'GET_ENTITY_LIST_SUCCESS',
  GET_ENTITY_LIST_FAILURE: 'GET_ENTITY_LIST_FAILURE',
  VERIFY_RESET_PASSWORD_OTP_REQUEST: 'VERIFY_RESET_PASSWORD_OTP_REQUEST',
  VERIFY_RESET_PASSWORD_OTP_SUCCESS: 'VERIFY_RESET_PASSWORD_OTP_SUCCESS',
  VERIFY_RESET_PASSWORD_OTP_FAILURE: 'VERIFY_RESET_PASSWORD_OTP_FAILURE',
  VERIFY_RESET_PASSWORD_OTP_RESET: 'VERIFY_RESET_PASSWORD_OTP_RESET',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',
  RESET_PASSWORD_RESET: 'RESET_PASSWORD_RESET',
  CREATE_ENTITY_EXISTING_ACCOUNT_REQUEST:
    'CREATE_ENTITY_EXISTING_ACCOUNT_REQUEST',
  CREATE_ENTITY_EXISTING_ACCOUNT_SUCCESS:
    'CREATE_ENTITY_EXISTING_ACCOUNT_SUCCESS',
  CREATE_ENTITY_EXISTING_ACCOUNT_FAILURE:
    'CREATE_ENTITY_EXISTING_ACCOUNT_FAILURE',
  CREATE_ENTITY_EXISTING_ACCOUNT_RESET: 'CREATE_ENTITY_EXISTING_ACCOUNT_RESET',

  GET_PRODUCER_REQUEST: 'GET_PRODUCER_REQUEST',
  GET_PRODUCER_SUCCESS: 'GET_PRODUCER_SUCCESS',
  GET_PRODUCER_FAILURE: 'GET_PRODUCER_FAILURE',

  // GET_PRODUCER_LIST_REQUEST: 'GET_PRODUCER_LIST_REQUEST',
  // GET_PRODUCER_LIST_SUCCESS: 'GET_PRODUCER_LIST_SUCCESS',
  // GET_PRODUCER_LIST_FAILURE: 'GET_PRODUCER_LIST_FAILURE',
  GET_PRODUCERS_REQUEST: 'GET_PRODUCERS_REQUEST',
  GET_PRODUCERS_SUCCESS: 'GET_PRODUCERS_SUCCESS',
  GET_PRODUCERS_FAILURE: 'GET_PRODUCERS_FAILURE',

  ADD_NEW_PRODUCER_REQUEST: 'ADD_NEW_PRODUCER_REQUEST',
  ADD_NEW_PRODUCER_SUCCESS: 'ADD_NEW_PRODUCER_SUCCESS',
  ADD_NEW_PRODUCER_FAILURE: 'ADD_NEW_PRODUCER_FAILURE',

  GET_BROKER_OFFER_REQUEST: 'GET_BROKER_OFFER_REQUEST',
  GET_BROKER_OFFER_SUCCESS: 'GET_BROKER_OFFER_SUCCESS',
  GET_BROKER_OFFER_FAILURE: 'GET_BROKER_OFFER_FAILURE',

  // GET_BROKER_OFFER_LIST_REQUEST: 'GET_BROKER_OFFER_LIST_REQUEST',
  // GET_BROKER_OFFER_LIST_SUCCESS: 'GET_BROKER_OFFER_LIST_SUCCESS',
  // GET_BROKER_OFFER_LIST_FAILURE: 'GET_BROKER_OFFER_LIST_FAILURE'

  GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_REQUEST:
    'GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_REQUEST',
  GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_SUCCESS:
    'GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_SUCCESS',
  GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_FAILURE:
    'GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_FAILURE',
  GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_RESET:
    'GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_RESET',

  ADD_NEW_BROKER_OFFER_REQUEST: 'ADD_NEW_BROKER_OFFER_REQUEST',
  ADD_NEW_BROKER_OFFER_SUCCESS: 'ADD_NEW_BROKER_OFFER_SUCCESS',
  ADD_NEW_BROKER_OFFER_FAILURE: 'ADD_NEW_BROKER_OFFER_FAILURE',

  ADD_NEW_BROKER_BID_REQUEST: 'ADD_NEW_BROKER_BID_REQUEST',
  ADD_NEW_BROKER_BID_SUCCESS: 'ADD_NEW_BROKER_BID_SUCCESS',
  ADD_NEW_BROKER_BID_FAILURE: 'ADD_NEW_BROKER_BID_FAILURE',

  GET_BROKER_OFFERS_REQUEST: 'GET_BROKER_OFFERS_REQUEST',
  GET_BROKER_OFFERS_SUCCESS: 'GET_BROKER_OFFERS_SUCCESS',
  GET_BROKER_OFFERS_FAILURE: 'GET_BROKER_OFFERS_FAILURE',

  GET_BROKER_BIDS_BY_BROKER_ID_REQUEST: 'GET_BROKER_BIDS_BY_BROKER_ID_REQUEST',
  GET_BROKER_BIDS_BY_BROKER_ID_SUCCESS: 'GET_BROKER_BIDS_BY_BROKER_ID_SUCCESS',
  GET_BROKER_BIDS_BY_BROKER_ID_FAILURE: 'GET_BROKER_BIDS_BY_BROKER_ID_FAILURE',

  GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_REQUEST:
    'GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_REQUEST',
  GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_SUCCESS:
    'GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_SUCCESS',
  GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_FAILURE:
    'GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_FAILURE',

  // GET OFFERS (LIST)
  GET_OFFER_LIST_REQUEST: 'GET_OFFER_LIST_REQUEST',
  GET_OFFER_LIST_SUCCESS: 'GET_OFFER_LIST_SUCCESS',
  GET_OFFER_LIST_FAILURE: 'GET_OFFER_LIST_FAILURE',

  // GET OFFERS (LIST with filtered status)
  GET_OFFER_LIST_FILTER_STATUS_REQUEST: 'GET_OFFER_LIST_FILTER_STATUS_REQUEST',
  GET_OFFER_LIST_FILTER_STATUS_SUCCESS: 'GET_OFFER_LIST_FILTER_STATUS_SUCCESS',
  GET_OFFER_LIST_FILTER_STATUS_FAILURE: 'GET_OFFER_LIST_FILTER_STATUS_FAILURE',

  // GET USER ACCOUNT (LIST)
  GET_USER_ACCOUNT_LIST_REQUEST: 'GET_USER_ACCOUNT_LIST_REQUEST',
  GET_USER_ACCOUNT_LIST_SUCCESS: 'GET_USER_ACCOUNT_LIST_SUCCESS',
  GET_USER_ACCOUNT_LIST_FAILURE: 'GET_USER_ACCOUNT_LIST_FAILURE',

  //GET USER ACCOUNT DETAILS
  GET_USER_ACCOUNT_DETAILS_REQUEST: 'GET_USER_ACCOUNT_DETAILS_REQUEST',
  GET_USER_ACCOUNT_DETAILS_SUCCESS: 'GET_USER_ACCOUNT_DETAILS_SUCCESS',
  GET_USER_ACCOUNT_DETAILS_FAILURE: 'GET_USER_ACCOUNT_DETAILS_FAILURE',

  // GET OFFERS DETAILS
  GET_OFFER_DETAILS_REQUEST: 'GET_OFFER_DETAILS_REQUEST',
  GET_OFFER_DETAILS_SUCCESS: 'GET_OFFER_DETAILS_SUCCESS',
  GET_OFFER_DETAILS_FAILURE: 'GET_OFFER_DETAILS_FAILURE',

  // GET BROKER LIST
  GET_BROKER_LIST_REQUEST: 'GET_BROKER_LIST_REQUEST',
  GET_BROKER_LIST_SUCCESS: 'GET_BROKER_LIST_SUCCESS',
  GET_BROKER_LIST_FAILURE: 'GET_BROKER_LIST_FAILURE',

  GET_RECEIPT_NUMBERS_REQUEST: 'GET_RECEIPT_NUMBERS_REQUEST',
  GET_RECEIPT_NUMBERS_SUCCESS: 'GET_RECEIPT_NUMBERS_SUCCESS',
  GET_RECEIPT_NUMBERS_FAILURE: 'GET_RECEIPT_NUMBERS_FAILURE',

  ACTIVATE_MEMBERSHIP_TYPE_REQUEST: 'ACTIVATE_MEMBERSHIP_TYPE_REQUEST',
  ACTIVATE_MEMBERSHIP_TYPE_SUCCESS: 'ACTIVATE_MEMBERSHIP_TYPE_SUCCESS',
  ACTIVATE_MEMBERSHIP_TYPE_FAILURE: 'ACTIVATE_MEMBERSHIP_TYPE_FAILURE',

  // NEW EXCHANGE ACTIONTYPES
  VERIFY_MEMBERSHIP_REQUEST: 'VERIFY_MEMBERSHIP_REQUEST',
  VERIFY_MEMBERSHIP_SUCCESS: 'VERIFY_MEMBERSHIP_SUCCESS',
  VERIFY_MEMBERSHIP_FAILURE: 'VERIFY_MEMBERSHIP_FAILURE',

  // MARKETS OFFERS ACTIONTYPES
  UPDATE_OFFER_STATUS_REQUEST: 'UPDATE_OFFER_STATUS_REQUEST',
  UPDATE_OFFER_STATUS_SUCCESS: 'UPDATE_OFFER_STATUS_SUCCESS',
  UPDATE_OFFER_STATUS_FAILURE: 'UPDATE_OFFER_STATUS_FAILURE',

  // NEW COOPERATIVE ACTIONTYPES
  GET_COOPERATIVE_MEMBER_LIST_REQUEST: 'GET_COOPERATIVE_MEMBER_LIST_REQUEST',
  GET_COOPERATIVE_MEMBER_LIST_SUCCESS: 'GET_COOPERATIVE_MEMBER_LIST_SUCCESS',
  GET_COOPERATIVE_MEMBER_LIST_FAILURE: 'GET_COOPERATIVE_MEMBER_LIST_FAILURE',

  UPDATE_BROKER_TRADING_AUTHORITY_REQUEST:
    'UPDATE_BROKER_TRADING_AUTHORITY_REQUEST',
  UPDATE_BROKER_TRADING_AUTHORITY_SUCCESS:
    'UPDATE_BROKER_TRADING_AUTHORITY_SUCCESS',
  UPDATE_BROKER_TRADING_AUTHORITY_FAILURE:
    'UPDATE_BROKER_TRADING_AUTHORITY_FAILURE',

  GET_SPECIFIC_ENTITY_LIST_REQUEST: 'GET_SPECIFIC_ENTITY_LIST_REQUEST',
  GET_SPECIFIC_ENTITY_LIST_SUCCESS: 'GET_SPECIFIC_ENTITY_LIST_SUCCESS',
  GET_SPECIFIC_ENTITY_LIST_FAILURE: 'GET_SPECIFIC_ENTITY_LIST_FAILURE',
  GET_SPECIFIC_ENTITY_LIST_RESET: 'GET_SPECIFIC_ENTITY_LIST_RESET',
  CREATE_DELIVERY_NOTE_REQUEST: 'CREATE_DELIVERY_NOTE_REQUEST',
  CREATE_DELIVERY_NOTE_SUCCESS: 'CREATE_DELIVERY_NOTE_SUCCESS',
  CREATE_DELIVERY_NOTE_FAILURE: 'CREATE_DELIVERY_NOTE_FAILURE',
  CREATE_DELIVERY_NOTE_RESET: 'CREATE_DELIVERY_NOTE_RESET',
  CREATE_COMMODITY_GRADING_REQUEST: 'CREATE_COMMODITY_GRADING_REQUEST',
  CREATE_COMMODITY_GRADING_SUCCESS: 'CREATE_COMMODITY_GRADING_SUCCESS',
  CREATE_COMMODITY_GRADING_FAILURE: 'CREATE_COMMODITY_GRADING_FAILURE',
  CREATE_COMMODITY_GRADING_RESET: 'CREATE_COMMODITY_GRADING_RESET',
  CREATE_STORAGE_INVENTORY_REQUEST: 'CREATE_STORAGE_INVENTORY_REQUEST',
  CREATE_STORAGE_INVENTORY_SUCCESS: 'CREATE_STORAGE_INVENTORY_SUCCESS',
  CREATE_STORAGE_INVENTORY_FAILURE: 'CREATE_STORAGE_INVENTORY_FAILURE',
  CREATE_STORAGE_INVENTORY_RESET: 'CREATE_STORAGE_INVENTORY_RESET',
  UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_REQUEST:
    'UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_REQUEST',
  UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_SUCCESS:
    'UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_SUCCESS',
  UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_FAILURE:
    'UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_FAILURE',
  UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_RESET:
    'UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_RESET',
  GENERATE_WAREHOUSE_RECEIPT_REQUEST: 'GENERATE_WAREHOUSE_RECEIPT_REQUEST',
  GENERATE_WAREHOUSE_RECEIPT_SUCCESS: 'GENERATE_WAREHOUSE_RECEIPT_SUCCESS',
  GENERATE_WAREHOUSE_RECEIPT_FAILURE: 'GENERATE_WAREHOUSE_RECEIPT_FAILURE',
  GENERATE_WAREHOUSE_RECEIPT_RESET: 'GENERATE_WAREHOUSE_RECEIPT_RESET',
  RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_REQUEST:
    'RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_REQUEST',
  RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_SUCCESS:
    'RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_SUCCESS',
  RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_FAILURE:
    'RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_FAILURE',
  RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_RESET:
    'RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_RESET',
  RETRIEVE_ALL_PRODUCER_LIST_REQUEST: 'RETRIEVE_ALL_PRODUCER_LIST_REQUEST',
  RETRIEVE_ALL_PRODUCER_LIST_SUCCESS: 'RETRIEVE_ALL_PRODUCER_LIST_SUCCESS',
  RETRIEVE_ALL_PRODUCER_LIST_FAILURE: 'RETRIEVE_ALL_PRODUCER_LIST_FAILURE',
  RETRIEVE_ALL_PRODUCER_LIST_RESET: 'RETRIEVE_ALL_PRODUCER_LIST_RESET',
  CREATE_PENDING_ENTITY_REQUEST: 'CREATE_PENDING_ENTITY_REQUEST',
  CREATE_PENDING_ENTITY_SUCCESS: 'CREATE_PENDING_ENTITY_SUCCESS',
  CREATE_PENDING_ENTITY_FAILURE: 'CREATE_PENDING_ENTITY_FAILURE',
  CREATE_PENDING_ENTITY_RESET: 'CREATE_PENDING_ENTITY_RESET',
  RETRIEVE_PENDING_ENTITY_LIST_REQUEST: 'RETRIEVE_PENDING_ENTITY_LIST_REQUEST',
  RETRIEVE_PENDING_ENTITY_LIST_SUCCESS: 'RETRIEVE_PENDING_ENTITY_LIST_SUCCESS',
  RETRIEVE_PENDING_ENTITY_LIST_FAILURE: 'RETRIEVE_PENDING_ENTITY_LIST_FAILURE',
  RETRIEVE_PENDING_ENTITY_LIST_RESET: 'RETRIEVE_PENDING_ENTITY_LIST_RESET',
  RETRIEVE_INBOUND_SHIPMENT_LIST_REQUEST:
    'RETRIEVE_INBOUND_SHIPMENT_LIST_REQUEST',
  RETRIEVE_INBOUND_SHIPMENT_LIST_SUCCESS:
    'RETRIEVE_INBOUND_SHIPMENT_LIST_SUCCESS',
  RETRIEVE_INBOUND_SHIPMENT_LIST_FAILURE:
    'RETRIEVE_INBOUND_SHIPMENT_LIST_FAILURE',
  RETRIEVE_INBOUND_SHIPMENT_LIST_RESET: 'RETRIEVE_INBOUND_SHIPMENT_LIST_RESET',
  RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_REQUEST:
    'RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_REQUEST',
  RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_SUCCESS:
    'RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_SUCCESS',
  RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_FAILURE:
    'RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_FAILURE',
  RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_RESET:
    'RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_RESET',
  RETRIEVE_WAREHOUSE_STORAGE_LIST_REQUEST:
    'RETRIEVE_WAREHOUSE_STORAGE_LIST_REQUEST',
  RETRIEVE_WAREHOUSE_STORAGE_LIST_SUCCESS:
    'RETRIEVE_WAREHOUSE_STORAGE_LIST_SUCCESS',
  RETRIEVE_WAREHOUSE_STORAGE_LIST_FAILURE:
    'RETRIEVE_WAREHOUSE_STORAGE_LIST_FAILURE',
  RETRIEVE_WAREHOUSE_STORAGE_LIST_RESET:
    'RETRIEVE_WAREHOUSE_STORAGE_LIST_RESET',
  RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_REQUEST:
    'RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_REQUEST',
  RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_SUCCESS:
    'RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_SUCCESS',
  RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_FAILURE:
    'RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_FAILURE',
  RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_RESET:
    'RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_RESET',
  CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_REQUEST:
    'CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_REQUEST',
  CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_SUCCESS:
    'CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_SUCCESS',
  CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_FAILURE:
    'CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_FAILURE',
  CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_RESET:
    'CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_RESET',
  UPDATE_WAREHOUSE_STATUS_REQUEST: 'UPDATE_WAREHOUSE_STATUS_REQUEST',
  UPDATE_WAREHOUSE_STATUS_SUCCESS: 'UPDATE_WAREHOUSE_STATUS_SUCCESS',
  UPDATE_WAREHOUSE_STATUS_FAILURE: 'UPDATE_WAREHOUSE_STATUS_FAILURE',
  UPDATE_WAREHOUSE_STATUS_RESET: 'UPDATE_WAREHOUSE_STATUS_RESET',
  RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_REQUEST:
    'RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_REQUEST',
  RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_SUCCESS:
    'RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_SUCCESS',
  RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_FAILURE:
    'RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_FAILURE',
  RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_RESET:
    'RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_RESET',
  AMEND_WAREHOUSE_RECEIPT_STATUS_REQUEST:
    'AMEND_WAREHOUSE_RECEIPT_STATUS_REQUEST',
  AMEND_WAREHOUSE_RECEIPT_STATUS_SUCCESS:
    'AMEND_WAREHOUSE_RECEIPT_STATUS_SUCCESS',
  AMEND_WAREHOUSE_RECEIPT_STATUS_FAILURE:
    'AMEND_WAREHOUSE_RECEIPT_STATUS_FAILURE',
  AMEND_WAREHOUSE_RECEIPT_STATUS_RESET: 'AMEND_WAREHOUSE_RECEIPT_STATUS_RESET',
  RECEIVE_SPECIFIC_BROKER_OFFER_REQUEST:
    'RECEIVE_SPECIFIC_BROKER_OFFER_REQUEST',
  RECEIVE_SPECIFIC_BROKER_OFFER_SUCCESS:
    'RECEIVE_SPECIFIC_BROKER_OFFER_SUCCESS',
  RECEIVE_SPECIFIC_BROKER_OFFER_FAILURE:
    'RECEIVE_SPECIFIC_BROKER_OFFER_FAILURE',
  RECEIVE_SPECIFIC_BROKER_OFFER_RESET: 'RECEIVE_SPECIFIC_BROKER_OFFER_RESET',
  ACCEPT_BID_ON_COMMODITY_ORDER_REQUEST:
    'ACCEPT_BID_ON_COMMODITY_ORDER_REQUEST',
  ACCEPT_BID_ON_COMMODITY_ORDER_SUCCESS:
    'ACCEPT_BID_ON_COMMODITY_ORDER_SUCCESS',
  ACCEPT_BID_ON_COMMODITY_ORDER_FAILURE:
    'ACCEPT_BID_ON_COMMODITY_ORDER_FAILURE',
  ACCEPT_BID_ON_COMMODITY_ORDER_RESET: 'ACCEPT_BID_ON_COMMODITY_ORDER_RESET',
  RETRIEVE_TRADE_OFFER_DETAILS_REQUEST: 'RETRIEVE_TRADE_OFFER_DETAILS_REQUEST',
  RETRIEVE_TRADE_OFFER_DETAILS_SUCCESS: 'RETRIEVE_TRADE_OFFER_DETAILS_SUCCESS',
  RETRIEVE_TRADE_OFFER_DETAILS_FAILURE: 'RETRIEVE_TRADE_OFFER_DETAILS_FAILURE',
  RETRIEVE_TRADE_OFFER_DETAILS_RESET: 'RETRIEVE_TRADE_OFFER_DETAILS_RESET',
  RETRIEVE_BROKER_BIDS_BY_BROKER_ID_REQUEST:
    'RETRIEVE_BROKER_BIDS_BY_BROKER_ID_REQUEST',
  RETRIEVE_BROKER_BIDS_BY_BROKER_ID_SUCCESS:
    'RETRIEVE_BROKER_BIDS_BY_BROKER_ID_SUCCESS',
  RETRIEVE_BROKER_BIDS_BY_BROKER_ID_FAILURE:
    'RETRIEVE_BROKER_BIDS_BY_BROKER_ID_FAILURE',
  RETRIEVE_BROKER_BIDS_BY_BROKER_ID_RESET:
    'RETRIEVE_BROKER_BIDS_BY_BROKER_ID_RESET',
  RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_REQUEST:
    'RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_REQUEST',
  RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_SUCCESS:
    'RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_SUCCESS',
  RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_FAILURE:
    'RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_FAILURE',
  RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_RESET:
    'RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_RESET',
  UPLOAD_TRADE_PROOF_OF_PAYMENT_REQUEST:
    'UPLOAD_TRADE_PROOF_OF_PAYMENT_REQUEST',
  UPLOAD_TRADE_PROOF_OF_PAYMENT_SUCCESS:
    'UPLOAD_TRADE_PROOF_OF_PAYMENT_SUCCESS',
  UPLOAD_TRADE_PROOF_OF_PAYMENT_FAILURE:
    'UPLOAD_TRADE_PROOF_OF_PAYMENT_FAILURE',
  UPLOAD_TRADE_PROOF_OF_PAYMENT_RESET: 'UPLOAD_TRADE_PROOF_OF_PAYMENT_RESET',
  RETRIEVE_BROKER_PRODUCERS_REQUEST: 'RETRIEVE_BROKER_PRODUCERS_REQUEST',
  RETRIEVE_BROKER_PRODUCERS_SUCCESS: 'RETRIEVE_BROKER_PRODUCERS_SUCCESS',
  RETRIEVE_BROKER_PRODUCERS_FAILURE: 'RETRIEVE_BROKER_PRODUCERS_FAILURE',
  RETRIEVE_BROKER_PRODUCERS_RESET: 'RETRIEVE_BROKER_PRODUCERS_RESET',
  APPROVE_TRADE_PAYMENT_REQUEST: 'APPROVE_TRADE_PAYMENT_REQUEST',
  APPROVE_TRADE_PAYMENT_SUCCESS: 'APPROVE_TRADE_PAYMENT_SUCCESS',
  APPROVE_TRADE_PAYMENT_FAILURE: 'APPROVE_TRADE_PAYMENT_FAILURE',
  APPROVE_TRADE_PAYMENT_RESET: 'APPROVE_TRADE_PAYMENT_RESET',
  UPLOAD_SIGNED_TRADE_CONTRACT_REQUEST: 'UPLOAD_SIGNED_TRADE_CONTRACT_REQUEST',
  UPLOAD_SIGNED_TRADE_CONTRACT_SUCCESS: 'UPLOAD_SIGNED_TRADE_CONTRACT_SUCCESS',
  UPLOAD_SIGNED_TRADE_CONTRACT_FAILURE: 'UPLOAD_SIGNED_TRADE_CONTRACT_FAILURE',
  UPLOAD_SIGNED_TRADE_CONTRACT_RESET: 'UPLOAD_SIGNED_TRADE_CONTRACT_RESET',
  CONFIRM_TRADE_BY_CONTRACT_NUMBER_REQUEST: 'CONFIRM_TRADE_BY_CONTRACT_NUMBER_REQUEST',
  CONFIRM_TRADE_BY_CONTRACT_NUMBER_SUCCESS: 'CONFIRM_TRADE_BY_CONTRACT_NUMBER_SUCCESS',
  CONFIRM_TRADE_BY_CONTRACT_NUMBER_FAILURE: 'CONFIRM_TRADE_BY_CONTRACT_NUMBER_FAILURE',
  CONFIRM_TRADE_BY_CONTRACT_NUMBER_RESET: 'CONFIRM_TRADE_BY_CONTRACT_NUMBER_RESET',
  RETRIEVE_TRADE_FULFILLMENT_LIST_REQUEST: 'RETRIEVE_TRADE_FULFILLMENT_LIST_REQUEST',
  RETRIEVE_TRADE_FULFILLMENT_LIST_SUCCESS: 'RETRIEVE_TRADE_FULFILLMENT_LIST_SUCCESS',
  RETRIEVE_TRADE_FULFILLMENT_LIST_FAILURE: 'RETRIEVE_TRADE_FULFILLMENT_LIST_FAILURE',
  RETRIEVE_TRADE_FULFILLMENT_LIST_RESET: 'RETRIEVE_TRADE_FULFILLMENT_LIST_RESET',
  RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_REQUEST: 'RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_REQUEST',
  RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_SUCCESS: 'RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_SUCCESS',
  RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_FAILURE: 'RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_FAILURE',
  RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_RESET: 'RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_RESET',
  CREATE_VIRTUAL_RECEIPT_REQUEST: 'CREATE_VIRTUAL_RECEIPT_REQUEST',
  CREATE_VIRTUAL_RECEIPT_SUCCESS: 'CREATE_VIRTUAL_RECEIPT_SUCCESS',
  CREATE_VIRTUAL_RECEIPT_FAILURE: 'CREATE_VIRTUAL_RECEIPT_FAILURE',
  CREATE_VIRTUAL_RECEIPT_RESET: 'CREATE_VIRTUAL_RECEIPT_RESET',
  RETIREVE_VIRTUAL_RECEIPTS_REQUEST: 'RETIREVE_VIRTUAL_RECEIPTS_REQUEST',
  RETIREVE_VIRTUAL_RECEIPTS_SUCCESS: 'RETIREVE_VIRTUAL_RECEIPTS_SUCCESS',
  RETIREVE_VIRTUAL_RECEIPTS_FAILURE: 'RETIREVE_VIRTUAL_RECEIPTS_FAILURE',
  RETIREVE_VIRTUAL_RECEIPTS_RESET: 'RETIREVE_VIRTUAL_RECEIPTS_RESET',
  UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_REQUEST: 'UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_REQUEST',
  UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_SUCCESS: 'UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_SUCCESS',
  UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_FAILURE: 'UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_FAILURE',
  UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_RESET: 'UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_RESET',
  RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_REQUEST: 'RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_REQUEST',
  RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_SUCCESS: 'RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_SUCCESS',
  RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_FAILURE: 'RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_FAILURE',
  RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_RESET: 'RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_RESET',
  RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_REQUEST: 'RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_REQUEST',
  RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_SUCCESS: 'RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_SUCCESS',
  RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_FAILURE: 'RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_FAILURE',
  RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_RESET: 'RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_RESET',
  RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_REQUEST: 'RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_REQUEST',
  RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_SUCCESS: 'RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_SUCCESS',
  RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_FAILURE: 'RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_FAILURE',
  RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_RESET: 'RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_RESET',
  LIST_ALL_PRODUCERS_WITH_ENTITIES_REQUEST: 'LIST_ALL_PRODUCERS_WITH_ENTITIES_REQUEST',
  LIST_ALL_PRODUCERS_WITH_ENTITIES_SUCCESS: 'LIST_ALL_PRODUCERS_WITH_ENTITIES_SUCCESS',
  LIST_ALL_PRODUCERS_WITH_ENTITIES_FAILURE: 'LIST_ALL_PRODUCERS_WITH_ENTITIES_FAILURE',
  LIST_ALL_PRODUCERS_WITH_ENTITIES_RESET: 'LIST_ALL_PRODUCERS_WITH_ENTITIES_RESET',
  RETRIEVE_ENTITY_PROFILE_INFO_REQUEST: 'RETRIEVE_ENTITY_PROFILE_INFO_REQUEST',
  RETRIEVE_ENTITY_PROFILE_INFO_SUCCESS: 'RETRIEVE_ENTITY_PROFILE_INFO_SUCCESS',
  RETRIEVE_ENTITY_PROFILE_INFO_FAILURE: 'RETRIEVE_ENTITY_PROFILE_INFO_FAILURE',
  RETRIEVE_ENTITY_PROFILE_INFO_RESET: 'RETRIEVE_ENTITY_PROFILE_INFO_RESET',
  RETRIEVE_ENTITY_USER_PROFILE_REQUEST: 'RETRIEVE_ENTITY_USER_PROFILE_REQUEST',
  RETRIEVE_ENTITY_USER_PROFILE_SUCCESS: 'RETRIEVE_ENTITY_USER_PROFILE_SUCCESS',
  RETRIEVE_ENTITY_USER_PROFILE_FAILURE: 'RETRIEVE_ENTITY_USER_PROFILE_FAILURE',
  RETRIEVE_ENTITY_USER_PROFILE_RESET: 'RETRIEVE_ENTITY_USER_PROFILE_RESET',
  AMEND_PROFILE_ROLE_REQUEST: 'AMEND_PROFILE_ROLE_REQUEST',
  AMEND_PROFILE_ROLE_SUCCESS: 'AMEND_PROFILE_ROLE_SUCCESS',
  AMEND_PROFILE_ROLE_FAILURE: 'AMEND_PROFILE_ROLE_FAILURE',
  AMEND_PROFILE_ROLE_RESET: 'AMEND_PROFILE_ROLE_RESET',
  RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_REQUEST: 'RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_REQUEST',
  RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_SUCCESS: 'RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_SUCCESS',
  RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_FAILURE: 'RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_FAILURE',
  RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_RESET: 'RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_RESET',
  PROCESS_BULK_DELIVERY_REQUEST: 'PROCESS_BULK_DELIVERY_REQUEST',
  PROCESS_BULK_DELIVERY_SUCCESS: 'PROCESS_BULK_DELIVERY_SUCCESS',
  PROCESS_BULK_DELIVERY_FAILURE: 'PROCESS_BULK_DELIVERY_FAILURE',
  PROCESS_BULK_DELIVERY_RESET: 'PROCESS_BULK_DELIVERY_RESET',
};

export const loginRequest = () => ({
  type: ActionTypes.LOGIN_REQUEST,
});

export const loginReset = () => ({
  type: ActionTypes.LOGIN_RESET
});

export const loginSuccess = (data) => {
  const tokenCreatedAt = new Date().toISOString();

  saveAuthData(data.token, tokenCreatedAt);

  localStorage.setItem('loginSuccess', true);

  return {
    type: ActionTypes.LOGIN_SUCCESS,
    payload: {
      ...data,
      tokenCreatedAt,
    },
  };
};

export const loginFailure = (error) => ({
  type: ActionTypes.LOGIN_FAILURE,
  payload: error,
});

export const registerRequest = () => ({
  type: ActionTypes.REGISTER_REQUEST,
});

export const registerSuccess = (data) => {
  const tokenCreatedAt = new Date().toISOString();

  saveAuthData(data.token, tokenCreatedAt);

  localStorage.setItem('registerSuccess', true);

  return {
    type: ActionTypes.REGISTER_SUCCESS,
    payload: {
      ...data,
      tokenCreatedAt,
    },
  };
};

export const registerFailure = (error) => ({
  type: ActionTypes.REGISTER_FAILURE,
  payload: error,
});

export const getCurrentAccountRequest = () => ({
  type: ActionTypes.GET_CURRENT_ACCOUNT_REQUEST,
});

export const getCurrentAccountSuccess = (data) => ({
  type: ActionTypes.GET_CURRENT_ACCOUNT_SUCCESS,
  payload: data,
});

export const getCurrentAccountFailure = (error) => ({
  type: ActionTypes.GET_CURRENT_ACCOUNT_FAILURE,
  payload: error,
});

const saveAuthData = (token, tokenCreatedAt) => {
  localStorage.setItem('authToken', token);
  localStorage.setItem('tokenCreatedAt', tokenCreatedAt);
};

export const verifyRegistrationRequest = () => ({
  type: ActionTypes.VERIFY_REGISTRATION_REQUEST,
});

export const verifyRegistrationSuccess = (message) => ({
  type: ActionTypes.VERIFY_REGISTRATION_SUCCESS,
  payload: message,
});

export const verifyRegistrationFailure = (error) => ({
  type: ActionTypes.VERIFY_REGISTRATION_FAILURE,
  payload: error,
});

export const resendOtpRequest = () => ({
  type: ActionTypes.RESEND_OTP_REQUEST,
});

export const resendOtpSuccess = (message) => ({
  type: ActionTypes.RESEND_OTP_SUCCESS,
  payload: message,
});

export const resendOtpFailure = (error) => ({
  type: ActionTypes.RESEND_OTP_FAILURE,
  payload: error,
});

export const createProfileRequest = () => ({
  type: ActionTypes.CREATE_PROFILE_REQUEST,
});

export const createProfileSuccess = (data) => ({
  type: ActionTypes.CREATE_PROFILE_SUCCESS,
  payload: data,
});

export const createProfileFailure = (error) => ({
  type: ActionTypes.CREATE_PROFILE_FAILURE,
  payload: error,
});

export const updateEntityRequest = () => ({
  type: ActionTypes.UPDATE_ENTITY_REQUEST,
});

export const updateEntitySuccess = (entity) => ({
  type: ActionTypes.UPDATE_ENTITY_SUCCESS,
  payload: entity,
});

export const updateEntityFailure = (error) => ({
  type: ActionTypes.UPDATE_ENTITY_FAILURE,
  payload: error,
});

export const addBankAccountRequest = () => ({
  type: ActionTypes.ADD_BANK_ACCOUNT_REQUEST,
});

export const addBankAccountSuccess = (data) => ({
  type: ActionTypes.ADD_BANK_ACCOUNT_SUCCESS,
  payload: data,
});

export const addBankAccountFailure = (error) => ({
  type: ActionTypes.ADD_BANK_ACCOUNT_FAILURE,
  payload: error,
});

export const registrationCompleteRequest = () => ({
  type: ActionTypes.REGISTRATION_COMPLETE_REQUEST,
});

export const registrationCompleteSuccess = (data) => ({
  type: ActionTypes.REGISTRATION_COMPLETE_SUCCESS,
  payload: data,
});

export const registrationCompleteFailure = (error) => ({
  type: ActionTypes.REGISTRATION_COMPLETE_FAILURE,
  payload: error,
});

export const getEntityRequest = () => ({
  type: ActionTypes.GET_ENTITY_REQUEST,
});

export const getEntitySuccess = (entity) => ({
  type: ActionTypes.GET_ENTITY_SUCCESS,
  payload: entity,
});

export const getEntityFailure = (error) => ({
  type: ActionTypes.GET_ENTITY_FAILURE,
  payload: error,
});

export const getEntityProfileRequest = () => ({
  type: ActionTypes.GET_ENTITY_PROFILE_REQUEST,
});

export const getEntityProfileSuccess = (entity) => ({
  type: ActionTypes.GET_ENTITY_PROFILE_SUCCESS,
  payload: entity,
});

export const getEntityProfileFailure = (error) => ({
  type: ActionTypes.GET_ENTITY_PROFILE_FAILURE,
  payload: error,
});

export const getEntityListRequest = () => ({
  type: ActionTypes.GET_ENTITY_LIST_REQUEST,
});

export const getEntityListSuccess = (entity) => ({
  type: ActionTypes.GET_ENTITY_LIST_SUCCESS,
  payload: entity,
});

export const getEntityListFailure = (error) => ({
  type: ActionTypes.GET_ENTITY_LIST_FAILURE,
  payload: error,
});

export const registerNewMemberRequest = () => ({
  type: ActionTypes.ADD_NEW_MEMBER_REQUEST,
});

export const registerNewMemberSuccess = (message) => ({
  type: ActionTypes.ADD_NEW_MEMBER_SUCCESS,
  payload: message,
});

export const registerNewMemberFailure = (error) => ({
  type: ActionTypes.ADD_NEW_MEMBER_FAILURE,
  payload: error,
});

export const getWarehouseListRequest = (error) => ({
  type: ActionTypes.GET_WAREHOUSE_LIST_REQUEST,
  payload: error,
});

export const getWarehouseListSuccess = (error) => ({
  type: ActionTypes.GET_WAREHOUSE_LIST_SUCCESS,
  payload: error,
});

export const getWarehouseListFailure = (error) => ({
  type: ActionTypes.GET_WAREHOUSE_LIST_FAILURE,
  payload: error,
});

export const addWarehouseRequest = (error) => ({
  type: ActionTypes.ADD_WAREHOUSE_REQUEST,
  payload: error,
});

export const addWarehouseSuccess = (error) => ({
  type: ActionTypes.ADD_WAREHOUSE_SUCCESS,
  payload: error,
});

export const addWarehouseFailure = (error) => ({
  type: ActionTypes.ADD_WAREHOUSE_FAILURE,
  payload: error,
});

export const createEntityProfileRequest = (error) => ({
  type: ActionTypes.CREATE_ENTITY_PROFILE_REQUEST,
  payload: error,
});

export const createEntityProfileSuccess = (error) => ({
  type: ActionTypes.CREATE_ENTITY_PROFILE_SUCCESS,
  payload: error,
});

export const createEntityProfileFailure = (error) => ({
  type: ActionTypes.CREATE_ENTITY_PROFILE_FAILURE,
  payload: error,
});

export const getSpecificWarehouseRequest = (error) => ({
  type: ActionTypes.GET_SPECIFIC_WAREHOUSE_REQUEST,
  payload: error,
});

export const getSpecificWarehouseSuccess = (error) => ({
  type: ActionTypes.GET_SPECIFIC_WAREHOUSE_SUCCESS,
  payload: error,
});

export const getSpecificWarehouseFailure = (error) => ({
  type: ActionTypes.GET_SPECIFIC_WAREHOUSE_FAILURE,
  payload: error,
});

export const sendresetpasswordOtpReuest = (error) => ({
  type: ActionTypes.SEND_RESET_PASSWORD_OTP_REQUEST,
  payload: error,
});

export const sendresetpasswordOtpSuccess = (message) => ({
  type: ActionTypes.SEND_RESET_PASSWORD_OTP_SUCCESS,
  payload: message,
});

export const sendresetpasswordOtpFailure = (error) => ({
  type: ActionTypes.SEND_RESET_PASSWORD_OTP_FAILURE,
  payload: error,
});

export const sendresetpasswordOtpReset = () => ({
  type: ActionTypes.SEND_RESET_PASSWORD_OTP_RESET,
  payload: 'idle',
});

export const verifyResetPasswordOtpReuest = (error) => ({
  type: ActionTypes.VERIFY_RESET_PASSWORD_OTP_REQUEST,
  payload: error,
});

export const verifyResetPasswordOtpSuccess = (message) => ({
  type: ActionTypes.VERIFY_RESET_PASSWORD_OTP_SUCCESS,
  payload: message,
});

export const verifyResetPasswordOtpFailure = (error) => ({
  type: ActionTypes.VERIFY_RESET_PASSWORD_OTP_FAILURE,
  payload: error,
});

export const verifyResetPasswordOtpReset = () => ({
  type: ActionTypes.VERIFY_RESET_PASSWORD_OTP_RESET,
  payload: 'idle',
});

export const resetPasswordRequest = (message) => ({
  type: ActionTypes.RESET_PASSWORD_REQUEST,
  payload: message,
});

export const resetPasswordSuccess = (message) => ({
  type: ActionTypes.RESET_PASSWORD_SUCCESS,
  payload: message,
});

export const resetPasswordFailure = (message) => ({
  type: ActionTypes.RESET_PASSWORD_FAILURE,
  payload: message,
});

export const resetPasswordReset = () => ({
  type: ActionTypes.RESET_PASSWORD_RESET,
  payload: 'idle',
});

export const createEntityExistingAccountRequest = (message) => ({
  type: ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_REQUEST,
  payload: message,
});

export const createEntityExistingAccountSuccess = (message) => ({
  type: ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_SUCCESS,
  payload: message,
});

export const createEntityExistingAccountFailure = (message) => ({
  type: ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_FAILURE,
  payload: message,
});

export const createEntityExistingAccountReset = () => ({
  type: ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_RESET,
  payload: 'idle',
});

export const getBrokerOfferRequest = () => ({
  type: ActionTypes.GET_BROKER_OFFER_REQUEST,
});

export const getBrokerOfferSuccess = (entity) => ({
  type: ActionTypes.GET_BROKER_OFFER_SUCCESS,
  payload: entity,
});

export const getBrokerOfferFailure = (error) => ({
  type: ActionTypes.GET_BROKER_OFFER_FAILURE,
  payload: error,
});

// GET OFFER LIST
export const getOfferListRequest = () => ({
  type: ActionTypes.GET_OFFER_LIST_SUCCESS,
});

export const getOfferListSuccess = (offerList) => ({
  type: ActionTypes.GET_OFFER_LIST_SUCCESS,
  payload: offerList,
});

export const getOfferListFailure = (error) => ({
  type: ActionTypes.GET_OFFER_LIST_FAILURE,
  payload: error,
});

// GET OFFER DETAILS
export const getOfferDetailsRequest = () => ({
  type: ActionTypes.GET_OFFER_DETAILS_REQUEST,
});

export const getOfferDetailsSuccess = (offerDetails) => ({
  type: ActionTypes.GET_OFFER_DETAILS_SUCCESS,
  payload: offerDetails,
});

export const getOfferDetailsFailure = (error) => ({
  type: ActionTypes.GET_OFFER_DETAILS_FAILURE,
  payload: error,
});

export const getBrokerOfferListRequest = () => ({
  type: ActionTypes.GET_BROKER_OFFER_LIST_REQUEST,
});

export const getBrokerOfferListSuccess = (producer) => ({
  type: ActionTypes.GET_BROKER_OFFER_LIST_SUCCESS,
  payload: producer,
});

export const getBrokerOfferListFailure = (error) => ({
  type: ActionTypes.GET_BROKER_OFFER_LIST_FAILURE,
  payload: error,
});

export const addNewBrokerOfferRequest = () => ({
  type: ActionTypes.ADD_NEW_BROKER_OFFER_REQUEST,
});

export const addNewBrokerOfferSuccess = (message) => ({
  type: ActionTypes.ADD_NEW_BROKER_OFFER_SUCCESS,
  payload: message,
});

export const addNewBrokerOfferFailure = (error) => ({
  type: ActionTypes.ADD_NEW_BROKER_OFFER_FAILURE,
  payload: error,
});

export const updateBrokerTradingAuthorityRequest = () => ({
  type: ActionTypes.UPDATE_BROKER_TRADING_AUTHORITY_REQUEST,
});

export const updateBrokerTradingAuthoritySuccess = (message) => ({
  type: ActionTypes.UPDATE_BROKER_TRADING_AUTHORITY_SUCCESS,
  payload: message,
});

export const updateBrokerTradingAuthorityFailure = (error) => ({
  type: ActionTypes.UPDATE_BROKER_TRADING_AUTHORITY_FAILURE,
  payload: error,
});

export const addNewBrokerBidRequest = () => ({
  type: ActionTypes.ADD_NEW_BROKER_BID_REQUEST,
});

export const addNewBrokerBidSuccess = (message) => ({
  type: ActionTypes.ADD_NEW_BROKER_BID_SUCCESS,
  payload: message,
});

export const addNewBrokerBidFailure = (error) => ({
  type: ActionTypes.ADD_NEW_BROKER_BID_FAILURE,
  payload: error,
});

export const getProducerRequest = () => ({
  type: ActionTypes.GET_PRODUCER_REQUEST,
});

export const getProducerSuccess = (entity) => ({
  type: ActionTypes.GET_PRODUCER_SUCCESS,
  payload: entity,
});

export const getProducerFailure = (error) => ({
  type: ActionTypes.GET_PRODUCER_FAILURE,
  payload: error,
});

export const getProducerListRequest = () => ({
  type: ActionTypes.GET_PRODUCER_LIST_REQUEST,
});

export const getProducerListSuccess = (producer) => ({
  type: ActionTypes.GET_PRODUCER_LIST_SUCCESS,
  payload: producer,
});

export const getProducerListFailure = (error) => ({
  type: ActionTypes.GET_PRODUCER_LIST_FAILURE,
  payload: error,
});

export const registerNewProducerRequest = () => ({
  type: ActionTypes.ADD_NEW_PRODUCER_REQUEST,
});

export const registerNewProducerSuccess = (message) => ({
  type: ActionTypes.ADD_NEW_PRODUCER_SUCCESS,
  payload: message,
});

export const registerNewProducerFailure = (error) => ({
  type: ActionTypes.ADD_NEW_PRODUCER_FAILURE,
  payload: error,
});

export const activateMembershipTypeRequest = () => ({
  type: ActionTypes.ACTIVATE_MEMBERSHIP_TYPE_REQUEST,
});

export const activateMembershipTypeSuccess = (message) => ({
  type: ActionTypes.ACTIVATE_MEMBERSHIP_TYPE_SUCCESS,
  payload: message,
});

export const activateMembershipTypeFailure = (error) => ({
  type: ActionTypes.ACTIVATE_MEMBERSHIP_TYPE_FAILURE,
  payload: error,
});

export const verifyMembershipRequest = () => ({
  type: ActionTypes.VERIFY_MEMBERSHIP_REQUEST,
});

export const verifyMembershipSuccess = (message) => ({
  type: ActionTypes.VERIFY_MEMBERSHIP_SUCCESS,
  payload: message,
});

export const verifyMembershipFailure = (error) => ({
  type: ActionTypes.VERIFY_MEMBERSHIP_FAILURE,
  payload: error,
});

export const getSpecificEntityProfileListRequest = () => ({
  type: ActionTypes.GET_SPECIFIC_ENTITY_LIST_REQUEST,
});

export const getSpecificEntityProfileListSuccess = (message) => ({
  type: ActionTypes.GET_SPECIFIC_ENTITY_LIST_SUCCESS,
  payload: message,
});

export const getSpecificEntityProfileListFailure = (error) => ({
  type: ActionTypes.GET_SPECIFIC_ENTITY_LIST_FAILURE,
  payload: error,
});

export const getSpecificEntityProfileListReset = () => ({
  type: ActionTypes.GET_SPECIFIC_ENTITY_LIST_RESET,
  payload: 'idle',
});

export const createDeliverynoteRequest = () => ({
  type: ActionTypes.CREATE_DELIVERY_NOTE_REQUEST,
});

export const createDeliverynoteSuccess = (message) => ({
  type: ActionTypes.CREATE_DELIVERY_NOTE_SUCCESS,
  payload: message,
});

export const createDeliverynoteFailure = (error) => ({
  type: ActionTypes.CREATE_DELIVERY_NOTE_FAILURE,
  payload: error,
});

export const createDeliverynoteReset = () => ({
  type: ActionTypes.CREATE_DELIVERY_NOTE_RESET,
  payload: 'idle',
});

export const createCommodityGradingRequest = () => ({
  type: ActionTypes.CREATE_COMMODITY_GRADING_REQUEST,
});

export const createCommodityGradingSuccess = (message) => ({
  type: ActionTypes.CREATE_COMMODITY_GRADING_SUCCESS,
  payload: message,
});

export const createCommodityGradingFailure = (error) => ({
  type: ActionTypes.CREATE_COMMODITY_GRADING_FAILURE,
  payload: error,
});

export const retreiveSpecificInboundShipmentRequest = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_REQUEST,
});

export const retreiveSpecificInboundShipmentSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_SUCCESS,
  payload: message,
});

export const retreiveSpecificInboundShipmentFailure = (error) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_FAILURE,
  payload: error,
});

export const retreiveSpecificInboundShipmentReset = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_INBOUND_SHIPMENT_RESET,
  payload: 'idle',
});

export const createCommodityGradingReset = () => ({
  type: ActionTypes.CREATE_COMMODITY_GRADING_RESET,
  payload: 'idle',
});

export const createStorageInventoryRequest = () => ({
  type: ActionTypes.CREATE_STORAGE_INVENTORY_REQUEST,
});

export const createStorageInventorySuccess = (message) => ({
  type: ActionTypes.CREATE_STORAGE_INVENTORY_SUCCESS,
  payload: message,
});

export const createStorageInventoryFailure = (error) => ({
  type: ActionTypes.CREATE_STORAGE_INVENTORY_FAILURE,
  payload: error,
});

export const createStorageInventoryReset = () => ({
  type: ActionTypes.CREATE_STORAGE_INVENTORY_RESET,
  payload: 'idle',
});

export const updateWarehouseReceiptSettlementRequest = () => ({
  type: ActionTypes.UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_REQUEST,
});

export const updateWarehouseReceiptSettlementSuccess = (message) => ({
  type: ActionTypes.UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_SUCCESS,
  payload: message,
});

export const updateWarehouseReceiptSettlementFailure = (error) => ({
  type: ActionTypes.UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_FAILURE,
  payload: error,
});

export const updateWarehouseReceiptSettlementReset = () => ({
  type: ActionTypes.UPDATE_WAREHOUSE_RECEIPT_SETTLEMENT_RESET,
  payload: 'idle',
});

export const generateWarehouseReceiptRequest = () => ({
  type: ActionTypes.GENERATE_WAREHOUSE_RECEIPT_REQUEST,
});

export const generateWarehouseReceiptSuccess = (message) => ({
  type: ActionTypes.GENERATE_WAREHOUSE_RECEIPT_SUCCESS,
  payload: message,
});

export const generateWarehouseReceiptFailure = (error) => ({
  type: ActionTypes.GENERATE_WAREHOUSE_RECEIPT_FAILURE,
  payload: error,
});

export const generateWarehouseReceiptReset = () => ({
  type: ActionTypes.GENERATE_WAREHOUSE_RECEIPT_RESET,
  payload: 'idle',
});

export const retreivePendingEntityListRequest = () => ({
  type: ActionTypes.RETRIEVE_PENDING_ENTITY_LIST_REQUEST,
});

export const retreivePendingEntityListSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_PENDING_ENTITY_LIST_SUCCESS,
  payload: message,
});

export const retreivePendingEntityListFailure = (error) => ({
  type: ActionTypes.RETRIEVE_PENDING_ENTITY_LIST_FAILURE,
  payload: error,
});

export const retreivePendingEntityListReset = () => ({
  type: ActionTypes.RETRIEVE_PENDING_ENTITY_LIST_RESET,
  payload: 'idle',
});

export const getProducersAndWarehouseReceiptsForBrokerRequest = () => ({
  type: ActionTypes.GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_REQUEST,
});

export const getProducersAndWarehouseReceiptsForBrokerSuccess = (message) => ({
  type: ActionTypes.GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_SUCCESS,
  payload: message,
});

export const getProducersAndWarehouseReceiptsForBrokerFailure = (error) => ({
  type: ActionTypes.GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_FAILURE,
  payload: error,
});

export const getProducersAndWarehouseReceiptsForBrokerReset = () => ({
  type: ActionTypes.GET_PRODUCERS_AND_WAREHOUSE_RECEIPTS_FOR_BROKER_RESET,
  payload: 'idle',
});

export const retrieveAllProducersListRequest = () => ({
  type: ActionTypes.RETRIEVE_ALL_PRODUCER_LIST_REQUEST,
});

export const retrieveAllProducersListSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_ALL_PRODUCER_LIST_SUCCESS,
  payload: message,
});

export const retrieveAllProducersListFailure = (error) => ({
  type: ActionTypes.RETRIEVE_ALL_PRODUCER_LIST_FAILURE,
  payload: error,
});

export const retreiveInboundShipmentListRequest = () => ({
  type: ActionTypes.RETRIEVE_INBOUND_SHIPMENT_LIST_REQUEST,
});

export const retreiveInboundShipmentListSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_INBOUND_SHIPMENT_LIST_SUCCESS,
  payload: message,
});

export const retreiveInboundShipmentListFailure = (error) => ({
  type: ActionTypes.RETRIEVE_INBOUND_SHIPMENT_LIST_FAILURE,
  payload: error,
});

export const retreiveInboundShipmentListReset = () => ({
  type: ActionTypes.RETRIEVE_INBOUND_SHIPMENT_LIST_RESET,
  payload: 'idle',
});

export const retrieveEntityWarehouseReceiptListRequest = () => ({
  type: ActionTypes.RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_REQUEST,
});

export const retrieveEntityWarehouseReceiptListSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_SUCCESS,
  payload: message,
});

export const retrieveEntityWarehouseReceiptListFailure = (error) => ({
  type: ActionTypes.RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_FAILURE,
  payload: error,
});

export const retrieveEntityWarehouseReceiptListReset = () => ({
  type: ActionTypes.RETRIEVE_ENTITY_WAREHOUSE_RECEIPT_LIST_RESET,
  payload: 'idle',
});

export const retreiveWarehouseStorageListRequest = () => ({
  type: ActionTypes.RETRIEVE_WAREHOUSE_STORAGE_LIST_REQUEST,
});

export const retreiveWarehouseStorageListSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_WAREHOUSE_STORAGE_LIST_SUCCESS,
  payload: message,
});

export const retreiveWarehouseStorageListFailure = (error) => ({
  type: ActionTypes.RETRIEVE_WAREHOUSE_STORAGE_LIST_FAILURE,
  payload: error,
});

export const retreiveWarehouseStorageListReset = () => ({
  type: ActionTypes.RETRIEVE_WAREHOUSE_STORAGE_LIST_RESET,
  payload: 'idle',
});

export const retreiveSpecificWarehouseReceiptRequest = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_REQUEST,
});

export const retreiveSpecificWarehouseReceiptSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_SUCCESS,
  payload: message,
});

export const retreiveSpecificWarehouseReceiptFailure = (error) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_FAILURE,
  payload: error,
});

export const retreiveSpecificWarehouseReceiptReset = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_RECEIPT_RESET,
  payload: 'idle',
});

export const continueProcessingWarehouseReceiptRequest = () => ({
  type: ActionTypes.CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_REQUEST,
});

export const continueProcessingWarehouseReceiptSuccess = (message) => ({
  type: ActionTypes.CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_SUCCESS,
  payload: message,
});

export const continueProcessingWarehouseReceiptFailure = (error) => ({
  type: ActionTypes.CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_FAILURE,
  payload: error,
});

export const continueProcessingWarehouseReceiptReset = () => ({
  type: ActionTypes.CONTINUE_PROCESSING_WAREHOUSE_RECEIPT_RESET,
  payload: 'idle',
});

export const updateWarehouseStatusRequest = () => ({
  type: ActionTypes.UPDATE_WAREHOUSE_STATUS_REQUEST,
});

export const updateWarehouseStatusSuccess = (message) => ({
  type: ActionTypes.UPDATE_WAREHOUSE_STATUS_SUCCESS,
  payload: message,
});

export const updateWarehouseStatusFailure = (error) => ({
  type: ActionTypes.UPDATE_WAREHOUSE_STATUS_FAILURE,
  payload: error,
});

export const updateWarehouseStatusReset = () => ({
  type: ActionTypes.UPDATE_WAREHOUSE_STATUS_RESET,
  payload: 'idle',
});

export const retriveIssuedWarehouseReceiptsRequest = () => ({
  type: ActionTypes.RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_REQUEST,
});

export const retriveIssuedWarehouseReceiptsSuccess = (message) => ({
  type: ActionTypes.RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_SUCCESS,
  payload: message,
});

export const retriveIssuedWarehouseReceiptsFailure = (error) => ({
  type: ActionTypes.RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_FAILURE,
  payload: error,
});

export const retriveIssuedWarehouseReceiptsReset = () => ({
  type: ActionTypes.RETIREVE_ISSUED_WAREHOUSE_RECEIPTS_RESET,
  payload: 'idle',
});

export const amendWarehouseReceiptStatusRequest = () => ({
  type: ActionTypes.AMEND_WAREHOUSE_RECEIPT_STATUS_REQUEST,
});

export const amendWarehouseReceiptStatusSuccess = (message) => ({
  type: ActionTypes.AMEND_WAREHOUSE_RECEIPT_STATUS_SUCCESS,
  payload: message,
});

export const amendWarehouseReceiptStatusFailure = (error) => ({
  type: ActionTypes.AMEND_WAREHOUSE_RECEIPT_STATUS_FAILURE,
  payload: error,
});

export const amendWarehouseReceiptStatusReset = () => ({
  type: ActionTypes.AMEND_WAREHOUSE_RECEIPT_STATUS_RESET,
  payload: 'idle',
});

export const retrieveSpecificBrokerOfferRequest = () => ({
  type: ActionTypes.RECEIVE_SPECIFIC_BROKER_OFFER_REQUEST,
});

export const retrieveSpecificBrokerOfferSuccess = (message) => ({
  type: ActionTypes.RECEIVE_SPECIFIC_BROKER_OFFER_SUCCESS,
  payload: message,
});

export const retrieveSpecificBrokerOfferFailure = (error) => ({
  type: ActionTypes.RECEIVE_SPECIFIC_BROKER_OFFER_FAILURE,
  payload: error,
});

export const retrieveSpecificBrokerOfferReset = () => ({
  type: ActionTypes.RECEIVE_SPECIFIC_BROKER_OFFER_RESET,
  payload: 'idle',
});

export const acceptBidOnCommodityOrderRequest = () => ({
  type: ActionTypes.ACCEPT_BID_ON_COMMODITY_ORDER_REQUEST,
});

export const acceptBidOnCommodityOrderSuccess = (message) => ({
  type: ActionTypes.ACCEPT_BID_ON_COMMODITY_ORDER_SUCCESS,
  payload: message,
});

export const acceptBidOnCommodityOrderFailure = (error) => ({
  type: ActionTypes.ACCEPT_BID_ON_COMMODITY_ORDER_FAILURE,
  payload: error,
});

export const acceptBidOnCommodityOrderReset = () => ({
  type: ActionTypes.ACCEPT_BID_ON_COMMODITY_ORDER_RESET,
  payload: 'idle',
});

export const retrieveTradeOfferDetailsRequest = () => ({
  type: ActionTypes.RETRIEVE_TRADE_OFFER_DETAILS_REQUEST,
});

export const retrieveTradeOfferDetailsSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_TRADE_OFFER_DETAILS_SUCCESS,
  payload: message,
});

export const retrieveTradeOfferDetailsFailure = (error) => ({
  type: ActionTypes.RETRIEVE_TRADE_OFFER_DETAILS_FAILURE,
  payload: error,
});

export const retrieveTradeOfferDetailsReset = () => ({
  type: ActionTypes.RETRIEVE_TRADE_OFFER_DETAILS_RESET,
  payload: 'idle',
});

export const retrieveBrokerBidsByBrokerIdRequest = () => ({
  type: ActionTypes.RETRIEVE_BROKER_BIDS_BY_BROKER_ID_REQUEST,
});

export const retrieveBrokerBidsByBrokerIdSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_BROKER_BIDS_BY_BROKER_ID_SUCCESS,
  payload: message,
});

export const retrieveBrokerBidsByBrokerIdFailure = (error) => ({
  type: ActionTypes.RETRIEVE_BROKER_BIDS_BY_BROKER_ID_FAILURE,
  payload: error,
});

export const retrieveBrokerBidsByBrokerIdReset = () => ({
  type: ActionTypes.RETRIEVE_BROKER_BIDS_BY_BROKER_ID_RESET,
  payload: 'idle',
});

export const retrieveSpecificBrokerBidDetailsRequest = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_REQUEST,
});

export const retrieveSpecificBrokerBidDetailsSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_SUCCESS,
  payload: message,
});

export const retrieveSpecificBrokerBidDetailsFailure = (error) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_FAILURE,
  payload: error,
});

export const retrieveSpecificBrokerBidDetailsReset = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_BROKER_BID_DETAILS_RESET,
  payload: 'idle',
});

export const uploadTradeProofOfPaymentRequest = () => ({
  type: ActionTypes.UPLOAD_TRADE_PROOF_OF_PAYMENT_REQUEST,
});

export const uploadTradeProofOfPaymentSuccess = (message) => ({
  type: ActionTypes.UPLOAD_TRADE_PROOF_OF_PAYMENT_SUCCESS,
  payload: message,
});

export const uploadTradeProofOfPaymentFailure = (error) => ({
  type: ActionTypes.UPLOAD_TRADE_PROOF_OF_PAYMENT_FAILURE,
  payload: error,
});

export const uploadTradeProofOfPaymentReset = () => ({
  type: ActionTypes.UPLOAD_TRADE_PROOF_OF_PAYMENT_RESET,
  payload: 'idle',
});

export const retrieveBrokerProducersRequest = () => ({
  type: ActionTypes.RETRIEVE_BROKER_PRODUCERS_REQUEST,
});

export const retrieveBrokerProducersSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_BROKER_PRODUCERS_SUCCESS,
  payload: message,
});

export const retrieveBrokerProducersFailure = (error) => ({
  type: ActionTypes.RETRIEVE_BROKER_PRODUCERS_FAILURE,
  payload: error,
});

export const retrieveBrokerProducersReset = () => ({
  type: ActionTypes.RETRIEVE_BROKER_PRODUCERS_RESET,
  payload: 'idle',
});

export const approveTradePaymentRequest = () => ({
  type: ActionTypes.APPROVE_TRADE_PAYMENT_REQUEST,
});

export const approveTradePaymentSuccess = (message) => ({
  type: ActionTypes.APPROVE_TRADE_PAYMENT_SUCCESS,
  payload: message,
});

export const approveTradePaymentFailure = (error) => ({
  type: ActionTypes.APPROVE_TRADE_PAYMENT_FAILURE,
  payload: error,
});

export const approveTradePaymentReset = () => ({
  type: ActionTypes.APPROVE_TRADE_PAYMENT_RESET,
  payload: 'idle',
});

export const uploadSignedTradeContractRequest = () => ({
  type: ActionTypes.UPLOAD_SIGNED_TRADE_CONTRACT_REQUEST,
});

export const uploadSignedTradeContractSuccess = (message) => ({
  type: ActionTypes.UPLOAD_SIGNED_TRADE_CONTRACT_SUCCESS,
  payload: message,
});

export const uploadSignedTradeContractFailure = (error) => ({
  type: ActionTypes.UPLOAD_SIGNED_TRADE_CONTRACT_FAILURE,
  payload: error,
});

export const uploadSignedTradeContractReset = () => ({
  type: ActionTypes.UPLOAD_SIGNED_TRADE_CONTRACT_RESET,
  payload: 'idle',
});

export const confirmTradeByContractNumberRequest = () => ({
  type: ActionTypes.CONFIRM_TRADE_BY_CONTRACT_NUMBER_REQUEST,
});

export const confirmTradeByContractNumberSuccess = (message) => ({
  type: ActionTypes.CONFIRM_TRADE_BY_CONTRACT_NUMBER_SUCCESS,
  payload: message,
});

export const confirmTradeByContractNumberFailure = (error) => ({
  type: ActionTypes.CONFIRM_TRADE_BY_CONTRACT_NUMBER_FAILURE,
  payload: error,
});

export const confirmTradeByContractNumberReset = () => ({
  type: ActionTypes.CONFIRM_TRADE_BY_CONTRACT_NUMBER_RESET,
  payload: 'idle',
});

export const retireveTradeFulfillmentListRequest = () => ({
  type: ActionTypes.RETRIEVE_TRADE_FULFILLMENT_LIST_REQUEST,
});

export const retireveTradeFulfillmentListSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_TRADE_FULFILLMENT_LIST_SUCCESS,
  payload: message,
});

export const retireveTradeFulfillmentListFailure = (error) => ({
  type: ActionTypes.RETRIEVE_TRADE_FULFILLMENT_LIST_FAILURE,
  payload: error,
});

export const retireveTradeFulfillmentListReset = () => ({
  type: ActionTypes.RETRIEVE_TRADE_FULFILLMENT_LIST_RESET,
  payload: 'idle',
});

export const retrieveSpecificTradeFulfillmentRequest = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_REQUEST,
});

export const retrieveSpecificTradeFulfillmentSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_SUCCESS,
  payload: message,
});

export const retrieveSpecificTradeFulfillmentFailure = (error) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_FAILURE,
  payload: error,
});

export const retrieveSpecificTradeFulfillmentReset = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_TRADE_FULFILLMENT_RESET,
  payload: 'idle',
});

export const createVirtualReceiptRequest = () => ({
  type: ActionTypes.CREATE_VIRTUAL_RECEIPT_REQUEST,
});

export const createVirtualReceiptSuccess = (message) => ({
  type: ActionTypes.CREATE_VIRTUAL_RECEIPT_SUCCESS,
  payload: message,
});

export const createVirtualReceiptFailure = (error) => ({
  type: ActionTypes.CREATE_VIRTUAL_RECEIPT_FAILURE,
  payload: error,
});

export const createVirtualReceiptReset = () => ({
  type: ActionTypes.CREATE_VIRTUAL_RECEIPT_RESET,
  payload: 'idle',
});

export const retriveVirtualReceiptsRequest = () => ({
  type: ActionTypes.RETIREVE_VIRTUAL_RECEIPTS_REQUEST,
});

export const retriveVirtualReceiptsSuccess = (message) => ({
  type: ActionTypes.RETIREVE_VIRTUAL_RECEIPTS_SUCCESS,
  payload: message,
});

export const retriveVirtualReceiptsFailure = (error) => ({
  type: ActionTypes.RETIREVE_VIRTUAL_RECEIPTS_FAILURE,
  payload: error,
});

export const retriveVirtualReceiptsReset = () => ({
  type: ActionTypes.RETIREVE_VIRTUAL_RECEIPTS_RESET,
  payload: 'idle',
});

export const uploadWarehouseInspectionCertificateRequest = () => ({
  type: ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_REQUEST,
});

export const uploadWarehouseInspectionCertificateSuccess = (message) => ({
  type: ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_SUCCESS,
  payload: message,
});

export const uploadWarehouseInspectionCertificateFailure = (error) => ({
  type: ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_FAILURE,
  payload: error,
});

export const uploadWarehouseInspectionCertificateReset = () => ({
  type: ActionTypes.UPLOAD_WAREHOUSE_INSPECTION_CERTIFCATE_RESET,
  payload: 'idle',
});

export const retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdRequest = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_REQUEST,
});

export const retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_SUCCESS,
  payload: message,
});

export const retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdFailure = (error) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_FAILURE,
  payload: error,
});

export const retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdReset = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPES_AND_CAPACITIES_VIA_DELIVERY_ID_RESET,
  payload: 'idle',
});

export const retrieveValidaSuperAndSubOffersBidsRequest = () => ({
  type: ActionTypes.RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_REQUEST,
});

export const retrieveValidaSuperAndSubOffersBidsSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_SUCCESS,
  payload: message,
});

export const retrieveValidaSuperAndSubOffersBidsFailure = (error) => ({
  type: ActionTypes.RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_FAILURE,
  payload: error,
});

export const retrieveValidaSuperAndSubOffersBidsReset = () => ({
  type: ActionTypes.RETRIEVE_VALID_SUPER_AND_SUB_OFFERS_BIDS_RESET,
  payload: 'idle',
});

export const retrieveBrokerSuperBidsByBrokerIdRequest = () => ({
  type: ActionTypes.RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_REQUEST,
});

export const retrieveBrokerSuperBidsByBrokerIdSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_SUCCESS,
  payload: message,
});

export const retrieveBrokerSuperBidsByBrokerIdFailure = (error) => ({
  type: ActionTypes.RETRIEVE_BROKER_SUPER_BIDS_BY_BROKER_ID_FAILURE,
  payload: error,
});

export const listAllProducersWithEntitiesRequest = () => ({
  type: ActionTypes.LIST_ALL_PRODUCERS_WITH_ENTITIES_REQUEST,
});

export const listAllProducersWithEntitiesSuccess = (message) => ({
  type: ActionTypes.LIST_ALL_PRODUCERS_WITH_ENTITIES_SUCCESS,
  payload: message,
});

export const listAllProducersWithEntitiesFailure = (error) => ({
  type: ActionTypes.LIST_ALL_PRODUCERS_WITH_ENTITIES_FAILURE,
  payload: error,
});

export const listAllProducersWithEntitiesReset = () => ({
  type: ActionTypes.LIST_ALL_PRODUCERS_WITH_ENTITIES_RESET,
  payload: 'idle',
});

export const retrieveEntityProfileInfoRequest = () => ({
  type: ActionTypes.RETRIEVE_ENTITY_PROFILE_INFO_REQUEST,
});

export const retrieveEntityProfileInfoSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_ENTITY_PROFILE_INFO_SUCCESS,
  payload: message,
});

export const retrieveEntityProfileInfoFailure = (error) => ({
  type: ActionTypes.RETRIEVE_ENTITY_PROFILE_INFO_FAILURE,
  payload: error,
});

export const retrieveEntityProfileInfoReset = () => ({
  type: ActionTypes.RETRIEVE_ENTITY_PROFILE_INFO_RESET,
  payload: 'idle',
});

export const retrieveEntityUserProfileRequest = () => ({
  type: ActionTypes.RETRIEVE_ENTITY_USER_PROFILE_REQUEST,
});

export const retrieveEntityUserProfileSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_ENTITY_USER_PROFILE_SUCCESS,
  payload: message,
});

export const retrieveEntityUserProfileFailure = (error) => ({
  type: ActionTypes.RETRIEVE_ENTITY_USER_PROFILE_FAILURE,
  payload: error,
});

export const retrieveEntityUserProfileReset = () => ({
  type: ActionTypes.RETRIEVE_ENTITY_USER_PROFILE_RESET,
  payload: 'idle',
});

export const amendProfileRoleRequest = () => ({
  type: ActionTypes.AMEND_PROFILE_ROLE_REQUEST,
});

export const amendProfileRoleSuccess = (message) => ({
  type: ActionTypes.AMEND_PROFILE_ROLE_SUCCESS,
  payload: message,
});

export const amendProfileRoleFailure = (error) => ({
  type: ActionTypes.AMEND_PROFILE_ROLE_FAILURE,
  payload: error,
});

export const amendProfileRoleReset = () => ({
  type: ActionTypes.AMEND_PROFILE_ROLE_RESET,
  payload: 'idle',
});

export const retrieveSpecificWarehouseStorageTypeRequest = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_REQUEST,
});

export const retrieveSpecificWarehouseStorageTypeSuccess = (message) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_SUCCESS,
  payload: message,
});

export const retrieveSpecificWarehouseStorageTypeFailure = (error) => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_FAILURE,
  payload: error,
});

export const retrieveSpecificWarehouseStorageTypeReset = () => ({
  type: ActionTypes.RETRIEVE_SPECIFIC_WAREHOUSE_STORAGE_TYPE_RESET,
  payload: 'idle',
});

export const processBulkDeliveryRequest = () => ({
  type: ActionTypes.PROCESS_BULK_DELIVERY_REQUEST,
});

export const processBulkDeliverySuccess = (message) => ({
  type: ActionTypes.PROCESS_BULK_DELIVERY_SUCCESS,
  payload: message,
});

export const processBulkDeliveryFailure = (error) => ({
  type: ActionTypes.PROCESS_BULK_DELIVERY_FAILURE,
  payload: error,
});

export const processBulkDeliveryReset = () => ({
  type: ActionTypes.PROCESS_BULK_DELIVERY_RESET,
  payload: 'idle',
});

export const login = (credentials) => async (dispatch) => {
  dispatch(loginRequest());
  try
  {
    const response = await apiService.post('/accounts/sign_in', credentials);
    const { token } = response.data;

    saveAuthData(token, new Date().toISOString());

    dispatch(loginSuccess(response.data));
    return response.data;
  } catch (error)
  {
    const errorMessage = error.response?.data?.error || error.message;
    dispatch(loginFailure(errorMessage));
    throw error;
  }
};

export const register = (accountData) => async (dispatch) => {
  dispatch(registerRequest());
  try
  {
    const response = await apiService.post('/accounts/create', {
      account: accountData,
    });
    const { token } = response.data;

    saveAuthData(token, new Date().toISOString());

    dispatch(registerSuccess(response.data));
    return response.data;
  } catch (error)
  {
    const errorMessage = error.response?.data?.error || error.message;
    dispatch(registerFailure(errorMessage));
    throw error;
  }
};

export const getCurrentAccount = () => async (dispatch) => {
  try
  {
    dispatch(getCurrentAccountRequest());

    const token = localStorage.getItem('authToken');
    apiService.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    return apiService.get('/accounts/current').then((response) => {
      const responseData = JSON.stringify(response.data);

      localStorage.setItem('account', responseData);

      dispatch(getCurrentAccountSuccess(response.data));
      return response.data;
    });
  } catch (error)
  {
    dispatch(getCurrentAccountFailure(error.message));
    throw error;
  }
};

export const verifyRegistration = (accountId, otp) => async (dispatch) => {
  dispatch(verifyRegistrationRequest());

  // Fetch token from local storage
  const token = localStorage.getItem('authToken');

  return apiService
    .post(
      '/accounts/verify_registration',
      {
        account_id: accountId,
        provided_otp: otp,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      dispatch(verifyRegistrationSuccess(response.data.message));
      return response.data;
    })
    .catch((error) => {
      dispatch(verifyRegistrationFailure(error.message));
      throw error;
    });
};

export const resendOtp = (accountId) => async (dispatch) => {
  dispatch(resendOtpRequest());
  try
  {
    const response = await apiService.post('/accounts/resend_otp', {
      account_id: accountId,
    });

    dispatch(resendOtpSuccess(response.data.message));
    return response.data;
  } catch (error)
  {
    dispatch(resendOtpFailure(error.message));
    throw error;
  }
};

export const createProfile =
  (entityParams, profileParams) => async (dispatch) => {
    dispatch(createProfileRequest());

    const account = JSON.parse(localStorage.getItem('account'));

    try
    {
      const response = await apiService.post('/profiles/create', {
        account_id: account.id,
        entity: entityParams,
        profile: profileParams,
      });

      dispatch(createProfileSuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(createProfileFailure(error.message));
      throw error;
    }
  };

export const updateEntity = (entityData) => async (dispatch) => {
  dispatch(updateEntityRequest());

  try
  {
    const accountData = JSON.parse(localStorage.getItem('account'));
    const accountId = accountData.id;
    const profile = accountData.profiles.find(
      (profile) => profile.account_id === accountId
    );

    const response = await apiService.post('/entities/update', {
      id: profile.entity_id,
      profile_id: profile.id,
      account_id: accountId,
      entity: entityData,
    });

    dispatch(updateEntitySuccess(response.data.entity));
    return response.data.entity;
  } catch (error)
  {
    dispatch(updateEntityFailure(error.message));
    throw error;
  }
};

export const uploadRegistrationDocument = (registrationDocument) => {
  return async (dispatch) => {
    const accountData = JSON.parse(localStorage.getItem('account'));
    const accountId = accountData.id;
    const profile = accountData.profiles.find(
      (profile) => profile.account_id === accountId
    );

    const entityId = profile.entity_id;
    const profileId = profile.id;

    try
    {
      // Generate a unique file name using timestamp and a random number
      const fileExtension = registrationDocument.name.split('.').pop(); // Extract file extension
      const uniqueFileName = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.${fileExtension}`;

      const formData = new FormData();
      formData.append('id', entityId);
      formData.append('account_id', accountId);
      formData.append('profile_id', profileId);
      formData.append(
        'registration_document',
        registrationDocument,
        uniqueFileName
      );

      const response = await apiService.post(
        'entities/upload/registration_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200)
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const uploadMemberRegistrationDocument = (entityId, selectedFile) => {
  return async (dispatch) => {
    try
    {
      const fileExtension = selectedFile.name.split('.').pop();
      const uniqueFileName = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.${fileExtension}`;

      const formData = new FormData();
      formData.append('id', entityId);
      formData.append('registration_document', selectedFile, uniqueFileName);

      const response = await apiService.post(
        'entities/members/upload/registration_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200)
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const uploadTaxRegistrationDocument = (registrationDocument) => {
  return async (dispatch) => {
    const accountData = JSON.parse(localStorage.getItem('account'));
    const accountId = accountData.id;
    const profile = accountData.profiles.find(
      (profile) => profile.account_id === accountId
    );

    const entityId = profile.entity_id;
    const profileId = profile.id;

    try
    {
      // Generate a unique file name using timestamp and a random number
      const fileExtension = registrationDocument.name.split('.').pop(); // Extract file extension
      const uniqueFileName = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.${fileExtension}`;

      const formData = new FormData();
      formData.append('id', entityId);
      formData.append('account_id', accountId);
      formData.append('profile_id', profileId);
      formData.append(
        'registration_document',
        registrationDocument,
        uniqueFileName
      );

      const response = await apiService.post(
        'entities/upload/tax_registration_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200)
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const uploadMemberTaxRegistrationDocument = (entityId, selectedFile) => {
  return async (dispatch) => {
    try
    {
      const fileExtension = selectedFile.name.split('.').pop();
      const uniqueFileName = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.${fileExtension}`;

      const formData = new FormData();
      formData.append('id', entityId);
      formData.append('registration_document', selectedFile, uniqueFileName);

      const response = await apiService.post(
        'entities/members/upload/tax_registration_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200)
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const uploadProfileDocument = (registrationDocument) => {
  return async (dispatch) => {
    const accountData = JSON.parse(localStorage.getItem('account'));
    const accountId = accountData.id;
    const profile = accountData.profiles.find(
      (profile) => profile.account_id === accountId
    );

    const entityId = profile.entity_id;
    const profileId = profile.id;

    try
    {
      // Generate a unique file name using timestamp and a random number
      const fileExtension = registrationDocument.name.split('.').pop(); // Extract file extension
      const uniqueFileName = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.${fileExtension}`;

      const formData = new FormData();
      formData.append('id', entityId);
      formData.append('account_id', accountId);
      formData.append('profile_id', profileId);
      formData.append(
        'registration_document',
        registrationDocument,
        uniqueFileName
      );

      const response = await apiService.post(
        'entities/upload/profile_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200)
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const uploadMemberProfileDocument = (entityId, selectedFile) => {
  return async (dispatch) => {
    try
    {
      const fileExtension = selectedFile.name.split('.').pop();
      const uniqueFileName = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.${fileExtension}`;

      const formData = new FormData();
      formData.append('id', entityId);
      formData.append('registration_document', selectedFile, uniqueFileName);

      const response = await apiService.post(
        'entities/members/upload/profile_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200)
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const uploadBoardResolution = (registrationDocument) => {
  return async (dispatch) => {
    const accountData = JSON.parse(localStorage.getItem('account'));
    const accountId = accountData.id;
    const profile = accountData.profiles.find(
      (profile) => profile.account_id === accountId
    );

    const entityId = profile.entity_id;
    const profileId = profile.id;

    try
    {
      // Generate a unique file name using timestamp and a random number
      const fileExtension = registrationDocument.name.split('.').pop(); // Extract file extension
      const uniqueFileName = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.${fileExtension}`;

      const formData = new FormData();
      formData.append('id', entityId);
      formData.append('account_id', accountId);
      formData.append('profile_id', profileId);
      formData.append(
        'registration_document',
        registrationDocument,
        uniqueFileName
      );

      const response = await apiService.post(
        'entities/upload/board_resolution_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200)
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const uploadMemberBoardResolution = (entityId, selectedFile) => {
  return async (dispatch) => {
    try
    {
      const fileExtension = selectedFile.name.split('.').pop();
      const uniqueFileName = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.${fileExtension}`;

      const formData = new FormData();
      formData.append('id', entityId);
      formData.append('registration_document', selectedFile, uniqueFileName);

      const response = await apiService.post(
        'entities/members/upload/board_resolution_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200)
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const uploadMemberPacraPrintoutDocument = (entityId, selectedFile) => {
  return async (dispatch) => {
    try
    {
      const fileExtension = selectedFile.name.split('.').pop();
      const uniqueFileName = `${Date.now()}_${Math.random()
        .toString(36)
        .substring(7)}.${fileExtension}`;

      const formData = new FormData();
      formData.append('id', entityId);
      formData.append('registration_document', selectedFile, uniqueFileName);

      const response = await apiService.post(
        'entities/members/upload/pacra_printout_image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200)
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_REGISTRATION_DOCUMENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const addEntityDirector = (directorData) => {
  return async (dispatch) => {
    if (
      !directorData.id_front_image_url ||
      !directorData.id_back_image_url ||
      !directorData.profile_image_url
    )
    {
      dispatch({
        type: ActionTypes.UPLOAD_DIRECTOR_IMAGES_FAILURE,
        payload: {
          error: new Error('All image URLs are required'),
        },
      });
      return;
    }

    const accountData = JSON.parse(localStorage.getItem('account'));
    const accountId = accountData.id;
    const profile = accountData.profiles.find(
      (profile) => profile.account_id === accountId
    );

    const entityId = profile.entity_id;

    try
    {
      const formData = new FormData();
      formData.append('entity_id', entityId);
      formData.append('profile_id', profile.id);
      formData.append('account_id', accountId);
      formData.append('first_name', directorData.first_name);
      formData.append('last_name', directorData.last_name);
      formData.append('identification', directorData.identification);
      formData.append('nationality', directorData.nationality);
      formData.append('history', directorData.history);
      formData.append('type', directorData.type);
      formData.append('id_front_image', directorData.id_front_image_url);
      formData.append('id_back_image', directorData.id_back_image_url);
      formData.append('profile_image', directorData.profile_image_url);

      const response = await apiService.post(
        'entities/directors/add_director',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200 || response.status === 201)
      {
        dispatch({
          type: ActionTypes.UPLOAD_DIRECTOR_IMAGES_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_DIRECTOR_IMAGES_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_DIRECTOR_IMAGES_FAILURE,
        payload: error,
      });
    }
  };
};

export const addEntityDirectorAndExecutive = (directorData) => {
  return async (dispatch) => {
    if (
      !directorData.id_front_image_url ||
      !directorData.id_back_image_url ||
      !directorData.profile_image_url
    )
    {
      dispatch({
        type: ActionTypes.UPLOAD_DIRECTOR_IMAGES_FAILURE,
        payload: {
          error: new Error('All image URLs are required'),
        },
      });
      return;
    }

    try
    {
      const formData = new FormData();
      formData.append('entity_id', directorData.entity_id);
      formData.append('profile_id', directorData.profile_id);
      formData.append('account_id', directorData.account_id);
      formData.append('first_name', directorData.first_name);
      formData.append('last_name', directorData.last_name);
      formData.append('identification', directorData.identification);
      formData.append('nationality', directorData.nationality);
      formData.append('history', directorData.history);
      formData.append('type', directorData.type);
      formData.append('id_front_image', directorData.id_front_image_url);
      formData.append('id_back_image', directorData.id_back_image_url);
      formData.append('profile_image', directorData.profile_image_url);

      const response = await apiService.post(
        'entities/directors/add_director',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200 || response.status === 201)
      {
        dispatch({
          type: ActionTypes.UPLOAD_DIRECTOR_IMAGES_SUCCESS,
          payload: response.data,
        });
      } else
      {
        dispatch({
          type: ActionTypes.UPLOAD_DIRECTOR_IMAGES_FAILURE,
          payload: {
            error: new Error(response.statusText),
          },
        });
      }
    } catch (error)
    {
      dispatch({
        type: ActionTypes.UPLOAD_DIRECTOR_IMAGES_FAILURE,
        payload: error,
      });
    }
  };
};

export const getDirectorList = () => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_DIRECTORS_REQUEST });

    // Fetch directors data
    const accountData = JSON.parse(localStorage.getItem('account'));
    const accountId = accountData.id;
    const profile = accountData.profiles.find(
      (profile) => profile.account_id === accountId
    );
    const entityId = profile.entity_id;

    const response = await apiService.get(`entities/${entityId}/directors`);
    const directors = response.data.data;

    dispatch({ type: ActionTypes.GET_DIRECTORS_SUCCESS, payload: directors });

    return directors;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_DIRECTORS_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const addBankAccount = (bankAccountData) => async (dispatch) => {
  dispatch(addBankAccountRequest());
  try
  {
    const response = await apiService.post('/entities/bank/add_account', {
      bank_account: bankAccountData,
    });
    dispatch(addBankAccountSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(addBankAccountFailure(error.message));
    throw error;
  }
};

export const submitRegistration = (accountData) => async (dispatch) => {
  dispatch(registrationCompleteRequest());

  try
  {
    const response = await apiService.post(
      '/entities/registration/complete',
      accountData
    );
    dispatch(registrationCompleteSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(registrationCompleteFailure(error.message));
    throw error;
  }
};

export const getEntities = (page, pageSize) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_ENTITIES_REQUEST });

    const response = await apiService.get('/entities', {
      params: {
        page: page,
        page_size: pageSize,
      },
    });
    const entities = response.data;

    dispatch({ type: ActionTypes.GET_ENTITIES_SUCCESS, payload: entities });

    return entities;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_ENTITIES_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const getOfferList = (page, pageSize) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_OFFER_LIST_REQUEST });

    const response = await apiService.get('/broker/list_all_offers_for_management', {
      // params: {
      //     page: page,
      //     page_size: pageSize
      // }
    });
    const offerList = response.data.data;

    dispatch({ type: ActionTypes.GET_OFFER_LIST_SUCCESS, payload: offerList });

    return offerList;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_OFFER_LIST_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const getBidList = (page, pageSize) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_OFFER_LIST_REQUEST });

    const response = await apiService.get('/broker/list_all_bids', {
      // params: {
      //     page: page,
      //     page_size: pageSize
      // }
    });
    const offerList = response.data.data;

    dispatch({ type: ActionTypes.GET_OFFER_LIST_SUCCESS, payload: offerList });

    return offerList;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_OFFER_LIST_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const getOfferListFilterStatus =
  (page, pageSize, AtsManagement) => async (dispatch) => {
    try
    {
      dispatch({ type: ActionTypes.GET_OFFER_LIST_FILTER_STATUS_REQUEST });

      const response = await apiService.get(
        AtsManagement ? '/broker/list_all_offers' : '/broker/list_all_offers_filter_status',
        {
          // params: {
          //     page: page,
          //     page_size: pageSize
          // }
        }
      );
      const offerListStatusFiltered = response.data.data;


      dispatch({
        type: ActionTypes.GET_OFFER_LIST_FILTER_STATUS_SUCCESS,
        payload: offerListStatusFiltered,
      });

      return offerListStatusFiltered;
    } catch (error)
    {
      dispatch({
        type: ActionTypes.GET_OFFER_LIST_FILTER_STATUS_FAILURE,
        payload: error.message,
      });
      throw error;
    }
  };

export const getOfferDetails = (offerId) => async (dispatch) => {
  try
  {
    const response = await apiService.get(
      `/broker/broker_offers/get_individual_broker_offer/${offerId}`
    );
    const offerDetails = response.data.data;

    dispatch({
      type: ActionTypes.GET_OFFER_DETAILS_SUCCESS,
      payload: offerDetails,
    });

    return offerDetails;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_OFFER_DETAILS_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const getVirtualReceiptDetails = (virtualReceiptId) => async (dispatch) => {
  try
  {
    const response = await apiService.get(
      `/broker/virtual_receipt/${virtualReceiptId}`
    );
    const offerDetails = response.data.data;

    dispatch({
      type: ActionTypes.GET_OFFER_DETAILS_SUCCESS,
      payload: offerDetails,
    });

    return offerDetails;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_OFFER_DETAILS_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const getBidDetails = (bidId) => async (dispatch) => {
  try
  {
    const response = await apiService.get(
      `/broker/broker_bids/get_individual_broker_bid/${bidId}`
    );
    const offerDetails = response.data.data;

    dispatch({
      type: ActionTypes.GET_OFFER_DETAILS_SUCCESS,
      payload: offerDetails,
    });

    return offerDetails;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_OFFER_DETAILS_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const updateOfferStatus = (offerStatusData) => async (dispatch) => {
  try
  {
    const response = await apiService.post(
      offerStatusData.MarketBidDetails ? '/broker/broker_offers/update_commodity_order_status_bid_management' : '/broker/broker_offers/update_commodity_order_status',
      {
        id: offerStatusData.commodity_order_id,
        status: offerStatusData.commodity_order_status,
      }
    );
    const offerStatusMessage = response.data.data;
    dispatch({
      type: ActionTypes.UPDATE_OFFER_STATUS_SUCCESS,
      payload: offerStatusMessage,
    });
    return response.data;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.UPDATE_OFFER_STATUS_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const getEntity = (entityId) => async (dispatch) => {
  dispatch(getEntityRequest());
  try
  {
    const response = await apiService.get(`/entities/${entityId}`);
    const entity = response.data.data;

    dispatch(getEntitySuccess(entity));
    return entity;
  } catch (error)
  {
    const errorMessage = error.response?.data?.error || error.message;
    dispatch(getEntityFailure(errorMessage));
    throw error;
  }
};

export const getEntityProfile = (entityId) => async (dispatch) => {
  dispatch(getEntityProfileRequest());
  try
  {
    const response = await apiService.get(`/entities/profile/${entityId}`);
    const entityprofile = response.data;

    dispatch(getEntityProfileSuccess(entityprofile));
    return entityprofile;
  } catch (error)
  {
    const errorMessage = error.response?.data?.error || error.message;
    dispatch(getEntityProfileFailure(errorMessage));
    throw error;
  }
};

export const getEntityProfileList = (entityId) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_ENTITIES_LIST_REQUEST });

    const response = await apiService.get(`profiles/entity/${entityId}/list`);

    const profileList = response.data.data;

    dispatch({
      type: ActionTypes.GET_ENTITIES_LIST_SUCCESS,
      payload: profileList,
    });

    return profileList;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_ENTITIES_LIST_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const getDirectorsList = (entityId) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_DIRECTORS_REQUEST });

    const response = await apiService.get(`entities/${entityId}/directors`);
    const directors = response.data.data;

    dispatch({ type: ActionTypes.GET_DIRECTORS_SUCCESS, payload: directors });

    return directors;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_DIRECTORS_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const checkRegistration = (entityParams) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.CHECK_REGISTRATION_REQUEST });

    const response = await apiService.post('entities/check/registration', {
      entity: entityParams,
    });
    const data = response.data;

    if (response.status === 404)
    {
      dispatch({
        type: ActionTypes.CHECK_REGISTRATION_FAILURE,
        payload: data.message,
      });
      throw new Error(data.message);
    } else if (response.status === 200)
    {
      dispatch({
        type: ActionTypes.CHECK_REGISTRATION_SUCCESS,
        payload: data.message,
      });
      return data.message;
    } else
    {
      dispatch({
        type: ActionTypes.CHECK_REGISTRATION_FAILURE,
        payload: 'Unexpected response from the server',
      });
      throw new Error('Unexpected response from the server');
    }
  } catch (error)
  {
    dispatch({
      type: ActionTypes.CHECK_REGISTRATION_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const registerNewMember = (memberData) => async (dispatch) => {
  dispatch(registerNewMemberRequest());
  try
  {
    const response = await apiService.post('/entities/member/new', {
      member_data: memberData,
    });
    dispatch(registerNewMemberSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(registerNewMemberFailure(error.message));
    throw error;
  }
};

export const createPendingEntityRequest = () => ({
  type: ActionTypes.CREATE_PENDING_ENTITY_REQUEST,
});

export const createPendingEntitySuccess = (message) => ({
  type: ActionTypes.CREATE_PENDING_ENTITY_SUCCESS,
  payload: message,
});

export const createPendingEntityFailure = (error) => ({
  type: ActionTypes.CREATE_PENDING_ENTITY_FAILURE,
  payload: error,
});

export const createPendingEntityReset = () => ({
  type: ActionTypes.CREATE_PENDING_ENTITY_RESET,
  payload: 'idle',
});

// Get Warehouse
export const getWarehouseList = (entityId) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_WAREHOUSE_LIST_REQUEST });

    const response = await apiService.get(`warehouses/${entityId}`);
    const warehouses = response.data.data;

    dispatch({
      type: ActionTypes.GET_WAREHOUSE_LIST_SUCCESS,
      payload: warehouses,
    });

    return warehouses;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_WAREHOUSE_LIST_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

// add Warehouse
export const addWarehouse = (warehouseData) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.ADD_WAREHOUSE_REQUEST });

    const response = await apiService.post(`warehouses/create`, {
      warehouse: warehouseData,
    });
    const warehouse = response.data.data;

    dispatch({ type: ActionTypes.ADD_WAREHOUSE_SUCCESS, payload: warehouse });
    return warehouse;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.ADD_WAREHOUSE_FAILURE,
      payload: error.message,
    });
    // throw error;
  }
};

// Create Entity Profile
export const addEntityProfile = (entity_profile) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.CREATE_ENTITY_PROFILE_REQUEST });

    const response = await apiService.post(`profiles/entity/create`, {
      entity_profile: entity_profile,
    });
    const warehouse = response.data.data;

    dispatch({
      type: ActionTypes.CREATE_ENTITY_PROFILE_SUCCESS,
      payload: warehouse,
    });

    return warehouse;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.CREATE_ENTITY_PROFILE_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

// Get Specific Warehouse
export const getSpecificWarehouse = (warehouse_id) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_SPECIFIC_WAREHOUSE_REQUEST });

    const response = await apiService.get(
      `warehouse/warehouse_id/${warehouse_id}`
    );
    const warehouse = response.data.data;

    dispatch({
      type: ActionTypes.GET_SPECIFIC_WAREHOUSE_SUCCESS,
      payload: warehouse,
    });

    return warehouse;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_SPECIFIC_WAREHOUSE_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

// Send OTP
export const sendResetPasswordOTP = (email) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.SEND_RESET_PASSWORD_OTP_REQUEST });

    const { data } = await apiService.post(`/accounts/reset/send_otp`, {
      email: email,
    });

    dispatch(sendresetpasswordOtpSuccess(data.message));
  } catch (error)
  {
    dispatch({
      type: ActionTypes.SEND_RESET_PASSWORD_OTP_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

// Verify OTP
export const verifyResetPasswordOTP = (verify_details) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.VERIFY_RESET_PASSWORD_OTP_REQUEST });

    const { data } = await apiService.post(
      `/accounts/verify_password_reset_otp`,
      verify_details
    );

    dispatch(verifyResetPasswordOtpSuccess(data.message));
  } catch (error)
  {
    dispatch({
      type: ActionTypes.VERIFY_RESET_PASSWORD_OTP_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

// Reset Password
export const resetPassword = (passwordDetails) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.RESET_PASSWORD_REQUEST });

    const { data } = await apiService.post(
      `/accounts/reset_password`,
      passwordDetails
    );

    dispatch(resetPasswordSuccess(data.message));
  } catch (error)
  {
    dispatch({
      type: ActionTypes.RESET_PASSWORD_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

// Create Entity Existing Account
export const createEntityExistingAccount =
  (entityDetails) => async (dispatch) => {
    try
    {
      dispatch({ type: ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_REQUEST });

      const { data } = await apiService.post(
        `/profiles/create/existing_account`,
        entityDetails
      );

      dispatch(createEntityExistingAccountSuccess(data.message));
    } catch (error)
    {
      dispatch({
        type: ActionTypes.CREATE_ENTITY_EXISTING_ACCOUNT_FAILURE,
        payload: error.message,
      });
      throw error;
    }
  };

// Get Producers
export const getProducers = (brokerId, page, pageSize) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_PRODUCERS_REQUEST });

    const response = await apiService.get('broker/producers', {
      params: {
        broker_id: brokerId,
        page: page,
        page_size: pageSize,
      },
    });
    const producers = response.data.data;

    dispatch({ type: ActionTypes.GET_PRODUCERS_SUCCESS, payload: producers });

    return producers;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_PRODUCERS_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const getCooperativeMembers = (cooperativeId) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_COOPERATIVE_MEMBER_LIST_REQUEST });

    const response = await apiService.get('broker/producers', {
      params: {
        cooperative_id: cooperativeId,
      },
    });
    const cooperative_list = response.data;

    dispatch({
      type: ActionTypes.GET_COOPERATIVE_MEMBER_LIST_SUCCESS,
      payload: cooperative_list,
    });

    return cooperative_list;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_COOPERATIVE_MEMBER_LIST_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

// Get Producer
export const getProducer = (producerId) => async (dispatch) => {
  dispatch(getProducerRequest());
  try
  {
    const response = await apiService.get(
      `/broker/producers/get_individual_producer/${producerId}`
    );
    const producer = response.data.data;

    dispatch(getProducerSuccess(producer));
    return producer;
  } catch (error)
  {
    const errorMessage = error.response?.data?.error || error.message;
    dispatch(getProducerFailure(errorMessage));
    throw error;
  }
};

// Register New Producer
export const registerNewProducer = (producerData) => async (dispatch) => {
  dispatch(registerNewProducerRequest());
  try
  {
    const response = await apiService.post(
      'broker/producers/register',
      producerData
    );
    dispatch(registerNewProducerSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(registerNewProducerFailure(error.message));
    throw error;
  }
};

// Activate Membership Type
export const activateMembershipType =
  (membershipTypeData) => async (dispatch) => {

    console.log(membershipTypeData);
    dispatch(activateMembershipTypeRequest());
    try
    {
      const response = await apiService.post(
        '/entities/registration/activate',
        membershipTypeData
      );
      dispatch(activateMembershipTypeSuccess(response.data.message));
      return response.data;
    } catch (error)
    {
      dispatch(activateMembershipTypeFailure(error.message));
      throw error;
    }
  };

// Verify Membership
export const verifyMembership = (verifyMembershipData) => async (dispatch) => {
  dispatch(verifyMembershipRequest());
  try
  {
    const response = await apiService.post(
      '/entities/registration/verify',
      verifyMembershipData
    );
    dispatch(verifyMembershipSuccess(response.data.message));
    // console.log("RESP: ", response);
    return response.data;
  } catch (error)
  {
    dispatch(verifyMembershipFailure(error.message));
    throw error;
  }
};

// update Membership type
export const updateMembership = (MembershipData) => async (dispatch) => {
  dispatch(verifyMembershipRequest());
  try
  {
    const response = await apiService.post(
      '/profiles/update_membership_type',
      MembershipData
    );
    dispatch(verifyMembershipSuccess(response.data.message));
    return response.data;
  } catch (error)
  {
    dispatch(verifyMembershipFailure(error.message));
    throw error;
  }
};

// get all receipt numbers for a specific broker
export const getReceiptNumbers = () => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_RECEIPT_NUMBERS_REQUEST });

    const response = await apiService.get(
      'broker/broker_offers/get_receipt_numbers'
    );
    const receiptNumbers = response.data;

    dispatch({
      type: ActionTypes.GET_RECEIPT_NUMBERS_SUCCESS,
      payload: receiptNumbers,
    });

    return receiptNumbers;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_RECEIPT_NUMBERS_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

// get all broker offers for a specific broker
export const getBrokerOffers =
  (brokerId, page, pageSize) => async (dispatch) => {
    try
    {
      dispatch({ type: ActionTypes.GET_BROKER_OFFERS_REQUEST });

      const response = await apiService.get(
        'broker/broker_offers/get_broker_offers',
        {
          params: {
            broker_id: brokerId,
            page: page,
            page_size: pageSize,
          },
        }
      );
      const brokerOffers = response.data.data;

      dispatch({
        type: ActionTypes.GET_BROKER_OFFERS_SUCCESS,
        payload: brokerOffers,
      });

      return brokerOffers;
    } catch (error)
    {
      dispatch({
        type: ActionTypes.GET_BROKER_OFFERS_FAILURE,
        payload: error.message,
      });
      throw error;
    }
  };

// get all broker bids for a specific broker
export const getBrokerBidsByBrokerId =
  (brokerId, page, pageSize) => async (dispatch) => {
    try
    {
      dispatch({ type: ActionTypes.GET_BROKER_BIDS_BY_BROKER_ID_REQUEST });

      const response = await apiService.get(
        'broker/broker_bids/get_broker_bids_by_broker_id',
        {
          params: {
            broker_id: brokerId,
            page: page,
            page_size: pageSize,
          },
        }
      );
      const brokerBids = response.data.data;

      console.log("BB: ", response);

      dispatch({
        type: ActionTypes.GET_BROKER_BIDS_BY_BROKER_ID_SUCCESS,
        payload: brokerBids,
      });

      return brokerBids;
    } catch (error)
    {
      dispatch({
        type: ActionTypes.GET_BROKER_BIDS_BY_BROKER_ID_FAILURE,
        payload: error.message,
      });
      throw error;
    }
  };

// get all broker bids for a specific commodity order
export const getBrokerBidsByCommodityOrderId =
  (commodityOrderId, page, pageSize) => async (dispatch) => {
    try
    {
      dispatch({
        type: ActionTypes.GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_REQUEST,
      });

      const response = await apiService.get(
        'broker/broker_bids/get_broker_bids_by_commodity_order_id',
        {
          params: {
            commodity_order_id: commodityOrderId,
            page: page,
            page_size: pageSize,
          },
        }
      );
      const brokerBids = response.data.data;

      dispatch({
        type: ActionTypes.GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_SUCCESS,
        payload: brokerBids,
      });

      return brokerBids;
    } catch (error)
    {
      dispatch({
        type: ActionTypes.GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_FAILURE,
        payload: error.message,
      });
      throw error;
    }
  };

// get all broker bids for a specific commodity order
export const getBrokerOfferByCommodityOrderId =
  (commodityOrderId, page, pageSize) => async (dispatch) => {
    try
    {
      dispatch({
        type: ActionTypes.GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_REQUEST,
      });

      const response = await apiService.get(
        'broker/broker_offers/get_broker_offers_by_commodity_order_id',
        {
          params: {
            commodity_order_id: commodityOrderId,
            page: page,
            page_size: pageSize,
          },
        }
      );
      const brokerBids = response.data.data;

      dispatch({
        type: ActionTypes.GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_SUCCESS,
        payload: brokerBids,
      });

      return brokerBids;
    } catch (error)
    {
      dispatch({
        type: ActionTypes.GET_BROKER_BIDS_BY_COMMODITY_ORDER_ID_FAILURE,
        payload: error.message,
      });
      throw error;
    }
  };

// get specific broker offer
export const getBrokerOffer = (brokerOfferId) => async (dispatch) => {
  dispatch(getBrokerOfferRequest());
  try
  {
    const response = await apiService.get(
      `broker/broker_offers/get_individual_broker_offer/${brokerOfferId}`
    );
    const brokerOffer = response.data.data;

    dispatch(getBrokerOfferSuccess(brokerOffer));
    return brokerOffer;
  } catch (error)
  {
    const errorMessage = error.response?.data?.error || error.message;
    dispatch(getBrokerOfferFailure(errorMessage));
    throw error;
  }
};

// create new broker offer
export const addNewBrokerOffer = (brokerOfferData) => async (dispatch) => {
  dispatch(addNewBrokerOfferRequest());
  try
  {
    const response = await apiService.post(
      'broker/broker_offers/create_offer',
      brokerOfferData
    );
    dispatch(addNewBrokerOfferSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(addNewBrokerOfferFailure(error.message));
    throw error;
  }
};

// create new broker offer
export const addNewBrokerSuperBid = (brokerOfferData) => async (dispatch) => {
  dispatch(addNewBrokerOfferRequest());
  try
  {
    const response = await apiService.post(
      brokerOfferData.BrokerOfferVirtualReceiptModal ? 'broker/broker_offers/create_virtual_rceipt_offer' : 'broker/broker_offers/create_offer',
      brokerOfferData
    );
    // console.log("DATA: ", response.data);
    dispatch(addNewBrokerOfferSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(addNewBrokerOfferFailure(error.message));
    throw error;
  }
};

// update broker trade authority
export const updateBrokerTradingAuthority =
  (brokerData) => async (dispatch) => {
    dispatch(updateBrokerTradingAuthorityRequest());
    try
    {
      const response = await apiService.post(
        `/broker/update_or_register_broker_as_producer`,
        brokerData
      );
      dispatch(updateBrokerTradingAuthoritySuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(updateBrokerTradingAuthorityFailure(error.message));
      throw error;
    }
  };

// create new broker bid
export const addNewBrokerBid = (brokerBidData) => async (dispatch) => {
  dispatch(addNewBrokerBidRequest());
  try
  {
    const response = await apiService.post(
      brokerBidData.broker_bid.virtual_receipt_id ? 'broker/broker_bids/bid_on_offer_with_virtual_receipt' : 'broker/broker_bids/create_bid',
      brokerBidData
    );
    dispatch(addNewBrokerBidSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(addNewBrokerBidFailure(error.message));
    throw error;
  }
};

// create new broker bid
export const addNewBrokerSubOffer = (brokerBidData) => async (dispatch) => {

  dispatch(addNewBrokerBidRequest());
  try
  {
    const response = await apiService.post(
      '/broker/broker_offers/create_offer_on_bid',
      brokerBidData
    );
    dispatch(addNewBrokerBidSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(addNewBrokerBidFailure(error.message));
    throw error;
  }
};

// Get Entity List By Entity Type, Active, Status and Verified
export const getFilteredEntityTypeActiveStatusVerified =
  (filterParams) => async (dispatch) => {
    dispatch(getSpecificEntityProfileListRequest());
    try
    {
      const response = await apiService.post(
        '/entities/entity_profile_list/type_status_verified',
        filterParams
      );
      dispatch(getSpecificEntityProfileListSuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(getSpecificEntityProfileListFailure(error.message));
      throw error;
    }
  };

// Create Warehouse Delivery Note
export const createWarehouseDeliveryNote =
  (deliveryNoteParams) => async (dispatch) => {
    dispatch(createDeliverynoteRequest());
    try
    {
      const response = await apiService.post(
        '/warehouses/delivery/create',
        deliveryNoteParams
      );
      dispatch(createDeliverynoteSuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(createDeliverynoteFailure(error.message));
      throw error;
    }
  };

// Fetch Warehouse List By Entity ID and Status
export const getWarehouseListByEntityStatus =
  (warehouse_list_params) => async (dispatch) => {
    dispatch(getWarehouseListRequest());
    try
    {
      const response = await apiService.post(
        '/warehouse/get_warehouse_list_by_status_entity_id',
        warehouse_list_params
      );
      dispatch(getWarehouseListSuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(getWarehouseListFailure(error.message));
      throw error;
    }
  };

// Carry Out Commodity Grading
export const createCommodityGrading = (grading_params) => async (dispatch) => {
  dispatch(createCommodityGradingRequest());
  try
  {
    const response = await apiService.post(
      '/warehouses/commodity_grading/create',
      grading_params
    );
    dispatch(createCommodityGradingSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(createCommodityGradingFailure(error.message));
    throw error;
  }
};

// Create Storage Invetory
export const createStorageInventory =
  (storage_inventory_params) => async (dispatch) => {
    dispatch(createStorageInventoryRequest());
    try
    {
      const response = await apiService.post(
        '/warehouses/delivery_storage/create',
        storage_inventory_params
      );
      dispatch(createStorageInventorySuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(createStorageInventoryFailure(error.message));
      throw error;
    }
  };

// Update Warehouse Receipt Settlement
export const updateWarehouseReceiptSettlement =
  (settlementParams) => async (dispatch) => {
    dispatch(updateWarehouseReceiptSettlementRequest());
    try
    {
      const response = await apiService.post(
        '/warehouse/settlement/update_warehouse_receipt_settlement',
        settlementParams
      );
      dispatch(updateWarehouseReceiptSettlementSuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(updateWarehouseReceiptSettlementFailure(error.message));
      throw error;
    }
  };

// Generate Warehouse Receipt
export const generateWarehouseReceipt =
  (receipt_params) => async (dispatch) => {
    dispatch(generateWarehouseReceiptRequest());
    try
    {
      const response = await apiService.post(
        '/warehouse/receipt/generate_warehouse_receipt',
        receipt_params
      );
      dispatch(generateWarehouseReceiptSuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(generateWarehouseReceiptFailure(error.message));
      throw error;
    }
  };

// Retrieve Warehose Receipt List
export const retrieveEntityWarehouseReceiptList =
  (memberTypeParams) => async (dispatch) => {
    dispatch(retrieveEntityWarehouseReceiptListRequest());
    try
    {
      const response = await apiService.get(
        `/warehouse/receipt/list/${memberTypeParams}`
      );
      dispatch(retrieveEntityWarehouseReceiptListSuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(retrieveEntityWarehouseReceiptListFailure(error.message));
      throw error;
    }
  };

// Retrieve Specific Entity Profile Inbound Shipment
export const retrieve_warehouse_storage_list =
  (member_type_id) => async (dispatch) => {
    dispatch(retreiveWarehouseStorageListRequest());
    try
    {
      const response = await apiService.get(`/storage/list/${member_type_id}`);
      dispatch(retreiveWarehouseStorageListSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retreiveWarehouseStorageListFailure(
            'Failed to Retrieve Warehouse Storage List'
          )
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retreiveSpecificInboundShipmentFailure(
              'Failed to Retrieve Warehouse Storage List'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Specific Warehouse Receipt
export const retrieve_specific_warehouse_receipt =
  (warehouse_receipt_number) => async (dispatch) => {
    dispatch(retreiveSpecificWarehouseReceiptRequest());
    try
    {
      const response = await apiService.get(
        `/warehouse/receipt/${warehouse_receipt_number}`
      );
      dispatch(retreiveSpecificWarehouseReceiptSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retreiveSpecificWarehouseReceiptFailure(
            'Failed to Retrieve Warehouse Receipt'
          )
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retreiveSpecificWarehouseReceiptFailure(
              'Failed to Retrieve Warehouse Receipt'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Continue Processing Warehouse Receipt
export const continue_processing_warehouse_receipt =
  (delivery_number) => async (dispatch) => {
    dispatch(continueProcessingWarehouseReceiptRequest());
    try
    {
      const response = await apiService.get(
        `/warehouse/receipt/continue_processing/${delivery_number}`
      );
      dispatch(continueProcessingWarehouseReceiptSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          continueProcessingWarehouseReceiptFailure(
            'Failed to Process Inbound Shipment'
          )
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            continueProcessingWarehouseReceiptFailure(
              'Failed to Process Inbound Shipment'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Create Pending Entity
export const createPendingEntity =
  (pendingEntityParams) => async (dispatch) => {
    dispatch(createPendingEntityRequest());
    try
    {
      const response = await apiService.post(
        `/pending_entities/create/`,
        pendingEntityParams
      );
      dispatch(createPendingEntitySuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(createPendingEntityFailure(error.message));
      throw error;
    }
  };

// Retrieve Specific Entity Profile Inbound Shipment
export const retrieve_entity_profile_inbound_shipments =
  (member_type_id) => async (dispatch) => {
    dispatch(retreiveInboundShipmentListRequest());
    try
    {
      const response = await apiService.get(
        `/deliveries/retrieve_entity_inbound_shipments/${member_type_id}`
      );
      dispatch(retreiveInboundShipmentListSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retreiveInboundShipmentListFailure(
            'Failed to Retrieve Inbound Shipments'
          )
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retreiveInboundShipmentListFailure(
              'Failed to Retrieve Inbound Shipments'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Specific Entity Profile Inbound Shipment
export const retrieve_specific_entity_profile_inbound_shipment =
  (member_type_id, delivery_id) => async (dispatch) => {
    dispatch(retreiveSpecificInboundShipmentRequest());
    try
    {
      const response = await apiService.get(
        `/deliveries/retrieve_entity_inbound_shipments/${member_type_id}/${delivery_id}`
      );
      dispatch(retreiveSpecificInboundShipmentSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retreiveSpecificInboundShipmentFailure(
            'Failed to Retrieve Inbound Shipment'
          )
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retreiveSpecificInboundShipmentFailure(
              'Failed to Retrieve Inbound Shipment'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve All Producers List
export const getAllProducerList = () => async (dispatch) => {
  dispatch(retrieveAllProducersListRequest());
  try
  {
    const response = await apiService.get(`/broker/list_all_producers`);
    dispatch(retrieveAllProducersListSuccess(response.data));
    return response.data;
  } catch (error)
  {
    dispatch(retrieveAllProducersListFailure(error.message));
    throw error;
  }
};

// Retrieve Producers and Warehouse Receipts for broker
export const getProducersAndWarehouseReceiptsForBroker =
  (entity_id) => async (dispatch) => {
    dispatch(getProducersAndWarehouseReceiptsForBrokerRequest(entity_id));
    try
    {
      const response = await apiService.get(
        `/broker/get_producers_and_warehouse_receipts/${entity_id}`
      );
      dispatch(getProducersAndWarehouseReceiptsForBrokerSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          getProducersAndWarehouseReceiptsForBrokerFailure(
            'Failed to Get Producers and their Issued Warehouse Receipts.'
          )
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            getProducersAndWarehouseReceiptsForBrokerFailure(
              error.response.data.message
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve All Broker List
export const getAllBrokerList = (page, pageSize, entity_category) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_BROKER_LIST_REQUEST });

    const response = await apiService.get(`/entities`, {
      params: {
        page: page,
        page_size: pageSize,
        entity_category: entity_category ? entity_category : 'broker',
      },
    });
    const brokerList = response.data;
    dispatch({
      type: ActionTypes.GET_BROKER_LIST_SUCCESS,
      payload: brokerList,
    });
    return brokerList;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.GET_BROKER_LIST_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

// Retrieve Pending Entity List by Type
export const retreive_pending_entity_list_by_type =
  (type) => async (dispatch) => {
    dispatch(retreivePendingEntityListRequest());
    try
    {
      const response = await apiService.get(
        `/pending_entities/pending_entity_list/${type}`
      );
      dispatch(retreivePendingEntityListSuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(retreivePendingEntityListFailure(error.message));
      throw error;
    }
  };

// Update Warehouse Status
export const updateWarehouseStatus = (updateParams) => async (dispatch) => {
  dispatch(updateWarehouseStatusRequest());
  try
  {
    const response = await apiService.post(
      `/warehouse/amend_warehuose_status`,
      updateParams
    );
    dispatch(updateWarehouseStatusSuccess(response.data));
    return response.data;
  } catch (error)
  {
    console.log(error);
    // Server or Network Error
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
    {
      dispatch(
        updateWarehouseStatusFailure('Failed to Update Warehouse Status.')
      );
    } else
    {
      // Bad Response Message
      if (error.response.status === 400 || error.response.status === 404)
      {
        dispatch(updateWarehouseStatusFailure(error.response.data.message));
      }
      //   // Unauthorized
      //   else if (error.response.status === 401)
      //   {
      //     dispatch(success_sign_out_modal());
      //   }
      //   // Access Denied
      //   else if (error.response.status === 403)
      //   {
      //     messageToast(accessDeniedMessage, app_consts.FAIL);
      //     dispatch(failState(accessDeniedMessage));
      //   }
    }
    // throw error;
  }
};

// Retrieved Issued Warehouse Receipts
export const retrieve_issued_receipts = () => async (dispatch) => {
  dispatch(retriveIssuedWarehouseReceiptsRequest());
  try
  {
    const response = await apiService.get(`/issued_warehouse_receipts/list`);
    dispatch(retriveIssuedWarehouseReceiptsSuccess(response.data));
    return response.data;
  } catch (error)
  {
    console.log(error);
    // Server or Network Error
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
    {
      dispatch(
        retriveIssuedWarehouseReceiptsFailure(
          'Failed to Retireve Issued Warehuose Receipts.'
        )
      );
    } else
    {
      // Bad Response Message
      if (error.response.status === 400 || error.response.status === 404)
      {
        dispatch(
          retriveIssuedWarehouseReceiptsFailure(error.response.data.message)
        );
      }
      //   // Unauthorized
      //   else if (error.response.status === 401)
      //   {
      //     dispatch(success_sign_out_modal());
      //   }
      //   // Access Denied
      //   else if (error.response.status === 403)
      //   {
      //     messageToast(accessDeniedMessage, app_consts.FAIL);
      //     dispatch(failState(accessDeniedMessage));
      //   }
    }
    // throw error;
  }
};

// Amend Warehouse Receipt Status
export const amend_warehouse_receipt_Status =
  (amend_params) => async (dispatch) => {
    dispatch(amendWarehouseReceiptStatusRequest());
    try
    {
      const response = await apiService.post(
        `/warehouse/amend_warehouse_receipt_status`,
        amend_params
      );
      dispatch(amendWarehouseReceiptStatusSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          amendWarehouseReceiptStatusFailure(
            'Failed to Amend Warehouse Receipt Status'
          )
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            amendWarehouseReceiptStatusFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

export const getUserAccountList = (page, pageSize) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_USER_ACCOUNT_LIST_REQUEST });

    const response = await apiService.get('/accounts/list_all_user_accounts', {
      // params: {
      //     page: page,
      //     page_size: pageSize
      // }
    });
    const userAccountList = response.data;

    dispatch({
      type: ActionTypes.GET_USER_ACCOUNT_LIST_SUCCESS,
      payload: userAccountList,
    });

    return userAccountList;
  } catch (error)
  {
    console.log(error);

    dispatch({
      type: ActionTypes.GET_USER_ACCOUNT_LIST_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};

export const getUserAccountDetails = (userId) => async (dispatch) => {
  try
  {
    dispatch({ type: ActionTypes.GET_USER_ACCOUNT_DETAILS_REQUEST });
    const response = await apiService.get(`/accounts/user/${userId}`);
    const userAccountDetails = response.data;

    dispatch({
      type: ActionTypes.GET_USER_ACCOUNT_DETAILS_SUCCESS,
      payload: userAccountDetails,
    });
    return userAccountDetails;
  } catch (error)
  {
    const errorMessage = error.response?.data?.error || error.message;
    dispatch({
      type: ActionTypes.GET_USER_ACCOUNT_DETAILS_FAILURE,
      payload: errorMessage,
    });
    throw error;
  }
};

// Retrieve Specific Broker Offer ID
export const retrieve_specific_broker_offer =
  (broker_offer_id) => async (dispatch) => {
    dispatch(retrieveSpecificBrokerOfferRequest());
    try
    {
      const response = await apiService.get(
        `/broker/broker_offers/gets_specific_broker_offer/${broker_offer_id}`
      );
      dispatch(retrieveSpecificBrokerOfferSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retrieveSpecificBrokerOfferFailure('Failed to Retrieve Broker Offer.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retrieveSpecificBrokerOfferFailure(
              'Failed to Retrieve Broker Offer.'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Accept Bid On Commodity Offer
export const accept_bid_on_commodity_order =
  (broker_bid_id) => async (dispatch) => {
    console.log(broker_bid_id);
    dispatch(acceptBidOnCommodityOrderRequest());
    try
    {
      const response = await apiService.post(
        `/broker/broker_offer/accept_bid_create_trade`,
        broker_bid_id
      );
      dispatch(acceptBidOnCommodityOrderSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(acceptBidOnCommodityOrderFailure('Failed to Accept Bid.'));
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            acceptBidOnCommodityOrderFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Trade Offer Details
export const retrieve_trade_offer_details = (trade_id) => async (dispatch) => {
  dispatch(retrieveTradeOfferDetailsRequest());
  try
  {
    const response = await apiService.get(
      `/broker/broker_offers/retrieve_trade_offer_details/${trade_id}`
    );
    dispatch(retrieveTradeOfferDetailsSuccess(response.data));
    return response.data;
  } catch (error)
  {
    console.log(error);
    // Server or Network Error
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
    {
      dispatch(
        retrieveTradeOfferDetailsFailure(
          'Failed to Retrieve Offer Trade Details.'
        )
      );
    } else
    {
      // Bad Response Message
      if (error.response.status === 400 || error.response.status === 404)
      {
        dispatch(
          retrieveTradeOfferDetailsFailure(
            'Failed to Retrieve Offer Trade Details.'
          )
        );
      }
      //   // Unauthorized
      //   else if (error.response.status === 401)
      //   {
      //     dispatch(success_sign_out_modal());
      //   }
      //   // Access Denied
      //   else if (error.response.status === 403)
      //   {
      //     messageToast(accessDeniedMessage, app_consts.FAIL);
      //     dispatch(failState(accessDeniedMessage));
      //   }
    }
    // throw error;
  }
};

// Retrieve Trade Offer Details
export const retrieve_broker_bids_by_broker_id =
  (broker_id) => async (dispatch) => {
    dispatch(retrieveBrokerBidsByBrokerIdRequest());
    try
    {
      const response = await apiService.get(
        `/broker/broker_bids/retrieve_broker_bids_by_broker_id/${broker_id}`
      );
      dispatch(retrieveBrokerBidsByBrokerIdSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retrieveBrokerBidsByBrokerIdFailure('Failed to Retrieve Broker Bids.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retrieveBrokerBidsByBrokerIdFailure(
              'Failed to Retrieve Broker Bids.'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Specific Broker Bid Details
export const retrieve_specific_broker_bid_details =
  (broker_bid_id) => async (dispatch) => {
    dispatch(retrieveSpecificBrokerBidDetailsRequest());
    try
    {
      const response = await apiService.get(
        `/broker/broker_bids/retrieve_specific_broker_bid_details/${broker_bid_id}`
      );
      dispatch(retrieveSpecificBrokerBidDetailsSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retrieveSpecificBrokerBidDetailsFailure(
            'Failed to Retrieve Broker Bid Details.'
          )
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retrieveSpecificBrokerBidDetailsFailure(
              'Failed to Retrieve Broker Bid Details.'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Upload Trade Proof of Payment
export const upload_trade_proof_of_payment =
  (trade_params) => async (dispatch) => {
    dispatch(uploadTradeProofOfPaymentRequest());
    try
    {
      const response = await apiService.post(
        `/broker/trade/upload_trade_proof_of_payment`,
        trade_params,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      dispatch(uploadTradeProofOfPaymentSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          uploadTradeProofOfPaymentFailure(
            'Failed to Upload Trade Proof of Payment.'
          )
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            uploadTradeProofOfPaymentFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Broker Producers
export const retrieve_broker_producers = (entity_id) => async (dispatch) => {
  dispatch(retrieveBrokerProducersRequest());
  try
  {
    const response = await apiService.get(
      `/broker/retrieve_broker_producers/${entity_id}`
    );
    dispatch(retrieveBrokerProducersSuccess(response.data));
    return response.data;
  } catch (error)
  {
    console.log(error);
    // Server or Network Error
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
    {
      dispatch(
        retrieveBrokerProducersFailure('Failed to Retrieve Broker Producers.')
      );
    } else
    {
      // Bad Response Message
      if (error.response.status === 400 || error.response.status === 404)
      {
        dispatch(
          retrieveBrokerProducersFailure('Failed to Retrieve Broker Producers.')
        );
      }
      //   // Unauthorized
      //   else if (error.response.status === 401)
      //   {
      //     dispatch(success_sign_out_modal());
      //   }
      //   // Access Denied
      //   else if (error.response.status === 403)
      //   {
      //     messageToast(accessDeniedMessage, app_consts.FAIL);
      //     dispatch(failState(accessDeniedMessage));
      //   }
    }
    // throw error;
  }
};

// Retrieve Broker Producers
export const approve_trade_payment = (trade_params) => async (dispatch) => {
  dispatch(approveTradePaymentRequest());
  try
  {
    const response = await apiService.post(
      `broker/trade/approve_trade_payment`,
      { trade_id: trade_params }
    );
    dispatch(approveTradePaymentSuccess(response.data));
    return response.data;
  } catch (error)
  {
    console.log(error);
    // Server or Network Error
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
    {
      dispatch(approveTradePaymentFailure('Failed to Approve Trade Payment.'));
    } else
    {
      // Bad Response Message
      if (error.response.status === 400 || error.response.status === 404)
      {
        dispatch(approveTradePaymentFailure(error.response.data.message));
      }
      //   // Unauthorized
      //   else if (error.response.status === 401)
      //   {
      //     dispatch(success_sign_out_modal());
      //   }
      //   // Access Denied
      //   else if (error.response.status === 403)
      //   {
      //     messageToast(accessDeniedMessage, app_consts.FAIL);
      //     dispatch(failState(accessDeniedMessage));
      //   }
    }
    // throw error;
  }
};

// Upload Signed Trade Document
export const upload_signed_trade_contract =
  (contract_params) => async (dispatch) => {
    dispatch(uploadSignedTradeContractRequest());
    try
    {
      const response = await apiService.post(
        `/broker/broker_bids/upload_signed_trade_contract`,
        contract_params,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      dispatch(uploadSignedTradeContractSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          uploadSignedTradeContractFailure('Failed to Upload Document.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            uploadSignedTradeContractFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };


// Confirm Trade By Contract Ref Number
export const confirm_trade_by_contract_ref_no =
  (trade_params) => async (dispatch) => {
    dispatch(confirmTradeByContractNumberRequest());
    try
    {
      const response = await apiService.post(
        `broker/trade/confirm_trade_by_contract_number`,
        trade_params
      );
      dispatch(confirmTradeByContractNumberSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          confirmTradeByContractNumberFailure('Failed Confirm Trade. Network Error.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            confirmTradeByContractNumberFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Trade Fulfillments List 
export const retrieve_trade_fulfillment_list =
  (entity_mid) => async (dispatch) => {
    dispatch(retireveTradeFulfillmentListRequest());
    try
    {
      const response = await apiService.get(
        `trade_fullfillments/retrieve_trade_fulfillment_list/${entity_mid}`,
      );
      dispatch(retireveTradeFulfillmentListSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retireveTradeFulfillmentListFailure('Could not Retrieve Trade Fulfillment List.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retireveTradeFulfillmentListFailure('Could not Retrieve Trade Fulfillment List.')
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Trade Fulfillments List 
export const retrieve_specific_trade_fulfillment =
  (contract_reference_no) => async (dispatch) => {
    dispatch(retrieveSpecificTradeFulfillmentRequest());
    try
    {
      const response = await apiService.get(
        `/trade_fullfillments/retrieve_specific_trade_fulfillment/${contract_reference_no}`
      );
      dispatch(retrieveSpecificTradeFulfillmentSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retrieveSpecificTradeFulfillmentFailure('Could not Retrieve Trade Fulfillment Details.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retrieveSpecificTradeFulfillmentFailure('Could not Retrieve Trade Fulfillment Details.')
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

export const createVirtualReceipt =
  (virtualReceiptDetails) => async (dispatch) => {
    // console.log("DATA: ", virtualReceiptDetails);

    try
    {
      dispatch(createVirtualReceiptRequest());

      const { data } = await apiService.post(
        `/broker/virtual_receipt/create`,
        virtualReceiptDetails
      );


      dispatch(createVirtualReceiptSuccess(data.message));
    } catch (error)
    {
      console.log(error);
      dispatch(createVirtualReceiptFailure());
      throw error;
    }

  };

// Retrieved Vitual Receipts
export const retrieve_virtual_receipts = () => async (dispatch) => {
  dispatch(retriveVirtualReceiptsRequest());
  try
  {
    const response = await apiService.get(`/broker/virtual_receipt/list`);
    console.log(response.data.data);
    dispatch(retriveVirtualReceiptsSuccess(response.data.data));
    return response.data.data;
  } catch (error)
  {
    console.log(error);
    // Server or Network Error
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
    {
      dispatch(
        retriveVirtualReceiptsFailure(
          'Failed to Retireve Virtual Receipts.'
        )
      );
    } else
    {
      // Bad Response Message
      if (error.response.status === 400 || error.response.status === 404)
      {
        dispatch(
          retriveVirtualReceiptsFailure(error.response.data.message)
        );
      }
      //   // Unauthorized
      //   else if (error.response.status === 401)
      //   {
      //     dispatch(success_sign_out_modal());
      //   }
      //   // Access Denied
      //   else if (error.response.status === 403)
      //   {
      //     messageToast(accessDeniedMessage, app_consts.FAIL);
      //     dispatch(failState(accessDeniedMessage));
      //   }
    }
    // throw error;
  }
};

// Retrieved Vitual Receipts
export const upload_warehouse_inspection_certificate = (warehouse_params) => async (dispatch) => {
  dispatch(uploadWarehouseInspectionCertificateRequest());
  try
  {
    const response = await apiService.post(`/warehouse/upload_warehouse_inspection_certificate`, warehouse_params,
      { headers: { 'Content-Type': 'multipart/form-data' } });

    dispatch(uploadWarehouseInspectionCertificateSuccess(response.data));
    return response.data;
  } catch (error)
  {
    console.log(error);
    // Server or Network Error
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
    {
      dispatch(
        uploadWarehouseInspectionCertificateFailure(
          'Failed to Upload Warehouse Inspection Certificate'
        )
      );
    } else
    {
      // Bad Response Message
      if (error.response.status === 400 || error.response.status === 404)
      {
        dispatch(
          uploadWarehouseInspectionCertificateFailure(error.response.data.message)
        );
      }
      //   // Unauthorized
      //   else if (error.response.status === 401)
      //   {
      //     dispatch(success_sign_out_modal());
      //   }
      //   // Access Denied
      //   else if (error.response.status === 403)
      //   {
      //     messageToast(accessDeniedMessage, app_consts.FAIL);
      //     dispatch(failState(accessDeniedMessage));
      //   }
    }
    // throw error;
  }
};

// Retrieved Vitual Receipts
export const retrieve_specific_warehouse_storage_types_and_capacities_via_delivery_id = (delivery_id) => async (dispatch) => {
  dispatch(retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdRequest());
  try
  {
    const response = await apiService.get(`/warehouse/retrieve_specific_warehouse_storage_types_and_capacities_via_delivery_id/${delivery_id}`);

    dispatch(retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdSuccess(response.data));
    return response.data;
  } catch (error)
  {
    console.log(error);
    // Server or Network Error
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
    {
      dispatch(
        retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdFailure(
          'Could not Retrieve Specific Warehouse Storage Types and Capacities'
        )
      );
    } else
    {
      // Bad Response Message
      if (error.response.status === 400 || error.response.status === 404)
      {
        dispatch(
          retrieveSpecificWarehouseStorageTypesAndCapacitiesViaDeliveryIdFailure(error.response.data.message)
        );
      }
      //   // Unauthorized
      //   else if (error.response.status === 401)
      //   {
      //     dispatch(success_sign_out_modal());
      //   }
      //   // Access Denied
      //   else if (error.response.status === 403)
      //   {
      //     messageToast(accessDeniedMessage, app_consts.FAIL);
      //     dispatch(failState(accessDeniedMessage));
      //   }
    }
    // throw error;
  }
};

export const retrieveVirtualReceiptList =
  (broker_id) => async (dispatch) => {
    dispatch(retrieveEntityWarehouseReceiptListRequest());
    try
    {
      const response = await apiService.get(
        `/broker/virtual_receipt/list_all/${broker_id}`
      );
      console.log("DATA", response);
      dispatch(retrieveEntityWarehouseReceiptListSuccess(response.data));
      return response.data;
    } catch (error)
    {
      dispatch(retrieveEntityWarehouseReceiptListFailure(error.message));
      throw error;
    }
  };

export const updateVirtualReceiptStatus = (StatusData) => async (dispatch) => {
  try
  {
    const response = await apiService.post(
      '/broker/virtual_receipt/update_status',
      {
        id: StatusData.id,
        virtual_receipt: { status: StatusData.status },
      });
    const offerStatusMessage = response.data.data;
    dispatch({
      type: ActionTypes.UPDATE_OFFER_STATUS_SUCCESS,
      payload: offerStatusMessage,
    });
    return response.data;
  } catch (error)
  {
    dispatch({
      type: ActionTypes.UPDATE_OFFER_STATUS_FAILURE,
      payload: error.message,
    });
    throw error;
  }
};


// Retrieved Vitual Receipts
export const retrieve_valid_super_and_sub_offers_bids = () => async (dispatch) => {
  dispatch(retrieveValidaSuperAndSubOffersBidsRequest());
  try
  {
    const response = await apiService.get(`/ats/retrieve_valid_super_and_sub_offers_bids`);

    dispatch(retrieveValidaSuperAndSubOffersBidsSuccess(response.data));
    return response.data;
  } catch (error)
  {
    console.log(error);
    // Server or Network Error
    if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
    {
      dispatch(
        retrieveValidaSuperAndSubOffersBidsFailure(
          'Could not Retrieve Bids and Offers'
        )
      );
    } else
    {
      // Bad Response Message
      if (error.response.status === 400 || error.response.status === 404)
      {
        dispatch(
          retrieveValidaSuperAndSubOffersBidsFailure(error.response.data.message)
        );
      }
      //   // Unauthorized
      //   else if (error.response.status === 401)
      //   {
      //     dispatch(success_sign_out_modal());
      //   }
      //   // Access Denied
      //   else if (error.response.status === 403)
      //   {
      //     messageToast(accessDeniedMessage, app_consts.FAIL);
      //     dispatch(failState(accessDeniedMessage));
      //   }
    }
    // throw error;
  }
};

// Retrieve Trade Offer Details
export const retrieve_broker_super_bids_by_broker_id =
  (broker_id) => async (dispatch) => {
    dispatch(retrieveBrokerSuperBidsByBrokerIdRequest());
    try
    {
      const response = await apiService.get(
        `/broker/broker_bids/retrieve_broker_super_bids_by_broker_id/${broker_id}`
      );
      dispatch(retrieveBrokerSuperBidsByBrokerIdSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retrieveBrokerSuperBidsByBrokerIdFailure('Failed to Retrieve Broker Bids.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retrieveBrokerSuperBidsByBrokerIdFailure(
              'Failed to Retrieve Broker Bids.'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Specific Broker Offer ID
export const retrieve_specific_broker_bid =
  (broker_offer_id) => async (dispatch) => {
    dispatch(retrieveSpecificBrokerOfferRequest());
    try
    {
      const response = await apiService.get(
        `/broker/broker_bids/gets_specific_broker_bid/${broker_offer_id}`
      );
      dispatch(retrieveSpecificBrokerOfferSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retrieveSpecificBrokerOfferFailure('Failed to Retrieve Broker Offer.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retrieveSpecificBrokerOfferFailure(
              'Failed to Retrieve Broker Offer.'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// List All Producers with Entities
export const list_all_producers_with_entities =
  () => async (dispatch) => {
    dispatch(listAllProducersWithEntitiesRequest());
    try
    {
      const response = await apiService.get(
        `/broker/list_all_producers_with_entities`
      );
      dispatch(listAllProducersWithEntitiesSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          listAllProducersWithEntitiesFailure('Failed to Retrieve Producers List.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            listAllProducersWithEntitiesFailure(
              'Failed to Retrieve Producers List.'
            )
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Entity Profile Info
export const retrieve_entity_profile_info =
  (entity_params) => async (dispatch) => {
    dispatch(retrieveEntityProfileInfoRequest());
    try
    {
      const response = await apiService.post(
        `/entities/retrieve_entity_profile_info`, entity_params
      );
      dispatch(retrieveEntityProfileInfoSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retrieveEntityProfileInfoFailure('Failed to Retrieve Entity Profile Info.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retrieveEntityProfileInfoFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Entity Profile Info
export const retrieve_entity_user_profile =
  (profile_id) => async (dispatch) => {
    dispatch(retrieveEntityUserProfileRequest());
    try
    {
      const response = await apiService.get(
        `/profiles/retrieve_entity_user_profile/${profile_id}`,
      );
      dispatch(retrieveEntityUserProfileSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retrieveEntityUserProfileFailure('Failed to Retrieve Entity User Profile Details.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retrieveEntityUserProfileFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Entity Profile Info
export const amend_proile_role =
  (profile_params) => async (dispatch) => {
    dispatch(amendProfileRoleRequest());
    try
    {
      const response = await apiService.post(
        `/profiles/amend_proile_role`, profile_params
      );
      dispatch(amendProfileRoleSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          amendProfileRoleFailure('Could Not Amend Profile Role.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            amendProfileRoleFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Retrieve Specific Warehouse Storage Type
export const retrieve_specific_warehouse_storage_types =
  (warehouse_id) => async (dispatch) => {
    dispatch(retrieveSpecificWarehouseStorageTypeRequest());
    try
    {
      const response = await apiService.get(
        `warehouses/retrieve_specific_warehouse_storage_types/${warehouse_id}`
      );
      dispatch(retrieveSpecificWarehouseStorageTypeSuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          retrieveSpecificWarehouseStorageTypeFailure('Could Not Retrieve Warehouse Storage Types.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            retrieveSpecificWarehouseStorageTypeFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };

// Process Bulk Delivery
export const process_bulk_delivery =
  (bulk_delivery_params) => async (dispatch) => {
    dispatch(processBulkDeliveryRequest());
    try
    {
      const response = await apiService.post(
        `/warehouse/process_bulk_delivery`, bulk_delivery_params, { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      dispatch(processBulkDeliverySuccess(response.data));
      return response.data;
    } catch (error)
    {
      console.log(error);
      // Server or Network Error
      if (error.code === 'ERR_NETWORK' || error.code === 'ERR_BAD_RESPONSE')
      {
        dispatch(
          processBulkDeliveryFailure('Could Not Process Bulk Delivery.')
        );
      } else
      {
        // Bad Response Message
        if (error.response.status === 400 || error.response.status === 404)
        {
          dispatch(
            processBulkDeliveryFailure(error.response.data.message)
          );
        }
        //   // Unauthorized
        //   else if (error.response.status === 401)
        //   {
        //     dispatch(success_sign_out_modal());
        //   }
        //   // Access Denied
        //   else if (error.response.status === 403)
        //   {
        //     messageToast(accessDeniedMessage, app_consts.FAIL);
        //     dispatch(failState(accessDeniedMessage));
        //   }
      }
      // throw error;
    }
  };