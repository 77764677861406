import React, { createRef, useEffect, useState } from 'react';
import Logo from '../../components/Logo';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword, resetPasswordReset, sendResetPasswordOTP, sendresetpasswordOtpReset, verifyResetPasswordOTP, verifyResetPasswordOtpReset } from '../../utils/actions';

const initialState = { email: "", provided_otp: "" };
const passwordState = { password_hash: "", confirm_password_hash: "", provided_otp: "", email: "" };

const ForgotPassword = () => {

    const [otp, setOtp] = useState(Array(6).fill(""));
    const inputsRef = otp.map(() => createRef());
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState(initialState);
    const [passwordData, setPasswordData] = useState(passwordState);
    const [userSearchLoading, setUserSearchLoading] = useState(false);
    const [verifyOtpLoading, setVerifyOtpLoading] = useState(false);
    const [passwordChangeLoading, setPasswordChangeLoading] = useState(false);
    const [errVal, setError] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSendOtp = async () => {
        try
        {
            setError("");
            await dispatch(sendResetPasswordOTP(formData.email));
        } catch (error)
        {
            if (error.response.status === 400)
            {
                setError(error.response.data.message);
            }
            else if (error.response.status === 500)
            {
                setError("Could not Send OTP.");
            }
        }
    };

    // Listen to Dispatch State of Sent Reset Password OTP
    const sendResetOtp = useSelector((state) => state.sendResetPasswordOtp);
    //
    useEffect(() => {
        if (sendResetOtp.status !== "idle")
        {
            if (sendResetOtp.isLoading) 
            {
                setUserSearchLoading(true);
            }
            else
            {
                setUserSearchLoading(false);
                if (sendResetOtp.data === "OTP Successfully Sent.")
                {
                    setFormData({ ...formData, ["provided_otp"]: "" });
                    setStep(2);
                }
                dispatch(sendresetpasswordOtpReset());
            }
        }
    }, [sendResetOtp]);

    const handleChange = (element, index) => {
        if (isNaN(element.target.value))
        {
            return;
        } else if (element.target.value.length === 1)
        {
            setOtp([...otp.slice(0, index), element.target.value, ...otp.slice(index + 1)]);
            if (index < otp.length - 1)
            {
                inputsRef[index + 1].current.focus();
            }
        }
    };

    const handleKeyUp = (element, index) => {
        if (element.keyCode === 8 || element.keyCode === 46)
        {
            setOtp([...otp.slice(0, index), "", ...otp.slice(index + 1)]);
            if (index > 0)
            {
                inputsRef[index - 1].current.focus();
            }
        }
    };

    const handleVerifyOtpSubmit = async () => {
        try
        {
            setError("");
            await dispatch(verifyResetPasswordOTP(formData));
        } catch (error)
        {
            if (error.response.status === 400)
            {
                setError(error.response.data.message);
            }
            else if (error.response.status === 500)
            {
                setError("Could not Verify OTP.");
            }
        }
    };

    // Listen to Reducer for Verifyiung OTP
    const verifyResetOtp = useSelector((state) => state.verifyResetPasswordOtp);
    //
    useEffect(() => {
        if (verifyResetOtp.status !== "idle")
        {
            if (verifyResetOtp.isLoading) 
            {
                setVerifyOtpLoading(true);
            }
            else
            {
                setVerifyOtpLoading(false);
                if (verifyResetOtp.data === "OTP Successfully Verified.")
                {
                    setPasswordData({ ...passwordData, ["provided_otp"]: formData.provided_otp, ["email"]: formData.email });
                    setStep(3);
                }
                dispatch(verifyResetPasswordOtpReset());
            }
        }
    }, [verifyResetOtp]);

    useEffect(() => {
        let otp_values = [];
        for (let i = 0; i < otp.length; i++)
        {
            if (otp[i] != "")
            {
                otp_values.push(otp[i]);
            }
        }
        if (otp_values.length === 6)
        {
            const otpString = otp.join('');
            setFormData({ ...formData, ["provided_otp"]: otpString });
        }
        else
        {
            setFormData({ ...formData, ["provided_otp"]: "" });
        }
    }, [otp]);

    // Handle Change Password
    const handleChangePassword = async () => {
        if (passwordData.password_hash === passwordData.confirm_password_hash) 
        {
            try
            {
                setError("");
                await dispatch(resetPassword(passwordData));
            } catch (error)
            {
                if (error.response.status === 400)
                {
                    setError(error.response.data.message);
                }
                else if (error.response.status === 500)
                {
                    setError("Could not Update Password.");
                }
            }
        }
        else
        {
            setError("Password do not Match.");
        }
    };

    // Listen to Reducer for Changing Password
    const resetPasswordListener = useSelector((state) => state.resetPassword);
    //
    useEffect(() => {
        if (resetPasswordListener.status !== "idle")
        {
            if (resetPasswordListener.isLoading) 
            {
                setPasswordChangeLoading(true);
            }
            else
            {
                setPasswordChangeLoading(false);
                if (resetPasswordListener.data === "Password Reset Successfully.")
                {
                    navigate("/");
                }
                dispatch(resetPasswordReset());
            }
        }
    }, [resetPasswordListener]);

    return (
        <>
            <section className="bg-gray-50 dark:bg-gray-900">
                <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                    <Logo />
                    <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                        <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                                Reset Password
                            </h1>
                            {errVal && (
                                <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                    <span className="font-medium">Error: </span>
                                    {errVal}
                                </div>
                            )}
                            <form onSubmit={(e) => e.preventDefault()}>
                                {
                                    step === 1 ?
                                        <>
                                            <div className='mb-4'>
                                                <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                    Email Address
                                                </label>
                                                <input
                                                    type="text"
                                                    name="email"
                                                    id="email"
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                    placeholder="name@company.com"
                                                    required=""
                                                    onChange={(e) => setFormData({ ...formData, ["email"]: e.target.value })}
                                                    value={formData.email}
                                                />
                                            </div>
                                            <button
                                                type="submit"
                                                className={`mt-4 mb-2 w-full text-white ${userSearchLoading || !(formData.email) ? "bg-gray-400 dark:bg-gray-500 dark:focus:ring-gray-700" : "bg-primary-600 hover:bg-primary-700  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"} focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                                                disabled={userSearchLoading ? userSearchLoading : !(formData.email)}
                                                onClick={handleSendOtp}
                                            >
                                                {userSearchLoading ? 'Sending OTP...' : 'Send OTP'}
                                            </button>
                                        </>
                                        :
                                        step === 2 ?
                                            <>
                                                <div>
                                                    <span className="block mb-4 text-sm font-medium text-gray-700 dark:text-white">
                                                        Please Enter the OTP Received On your Mobile or Email Address.
                                                    </span>
                                                </div>
                                                <div className="mt-4 flex justify-between mb-6">
                                                    {otp.map((value, index) => (
                                                        <input
                                                            key={index}
                                                            ref={inputsRef[index]}
                                                            id={`otp${index}`}
                                                            name={`otp${index}`}
                                                            type="text"
                                                            maxLength="1"
                                                            value={value}
                                                            onChange={(e) => handleChange(e, index)}
                                                            onKeyUp={(e) => handleKeyUp(e, index)}
                                                            required
                                                            className="block w-10 text-center rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                        />
                                                    ))}
                                                </div>
                                                <div className="flex items-center justify-end">
                                                    <span onClick={() => { setStep(1); handleSendOtp(); }} className="cursor-pointer text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">
                                                        Resend OTP?
                                                    </span>
                                                </div>
                                                <button
                                                    type="submit"
                                                    className={`mt-4 mb-2 w-full text-white ${verifyOtpLoading || !(formData.email && formData.provided_otp) ? "bg-gray-400 dark:bg-gray-500 dark:focus:ring-gray-700" : "bg-primary-600 hover:bg-primary-700  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"} focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                                                    disabled={verifyOtpLoading ? verifyOtpLoading : !(formData.email && formData.provided_otp)}
                                                    onClick={handleVerifyOtpSubmit}
                                                >
                                                    {verifyOtpLoading ? 'Verify OTP...' : 'Verify OTP'}
                                                </button>
                                            </>
                                            :
                                            step === 3 ?
                                                <>
                                                    <div className='mb-4'>
                                                        <label htmlFor="password_hash" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                            New Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="password_hash"
                                                            id="password_hash"
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                            required=""
                                                            onChange={(e) => setPasswordData({ ...passwordData, ["password_hash"]: e.target.value })}
                                                            value={passwordData.password_hash}
                                                        />
                                                    </div>
                                                    <div className='mb-4'>
                                                        <label htmlFor="confirm_password_hash" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                            Confirm Password
                                                        </label>
                                                        <input
                                                            type="password"
                                                            name="confirm_password_hash"
                                                            id="confirm_password_hash"
                                                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                            required=""
                                                            onChange={(e) => setPasswordData({ ...passwordData, ["confirm_password_hash"]: e.target.value })}
                                                            value={passwordData.confirm_password_hash}
                                                        />
                                                    </div>
                                                    <button
                                                        type="submit"
                                                        className={`mt-4 mb-2 w-full text-white ${passwordChangeLoading || !(passwordData.confirm_password_hash && passwordData.password_hash && passwordData.password_hash.length >= 8 && passwordData.provided_otp) ? "bg-gray-400 dark:bg-gray-500 dark:focus:ring-gray-700" : "bg-primary-600 hover:bg-primary-700  dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"} focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                                                        disabled={passwordChangeLoading ? passwordChangeLoading : !(passwordData.confirm_password_hash && passwordData.password_hash && passwordData.password_hash.length >= 8 && passwordData.provided_otp)}
                                                        onClick={handleChangePassword}
                                                    >
                                                        {passwordChangeLoading ? 'Updating Password...' : 'Update Password'}
                                                    </button>
                                                </>
                                                :
                                                ""
                                }
                                <button
                                    type="submit"
                                    onClick={() => navigate("/")}
                                    className="mt-4 w-full text-green-600  hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                >
                                    Return to Login
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ForgotPassword;