import { useEffect, useState } from "react";
import { wizard_step_guide_data } from "./wizard_step_guide_data";
import AvatarText from "../text/AvatarText";

export const BreadcrumbStepGuide = ({ sentStep, wizard_type }) => {
    const [step, setStep] = useState(sentStep);
    const [showSteps, setShowSteps] = useState([]);
    const [title, setTitle] = useState("");

    useEffect(() => {
        setShowSteps(wizard_step_guide_data(wizard_type));
    }, []);

    useEffect(() => {
        setShowSteps(wizard_step_guide_data(wizard_type));
    }, [wizard_type]);

    useEffect(() => {
        setStep(sentStep);
    }, [sentStep]);

    return (
        <>
            <ol class="flex items-center w-full p-3 space-x-2 text-sm font-medium text-center text-gray-500 bg-white shadow-sm dark:text-gray-400 sm:text-base dark:bg-gray-800 dark:border-gray-700 sm:p-4 sm:space-x-4">
                {
                    showSteps?.map((showStep) => {
                        return (
                            <div key={showStep.index}>
                                <AvatarText avatarText={showStep.index} step={step} avatarLabel={showStep.step} />
                            </div>
                        );
                    })
                }
            </ol>
        </>
    );
};
