import React, { useEffect, useState } from 'react';
import * as app_consts from "../../utils/constants";
import { table_columns } from './table_columns';
import { BidManagemenetTable, BrokerOfferTradeTable, EntityProfileUserProfilesTable, OfferHistoryTable, SuperBidManagemenetTable, TradeFulfillmentListTable, VirtualReceiptListTable, WarehouseReceiptHistoryTable, WarehouseReceiptListTable } from './TableRows';

const TableComp = ({ tableData, tableHeading, tableType, checkboxRow }) => {

    const [data, setData] = useState([]);
    const [heading, setHeading] = useState("");
    const [type, setType] = useState("");
    const [columns, setColumns] = useState(table_columns(tableType));
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(20);
    const [totalDataSet, setTotalDataSet] = useState(0);


    useEffect(() => {
        setData(tableData);
        setTotalDataSet(tableData?.length);
    }, [tableData]);

    useEffect(() => {
        setType(tableType);
        setColumns(table_columns(tableType));
    }, [type]);

    // Get current items based on pagination
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate the start and end index for the current page
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    // Pagination handlers
    const nextPage = () => {
        if (currentPage * pageSize < totalDataSet)
        {
            setCurrentPage(currentPage + 1);
        }
    };

    const prevPage = () => {
        if (currentPage > 1)
        {
            setCurrentPage(currentPage - 1);
        }
    };


    return (
        <>
            <div className="relative overflow-x-auto">
                <br />
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    {/* Table Head */}
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            {
                                checkboxRow && (
                                    <>
                                        <th scope="col" className="p-4">
                                            <div className="flex items-center">
                                                <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                <label for="checkbox-all-search" className="sr-only">checkbox</label>
                                            </div>
                                        </th>
                                    </>
                                )
                            }
                            {columns &&
                                columns?.map((column) => {
                                    return (
                                        <th scope="col" className="px-6 py-3" key={column.key}>
                                            <span>{column.label}</span>
                                        </th>
                                    );
                                })}
                        </tr>
                    </thead>
                    {/* Table Body */}
                    <tbody>
                        {
                            data?.length === 0 && currentItems?.length === 0 ?
                                <>
                                    <tr>
                                        <td colSpan="8" className="text-center py-4">
                                            No {tableHeading || "Data"}  Found.
                                        </td>
                                    </tr>
                                </>
                                :
                                <>
                                    {
                                        type === app_consts.WAREHOUSE_RECEIPT_TABLE ?
                                            <WarehouseReceiptHistoryTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                            :
                                            type === app_consts.OFFER_HISTORY_TABLE ?
                                                <OfferHistoryTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                :
                                                type === app_consts.BROKER_OFFER_TRADE_TABLE ?
                                                    <BrokerOfferTradeTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                    :
                                                    type === app_consts.BID_MANAGEMENT_TABLE ?
                                                        <BidManagemenetTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                        :
                                                        type === app_consts.WAREHOUSE_RECEIPT_LIST_TABLE ?
                                                            <WarehouseReceiptListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                            :
                                                            type === app_consts.TRADE_FULFILLMENTS_LIST_TABLE ?
                                                                <TradeFulfillmentListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                :
                                                                type === app_consts.SUPER_BID_MANAGEMENT_TABLE ?
                                                                    <SuperBidManagemenetTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                    :
                                                                    type === app_consts.ENTITY_USER_PROFILES_TABLE ?
                                                                        <EntityProfileUserProfilesTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                        :
                                                                        type === app_consts.VIRTUAL_RECEIPT_LIST_TABLE ?
                                                                            <VirtualReceiptListTable currentItems={currentItems} data={data} page_size={pageSize} currentPage={currentPage} />
                                                                            :
                                                                            ""
                                    }
                                </>
                        }
                    </tbody>
                </table>{
                    data && data?.length !== 0 && (
                        <nav className=" pt-4" aria-label="Table navigation">
                            {/* Pagination controls */}
                            <div className="flex flex-row items-center justify-between mt-4 mr-8">
                                <div className="flex-1">
                                    <p className="text-sm text-gray-800 dark:text-gray-400">
                                        Showing {startIndex + 1}-{Math.min(endIndex, totalDataSet)} of {totalDataSet} {tableHeading}
                                    </p>
                                </div>
                                {
                                    data && totalDataSet > pageSize && (
                                        <>
                                            <div className="flex">
                                                <button
                                                    onClick={prevPage}
                                                    disabled={currentPage === 1}
                                                    className="px-3 py-1 text-sm text-gray-500 dark:text-gray-400 rounded-full border border-grey-100 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-white cursor-pointer"
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    onClick={nextPage}
                                                    disabled={currentPage * pageSize >= totalDataSet}
                                                    className="ml-2 px-3 py-1 text-sm text-gray-500 dark:text-gray-400 rounded-full border border-grey-100 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-700 dark:hover:text-white cursor-pointer"
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </nav>
                    )
                }
            </div>
        </>
    );
};

export default TableComp;