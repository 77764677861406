import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
      <div className="w-full mx-auto max-w-screen-xl p-4 flex flex-col md:flex-row md:items-center md:justify-between">
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0 md:order-2">
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">About</a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">Privacy Policy</a>
          </li>
          <li>
            <a href="#" className="mr-4 hover:underline md:mr-6">Licensing</a>
          </li>
          <li>
            <a href="#" className="hover:underline">Contact</a>
          </li>
        </ul>
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 md:order-1">
          &copy; {new Date().getFullYear()}<a href="https://flowbite.com/" className="hover:underline"> Zambian Commodities Exchange Ltd</a>. All Rights Reserved.
        </span>
      </div>
    </footer>
  );
}

export default Footer;

