import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addEntityDirector, getCurrentAccount, getDirectorList } from '../../utils/actions';
import { useNavigate } from 'react-router-dom';
import { BuildingOfficeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import 'react-phone-input-2/lib/style.css';
import countries from '../../utils/countries.json';
import NavHeader from '../../layouts/NavHeader';
import Footer from '../../layouts/Footer';
import FileInput from '../../layouts/FileInput';
import { format } from 'date-fns';

const DirectorList = ({ directors, profileType }) => {
    if (directors.length === 0)
    {
        return <p>{profileType === 'cooperative' ? 'Add at least 3 executive members' : 'Add at least 1 director or executive member'}</p>;
    }

    return (
        <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">First Name</th>
                        <th scope="col" className="px-6 py-3">Last Name</th>
                        <th scope="col" className="px-6 py-3">Identification</th>
                        <th scope="col" className="px-6 py-3">Nationality</th>
                        <th scope="col" className="px-6 py-3">Type</th>
                    </tr>
                </thead>
                <tbody>
                    {directors.map((director) => (
                        <tr key={director.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">{director.first_name}</th>
                            <td className="px-6 py-4">{director.last_name}</td>
                            <td className="px-6 py-4">{director.identification}</td>
                            <td className="px-6 py-4">{director.nationality}</td>
                            <td className="px-6 py-4">{director.type}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const EntityManagementInformation = ({ entity }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const [selectedFiles, setSelectedFiles] = useState({
        id_front_image_url: null,
        id_back_image_url: null,
        profile_image_url: null,
    });

    // Get the director list from the Redux store

    const directors = useSelector((state) => state.directorList.data);

    const executiveMembers = directors?.length >= 3;

    useEffect(() => {
        const fetchDirectorList = async () => {
            try
            {
                await dispatch(getDirectorList());
            } catch (error)
            {
                console.error(error);
            }
        };

        fetchDirectorList();
    }, [dispatch]);


    const account = JSON.parse(localStorage.getItem('account'));
    const profile = account.profiles.find((profile) => profile.account_id === account.id);

    const profileName = profile.name;
    const profileType = profile.type;
    const profileStatus = profile.status;
    const registrationDirectorDetails = account.profiles.find((profile) => profile.entity_owners_uploaded === 1 && profile.account_id === account.id);

    const executiveDirectorDetails = profileType === 'cooperative' ? executiveMembers : registrationDirectorDetails;

    const [entityData, setEntityData] = useState({
        first_name: '',
        identification: '',
        last_name: '',
        nationality: '',
        id_back_image_url: '',
        id_front_image_url: '',
        profile_image_url: '',
        history: [],
        type: ''
    });

    const handleNextStep = async () => {
        try
        {
            await dispatch(getCurrentAccount());
            navigate('/bank_details');
        } catch (error)
        {
            // Handle error if needed
            console.error(error);
        }
    };

    const validateInputs = (data) => {
        const errors = {};

        if (!data.first_name)
        {
            errors.first_name = 'Please enter first name';
        }

        if (!data.last_name)
        {
            errors.last_name = 'Please enter last name';
        }

        if (!data.identification)
        {
            errors.identification = 'Please enter NRC or passport number';
        }

        if (!data.nationality)
        {
            errors.nationality = 'Please select nationality';
        }

        if (!data.type)
        {
            errors.type = 'Please select title';
        }

        return errors;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEntityData((prevData) => ({ ...prevData, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: null }));
    };

    const MAX_FILE_SIZE_MB = 1;

    const handleFileChange = (e, fieldName) => {
        const selectedFile = e.target.files[0];

        if (selectedFile)
        {
            const fileSizeInMB = selectedFile.size / (1024 * 1024);
            if (fileSizeInMB > MAX_FILE_SIZE_MB)
            {
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: null }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: `File size exceeds the ${MAX_FILE_SIZE_MB}MB limit.`,
                }));
                return;
            }

            const allowedFileTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/bmp',
                'image/tiff',
                'image/heif',
            ];

            if (allowedFileTypes.includes(selectedFile.type))
            {
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: selectedFile }));
                setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: null, file: null }));
            } else
            {
                setSelectedFiles((prevFiles) => ({ ...prevFiles, [fieldName]: null }));
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    [fieldName]: 'Please select a valid image or PDF file (JPEG, PNG, GIF, BMP, TIFF or HEIF).',
                }));
            }
        }
    };


    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;

        setEntityData((prevEntity) => ({
            ...prevEntity,
            nationality: selectedCountry,
        }));
    };

    const handleAddDirector = async (e) => {
        e.preventDefault();

        if (!selectedFiles.id_front_image_url || !selectedFiles.profile_image_url)
        {
            setErrors({ file: "Please select all required image files to upload." });
            return;
        }

        const inputErrors = validateInputs(entityData);
        if (Object.keys(inputErrors).length > 0)
        {
            setErrors(inputErrors);
            return;
        }

        const directorIdentification = entityData.identification;

        const isDirectorExist = directors.some((director) => director.identification === directorIdentification);

        if (isDirectorExist)
        {
            setErrors({ identification: 'You have already added a director with the same details' });
            return;
        }

        try
        {
            setLoading(true);

            const timestamp = new Date();
            const historyStatement = `Added to ${profileName} as director on ${format(timestamp, 'MMMM dd, yyyy hh:mm a')}`;

            const directorData = {
                ...entityData,
                history: [...entityData.history, historyStatement],
                id_front_image_url: selectedFiles.id_front_image_url,
                id_back_image_url: selectedFiles.id_back_image_url,
                profile_image_url: selectedFiles.profile_image_url,
            };

            await dispatch(addEntityDirector(directorData));
            await dispatch(getCurrentAccount());
            setSuccess(true);
            setLoading(false);

            // Clear fields after successful addition
            setEntityData({
                ...entityData,
                first_name: '',
                identification: '',
                last_name: '',
                nationality: '',
                id_back_image_url: '',
                id_front_image_url: '',
                profile_image_url: '',
                history: [],
                type: ''
            });

            setSelectedFiles({
                id_front_image_url: null,
                id_back_image_url: null,
                profile_image_url: null,
            });

            setErrors({});

            window.location.reload();
        } catch (error)
        {
            if (error.response && error.response.data && error.response.data.errors)
            {
                setErrors(error.response.data.errors);
                setLoading(false);
            } else
            {
                console.error(error);
                setLoading(false);
            }
        }
    };


    return (
        <>
            <NavHeader />
            <section className="flex h-screen ">
                <div className="w-full lg:px-9">
                    <div className="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                        <div className="grid w-full place-items-center bg-white p-6 sm:p-16 lg:p-8">
                            <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0">
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        2
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Verify your registration</h3>
                                        <p className="text-sm">Use OTP for verification</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-500 dark:text-gray-400 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                                        3
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Membership Type</h3>
                                        <p className="text-sm">Set membership type</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        4
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Organization Information</h3>
                                        <p className="text-sm">Provide organization details</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-green-600 dark:text-green-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500">
                                        5
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">{profileType == 'cooperative' ? 'Executive Members' : 'Directors & Management'}</h3>
                                        <p className="text-sm">Provide KYC information</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        6
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Add Bank Account</h3>
                                        <p className="text-sm">Provide bank details</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        7
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Submit Registration</h3>
                                        <p className="text-sm">Submit registration for verification</p>
                                    </span>
                                </li>
                            </ol>

                            <form onSubmit={handleAddDirector}>
                                <br />

                                <div className="flex flex-col items-stretch justify-start border-t border-gray-100">
                                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                                        Registrant Name: {profileName}
                                    </h3>
                                    <br />
                                    <div className="flex flex-row space-x-6">
                                        <div className="flex items-center text-sm text-green-800">
                                            <BuildingOfficeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-800" aria-hidden="true" />
                                            <span className="font-bold uppercase">{profileType}</span>
                                        </div>
                                        <div className="flex items-center text-sm text-red-800">
                                            <InformationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-800" aria-hidden="true" />
                                            <span className="font-bold uppercase">{profileStatus}</span>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <p className="text-xl font-semibold text-green-600/100 dark:text-green-500/100">Details of {profileName}'s {profileType === 'cooperative' ? 'Executive Members' : 'Directors & Executive'}</p>
                                <p className="tracking-tighter text-gray-500 md:text-lg dark:text-gray-400">Please provide personal details of {profileName}'s {profileType === 'cooperative' ? 'Executive Members' : 'Directors and Executives'} as listed on {profileName} official incorporation documentation.</p>
                                <br />
                                <DirectorList directors={directors} profileType={profileType} />

                                <div className="mt-8">
                                    <p className="text-lg font-medium text-gray-900 dark:text-white">Add individual details one at a time.</p>
                                </div>

                                <div className="grid gap-6 mb-8 lg:grid-cols-4 p-6 sm:p-16 lg:p-8">

                                    <div>
                                        <label htmlFor="first_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            First Name
                                        </label>
                                        <input
                                            type="text"
                                            name="first_name"
                                            value={entityData.first_name}
                                            onChange={handleInputChange}
                                            required
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        />
                                        {errors.first_name && <p className="text-red-500 text-xs mt-1">{errors.first_name}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="last_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Surname
                                        </label>
                                        <input
                                            type="text"
                                            name="last_name"
                                            value={entityData.last_name}
                                            onChange={handleInputChange}
                                            required
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        />
                                        {errors.last_name && <p className="text-red-500 text-xs mt-1">{errors.last_name}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="identification" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            NRC/Passport Number
                                        </label>
                                        <input
                                            type="text"
                                            name="identification"
                                            value={entityData.identification}
                                            onChange={handleInputChange}
                                            required
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        />
                                        {errors.identification && <p className="text-red-500 text-xs mt-1">{errors.identification}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="nationality" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Select Nationality
                                        </label>
                                        <select
                                            name="nationality"
                                            value={entityData.nationality}
                                            onChange={handleCountryChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            required
                                        >
                                            {countries.map((country, index) => (
                                                <option key={index} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.nationality && (
                                            <p className="text-red-500 text-xs mt-1">{errors.nationality[0]}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Select Title
                                        </label>
                                        <select
                                            name="type"
                                            value={entityData.type}
                                            onChange={handleInputChange}
                                            required
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        >
                                            <option value="">Choose a category</option>
                                            {profileType === 'cooperative' ?
                                                <>
                                                    <option value="chairperson">Chairperson</option>
                                                    <option value="secretary">Secretary</option>
                                                    <option value="vice_secretary">Vice Secretary</option>
                                                    <option value="treasurer">Treasurer</option>
                                                    <option value="vice_treasurer">Vice Treasurer</option>
                                                    <option value="committee_member">Committee Member</option>
                                                </>
                                                :
                                                <>
                                                    <option value="ceo">CEO</option>
                                                    <option value="cfo">CFO/Finance Director</option>
                                                    <option value="director">Director</option>
                                                </>}
                                        </select>
                                        {errors.type && (
                                            <p className="text-red-500 text-xs mt-1">{errors.type[0]}</p>
                                        )}
                                    </div>
                                    <div>
                                        <FileInput
                                            label="Upload Front NRC/Passport Image"
                                            name="id_front_image_url"
                                            onChange={(e) => handleFileChange(e, 'id_front_image_url')}
                                        />
                                        {errors.id_front_image_url && <p className="text-red-500 text-xs mt-1">{errors.id_front_image_url}</p>}
                                    </div>
                                    <div>

                                        <FileInput
                                            label="Upload Back NRC Image"
                                            name="id_back_front_url"
                                            onChange={(e) => handleFileChange(e, 'id_back_image_url')}
                                        />
                                        {errors.id_back_image_url && <p className="text-red-500 text-xs mt-1">{errors.id_back_image_url}</p>}
                                    </div>

                                    <div>

                                        <FileInput
                                            label="Upload Profile Image (full face ONLY)"
                                            name="profile_image_url"
                                            onChange={(e) => handleFileChange(e, 'profile_image_url')}
                                        />
                                        {errors.profile_image_url && <p className="text-red-500 text-xs mt-1">{errors.profile_image_url}</p>}
                                    </div>
                                    <button
                                        type="submit"
                                        className="text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                                    >
                                        Add Director/Executive
                                    </button>
                                </div>

                                {Object.entries(errors).map(([field, messages]) => (
                                    Array.isArray(messages) && messages.length > 0 && (
                                        <div
                                            key={field}
                                            className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                            role="alert"
                                        >
                                            {messages.map((message, idx) => (
                                                <p className="font-medium" key={idx}>
                                                    {message}
                                                </p>
                                            ))}
                                        </div>
                                    )
                                ))}

                                {loading && (
                                    <div className="text-center">
                                        <div role="status">
                                            <svg
                                                aria-hidden="true"
                                                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                            <span className="sr-only">Please wait...</span>
                                        </div>
                                    </div>
                                )}

                                <div className="flex justify-end mt-4">
                                    {executiveDirectorDetails ? (
                                        <button
                                            type="button"
                                            onClick={handleNextStep}
                                            className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                        >
                                            Next Step: Add {profileName}'s Bank Account
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="text-white bg-gray-400 dark:bg-gray-500 cursor-not-allowed font-medium rounded-md text-sm px-5 py-2.5 text-center"
                                            disabled
                                        >
                                            {profileType === 'cooperative' ? 'Add at least 3 officials before proceeding' : 'Add at least 1 official before proceeding'}
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};


export default EntityManagementInformation;
