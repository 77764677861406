import React from "react";

function Logo () {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="217"    // 7% of 3100
      height="90"    // 7% of 1280
      version="1.1"
      viewBox="0 0 820.208 338.667"
    >
      <g>
        <g transform="translate(147.096 -227.014) scale(2.10382)">
          <g fillOpacity="1" transform="matrix(.50663 0 0 .50663 -53.62 17.88)">
            <g fill="#009e49" transform="translate(-.693 187.068)">
              <path
                d="M445.95 35.93c9.98-12.69 21.83-23.68 33.62-34.63-.55 12.49-.83 25.07.62 37.52 2.48 23 9.88 45.05 17.31 66.84 6.62 20.01 12.86 41.16 9.53 62.44-3.71 26.79-19.61 49.78-36.53 70.09 11.19-18.12 21.7-37.15 26.42-58.09 2.34-9.83 2.28-20.04 1.62-30.06-2.01-18.27-9.95-35.07-16.18-52.16-5.08-13.68-9.04-27.89-10.51-42.44-.57-8.23-1.65-16.52-.53-24.75-18.61 15-34.37 33.96-43.72 56.1-6.86 15.94-11.63 32.69-15.88 49.48-1.65-11.45-.61-23.13 1.55-34.44 4.73-24.43 17.11-46.74 32.68-65.9z"
                opacity="1"
              ></path>
              <path
                d="M368.82 137.03c-.56-13.44-.62-27.04 2.36-40.24 5.49 10.36 12.6 19.83 21.2 27.81 15.7 15.51 35.11 26.7 50.63 42.41 16.56 15.38 26.34 37.88 25.28 60.57-3.16-10.47-7.81-20.6-14.83-29.06-15.74-19.62-38.9-30.94-57.07-47.92-6.87-6.1-12.58-13.32-17.84-20.82-.68 14.53-1.42 29.06-2.4 43.57-4.42-11.6-6.07-24.03-7.33-36.32z"
                opacity="1"
              ></path>
              <path
                d="M331.65 185.22c32.53 13.1 68.87 9.95 101.73 21.82 12.22 4.19 23.67 12.08 29.95 23.6 1.05 1.96.42 4.28.52 6.4-8.1-11.36-19.51-20.67-32.98-24.72-17.46-5.34-35.95-3.94-53.92-3.25-4.51-.22-9.02-.66-13.53-.82 5.6 8.14 13.75 14 21.92 19.33 6.11 3.81 12.32 7.59 19.07 10.18-14.87 1.05-29.65-4.69-41.07-14.03-13.1-10.53-22.01-25.02-31.69-38.51z"
                opacity="1"
              ></path>
            </g>
            <path
              style={{ lineHeight: "1.25" }}
              fill="#000"
              stroke="none"
              strokeWidth="1.461"
              d="M-65.94 627.902v46.274h37.577l-42.569 151.39v33.563H31.021v-46.274h-41.253l41.253-138.68v-46.273zm132.17 0L38.115 859.13h57.281l3.286-41.56h19.838l2.76 41.56h56.624l-31.662-231.227zm121.133 0V859.13h48.35V706.596l20.363 152.533h34.291l19.313-156.104V859.13h48.347V627.902H285.9l-12.613 107.973-7.621-58.844c-2.365-18.852-4.597-35.227-6.7-49.129zm-78.828 51.702c2.803 38.466 5.606 70.79 8.408 96.974H98.025c1.402-20.47 4.905-52.795 10.51-96.974z"
              fontFamily="sans-serif"
              fontSize="210.404"
              fontStyle="normal"
              fontWeight="normal"
              transform="matrix(.75 0 0 .75 55.777 -219.798)"
            ></path>
            <path
              style={{ lineHeight: "1.25" }}
              fill="#ef3340"
              stroke="none"
              strokeWidth="1.461"
              d="M764.297 634.508v231.228h95.908v-46.275h-40.598v-50.844h34.553V724.63h-34.553V680.78h36.918v-46.273z"
              fontFamily="sans-serif"
              fontSize="210.404"
              fontStyle="normal"
              fontWeight="normal"
              transform="matrix(.75 0 0 .75 55.777 -219.798)"
            ></path>
            <path
              style={{ lineHeight: "1.25" }}
              fill="#fcad56"
              stroke="none"
              strokeWidth="1.461"
              d="M678.242 629.652c-12 0-22.641 2.238-31.926 6.713-9.196 4.38-16.422 10.425-21.677 18.137-5.168 7.712-8.277 15.758-9.328 24.137-1.052 8.283-1.577 20.757-1.577 37.42v67.554c0 22.661 1.708 39.656 5.123 50.987 3.416 11.235 10.379 20.042 20.89 26.421 10.51 6.38 23.868 9.57 40.071 9.57 15.59 0 28.686-3.76 39.284-11.282 10.685-7.522 17.561-16.282 20.627-26.28 3.153-9.997 4.728-26.945 4.728-50.843v-8.998h-55.31v28.992c0 12.663-.657 20.709-1.971 24.136-1.226 3.428-3.897 5.143-8.014 5.143-4.292 0-7.051-1.715-8.277-5.143-1.226-3.427-1.84-10.758-1.84-21.994V696.92c0-11.711.614-19.329 1.84-22.852 1.226-3.523 4.115-5.283 8.67-5.283 4.029 0 6.613 1.476 7.752 4.428 1.226 2.856 1.84 10.14 1.84 21.851v40.276h55.31v-21.994c0-21.614-1.664-37.516-4.992-47.703-3.328-10.284-10.29-18.852-20.889-25.707-10.598-6.856-24.043-10.284-40.334-10.284z"
              fontFamily="sans-serif"
              fontSize="210.404"
              fontStyle="normal"
              fontWeight="normal"
              transform="matrix(.75 0 0 .75 55.777 -219.798)"
            ></path>
          </g>
          <text
            xmlSpace="preserve"
            style={{ lineHeight: "1.25" }}
            x="-54.511"
            y="258.626"
            strokeWidth="0.392"
            fontFamily="sans-serif"
            fontSize="25.065"
          >
            <tspan
              style={{
                InkscapeFontSpecification: "'Apple SD Gothic Neo, Normal'",
                fontVariantLigatures: "normal",
                fontVariantCaps: "normal",
                fontVariantNumeric: "normal",
                fontVariantEastAsian: "normal",
              }}
              x="-54.511"
              y="258.626"
              strokeWidth="0.392"
              fontFamily="Apple SD Gothic Neo"
              fontSize="23.065"
              fontStretch="normal"
              fontStyle="normal"
              fontVariant="normal"
              fontWeight="normal"
            >
              INTEGRATED DIGITAL PLATFORM
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}

export default Logo;