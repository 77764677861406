import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import * as yup from 'yup';
import { createPendingEntity, createPendingEntityReset } from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';

const AddPendingEntityModal = ({ isOpen, setIsOpen, type, typeDisabled, successFunction }) => {

  const [openModal, setOpenModal] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState("");
  const [entity_type, setEntityType] = useState(type);
  const dispatch = useDispatch();

  useEffect(() => {
    setOpenModal(isOpen);
    formik.resetForm();
  }, [isOpen]);

  useEffect(() => {
    setEntityType(type);
  }, [type]);


  // Close Modal
  const closeModal = () => {
    setIsOpen(false);
  };

  const addPendingEntitySchema = yup.object().shape({
    entity_email_address: yup.string().required('Company Email Address is Required.'),
    entity_contact_number: yup.string().required('Company Contact Number is Required'),
    entity_name: yup.string().required('Company Name is Required.'),
  });

  const formik = useFormik({
    initialValues: {
      entity_email_address: "",
      entity_contact_number: '',
      entity_name: '',
      type: entity_type,
    },
    validationSchema: addPendingEntitySchema,
    onSubmit: async (values) => {
      setError("");
      setLoading(true);
      try
      {
        const pendingEntityParams = {
          entity_email_address: values.entity_email_address,
          entity_contact_number: values.entity_contact_number,
          entity_name: values.entity_name,
          type: entity_type,
        };

        await dispatch(createPendingEntity(pendingEntityParams));
        // Reset Create Pending Entity State
        dispatch(createPendingEntityReset());
        // successFunction();
        closeModal();

      } catch (error)
      {
        if (error.response)
        {
          if (error.response.status === 400)
          {
            setError(error.response.data.message);
          }
          else if (error.response.status === 500)
          {
            setError("Could Not Create Pending Entity.");
          }
        }
        else
        {
          setError("Failed to Add Entity");
        }
      } finally
      {
        setLoading(false);
      }
    },
  });

  return (
    <>
      {
        openModal && (
          <>
            <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
              <div className="fixed inset-0 bg-black opacity-50"></div>
              {/* Modal content goes here */}
              <div className="my-8 relative p-4 bg-white lg:w-5/12 w-5/6 rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto">
                {/* Add your modal content here */}
                <div class="flex justify-center items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                  <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
                    Add an Entity
                  </h3>
                  <button type="button" onClick={closeModal} class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    <span class="sr-only">Close modal</span>
                  </button>
                </div>
                <form className="space-y-4 md:space-y-6" onSubmit={formik.handleSubmit}>
                  <p className='text-sm text-center font-semibold text-gray-600 dark:text-white'>(For Use on Entities to that do not Want to Be Onboarded to the System) </p>
                  <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                    <div className="mb-6">
                      <label htmlFor="entity_name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company Name</label>
                      <input
                        type="text"
                        name="entity_name"
                        value={formik.values.entity_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      />
                      {formik.touched.entity_name && formik.errors.entity_name && (
                        <p className="text-red-500 text-sm mt-1">{formik.errors.entity_name}</p>
                      )}
                    </div>
                  </div>
                  <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                    <div className="mb-6">
                      <label htmlFor="entity_email_address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Company Email Address</label>
                      <input
                        type="text"
                        name="entity_email_address"
                        value={formik.values.entity_email_address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      />
                      {formik.touched.entity_email_address && formik.errors.entity_email_address && (
                        <p className="text-red-500 text-sm mt-1">{formik.errors.entity_email_address}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    <label htmlFor="entity_contact_number" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Company Contact Number
                    </label>
                    <PhoneInput
                      country={'zm'}
                      value={formik.values.entity_contact_number}
                      onChange={(mobile) => formik.setFieldValue('entity_contact_number', mobile)}
                      inputClass="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      containerClass="w-full"
                      buttonClass="dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      required
                    />
                    {formik.touched.entity_contact_number && formik.errors.entity_contact_number ? (
                      <div className="p-2 mb-4 text-sm text-red-800 dark:text-red-400">{formik.errors.entity_contact_number}</div>
                    ) : null}
                  </div>
                  <div className="container grid grid-cols-1 gap-6 sm:grid-cols-1 lg:grid-cols-1">
                    <div className="mb-6">
                      <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Membership Type</label>
                      <input
                        type="text"
                        name="type"
                        value={formik.values.type.replace(/_/g, ' ').toUpperCase()}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        disabled={typeDisabled}
                        className="cursor-not-allowed bg-gray-100 border border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                      />
                      {formik.touched.type && formik.errors.type && (
                        <p className="text-red-500 text-sm mt-1">{formik.errors.type}</p>
                      )}
                    </div>
                  </div>
                  {error && error.length > 0 && (
                    <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                      <span className="font-medium">Error: </span>
                      {error}
                    </div>
                  )}
                  {/* Close button */}
                  <div className="flex space-x-4">
                    <button
                      type="button"
                      onClick={closeModal}
                      className="mt-4 text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
                    >
                      Close Modal
                    </button>
                    <button
                      type="submit"
                      //   disabled={!formik.isValid}
                      className="text-white mt-4 bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-primary-800 mr-2 mb-2"
                    >

                      {formik.isSubmitting ? 'Adding Entity...' : 'Add Entity'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </>
        )
      }
    </>
  );
};

export default AddPendingEntityModal;