import React, { useEffect, useState } from 'react';

const YesNoModal = ({ isOpen, closeFunction, modalTitle, modalMessage, cancelButtonLabel, agreeButtonLabel, agreeClickFunction, modalLoading, modalError, messageHint }) => {

    const [openModal, setOpenModal] = useState(false);
    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setIsloading] = useState(false);
    const [error, setError] = useState("");
    const [additional_message, setAdditionalMessage] = useState(messageHint);

    useEffect(() => {
        setOpenModal(isOpen);
        setMessage(modalMessage);
        setTitle(modalTitle);
        setAdditionalMessage(messageHint);
    }, [isOpen, modalTitle, modalMessage, messageHint]);

    useEffect(() => {
        setIsloading(modalLoading);
    }, [modalLoading]);

    useEffect(() => {
        setError(modalError);
    }, [modalError]);

    const closeModal = () => {
        closeFunction();
    };

    return (
        <>
            {
                openModal && (
                    <>
                        <div className="fixed inset-0 flex items-center justify-center z-50 overflow-y-auto">
                            <div className="fixed inset-0 bg-black opacity-50"></div>
                            {/* Modal content goes here */}
                            <div className="my-8 relative p-4 bg-white lg:w-4/12 w-5/6 rounded-lg shadow dark:bg-gray-800 md:p-8 max-h-screen overflow-y-auto">
                                {/* Add your modal content here */}
                                <div className="flex justify-between items-center pb-4 mb-4 rounded-t border-b sm:mb-5 dark:border-gray-600">
                                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                                        {title}
                                    </h3>
                                    {
                                        !loading && (
                                            <button type="button" onClick={closeModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="updateProductModal">
                                                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                                <span className="sr-only">Close modal</span>
                                            </button>
                                        )
                                    }
                                </div>
                                {error && error.length > 0 && (
                                    <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                        <span className="font-medium">Error: </span>
                                        {error}
                                    </div>
                                )}
                                <div className='flex flex-col'>
                                    <span className='text-gray-700 text-xl leading-6'>{message}</span>
                                    <span className='text-gray-600 text-sm leading-6 mt-4'>{additional_message}</span>
                                </div>
                                <div className='flex flex-row items-center justify-between mt-8 px-6 m-auto'>
                                    <button type="button" onClick={closeModal} disabled={loading} className={`${loading ? "cursor-not-allowed" : "cursor-pointer"} px-6 bg-red-700 text-white hover:bg-red-900 rounded-lg text-sm p-1.5  items-center dark:hover:bg-gray-600 dark:hover:text-white`} data-modal-toggle="updateProductModal">
                                        {cancelButtonLabel}
                                    </button>
                                    <button type="button" onClick={agreeClickFunction} disabled={loading} className={`${loading ? "cursor-not-allowed" : "cursor-pointer"} px-6 bg-green-600 text-white hover:bg-green-800  rounded-lg text-sm p-1.5 items-center dark:hover:bg-gray-600 dark:hover:text-white`} data-modal-toggle="updateProductModal">
                                        {agreeButtonLabel}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        </>
    );
};

export default YesNoModal;