import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../components/Logo';

const RegistrationComplete = () => {
  const navigate = useNavigate();

  const account = JSON.parse(localStorage.getItem('account'));
  const profile = account.profiles.find((profile) => profile.account_id === account.id);

  const profileName = profile.name;
  //const profileType = profile.type;
  //const profileStatus = profile.status;

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  }
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <Logo />
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center">
            <h1 className="mb-4 text-3xl tracking-tight font-extrabold lg:text-4xl text-primary-600 dark:text-primary-500">Registration Under Review</h1>
            <p className="mb-4 text-2xl tracking-tight font-bold text-gray-900 md:text-2xl dark:text-white">Your ZAMACE registration is being verified.</p>
            <p className="mb-4 text-lg font-light text-gray-500 dark:text-gray-400">Hello {profileName}. Your ZAMACE registration is currently being verified. As soon as the verification process is complete you will be notified via email. If verification is successful, you will be granted access to the ZAMACE platform. </p>
            <button onClick={handleLogout} className="inline-flex text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:focus:ring-primary-900 my-4">
              Exit ZAMACE Platform
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RegistrationComplete;


