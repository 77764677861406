import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const PublicRoute = ({ element: Component, ...rest }) => {
  const navigate = useNavigate();

  const isAuthenticated = () => {
    const token = localStorage.getItem('authToken');
    const account = localStorage.getItem('account');
    return token && account;
  };

  useEffect(() => {
    if (isAuthenticated())
    {
      navigate("/home");
    }
  }, [navigate]);

  return <Component {...rest} />;
};

export default PublicRoute;
