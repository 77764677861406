// import { div, Font, PDFdiver, div, div, div } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import PDFSVGLogo from '../../components/PDFSVGLogo';
// Import the font files
import LatoRegular from '../../utils/fonts/Lato-Regular.ttf';
import LatoBold from '../../utils/fonts/Lato-Bold.ttf';
import { format } from 'date-fns';
import DynamicNavLogo from '../../components/DynamicNavLogo';
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';

const WarehouseReceiptTradingContract = ({ data, downloadTradingContract, downloadable, setDownloadTradingContract }) => {
    const targetRef = useRef();

    const [contract_data, setContractData] = useState(data);

    //
    useEffect(() => {
        console.log(data);
        setContractData(data);
    }, [data]);
    // Register the Lato font
    // Font.register({ family: 'Lato', fonts: [{ src: LatoRegular }, { src: LatoBold, fontWeight: 'bold' }] });

    useEffect(() => {
        if (downloadable === true && downloadTradingContract === true)
        {
            generatePDF(targetRef, { filename: `${contract_data?.contract?.contract_reference_no} Trading Contract` || "Trading Contract" });
            setDownloadTradingContract(false);
        }
    }, [downloadTradingContract]);

    return (
        <div title="Warehouse Receipt Trading Contract" ref={targetRef}>
            <div size="A4" style={{
                flexDirection: "column",
                backgroundColor: "rgba(0,0,0,0)",
                height: "100%",
                fontFamily: [LatoBold, LatoRegular],
                paddingLeft: 35,
                paddingRight: 35,
                paddingTop: 10,
                paddingBottom: 15,
            }}>
                <div fixed style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", position: "relative", marginBottom: 15 }}>
                    {/* <PDFSVGLogo height="80px" width="300px" /> */}
                    <DynamicNavLogo height='100px' width='200px' />
                    <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 20, }}>Zambian <div style={{ color: "green", marginRight: 3, marginLeft: 2 }}>Commodity</div> Exchange</div>
                </div>
                <div style={{ textAlign: "center", fontWeight: 700, fontSize: 18, textDecoration: "underline" }}>ZAMACE LIMITED ("ZAMBIAN COMMODITY EXCHANGE") WAREHOUSE RECEIPT TRADING CONTRACT</div>
                <div style={{ marginTop: 10 }}>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%", fontSize: 14, alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "50%", marginTop: 5 }}>
                            <div style={{ fontWeight: 500, }}>Contract Date:</div>
                            <div style={{ marginLeft: 3, fontWeight: 600, }}>{contract_data?.contract?.contract_date ? format(new Date(contract_data?.contract?.contract_date), 'yyyy-MM-dd') : "---"}</div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", width: "50%", marginTop: 5 }}>
                            <div style={{ fontWeight: 500, }}>Contract Ref:</div>
                            <div style={{ marginLeft: 3, fontWeight: 600, }}>{contract_data?.contract?.contract_reference_no ? contract_data?.contract?.contract_reference_no : "---"}</div>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "100%", fontSize: 14, marginTop: 10 }}>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
                            <div style={{ fontWeight: 500, }}>Seller:</div>
                            <div style={{ marginLeft: 3, fontWeight: 600, }}>{contract_data?.seller?.entity_name ? contract_data?.seller?.entity_name : "---"}</div>
                        </div>
                    </div>
                    {/* Rules */}
                    <div style={{ marginTop: 5, paddingLeft: 15, paddingRight: 15, paddingTop: 15, }}>
                        <div style={{ display: "flex", flexDirection: "row", fontSize: 14 }}>
                            <div style={{ marginRight: 2 }}>A.</div>
                            <div style={{ marginLeft: 2 }}>
                                These conditions of sale of ZAMACE, read together with the Agricultural Credits Act of 2010, shall be known as the ZAMACE General Conditions of Sale (the "General Conditions") and shall govern all sales / Conveyance of Warehouse Receipts issued by Certified ZAMACE Warehouse Operators.
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", fontSize: 14, marginTop: 10 }}>
                            <div style={{ marginRight: 2 }}>B.</div>
                            <div style={{ marginLeft: 2 }}>
                                The terms of this contract will always be subject to the legislation from time to time, in force governing conveyance of Warehouse Receipts in Zambia.
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", fontSize: 14, marginTop: 10 }}>
                            <div style={{ marginRight: 2 }}>C.</div>
                            <div style={{ marginLeft: 2 }}>
                                In this Agreement words and expressions shall have the meanings assigned to them in the INCOTERMS 2000, as published by the International Chamber of Commerce, Paris except where the context requires otherwise and subject to the context, the plural shall include the singular and the singular the plural and the masculine shall include the feminine and vice versa.
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                            <div style={{ marginRight: 2 }}>1.</div>
                            <div style={{ marginLeft: 2 }}>SELLER</div>
                        </div>
                        <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 5, fontSize: 14 }}> (In own right or acting for holder of the Warehouse Receipt), and BUYER being the only two parties who have any rights whatsoever under this contract have this day entered into a contract whereby the BUYER agrees to buy and the SELLER agrees to sell commodities not necessarily being the SELLER'S own produce, as stipulated on the Warehouse Receipt as follows:
                        </div>
                        <div style={{ padding: 15, fontSize: 14 }}>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ marginRight: 2 }}>1.1</div>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ marginLeft: 2, width: "50%", }}>Warehouse Receipt Number	:</div>
                                    <div style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.receipt_number ? contract_data?.warehouse_receipt?.receipt_number : "---"}</div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div style={{ marginRight: 2 }}>1.2</div>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ marginLeft: 2, width: "50%", }}>Warehouse Operator	:</div>
                                    <div style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.entity_name ? contract_data?.warehouse_receipt?.entity_name : "---"}</div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div style={{ marginRight: 2 }}>1.3</div>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ marginLeft: 2, width: "50%", }}>Warehouse Site	:</div>
                                    <div style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.name ? contract_data?.warehouse_receipt?.name : "---"}</div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div style={{ marginRight: 2 }}>1.4</div>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ marginLeft: 2, width: "50%", }}>Commodity	:</div>
                                    <div style={{ marginLeft: 2, width: "50%", textTransform: "uppercase", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.commodity ? contract_data?.warehouse_receipt?.commodity : "---"}</div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div style={{ marginRight: 2 }}>1.5</div>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ marginLeft: 2, width: "50%", }}>Quality	:</div>
                                    <div style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.broker_offer_data?.quality ? contract_data?.broker_offer_data?.quality : "---"}</div>
                                </div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                <div style={{ marginRight: 2 }}>1.6</div>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                    <div style={{ marginLeft: 2, width: "50%", }}>Quantity	:</div>
                                    <div style={{ marginLeft: 2, width: "50%", fontWeight: 600, }}>{contract_data?.warehouse_receipt?.quantity ? `${parseFloat(contract_data?.warehouse_receipt?.quantity).toFixed(2)} MT` : "---"}</div>
                                </div>
                            </div>
                        </div>
                        <div break style={{ marginTop: 10 }}>
                            <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                <div style={{ marginRight: 2 }}>2.</div>
                                <div style={{ marginLeft: 2 }}>Charges</div>
                            </div>
                            <div style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 15, fontSize: 14 }}>Charges shown upon the face of the Warehouse receipt are to be paid as follows:
                            </div>
                            <div style={{ padding: 15, fontSize: 14 }}>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginRight: 2 }}>2.1</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Warehouse Receipt Issuance</div>
                                        </div>
                                        <div style={{ width: "50%", textAlign: "center" }}>
                                            <div>Account Seller</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginRight: 2 }}>2.2</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Handling In Charge</div>
                                        </div>
                                        <div style={{ width: "50%", textAlign: "center" }}>
                                            <div>Account Seller</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginRight: 2 }}>2.3</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Fumigation and Initial Conditioning costs	  </div>
                                        </div>
                                        <div style={{ width: "50%", textAlign: "center" }}>
                                            <div>Account Seller</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginRight: 2 }}>2.4</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Storage Fees</div>
                                        </div>
                                        <div style={{ width: "50%", textAlign: "center" }}>
                                            <div>Account Seller</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginRight: 2 }}>2.5</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Handling Out</div>
                                        </div>
                                        <div style={{ width: "50%", textAlign: "center" }}>
                                            <div>Account Buyer</div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ marginRight: 2 }}>2.6</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", marginLeft: 2 }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Change of Warehouse Receipt Ownership	</div>
                                        </div>
                                        <div style={{ width: "50%", textAlign: "center" }}>
                                            <div>Account Buyer</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <div style={{ marginRight: 2 }}>3.</div>
                                    <div style={{ marginLeft: 2 }}>Settlement</div>
                                </div>
                            </div>
                            <div style={{ padding: 15, fontSize: 14 }}>
                                <div style={{ marginTop: 5 }}>The buyer and Seller agree to be bound by the ZAMACE Bank Settlement system and undertake to open Bank Accounts in the designated ZAMACE Settlement Bank. They further undertake to settle this transaction through the settlement system. Buyer and Seller acknowledge that transfer of ownership of the Warehouse Receipt will be authorized by ZAMACE upon settlement within the stipulated period i.e. Twenty-Four Hours. It is further agreed that communication between Seller, Buyer and the Exchange is per the Spot trade, Tele-trade phone number supplied by respective Broker/Seller/Buyer.</div>
                                <div style={{ marginTop: 5 }}>The Seller understands that the buyer will deposit the contract value into their Settlement Account held in the ZAMACE Settlement Bank and that ZAMACE will settle all outstanding liens, such as fees incidental to storage, finance and finance costs, before transferring the balance to the seller.</div>
                            </div>
                            <div style={{ marginTop: 10 }}>
                                <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <div style={{ marginRight: 2 }}>4.</div>
                                    <div style={{ marginLeft: 2 }}>Quality, Quality and Location</div>
                                </div>
                            </div>
                            <div style={{ padding: 15, fontSize: 14 }}>
                                <div style={{ marginTop: 5 }}>On the date of transfer of ownership of the Warehouse Receipt, the Buyer immediately takes ownership of the commodity as indicated on the Warehouse Receipt in terms of Quality, Quantity and Location.</div>
                            </div>
                            <div style={{ marginTop: 10 }} break>
                                <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <div style={{ marginRight: 2 }}>5.</div>
                                    <div style={{ marginLeft: 2 }}>Delivery</div>
                                </div>
                            </div>
                            <div style={{ padding: 15, fontSize: 14 }}>
                                <div style={{ marginTop: 10 }}>If need be, Buyer will take delivery of the commodity following transfer of ownership of the Warehouse Receipt to the Buyer by the Seller. No goods covered by this receipt shall be released or ownership transferred, except upon confirmation of change of ownership of the Receipt or removal of encumbrance in the Silo Certs System.</div>
                                <div style={{ marginTop: 10 }}>The ZAMACE Silo Certs system has an “electronic handshake” providing for acceptance of a transferred Warehouse Receipt within 72 hours. Buyer understands that in the event of failure to accept the transfer within the stipulated time, this will result in ownership reverting to the seller.</div>
                                <div style={{ marginTop: 10 }}>If need be, Buyer will take delivery of the commodity following transfer of ownership of the Warehouse Receipt to the Buyer by the Seller. No goods covered by this receipt shall be released or ownership transferred, except upon confirmation of change of ownership of the Receipt or removal of encumbrance in the Silo Certs System.</div>
                                <div style={{ marginTop: 10 }}>The Quantity or Mass as indicated on the Warehouse Receipt and out-loaded by the Warehouse Operator shall be final and shall be accepted by both Seller and Buyer as binding.</div>
                            </div>
                            <div style={{ marginTop: 10 }} >
                                <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <div style={{ marginRight: 2 }}>6.</div>
                                    <div style={{ marginLeft: 2 }}>Price and Payment</div>
                                </div>
                            </div>
                            <div style={{ padding: 15, fontSize: 14 }}>
                                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <div style={{ marginRight: 2 }}>6.1</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ marginLeft: 2 }}>The contract Price is <div style={{ marginLeft: 2, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.broker_bid_data?.bid_volume ? `${parseFloat(contract_data?.broker_bid_data?.bid_volume).toFixed(2)} ZMW` : "---"}</div> per Tonne in respect of ZAMACE Warehouse Receipt Number : <div style={{ marginLeft: 2, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.warehouse_receipt?.receipt_number ? contract_data?.warehouse_receipt?.receipt_number : "---"}</div> </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <div style={{ marginRight: 2 }}>6.2</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ marginLeft: 2 }}>Total consideration for this transaction is thus: <div style={{ marginLeft: 2, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.broker_bid_data?.bid_total ? `${parseFloat(contract_data?.broker_bid_data?.bid_total).toFixed(2)} ZMW` : "---"}</div></div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <div style={{ marginRight: 2 }}>6.3</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ marginLeft: 2 }}>Lodgement fee for placement of position on the ZAMACE Platform is K100 per tone transacted on this receipt thus:  <div style={{ marginLeft: 2, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.broker_bid_data?.bid_volume ? `${parseFloat(parseFloat(contract_data?.broker_bid_data?.bid_volume) * 100).toFixed(2)} ZMW` : "---"}</div></div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: 10 }} break >
                                <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <div style={{ marginRight: 2 }}>7.</div>
                                    <div style={{ marginLeft: 2 }}>Force Majeure</div>
                                </div>
                            </div>
                            <div style={{ padding: 15, fontSize: 14 }}>
                                <div style={{ marginTop: 5 }}>INeither the BUYER nor the SELLER shall be responsible for delay in despatch or delivery of the underlying commodity or any part thereof occasioned by any Act of God, action by any government, strike, lockout, combination of workmen, break-down of machinery, power failure or fire, provided that the party invoking this clause immediately despatches written notice to the other party of the occurrence and supplies, if so requested by the other, satisfactory evidence justifying the delay or non-fulfilment.</div>
                            </div>
                            <div style={{ marginTop: 10 }} >
                                <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <div style={{ marginRight: 2 }}>8.</div>
                                    <div style={{ marginLeft: 2 }}>Notices</div>
                                </div>
                            </div>
                            <div style={{ padding: 15, fontSize: 14 }}>
                                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <div style={{ marginRight: 2 }}>8.1</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ marginLeft: 2 }}>Any notice in respect of this contract shall be communicated in the shortest possible time and in a legible form.</div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", marginTop: 10 }}>
                                    <div style={{ marginRight: 2 }}>8.2</div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ marginLeft: 2 }}>Notices received after 12:00 on a Business Day shall be deemed to have been received on the Business Day following. All such notices shall be given by letter (to be delivered on the day of writing), fax or e-mail. Proof of sending shall be deemed to be proof of receipt.</div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ marginTop: 10 }} >
                                <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <div style={{ marginRight: 2 }}>9.</div>
                                    <div style={{ marginLeft: 2 }}>Business Day/Non-Business Day</div>
                                </div>
                            </div>
                            <div style={{ padding: 15, fontSize: 14 }}>
                                <div style={{ marginTop: 5 }}>A business day, for Trading purposes is the period between 09:00 and 12:00 inclusive on any day other than a non- business day. Saturdays, Sundays and officially recognized national holidays applicable throughout the Zambia and any days which may from time to time be declared as non-business days for specific purposes shall be deemed non-business days for the purpose of passing of notices.</div>
                            </div>

                            <div style={{ marginTop: 10 }} >
                                <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <div style={{ marginRight: 2 }}>10.</div>
                                    <div style={{ marginLeft: 2 }}>Domicile: SELLER and BUYER agree that, for the purposes of any proceedings</div>
                                </div>
                            </div>
                            <div style={{ padding: 15, fontSize: 14 }}>
                                <div style={{ marginTop: 5 }}>This contract shall be deemed to have been made in Zambia and to be performed there, any correspondence in reference to the offer, the acceptance, the place of payment, or otherwise, notwithstanding, and jurisdiction over this transaction is per the law of the Republic of Zambia whatever the domicile, residence or place of business of the parties to this contract may be or become.</div>
                            </div>
                            <div style={{ marginTop: 10 }} break>
                                <div style={{ fontWeight: 600, display: "flex", flexDirection: "row", fontSize: 14 }}>
                                    <div style={{ marginRight: 2 }}>11.</div>
                                    <div style={{ marginLeft: 2 }}>Special Terms</div>
                                </div>
                                <div style={{ padding: 15, fontSize: 14, textDecoration: "underline", fontWeight: 600 }}>{contract_data?.broker_offer_data?.special_terms_and_conditions ? contract_data?.broker_offer_data?.special_terms_and_conditions : "---"}</div>
                            </div>
                            <div style={{ marginTop: 10, fontSize: 14 }}>
                                <div>
                                    <div><div style={{ fontWeight: 600 }}>IN WITNESS WHEREOF</div> the parties have caused this agreement to be executed by their duly appointed representatives</div>
                                </div>
                                <div>
                                    <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>The Seller:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5, fontWeight: 600 }}>{contract_data?.seller?.entity_name ? contract_data?.seller?.entity_name : "---"}</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Signature:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5 }}>____________________________________</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>As Witness:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5 }}>____________________________________</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Occupation:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5 }}>____________________________________</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Signature:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5 }}>____________________________________</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 10, marginBottom: 10, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div>Signed at_________________this_________________day of______________________20___</div>
                                    </div>
                                    <div style={{ marginTop: 25, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>The Buyer:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5, fontWeight: 600 }}>{contract_data?.buyer_data?.entity_name ? contract_data?.buyer_data?.entity_name : "---"}</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Signature:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5 }}>____________________________________</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>As Witness:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5 }}>____________________________________</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Occupation:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5 }}>____________________________________</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div style={{ width: "50%" }}>
                                            <div>Signature:</div>
                                        </div>
                                        <div style={{ width: "50%" }}>
                                            <div style={{ marginTop: 5 }}>____________________________________</div>
                                        </div>
                                    </div>
                                    <div style={{ marginTop: 10, marginBottom: 10, display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
                                        <div>Signed at_________________this_________________day of______________________20___</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    color: "#8094ae",
                    fontSize: 9,
                    position: "relative",
                    bottom: 10,
                    left: 30,
                    right: 0,
                    marginTop: 30
                }} fixed>
                    <div>
                        &copy; {new Date().getFullYear()} Zambia Commodity Exchange Limited.
                    </div>
                    <div>
                        All Rights Reserved.
                    </div>
                </div>

                <div
                    style={{
                        position: "relative",
                        bottom: 15,
                        left: 0,
                        right: 0,
                        textAlign: "center",
                        color: "#252b32",
                    }}
                    render={({ divNumber, totaldivs }) => `div ${divNumber} of ${totaldivs}`}
                    fixed
                />
                <div style={{
                    color: "rgb(75 85 99)",
                    fontSize: 9,
                    position: "relative",
                    bottom: 20,
                    left: 30,
                    right: 0,
                    width: "90%",
                    padding: 15,
                    borderTop: "1px solid #8094ae",
                    paddingTop: 5,
                }} fixed>This div and all the contents herein are the property of ZAMACE Ltd.  No portion of this div may be copied or distributed in any form whatsoever without the written consent of ZAMACE</div>
            </div>
        </div>
    );
};

export default WarehouseReceiptTradingContract;