import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrentAccount, updateEntity } from '../../utils/actions';
import { useNavigate } from 'react-router-dom';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { BuildingOfficeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import 'react-phone-input-2/lib/style.css';
import countries from '../../utils/countries.json';
import provinces from '../../utils/provinces.json';
import NavHeader from '../../layouts/NavHeader';
import Footer from '../../layouts/Footer';
import PhoneInput from 'react-phone-input-2';

const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const EntityKycInformation = ({ entity }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const account = JSON.parse(localStorage.getItem('account'));
    const profile = account.profiles.find((profile) => profile.account_id === account.id);

    const profileName = profile.name;
    const profileType = profile.type;
    const profileStatus = profile.status;

    const [entityData, setEntityData] = useState({
        entity_type: '',
        entity_category: profileType,
        entity_email: '',
        entity_phone: '',
        postal_address: '',
        physical_address: '',
        province: '',
        country: '',
    });

    const validateInputs = (data) => {
        const errors = {};

        // Validate entity_type
        if (!data.entity_type)
        {
            errors.entity_type = 'Please select an organization category';
        }

        // Validate entity_email
        if (!data.entity_email)
        {
            errors.entity_email = 'Please enter the organization email address';
        } else if (!isValidEmail(data.entity_email))
        {
            errors.entity_email = 'Please enter a valid email address';
        }

        // Validate entity_phone
        if (!data.entity_phone)
        {
            errors.entity_phone = 'Please enter the organization phone number';
        }

        // Validate postal_address
        if (!data.postal_address)
        {
            errors.postal_address = 'Please enter the postal address';
        }

        // Validate physical_address
        if (!data.physical_address)
        {
            errors.physical_address = 'Please enter the organization street address';
        }

        // Validate country
        if (!data.country)
        {
            errors.country = 'Please select a country';
        }

        // Validate province
        if (!data.province)
        {
            errors.province = 'Please select a province';
        }

        return errors;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEntityData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handlePhoneChange = (phone) => {
        const phoneNumber = parsePhoneNumberFromString(phone);
        const formattedPhoneNumber = phoneNumber && phoneNumber.isValid() ? phoneNumber.formatInternational() : phone;

        setEntityData((prevEntity) => ({
            ...prevEntity,
            entity_phone: formattedPhoneNumber,
        }));
    };

    useEffect(() => {
        if (entity && entity.entity_phone)
        {
            const phoneNumber = parsePhoneNumberFromString(entity.entity_phone);
            const formattedPhoneNumber = phoneNumber && phoneNumber.isValid() ? phoneNumber.formatNational() : entity.entity_phone;

            setEntityData((prevEntity) => ({
                ...prevEntity,
                entity_phone: formattedPhoneNumber,
            }));
        }
    }, [entity]);

    const handleCountryChange = (e) => {
        const selectedCountry = e.target.value;
        const selectedProvinces = provinces[selectedCountry] || '';

        setEntityData((prevEntity) => ({
            ...prevEntity,
            country: selectedCountry,
            province: selectedProvinces,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate inputs
        const inputErrors = validateInputs(entityData);
        if (Object.keys(inputErrors).length > 0)
        {
            setErrors(inputErrors);
            return;
        }

        try
        {
            setLoading(true);
            await dispatch(updateEntity(entityData));
            await dispatch(getCurrentAccount());
            setLoading(false);
            navigate('/registration_certificate');
        } catch (error)
        {
            if (error.response && error.response.data && error.response.data.errors)
            {
                setErrors(error.response.data.errors);
            } else
            {
                console.error(error);
            }
        } finally
        {
            setLoading(false);
        }
    };

    return (
        <>
            <NavHeader />
            <section className="flex h-screen ">
                <div className="w-full lg:px-9">
                    <div className="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                        <div className="grid w-full place-items-center bg-white p-6 sm:p-16 lg:p-8">
                            <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0">
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        2
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Verify your registration</h3>
                                        <p className="text-sm">Use OTP for verification</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-500 dark:text-gray-400 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-500 rounded-full shrink-0 dark:border-gray-400">
                                        3
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Membership Type</h3>
                                        <p className="text-sm">Set membership type</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-green-600 dark:text-green-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-green-600 rounded-full shrink-0 dark:border-green-500">
                                        4
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Organization Information</h3>
                                        <p className="text-sm">Provide organization details</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        5
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">{profileType == 'cooperative' ? 'Executive Members' : 'Directors & Management'}</h3>
                                        <p className="text-sm">Provide KYC information</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        6
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Add Bank Account</h3>
                                        <p className="text-sm">Provide bank details</p>
                                    </span>
                                </li>
                                <li className="flex items-center text-gray-600 dark:text-gray-500 space-x-2.5">
                                    <span className="flex items-center justify-center w-8 h-8 border border-gray-600 rounded-full shrink-0 dark:border-gray-500">
                                        7
                                    </span>
                                    <span>
                                        <h3 className="font-medium leading-tight">Submit Registration</h3>
                                        <p className="text-sm">Submit registration for verification</p>
                                    </span>
                                </li>
                            </ol>

                            <form onSubmit={handleSubmit}>
                                <br />

                                <div className="flex flex-col items-stretch justify-start border-t border-gray-100">
                                    <h3 className="text-2xl font-bold leading-7 text-green-800 sm:truncate sm:text-2xl sm:tracking-tight">
                                        Registrant Name: {profileName}
                                    </h3>
                                    <br />
                                    <div className="flex flex-row space-x-6">
                                        <div className="flex items-center text-sm text-green-800">
                                            <BuildingOfficeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-800" aria-hidden="true" />
                                            <span className="font-bold uppercase">{profileType}</span>
                                        </div>
                                        <div className="flex items-center text-sm text-red-800">
                                            <InformationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-red-800" aria-hidden="true" />
                                            <span className="font-bold uppercase">{profileStatus}</span>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <ol class="flex items-center w-full text-sm font-medium text-center text-gray-500 dark:text-gray-400 sm:text-base">
                                    <li class="flex md:w-full items-center text-green-600 dark:text-green-500 sm:after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <svg class="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                            </svg>
                                            Contact <span class="hidden sm:inline-flex sm:ml-2">Info</span>
                                        </span>
                                    </li>
                                    <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <span class="mr-2">2</span>
                                            Registration <span class="hidden sm:inline-flex sm:ml-2">Certificate</span>
                                        </span>
                                    </li>
                                    <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <span class="mr-2">3</span>
                                            Registration <span class="hidden sm:inline-flex sm:ml-2">Printout</span>
                                        </span>
                                    </li>
                                    <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <span class="mr-2">4</span>
                                            Tax <span class="hidden sm:inline-flex sm:ml-2">Certificate</span>
                                        </span>
                                    </li>
                                    <li class="flex md:w-full items-center after:content-[''] after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10 dark:after:border-gray-700">
                                        <span class="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200 dark:after:text-gray-500">
                                            <span class="mr-2">5</span>
                                            Company <span class="hidden sm:inline-flex sm:ml-2">Profile</span>
                                        </span>
                                    </li>
                                    <li class="flex items-center">
                                        <span class="mr-2">6</span>
                                        Board <span class="hidden sm:inline-flex sm:ml-2">Resolution</span>
                                    </li>
                                </ol>

                                <div className="grid gap-6 mb-8 lg:grid-cols-4 p-6 sm:p-16 lg:p-8">
                                    <div>
                                        <label htmlFor="entity_type" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Select Organization Category
                                        </label>
                                        <select
                                            name="entity_type"
                                            value={entityData.entity_type}
                                            onChange={handleInputChange}
                                            required
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        >
                                            <option value="">Choose a category</option>
                                            <option value="private">Private Limited Company</option>
                                            <option value="public">Public Company</option>
                                            <option value="cooperative">Cooperative</option>
                                            {profileType === 'cooperative' ? <option value="foundation">Foundation</option> : ""}
                                            <option value="other">Other</option>
                                        </select>
                                        {errors.entity_type && (
                                            <p className="text-red-500 text-xs mt-1">{errors.entity_type[0]}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="entity_email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Organization Email Address
                                        </label>
                                        <input
                                            type="email"
                                            name="entity_email"
                                            value={entityData.entity_email}
                                            onChange={handleInputChange}
                                            required
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        />
                                        {errors.entity_email && <p className="text-red-500 text-xs mt-1">{errors.entity_email}</p>}
                                    </div>
                                    <div>
                                        <label htmlFor="entity_phone" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Organization Primary Phone No.
                                        </label>
                                        <PhoneInput
                                            name="entity_phone"
                                            country={'zm'}
                                            value={entityData.entity_phone}
                                            onChange={handlePhoneChange}
                                            containerClass="w-full"
                                            buttonClass="dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            required
                                        >
                                            <div className="mb-2"></div>
                                        </PhoneInput>

                                        {errors.entity_phone && (
                                            <p className="text-red-500 text-xs mt-1">{errors.entity_phone[0]}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="postal_address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Postal Address (P.O. Box)
                                        </label>
                                        <input
                                            type="text"
                                            name="postal_address"
                                            value={entityData.postal_address}
                                            onChange={handleInputChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="physical_address" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Organization Street Address
                                        </label>
                                        <input
                                            type="text"
                                            name="physical_address"
                                            value={entityData.physical_address}
                                            onChange={handleInputChange}
                                            required
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                        />
                                        {errors.physical_address && (
                                            <p className="text-red-500 text-xs mt-1">{errors.physical_address}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="country" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                            Select Country
                                        </label>
                                        <select
                                            name="country"
                                            value={entityData.country}
                                            onChange={handleCountryChange}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                            required
                                        >
                                            {countries.map((country, index) => (
                                                <option key={index} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.country && (
                                            <p className="text-red-500 text-xs mt-1">{errors.country[0]}</p>
                                        )}
                                    </div>
                                    <div>
                                        {entityData.country === 'Zambia' ? (
                                            <>
                                                <label htmlFor="province" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                    Select Province
                                                </label>
                                                <select
                                                    name="province"
                                                    value={entityData.province}
                                                    onChange={handleInputChange}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                    required
                                                >
                                                    {provinces.Zambia.map((province, index) => (
                                                        <option key={index} value={province}>
                                                            {province}
                                                        </option>
                                                    ))}
                                                </select>
                                                {errors.province && (
                                                    <p className="text-red-500 text-xs mt-1">{errors.province[0]}</p>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <label htmlFor="province" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                    Province / State
                                                </label>
                                                <input
                                                    type="text"
                                                    name="province"
                                                    value={entityData.province}
                                                    onChange={handleInputChange}
                                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-600 focus:border-green-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
                                                />
                                            </>
                                        )}
                                    </div>

                                </div>
                                <button
                                    type="submit"
                                    className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                                >
                                    Next Step: Upload Organization Registration Certificate
                                </button>
                                <br />
                                {Object.entries(errors).map(([field, messages]) => (
                                    <div
                                        key={field}
                                        className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                        role="alert"
                                    >
                                        {Array.isArray(messages) ? (
                                            messages.map((message, idx) => (
                                                <p className="font-medium" key={idx}>
                                                    {field} {message}
                                                </p>
                                            ))
                                        ) : (
                                            <p className="font-medium">
                                                {field} {messages}
                                            </p>
                                        )}
                                    </div>
                                ))}
                                {loading && (
                                    <div className="text-center">
                                        <div role="status">
                                            <svg
                                                aria-hidden="true"
                                                className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                viewBox="0 0 100 101"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                    fill="currentFill"
                                                />
                                            </svg>
                                            <span className="sr-only">Please wait...</span>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default EntityKycInformation;

