import React, { useEffect, useState } from 'react';
import NavLogo from '../../components/NavLogo';
import { useNavigate, useParams } from 'react-router-dom';
import Logo from '../../components/Logo';
import HeaderBanner from '../../layouts/HeaderBanner';
import ChildHeaderBanner from '../../layouts/ChildHeaderBanner';
import { amendWarehouseReceiptStatusReset, amend_warehouse_receipt_Status, generateWarehouseReceipt, generateWarehouseReceiptReset, getEntityProfileList, retreiveSpecificWarehouseReceiptReset, retrieve_specific_warehouse_receipt } from '../../utils/actions';
import { useDispatch, useSelector } from 'react-redux';
import * as app_consts from '../../utils/constants';
import CustomTabs from '../../layouts/CustomTabs';
import { format, parseISO } from 'date-fns';
import TableComp from '../../components/table/TableComp';
import { addThreeDots, capitalizeSentences, convertToTitleCase, replace_string_with_forward_slash } from '../../utils/functionalUtils';
import WarehouseReceiptPDF from '../../layouts/generated_PDFs/WarehouseReceiptPDF';
// import { PDFViewer } from '@react-pdf/renderer';
import YesNoModal from '../../components/modals/YesNoModal';

const SpecificWarehouseReceipt = () => {

    const { entity_id, entity_mid, receipt_number, view } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();


    const [searchTerm, setSearchTerm] = useState("");
    const [profilesList, setProfilesList] = useState([]);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [activeTab, setActiveTab] = useState('Warehouse Receipt Summary');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [warehuose_receipt_data, setWarehouseReceiptData] = useState([]);
    const [receiptGenerationLoading, setReceiptGenerationLoading] = useState(false);
    const [receiptGenerationError, setReceiptGenerationError] = useState("");
    const [receiptGenerationData, setReceiptGenerationData] = useState([]);
    const [receiptGenerationStatus, setReceiptGenerationStatus] = useState(false);
    const [openYesNoModal, setOpenYesNoModal] = useState(false);
    const [openYesNoModalAction, setOpenYesNoModalAction] = useState("");
    const [openYesNoModalLoading, setOpenYesNoModalLoading] = useState("");
    const [openYesNoModalError, setOpenYesNoModalError] = useState("");
    const [updateWarehouseReceiptStatusAction, setUpdateWarehousReceiptStatusAction] = useState("");
    const [downloadWarehouseReceipt, setDownloadWarehouseReceipt] = useState(false);

    const [openDropDown, setOpenDropdown] = useState(false);

    const account = JSON.parse(localStorage.getItem('account'));
    const profiles = account.profiles;

    const tabs = [
        { title: 'Warehouse Receipt Summary', disabled: !isDataFetched },
        { title: 'Depositor Information', disabled: !isDataFetched },
        { title: 'Delivery Information', disabled: !isDataFetched },
        { title: 'Commodity Grading Information', disabled: !isDataFetched },
        { title: 'Warehouse Settlement Information', disabled: !isDataFetched },
        { title: 'Generate Warehouse Receipt', disabled: !isDataFetched },
    ];

    // First Code to Run When Page Opens
    useEffect(() => {
        // Fetch Specific Warehouse Receipt
        dispatch(retrieve_specific_warehouse_receipt(receipt_number));
        fetchProfileList();
    }, []);

    // Fetech Profile List
    const fetchProfileList = async () => {
        try
        {
            dispatch(getEntityProfileList(entity_id));
        } catch (error)
        {
            console.error(error);
        }
    };

    // Retrieve Warehuose Receipt From State
    const warehouse_receipt_listener = useSelector((state) => state.retrieveSpecificWarehouseReceipt);
    // 
    useEffect(() => {
        if (warehouse_receipt_listener.status !== app_consts.IDLE)
        {
            if (warehouse_receipt_listener.isLoading)
            {
                setLoading(true);
            }
            else
            {
                if (warehouse_receipt_listener.error)
                {
                    setError(warehouse_receipt_listener.error);
                }
                else
                {
                    console.log(warehouse_receipt_listener.data);
                    setIsDataFetched(true);
                    setWarehouseReceiptData(warehouse_receipt_listener.data);
                    dispatch(retreiveSpecificWarehouseReceiptReset());
                }
            }
        }
    }, [warehouse_receipt_listener]);

    // Retrieve Profile List from State
    const profile_list = useSelector((state) => state.entityProfilesList);

    useEffect(() => {
        if (profile_list.data) 
        {
            const selectedlist = profile_list.data?.find((profile) => profile.member_type_id === entity_mid);
            setProfilesList(selectedlist);
        }
    }, [profile_list]);

    // Handle Logout
    const handleLogout = () => {
        localStorage.clear();
        navigate("/");
    };

    // Generate Warehouse Receipt
    const generateReceipt = async () => {
        setError("");
        if (warehuose_receipt_data.warehouse_receipt_id)
        {
            setReceiptGenerationLoading(true);
            try
            {
                await dispatch(generateWarehouseReceipt({ warehouse_receipt_id: warehuose_receipt_data.warehouse_receipt_id }));
            }
            catch (error)
            {
                console.log(error);
                if (error.response)
                {
                    if (error.response.status === 500)
                    {
                        setReceiptGenerationError("Failed to Generate Warehouse Receipt.");
                    }
                    else
                    {
                        setReceiptGenerationError(error.response.data.message);
                    }
                }
                else
                {
                    setReceiptGenerationError("Failed to Generate Warehouse Receipt.");
                }
            } finally
            {
                setTimeout(function () {
                    setReceiptGenerationLoading(false);
                }, 500);
            }
        }
    };

    // Listen to Generate Warehouse Receipt State
    const generateReceiptListener = useSelector((state) => state.generateWarehouseReceipt);
    // 
    useEffect(() => {
        if (generateReceiptListener.data)
        {
            setReceiptGenerationData(generateReceiptListener.data);
            dispatch(generateWarehouseReceiptReset());
            setReceiptGenerationStatus(true);
            dispatch(retrieve_specific_warehouse_receipt(receipt_number));
        }
    }, [generateReceiptListener]);

    // URL Prefixes
    const warehouseUrlPrefix = () => {
        if (view)
        {
            return 'warehouse/view';
        }
        else
        {
            return 'warehouse';
        }
    };

    const openYesNoModalOpenFunction = (action, transaction_action) => {
        setOpenYesNoModalAction(action);
        setOpenYesNoModal(true);
        setOpenDropdown(false);
        setOpenYesNoModalError("");
        setUpdateWarehousReceiptStatusAction(transaction_action);
    };

    const openYesNoModalCloseFunction = () => {
        setOpenYesNoModalAction("");
        setOpenYesNoModal(false);
        setOpenYesNoModalError("");
        setUpdateWarehousReceiptStatusAction("");
    };

    // Update Warehouse Receipt Status
    const updateWarehouseStatusReceiptAction = () => {
        setOpenYesNoModalError("");
        dispatch(amend_warehouse_receipt_Status({ receipt_number: receipt_number, status: updateWarehouseReceiptStatusAction }));
    };
    // Listen to Update Warehuose State
    const amendWarehouseReceiptStatus = useSelector((state) => state.amendWarehouseReceiptStatus);
    // 
    useEffect(() => {
        if (amendWarehouseReceiptStatus.status !== app_consts.IDLE)
        {
            if (amendWarehouseReceiptStatus.isLoading)
            {
                setOpenYesNoModalLoading(true);
            }
            else
            {
                setOpenYesNoModalLoading(false);
                if (amendWarehouseReceiptStatus.error)
                {
                    setOpenYesNoModalError(amendWarehouseReceiptStatus.message);
                }
                else
                {
                    openYesNoModalCloseFunction();
                    dispatch(retrieve_specific_warehouse_receipt(receipt_number));
                }
                dispatch(amendWarehouseReceiptStatusReset());
            }
        }
    }, [amendWarehouseReceiptStatus]);

    return (
        <>
            <div>
                <button data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar" aria-controls="logo-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                    <span className="sr-only">Open sidebar</span>
                    <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                    </svg>
                    <NavLogo />
                </button>

                <aside id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
                    <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                        <a href="/" className="flex items-center pl-2.5 mb-5">
                            <Logo /></a>

                        <ul className="space-y-2 font-medium relative">
                            <li>
                                <a href="/home" className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                    <svg class="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6.143 1H1.857A.857.857 0 0 0 1 1.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 6.143V1.857A.857.857 0 0 0 6.143 1Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 17 6.143V1.857A.857.857 0 0 0 16.143 1Zm-10 10H1.857a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286A.857.857 0 0 0 7 16.143v-4.286A.857.857 0 0 0 6.143 11Zm10 0h-4.286a.857.857 0 0 0-.857.857v4.286c0 .473.384.857.857.857h4.286a.857.857 0 0 0 .857-.857v-4.286a.857.857 0 0 0-.857-.857Z" />
                                    </svg>
                                    <span className="ml-3">My Dashboards</span>
                                </a>
                            </li>
                            <li>
                                <a href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 21v-8.25M15.75 21v-8.25M8.25 21v-8.25M3 9l9-6 9 6m-1.5 12V10.332A48.36 48.36 0 0012 9.75c-2.551 0-5.056.2-7.5.582V21M3 21h18M12 6.75h.008v.008H12V6.75z" />
                                    </svg>


                                    <span className="ml-3">Warehouse Dashboard</span>
                                </a>
                            </li>
                            <li>
                                <a href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/locations`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                    </svg>
                                    <span className="ml-3">Warehouse Locations</span>
                                </a>
                            </li>
                            <li>
                                {/* <a className="cursor-not-allowed flex items-center p-2 text-gray-400 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"> */}
                                <a href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/storage`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                                    </svg>
                                    <span className="ml-3">Warehouse Storage</span>
                                </a>
                            </li>
                            <li>
                                <a href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/inbound_shipments`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                                    </svg>
                                    <span className="ml-3">Inbound Shipments</span>
                                </a>
                            </li>
                            <li>
                                <a href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/receipts`} className="flex bg-white text-green-600  items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M9 14.25l6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0111.186 0c1.1.128 1.907 1.077 1.907 2.185zM9.75 9h.008v.008H9.75V9zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm4.125 4.5h.008v.008h-.008V13.5zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
                                    </svg>

                                    <span className="ml-3">Warehouse Receipts</span>
                                </a>
                            </li>
                            <li>
                                <a href={`/${warehouseUrlPrefix()}/${entity_id}/${entity_mid}/trade_fulfillments`} className="flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75" />
                                    </svg>

                                    <span className="ml-3">Trade Fulfillments</span>
                                </a>
                            </li>
                            <li>
                                <a className="cursor-not-allowed flex items-center p-2 text-gray-400 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                                    </svg>

                                    <span className="ml-3">Warehouse Reports</span>
                                </a>
                            </li>v
                            <li className="my-2 fixed bottom-12 w-11/12">
                                <a onClick={handleLogout} className="cursor-pointer flex items-center p-2 text-gray-600 hover:text-green-600 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                    <svg class="w-6 h-6 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 8h11m0 0-4-4m4 4-4 4m-5 3H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h3" />
                                    </svg>
                                    <span className="flex-1 ml-3 whitespace-nowrap">Sign Out</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </aside>


                <div className="p-4 sm:ml-64 mb-8">
                    <div className="p-4 rounded-lg dark:border-gray-700">
                        <ChildHeaderBanner title="Warehouse Receipt Management" header_addition={profilesList && profilesList.entity_name ? profilesList.entity_name : ""} type={app_consts.SPECIFIC_WAREHOUSE_RECEIPT} navInfo={{ entity_id, entity_mid, receipt_number }} home={`${warehouseUrlPrefix()}/${entity_id}/${entity_mid}`} />


                        <div className="relative ">
                            <CustomTabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                            <br />
                            <div className='flex flex-row items-cent justify-end mr-4'>
                                {
                                    account.profiles[0].roles.includes("administrator") && warehuose_receipt_data && warehuose_receipt_data?.status && view && (
                                        <>
                                            <div className='relative flex flex-column'>
                                                <button className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800" type="button" onClick={() => setOpenDropdown(!openDropDown)}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        className="w-5 h-5 mr-2"
                                                    >
                                                        <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
                                                        <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
                                                    </svg>
                                                    Update Issued Receipt Status
                                                </button>

                                                <div className={`z-10 ${openDropDown ? "absolute right-0 origin-top-right mt-12 right-6" : "hidden"} w-full bg-gray-100 divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700 border-1 border-gray-300`}>
                                                    <ul className=" border-1 font-bold border-gray-200 rounded-lg text-sm text-gray-800 dark:text-gray-200" aria-labelledby="multiLevelDropdownButton">
                                                        {
                                                            warehuose_receipt_data?.status !== app_consts.APPROVED && (
                                                                <li>
                                                                    <span onClick={() => openYesNoModalOpenFunction("Approved", app_consts.APPROVED)} className="hover:bg-gray-300 cursor-pointer block px-4 py-4 dark:hover:bg-gray-600 dark:hover:text-white">Approved</span>
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            warehuose_receipt_data?.status !== app_consts.PENDING && (
                                                                <li>
                                                                    <span onClick={() => openYesNoModalOpenFunction("Pending", app_consts.PENDING)} className="hover:bg-gray-300 cursor-pointer block px-4 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Pending</span>
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            warehuose_receipt_data?.status !== app_consts.EXPIRED && (
                                                                <li>
                                                                    <span onClick={() => openYesNoModalOpenFunction("Expired", app_consts.EXPIRED)} className="hover:bg-gray-300 cursor-pointer block px-4 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Expired</span>
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            warehuose_receipt_data?.status !== app_consts.CANCELLED && (
                                                                <li>
                                                                    <span onClick={() => openYesNoModalOpenFunction("Cancelled", app_consts.CANCELLED)} className="hover:bg-gray-300 cursor-pointer block px-4 py-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Cancelled</span>
                                                                </li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                            {activeTab === 'Warehouse Receipt Summary' &&
                                <>
                                    <div>
                                        <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                            <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                <div>
                                                    <h5 class="mr-3 font-semibold dark:text-white">Warehouse Receipt Summary</h5>
                                                </div>
                                            </div>
                                            <dl className="grid grid-cols-2 gap-4 mb-4">
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Receipt Serial Number</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.receipt_number ? warehuose_receipt_data.receipt_number : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.commodity ? warehuose_receipt_data.commodity.replace(/_/g, ' ').toUpperCase() : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Storage Type</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.storage_type ? addThreeDots(capitalizeSentences(replace_string_with_forward_slash(convertToTitleCase(warehuose_receipt_data.storage_type)))) : '-'}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Description of Storage Loaction</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.detailed_storage_description ? warehuose_receipt_data.detailed_storage_description : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Date of Deposit</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.warehouse_date_of_deposit ? format(parseISO(warehuose_receipt_data?.warehouse_date_of_deposit), "yyyy-MM-dd HH:mm:ss") : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Maturity Date</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.expiry_date ? format(parseISO(warehuose_receipt_data.expiry_date), "yyyy-MM-dd HH:mm:ss") : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Owner ID</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.owner_id ? warehuose_receipt_data.owner_id : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Owner Name</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.owner_name ? warehuose_receipt_data.owner_name : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Owner Address</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.owner_address ? warehuose_receipt_data.owner_address : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="flex flex-row items-center mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity Quantity <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span></dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.quantity ? parseFloat(warehuose_receipt_data.quantity).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Warehouse Receipt Status</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        <span className='bg-yellow-100 text-yellow-800'>{warehuose_receipt_data?.status ? warehuose_receipt_data.status.toUpperCase() : "---"}</span>
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Warehouse Receipt Origin</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        <span className='bg-green-100 text-green-800'>{warehuose_receipt_data?.warehouse_receipt_origin ? warehuose_receipt_data.warehouse_receipt_origin.replace(/_/g, ' ').toUpperCase() : "---"}</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                    <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                        <div>
                                            <h5 class="mr-3 font-semibold dark:text-white">Warehouse Receipt History</h5>
                                        </div>
                                    </div>
                                    <TableComp tableData={warehuose_receipt_data?.warehouse_receipt_history} tableType={app_consts.WAREHOUSE_RECEIPT_TABLE} />
                                    {/* // warehouse_receipt_history: warehouse_receipt.history,
                                // receipt_generation_count: warehouse_receipt.receipt_generation_count , 
                                // receipt_generation_history: warehouse_receipt.receipt_generation_history ,  */}
                                </>
                            }
                            {activeTab === 'Depositor Information' &&
                                <>
                                    <div>
                                        <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                            <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                <div>
                                                    <h5 class="mr-3 font-semibold dark:text-white">Depositor Information</h5>
                                                </div>
                                            </div>
                                            <dl className="grid grid-cols-2 gap-4 mb-4">
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Producer ID</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.producer_unique_id ? warehuose_receipt_data.producer_unique_id : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Depositor Name</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data.producer_type ? <>{warehuose_receipt_data.producer_type !== app_consts.INDIVIDUAL.toLowerCase() ? warehuose_receipt_data?.producer_entity_name : warehuose_receipt_data?.producer_contact_name}</> : ""}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Depositor Email Address</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data.producer_type ? <>{warehuose_receipt_data.producer_type !== app_consts.INDIVIDUAL.toLowerCase() ? warehuose_receipt_data?.producer_entity_email : warehuose_receipt_data?.producer_contact_email}</> : ""}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Depositor Mobile</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data.producer_type ? <>{warehuose_receipt_data.producer_type !== app_consts.INDIVIDUAL.toLowerCase() ? warehuose_receipt_data?.producer_entity_phone : warehuose_receipt_data?.producer_contact_phone}</> : ""}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Depositor Address</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data.producer_type ? <>{warehuose_receipt_data.producer_type !== app_consts.INDIVIDUAL.toLowerCase() ? warehuose_receipt_data?.producer_entity_physical_address : warehuose_receipt_data?.producer_contact_address}</> : ""}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Depositor Province</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data.producer_type ? <>{warehuose_receipt_data.producer_type !== app_consts.INDIVIDUAL.toLowerCase() ? warehuose_receipt_data?.producer_entity_province : warehuose_receipt_data?.producer_province}</> : ""}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Depositor Country</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data.producer_type ? <>{warehuose_receipt_data.producer_type !== app_consts.INDIVIDUAL.toLowerCase() ? warehuose_receipt_data?.producer_entity_country : warehuose_receipt_data?.producer_country}</> : ""}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </>
                            }
                            {activeTab === 'Delivery Information' &&
                                <>
                                    <div>
                                        <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                            <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                <div>
                                                    <h5 class="mr-3 font-semibold dark:text-white">Delivery Information</h5>
                                                </div>
                                            </div>
                                            <dl className="grid grid-cols-2 gap-4 mb-4">
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Arrival Time</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_arrival_time ? format(parseISO(warehuose_receipt_data.delivery_arrival_time), "yyyy-MM-dd HH:mm:ss") : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Offloading Time</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_offloading_time ? format(parseISO(warehuose_receipt_data.delivery_offloading_time), "yyyy-MM-dd HH:mm:ss") : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Driver ID </dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_driver_id ? warehuose_receipt_data.delivery_driver_id.toUpperCase() : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Driver Name</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_driver_name ? warehuose_receipt_data.delivery_driver_name.toUpperCase() : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">License Plate</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_license_plate ? warehuose_receipt_data.delivery_license_plate : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Trailer Number</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_trailer_number ? warehuose_receipt_data.delivery_trailer_number : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="flex flex-row items-center mb-2 font-semibold leading-none text-gray-900 dark:text-white">Gross Weight  <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span></dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.gross_weight ? parseFloat(warehuose_receipt_data?.gross_weight).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="flex flex-row items-center mb-2 font-semibold leading-none text-gray-900 dark:text-white">Tare Weight  <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span></dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_tare_weight ? parseFloat(warehuose_receipt_data?.delivery_tare_weight).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="flex flex-row items-center mb-2 font-semibold leading-none text-gray-900 dark:text-white">Net Weight  <span className="block ml-2 text-xs font-medium text-gray-600 dark:text-white">(Metric Tonnes)</span></dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.weiging_net_weight ? parseFloat(warehuose_receipt_data?.weiging_net_weight).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Latitude</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_latitude ? warehuose_receipt_data?.delivery_latitude : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Longitude</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_longitude ? warehuose_receipt_data.delivery_longitude : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Delivery Service Provider Type</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_service_provider_type ? warehuose_receipt_data.delivery_service_provider_type.toUpperCase() : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Delivery Transport Type</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.delivery_transport_type ? warehuose_receipt_data.delivery_transport_type.toUpperCase() : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Delivery Status</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        <span className='bg-yellow-100 text-yellow-800'>{warehuose_receipt_data?.delivery_status ? warehuose_receipt_data.delivery_status.replace(/_/g, ' ').toUpperCase() : "---"}</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </>
                            }
                            {activeTab === 'Commodity Grading Information' &&
                                <>
                                    <div>
                                        <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                            <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                <div>
                                                    <h5 class="mr-3 font-semibold dark:text-white">Commodity Information</h5>
                                                </div>
                                            </div>
                                            <dl className="grid grid-cols-2 gap-4 mb-4">
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.commodity_name ? warehuose_receipt_data.commodity_name.toUpperCase() : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity Description</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.commodity_description ? warehuose_receipt_data.commodity_description : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity Deposit Date</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.commodity_deposit_date ? format(parseISO(warehuose_receipt_data.commodity_deposit_date), "yyyy-MM-dd") : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity Origin</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.commodity_origin ? warehuose_receipt_data.commodity_origin : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity Storage Type</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.commodity_storage_type ? addThreeDots(capitalizeSentences(replace_string_with_forward_slash(convertToTitleCase(warehuose_receipt_data.commodity_storage_type)))) : '-'}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity Quantity</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.commodity_quantity ? parseFloat(warehuose_receipt_data.commodity_quantity).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity Status</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        <span className='bg-yellow-100 text-yellow-800'>{warehuose_receipt_data?.commodity_status ? warehuose_receipt_data.commodity_status.replace(/_/g, ' ').toUpperCase() : "---"}</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                            <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                <div>
                                                    <h5 class="mr-3 font-semibold dark:text-white">Commodity Grading Information</h5>
                                                </div>
                                            </div>
                                            <dl className="grid grid-cols-2 gap-4 mb-4">
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Commodity Grade</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.commodity_grade || warehuose_receipt_data?.commodity_grade == 0 ? warehuose_receipt_data.commodity_grade.toUpperCase() : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Test Density</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.test_density ? warehuose_receipt_data.test_density : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Mositure Content</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.moisture_content ? warehuose_receipt_data.moisture_content : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Extraneous Matter</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.extraneous_matter ? warehuose_receipt_data.extraneous_matter : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Broken Grains</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.broken_grains ? warehuose_receipt_data.broken_grains : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Other Coloured Grains</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.other_coloured_grains ? warehuose_receipt_data.other_coloured_grains : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Total Other Defective Grains</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.total_other_defective_grains ? warehuose_receipt_data.total_other_defective_grains : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Discoloured Grains</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.discoloured_grains ? warehuose_receipt_data.discoloured_grains : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Insect Pest Damaged Grains</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.insect_pest_damaged_grains ? warehuose_receipt_data.insect_pest_damaged_grains : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Diseased Grains</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.diseased_grain ? warehuose_receipt_data.diseased_grain : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Immature Shrivelled Grain</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.immature_shrivelled_grain ? warehuose_receipt_data.immature_shrivelled_grain : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Fungal Damaged Grains</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.fungal_damaged_grains ? warehuose_receipt_data.fungal_damaged_grains : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Germinaated Grains</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.germinated_grains ? warehuose_receipt_data.germinated_grains : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Pass Throuugh 6-35mm Sieve</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.pass_thru_6_35mm_sieve ? warehuose_receipt_data.pass_thru_6_35mm_sieve : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Diplodia</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.diplodia || warehuose_receipt_data?.diplodia == 0 ? warehuose_receipt_data.diplodia : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Fusarium</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.fusarium ? warehuose_receipt_data.fusarium : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Green Beans</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.green_beans ? warehuose_receipt_data.green_beans : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Immature Shrivelled Beans</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.immature_shrivelled_beans ? warehuose_receipt_data.immature_shrivelled_beans : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Oil Content</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.oil_content ? warehuose_receipt_data.oil_content : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Protein Content</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.protein_content ? warehuose_receipt_data.protein_content : "---"}
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </>
                            }
                            {activeTab === 'Warehouse Settlement Information' &&
                                <>
                                    <div>
                                        <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                            <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                <div>
                                                    <h5 class="mr-3 font-semibold dark:text-white">Warehouse Settlement Information</h5>
                                                </div>
                                            </div>
                                            <dl className="grid grid-cols-2 gap-4 mb-4">
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Invoice Number</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.invoice_number ? warehuose_receipt_data.invoice_number : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Handling Fee</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.handling_fee ? parseFloat(warehuose_receipt_data.handling_fee).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Insurance Fee</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.insurance_fee ? parseFloat(warehuose_receipt_data.insurance_fee).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Receipt Issuance Fee</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.receipt_issuance_fee ? parseFloat(warehuose_receipt_data.receipt_issuance_fee).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Storage Fee</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.storage_fee ? parseFloat(warehuose_receipt_data.storage_fee).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Tax Rate</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.tax_rate ? `${parseFloat(warehuose_receipt_data.tax_rate).toFixed(2)}` : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Total Amount</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.total_amount ? parseFloat(warehuose_receipt_data.total_amount).toFixed(2) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Payment Method</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.payment_method ? convertToTitleCase(warehuose_receipt_data.payment_method) : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Payment Reference</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        {warehuose_receipt_data?.payment_reference ? warehuose_receipt_data.payment_reference : "---"}
                                                    </dd>
                                                </div>
                                                <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                    <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Payment Status</dt>
                                                    <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                        <span className='bg-yellow-100 text-yellow-800'>{warehuose_receipt_data?.payment_status ? warehuose_receipt_data.payment_status.toUpperCase() : "---"}</span>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </>
                            }
                            {activeTab === 'Generate Warehouse Receipt' &&
                                <>
                                    {
                                        !view ?
                                            <>
                                                <div>
                                                    <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                                        <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                            <div>
                                                                <h5 class="mr-3 font-semibold dark:text-white">Generate Warehouse Receipt</h5>
                                                            </div>
                                                        </div>
                                                        <div className="flex flex-row items-center justify-center my-8">
                                                            <button
                                                                type="button"
                                                                onClick={generateReceipt}
                                                                disabled={receiptGenerationLoading}
                                                                className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                                            >
                                                                {receiptGenerationLoading ? "Generate Warehouse Receipt..." : "Generate Warehouse Receipt"}
                                                            </button>
                                                        </div>
                                                        {
                                                            receiptGenerationLoading ? <>
                                                                <div className="text-center mt-4 h-10 my-10">
                                                                    <div role="status" className=' flex flex-col items-center justify-center'>
                                                                        <svg
                                                                            aria-hidden="true"
                                                                            className="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-green-600"
                                                                            viewBox="0 0 100 101"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                                                fill="currentColor"
                                                                            />
                                                                            <path
                                                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                                                fill="currentFill"
                                                                            />
                                                                        </svg>
                                                                        <span className="my-2 text-gray-800 font-meduim">Generating Warehouse Receipt...</span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                                :
                                                                <>
                                                                    {
                                                                        receiptGenerationError && receiptGenerationError.length > 0 ?
                                                                            <>
                                                                                <div className="text-center mt-8 h-10 mb-16">
                                                                                    <div role="status" className=' flex flex-col items-center justify-center'>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" className='text-redd-700' viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="w-6 h-6">
                                                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                                                                                        </svg>
                                                                                        <span className="my-2 text-red-600 font-meduim">Error Generating Warehouse Receipt.</span>
                                                                                        <span onClick={generateReceipt} className="cursor-pointer my-2 text-blue-800 underline font-meduim">Retry</span>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {
                                                                                    receiptGenerationData && receiptGenerationStatus && (
                                                                                        <>
                                                                                            <div>
                                                                                                <div className="p-4 sm:ml-10">
                                                                                                    <div className="p-4 rounded-lg dark:border-gray-700">
                                                                                                        <div className="relative">
                                                                                                            <div>
                                                                                                                <div class="relative overflow-hidden bg-white dark:bg-gray-800 sm:rounded-lg">
                                                                                                                    <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                                                                                        <div>
                                                                                                                            <h5 class="mr-3 text-3xl text-gray-800 font-bold dark:text-white">Warehouse Receipt </h5>
                                                                                                                            <p class="text-gray-500 dark:text-gray-400 my-2">Preview and Print Warehouse Receipt</p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="grid grid-cols-1 md:grid-cols-1 mb-4 w-12/12 lg:w-7/12 m-auto overflow-hidden">
                                                                                                                <div className="flex justify-between items-center p-4 bg-gray-100 border-b border-gray-300">
                                                                                                                    <h2 className="text-xl font-bold dark:text-white">Warehouse Receipt</h2>
                                                                                                                    <button
                                                                                                                        onClick={() => setDownloadWarehouseReceipt(true)}
                                                                                                                        type="button"
                                                                                                                        className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
                                                                                                                    >
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                                                                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                                                                                                        </svg>
                                                                                                                        Download

                                                                                                                    </button>
                                                                                                                </div>
                                                                                                                <div className="border border-gray-200 h-auto">
                                                                                                                    {/* <WarehouseReceiptPDF data={receiptGenerationData} /> */}
                                                                                                                    {/* <PDFViewer height="100vh" width="100vh" showToolbar> */}
                                                                                                                </div>
                                                                                                                {/* </PDFViewer> */}
                                                                                                                <div style={{ height: "90vh", width: "100%", overflow: "scroll" }} className='border-2 border-text-gray-400'>
                                                                                                                    {/* <PDFViewer height="100%" width="100%" showToolbar>*/}
                                                                                                                    {
                                                                                                                        receiptGenerationData && (
                                                                                                                            <WarehouseReceiptPDF data={receiptGenerationData} downloadable={true} downloadWarehouseReceipt={downloadWarehouseReceipt} setDownloadWarehouseReceipt={setDownloadWarehouseReceipt} />
                                                                                                                        )
                                                                                                                    }
                                                                                                                    {/* </PDFViewer> */}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                    }
                                                                </>
                                                        }
                                                        <dl className="grid grid-cols-2 gap-4 mb-4 my-2">
                                                            <div className="col-span-2 p-3 bg-gray-100 rounded-lg border border-gray-200 dark:bg-gray-700 sm:col-span-1 dark:border-gray-600">
                                                                <dt className="mb-2 font-semibold leading-none text-gray-900 dark:text-white">Number of Receipts Generated</dt>
                                                                <dd className="flex items-center text-gray-500 dark:text-gray-400">
                                                                    {warehuose_receipt_data?.receipt_generation_count ? warehuose_receipt_data.receipt_generation_count : "---"}
                                                                </dd>
                                                            </div>
                                                        </dl>
                                                        <div class="flex-row items-center justify-between p-4 space-y-3 sm:flex sm:space-y-0 sm:space-x-4">
                                                            <div>
                                                                <h5 class="mr-3 font-semibold dark:text-white">Warehouse Receipt Generation History</h5>
                                                            </div>
                                                        </div>
                                                        <TableComp tableData={warehuose_receipt_data?.receipt_generation_history} tableType={app_consts.WAREHOUSE_RECEIPT_TABLE} />
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="text-center mt-8 h-10">
                                                    <div role="status" className='text-red-600 flex flex-col items-center justify-center mb-4 leading-4'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </div>
                                                    <span colSpan="8" className="text-gray-700 py-4 my-6 tracking-wider">
                                                        Not Available.
                                                    </span>
                                                </div>
                                            </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <YesNoModal isOpen={openYesNoModal} setIsOpen={setOpenYesNoModal} closeFunction={openYesNoModalCloseFunction} modalTitle={`Update Warehouse Receipt Status to ${openYesNoModalAction}?`} modalMessage={`Are you sure that you want to update this Warehouse Receipt Status to ${openYesNoModalAction}?`} cancelButtonLabel="NO" agreeButtonLabel="YES" agreeClickFunction={updateWarehouseStatusReceiptAction} modalError={openYesNoModalError} modalLoading={openYesNoModalLoading} />
        </>
    );
};

export default SpecificWarehouseReceipt;