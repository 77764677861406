import React, { useState } from 'react';

function ProfileCard({ data }) {
    const [showModal, setShowModal] = useState(false);
    const [currentImage, setCurrentImage] = useState("");

    return (
        <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <img
                src={data.profile_image_url}
                alt="Profile"
                className="rounded-full w-20 h-20 mx-auto mb-4 cursor-pointer"
                onClick={() => {
                    setCurrentImage(data.profile_image_url);
                    setShowModal(true);
                }}
            />
            <h1 className="text-xl text-center">{data.first_name} {data.last_name}</h1>
            <p className="text-center text-sm text-gray-500">{data.type}</p>
            <p className="mt-2 text-center text-sm">ID: {data.identification}</p>
            <p className="text-center text-sm">Nationality: {data.nationality}</p>

            <div className="mt-4 flex justify-between">
                <img
                    src={data.id_front_image_url}
                    alt="ID Front"
                    className="w-20 h-20 cursor-pointer"
                    onClick={() => {
                        setCurrentImage(data.id_front_image_url);
                        setShowModal(true);
                    }}
                />
                <img
                    src={data.id_back_image_url}
                    alt="ID Back"
                    className="w-20 h-20 cursor-pointer"
                    onClick={() => {
                        setCurrentImage(data.id_back_image_url);
                        setShowModal(true);
                    }}
                />
            </div>

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
                    <div className="bg-white p-4 rounded-lg max-w-lg max-h-full overflow-auto">
                        <img src={currentImage} alt="Large View" className="w-full h-auto max-h-96" />

                        <button
                            className="mt-4 px-4 py-2 bg-red-500 text-sm text-white rounded"
                            onClick={() => setShowModal(false)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default ProfileCard;